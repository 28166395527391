import { isDefined } from '@monash/portal-frontend-common';

const INDICATOR_GAP = 11;
const SCHEDULE_INDICATOR_GAP = 5;

export const calculateTimePercentage = ({
  startTime,
  endTime,
  currentDate,
}) => {
  // Assessments have same start and end time
  if (startTime === endTime) {
    return 0;
  }

  const startEndDiff = endTime - startTime;
  const currentDiff = currentDate - startTime;
  return (currentDiff / startEndDiff) * 100;
};

export const getTimeIndicatorData = ({ events, currentDate }) => {
  const currentEvents = events
    .map((event, index) => {
      if (currentDate >= event.start.time && currentDate <= event.end.time) {
        const startTime = event.start.time;
        const endTime = event.end.time;

        const timePercentage = calculateTimePercentage({
          startTime,
          endTime,
          currentDate,
        });
        return { index, timePercentage };
      }
      return null;
    })
    .filter(Boolean);

  // Either the current time is within the duration of an event(s),
  // otherwise we display the time indicator above the next event
  if (currentEvents.length) {
    return currentEvents;
  } else {
    const nextEvent = events.reduce(
      (acc, curr, index) => {
        const difference = curr.start.time - currentDate;
        const eventIsPast = difference > 0;
        const lastEventIndex = events.length - 1;
        const isLastEvent = index === events.length - 1;
        const isFinished = isLastEvent && currentDate > curr.end.time;

        if (difference < acc.difference && eventIsPast) {
          return { index, difference, isFinished };
        }
        return {
          ...acc,
          isFinished,
          ...(isFinished && { index: lastEventIndex }),
        };
      },
      { index: null, difference: Infinity, isFinished: false }
    );

    return [
      {
        index: nextEvent.index,
        isFinished: nextEvent.isFinished,
      },
    ];
  }
};

export const getTimeIndicatorStyle = ({
  timePercentage,
  isFinished,
  isSchedule,
}) => {
  const gap = isSchedule ? SCHEDULE_INDICATOR_GAP : INDICATOR_GAP;
  if (isFinished) {
    return { bottom: `-${gap}px` };
  }

  return isDefined(timePercentage)
    ? { top: `${timePercentage}%` }
    : { top: `-${gap}px` };
};
