import { useResponsiveValue } from '@monash/portal-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { getColumnSpan, assignKeyNavItem } from '../utils';
import { widgetDirectory } from '../widgets/widgetDirectory';
import c from './widget-container.module.scss';
import {
  ImpersonationContext,
  deepClone,
  fsDocRef,
  fsGetDoc,
  fsUpdateDoc,
  fsWriteBatch,
  useSessionStorage,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import EditMenu from './edit-menu/EditMenu';
import { WidgetContext } from 'components/providers/WidgetProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import Header from './header/Header';
import WidgetError from './widget-error/WidgetError';
import WIDGET_ERROR_MESSAGE from './widget-error/WidgetErrorMessage';
import classNames from 'classnames';
import { getUseFakeData } from 'components/providers/data-provider/utils';
import DeleteWidgetModal from './in-edit-options/delete-widget-modal/DeleteWidgetModal';
import { ID_SR_PAGE_HEADING, MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import { Page } from 'components/providers/page-provider/PageProvider';
import { Data } from 'components/providers/data-provider/DataProvider';
import WidgetFloatingError from './widget-floating-error/WidgetFloatingError';
import { formatDefaultData } from '../widget-library/utils';

const WidgetContainer = ({
  pageId,
  widgetId,
  widget,
  widgetIndex,
  inEditMode,
  onSelectedPage,
  isOverlay = false,
  newWidgetId,
  setNewWidgetId,
  setWidgetOrder,
  size,
}) => {
  // context
  const { currentUser } = useContext(ImpersonationContext);
  const { setPortalPreferences } = useContext(Data);
  const { pagesData } = useContext(Page);
  const widgetProviderData = useContext(WidgetContext);
  const { setIsGroupKeyNavDisabled } = widgetProviderData;
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);

  // hooks
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';
  const { addSnackbar } = useSnackbar();

  // states
  const [data, setData] = useSessionStorage(`widget:${widgetId}`);
  const [editMenuShown, setEditMenuShown] = useState(null);
  const [isDeletingWidget, setIsDeletingWidget] = useState(false);
  const [isDeleteWidgetModalShown, setIsDeleteWidgetModalShown] =
    useState(false);
  const [deleteWidgetError, setDeleteWidgetError] = useState(false);
  const [error, setError] = useState(null);
  const [floatingError, setFloatingError] = useState(null);
  const [isNew, setIsNew] = useState(false);

  // refs
  const editMenuButtonRef = useRef();
  const deleteWidgetButtonRef = useRef();
  const widgetContainerRef = useRef();
  const widgetRef = useRef();
  const widgetScrollContainerRef = useRef();
  const widgetHeaderRef = useRef();

  // data / mapped data
  const useFakeData = getUseFakeData();
  const WidgetModule = widgetDirectory[widget?.typeId];
  const WidgetComponent = WidgetModule?.component;
  const widgetDataDocPath = `users/${currentUser.uid}/widgets/${widgetId}`;
  const widgetName = data?.name || WidgetModule?.name;

  // dynamic additional options
  const [additionalOptions, setAdditionalOptions] = useState(
    WidgetModule?.additionalOptions
  );

  const additionalActions = WidgetModule?.additionalActions?.map((item) => {
    return {
      ...item,
      onClick: () => widgetProviderData[item.action](widgetId),
    };
  });

  // constants / conditions
  const hasAdditionalOptionsInEditMenu = additionalOptions?.some(
    (item) => item.editType
  );

  const canUserUpdateNoteColour =
    WidgetModule?.name === 'Notes' &&
    additionalOptions?.find((item) => {
      return item.key === 'colour';
    });
  const noteColour = canUserUpdateNoteColour?.options?.find((option) => {
    return option.id === data?.colour;
  });
  const navProps =
    !inEditMode &&
    assignKeyNavItem(widgetIndex, data?.name || WidgetModule?.name);
  const isDeleteWidgetModalShownForDesktop =
    isDeleteWidgetModalShown && !isMobile;

  // classes
  const widgetScrollContainerClasses = classNames(c.scrollContainer, {
    [c.inert]: inEditMode,
  });

  // update widget doc data
  const getAndSetDataInSessionStorage = async () => {
    try {
      const dataDoc = await fsGetDoc(widgetDataDocPath);
      const data = dataDoc.data();
      const defaultData = formatDefaultData(WidgetModule?.additionalOptions);

      // Note: an existing widget without a data doc (i.e. no additional options)
      // will error if additional options are added to its module. Will need to be addressed
      // in future if, for example, we add filters to the important dates widget

      // set data if data doc exists
      if (dataDoc.exists()) {
        // ensure that all option keys defined in the module are present in widget data
        // b/c they can become desynced if devs add new widget options to an existing widget module
        const missingDefaultData = getMissingDefaultData(data, defaultData);

        // if the widget is missing default data in firestore, try to write update the fs doc with the missing data
        if (Object.keys(missingDefaultData).length > 0) {
          try {
            await fsUpdateDoc(widgetDataDocPath, missingDefaultData);
            setData({ ...data, ...missingDefaultData });
            setError(null);
          } catch (error) {
            console.warn(
              `Unable to update firestore with missing default options for widget ${widgetId}`,
              error
            );
            setError(WIDGET_ERROR_MESSAGE.GENERIC);
          }
        }
        // if firestore is not missing data, just use firestore data
        else {
          setData({ ...data });
          setError(null);
        }
      }
      // as of 2 May 2024, using fake data makes you unable to write to firestore,
      // so for now, to make mock widgets not constantly display error,
      // skip default data verification if using fake data
      else if (useFakeData) {
        setData({ ...defaultData });
        setError(null);
      } else {
        console.warn(
          `Widget (id: ${widgetId}) data document from firestore was empty`
        );
        setError(WIDGET_ERROR_MESSAGE.GENERIC);
      }
    } catch (error) {
      console.warn(
        `Couldn't retrieve widget (id: ${widgetId}) data document from firestore`,
        error
      );
      setError(WIDGET_ERROR_MESSAGE.GENERIC);
    }
  };

  // returns missing data for a widget
  // to determine missing data, remove keys from the default data that have already been set in fs
  // data: user's current widget option data from firestore
  // defaultData: widget's default option data
  const getMissingDefaultData = (data, defaultData) => {
    const defaultDataCopy = { ...defaultData };
    for (const key in data) {
      delete defaultDataCopy[key];
    }
    return defaultDataCopy;
  };

  const updateDataKeyValue = (key, value) =>
    setData((d) => {
      return { ...d, [key]: value };
    });

  const updateData = (key, value, onSuccess, optimistic) => {
    if (optimistic) {
      // update UI optimistically without reverting
      updateDataKeyValue(key, value);
    }

    fsUpdateDoc(widgetDataDocPath, {
      [key]: value,
    })
      .then(() => handleUpdateSuccess(key, value, onSuccess, optimistic))
      .catch(handleUpdateError);
  };

  const handleUpdateSuccess = (key, value, onSuccess, optimistic) => {
    if (onSuccess) {
      onSuccess();
    }
    if (!optimistic) {
      updateDataKeyValue(key, value);
    }
  };

  const handleUpdateError = (error) => {
    resetAppLiveMsgs();
    addSnackbar({
      message:
        "We're not able to update widgets right now - please try again later",
      type: 'error',
    });
    console.warn(
      '[updateWidgetData]: api call error, failed to update widget data',
      error
    );
  };

  // delete widget
  const deleteWidget = async () => {
    setIsDeletingWidget(true);
    const batch = fsWriteBatch();

    // pages
    const newPages = deepClone(pagesData);

    // widget order
    newPages.customPages[pageId].widgetOrder.splice(widgetIndex, 1);

    // widgets
    newPages.widgets = Object.entries(pagesData.widgets)
      .filter((widget) => widget[0] !== widgetId)
      .reduce((arr, entry) => ({ ...arr, [entry[0]]: entry[1] }), {});

    if (data) {
      const widgetDoc = fsDocRef(
        `users/${currentUser.uid}/widgets/${widgetId}`
      );
      batch.delete(widgetDoc);
    }

    // update pages
    const preferencesDoc = fsDocRef(`users/${currentUser.uid}`);
    batch.update(preferencesDoc, {
      'preferences.pages': newPages,
    });

    batch
      .commit()
      .then(() => handleDeleteWidgetSuccess(newPages))
      .catch(handleDeleteWidgetError)
      .finally(() => {
        setIsDeletingWidget(false);
        document.querySelector(`#${ID_SR_PAGE_HEADING}`)?.focus();
      });
  };

  const handleDeleteWidgetSuccess = (newPages) => {
    setDeleteWidgetError(false);
    setWidgetOrder(newPages.customPages[pageId].widgetOrder);
    resetAppLiveMsgs();
    setPortalPreferences((f) => {
      addSnackbar({
        message: `${widgetName} widget has been deleted.`,
        type: 'success',
      });
      return { ...f, pages: newPages };
    });
    closeDeleteWidgetModal();
  };

  const handleDeleteWidgetError = (error) => {
    setDeleteWidgetError(true);
    resetAppLiveMsgs();
    addSnackbar({
      message:
        "We're not able to delete widgets right now – please try again later",
      type: 'error',
    });
    console.warn(
      '[updatePortalPreferences]: api call error, failed to delete widget.',
      error
    );
  };

  // Retain focus position when modal closes
  const closeDeleteWidgetModal = () => {
    setIsDeleteWidgetModalShown(false);
    const triggerRef = inEditMode ? deleteWidgetButtonRef : editMenuButtonRef;
    triggerRef?.current?.focus();
  };

  const confirmDeleteWidgetOnMobile = () => {
    const confirmationText = `Delete ${widgetName}\nYour changes will be lost.`;
    if (confirm(confirmationText) === true) {
      deleteWidget();
    }
  };

  // renders

  // render widget card, only pass in refs to the "real" widget card
  const renderWidgetCard = (widgetHeaderRef, editMenuButtonRef) => (
    <div
      className={`${c.widgetCard} ${isOverlay && c.overlay}`}
      style={{
        background: `${noteColour?.value || 'var(--card-bg-color)'}`,
        color: `${noteColour?.contrast}`,
      }}
    >
      <Header
        data={data}
        updateData={updateData}
        WidgetModule={WidgetModule}
        pageId={pageId}
        widgetId={widgetId}
        widgetIndex={widgetIndex}
        widget={widget}
        setEditMenuShown={setEditMenuShown}
        widgetHeaderRef={widgetHeaderRef}
        editMenuButtonRef={editMenuButtonRef}
        additionalOptions={additionalOptions}
        additionalActions={additionalActions}
        inEditMode={inEditMode}
        noteColour={noteColour}
        setWidgetOrder={setWidgetOrder}
        error={error}
        setIsDeleteWidgetModalShown={setIsDeleteWidgetModalShown}
        deleteWidgetButtonRef={deleteWidgetButtonRef}
        widgetScrollContainerRef={widgetScrollContainerRef}
        confirmDeleteWidgetOnMobile={confirmDeleteWidgetOnMobile}
        widgetName={widgetName}
      />
      {error ? (
        <WidgetError message={error} />
      ) : (
        <div
          className={widgetScrollContainerClasses}
          ref={widgetScrollContainerRef}
          inert={inEditMode ? '' : null}
        >
          <div className={c.widget} ref={widgetRef}>
            {WidgetComponent ? (
              <WidgetComponent
                data={data}
                updateData={updateData}
                widgetRef={widgetRef}
                widgetScrollContainerRef={widgetScrollContainerRef}
                onSelectedPage={onSelectedPage}
                escapeSettingActionsFocusRef={editMenuButtonRef}
                inEditMode={inEditMode}
                typeId={widget?.typeId}
                widgetId={widgetId}
                setError={setError}
                setFloatingError={setFloatingError}
                additionalOptions={additionalOptions}
                setAdditionalOptions={setAdditionalOptions}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );

  // effects

  //  get widget data only when widget is on the selected page, requires data and data is not already available in storage
  useEffect(() => {
    if (onSelectedPage && additionalOptions && !data) {
      getAndSetDataInSessionStorage();
    }
  }, [onSelectedPage]);

  // show edit menu if widget is new (desktop only), and remove new widget Id afterwards
  useEffect(() => {
    setEditMenuShown(!isMobile && isNew && hasAdditionalOptionsInEditMenu);
    setNewWidgetId(null);
  }, [isMobile, isNew, hasAdditionalOptionsInEditMenu]);

  // set widget new status when newWidgetId changes
  useEffect(() => {
    if (newWidgetId && newWidgetId === widgetId) {
      // state change to ensure edit menu is rendered with the final widget position
      setIsNew(true);
    }
  }, [newWidgetId]);

  // disable widgets group key nav if edit menu is expanded
  useEffect(() => {
    setIsGroupKeyNavDisabled(!!editMenuShown);
  }, [editMenuShown]);

  return (
    <div
      ref={widgetContainerRef}
      className={`${c.widgetContainer} ${inEditMode ? c.inEditMode : ''} `}
      style={{
        gridColumn: `span ${getColumnSpan(size, widget?.size)}`,
      }}
      data-edit-menu-shown={editMenuShown ? 'true' : 'false'}
      id={`widget-${widgetId}`}
      {...navProps}
    >
      <DeleteWidgetModal
        open={isDeleteWidgetModalShownForDesktop}
        setOpen={setIsDeleteWidgetModalShown}
        deleteWidget={deleteWidget}
        isDeletingWidget={isDeletingWidget}
        deleteWidgetError={deleteWidgetError}
        closeDeleteWidgetModal={closeDeleteWidgetModal}
        widgetName={widgetName}
      />
      {renderWidgetCard(widgetHeaderRef, editMenuButtonRef)}
      {floatingError ? <WidgetFloatingError error={floatingError} /> : null}
      <EditMenu
        isShown={editMenuShown}
        setIsShown={setEditMenuShown}
        widgetId={widgetId}
        widgetIndex={widgetIndex}
        widget={widget}
        widgetHeaderRef={widgetHeaderRef}
        escapeFocusRef={editMenuButtonRef}
        additionalOptions={additionalOptions}
        additionalActions={additionalActions}
        updateData={updateData}
        data={data}
        pageId={pageId}
        widgetName={widgetName}
        setWidgetOrder={setWidgetOrder}
        setIsDeleteWidgetModalShown={setIsDeleteWidgetModalShown}
        renderWidgetCard={renderWidgetCard}
        confirmDeleteWidgetOnMobile={confirmDeleteWidgetOnMobile}
        hasAdditionalOptionsInEditMenu={hasAdditionalOptionsInEditMenu}
      />
    </div>
  );
};

export default WidgetContainer;
