import { isNumber, isStringEmpty } from '@monash/portal-frontend-common';

/**
 * getCustomThemeGroupAriaLabel
 * @param {string} groupTitle - group title text
 * @param {number?} groupIndex - current group index
 * @param {number?} totalGroups - total groups count
 * @returns {string|null}
 */
export const getCustomThemeGroupAriaLabel = (
  groupTitle = '',
  groupIndex,
  totalGroups
) => {
  if (isStringEmpty(groupTitle)) {
    return null;
  }
  let groupIndexText = '';
  if (isNumber(groupIndex) && isNumber(totalGroups)) {
    groupIndexText = ` - ${groupIndex} of ${totalGroups}`;
  }
  return `Custom theme group${groupIndexText}: ${groupTitle}. Use the arrow keys to navigate across the groups and tab key to navigate options.`;
};
