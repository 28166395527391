// availability check

const isAvailable = (type) => {
  const storage = window[type];

  try {
    const test = 'xtestx';
    storage.setItem(test, test);
    storage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

// storage helper

const fn = (type) => {
  return {
    set: (key, value) => {
      if (isAvailable(type)) window[type].setItem(key, JSON.stringify(value));
    },
    get: (key, fallback = null) => {
      if (isAvailable(type)) {
        const data = window[type].getItem(key);
        return data ? JSON.parse(data) : fallback;
      } else {
        return fallback;
      }
    },
  };
};

// storage object

const storage = {
  local: fn('localStorage'),
  session: fn('sessionStorage'),
};

// export

export default storage;
