import React from 'react';
import c from './disclaimer.module.scss';
import { Icon } from '@monash/portal-react';

const Disclaimer = () => {
  return (
    <label className={c.disclaimer}>
      <Icon.Alert size={16} /> Some assessments may not be shown.
    </label>
  );
};

export default Disclaimer;
