import { isNumber } from '@monash/portal-frontend-common';

export const getGradeString = (data, shortenText) => {
  const shouldIncludeDescription = !shortenText && data?.gradeLetterDescription;

  // grade string is by default formatted grade
  let gradeLabelString = data?.gradeFormatted;

  // string should be "grade/gradeMax" when grade is numeric with a point scale type and the formatted grade is a number
  if (
    data?.gradeTypeName === 'NUMERIC' &&
    data?.gradeScaleType === 'POINT' &&
    isNumber(Number(data?.gradeFormatted))
  ) {
    gradeLabelString = data?.grade + '/' + data?.gradeMax;
  }

  return shouldIncludeDescription
    ? `${gradeLabelString} ${data?.gradeLetterDescription}`
    : gradeLabelString;
};
