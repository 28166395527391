import React from 'react';
import c from './error-message.module.scss';
import { Icon, VirtuallyHidden } from '@monash/portal-react';

const ErrorMessage = ({ errorMessage }) => {
  return (
    <div className={c.errorMessage}>
      <VirtuallyHidden>
        <div aria-live="polite" aria-atomic="true" id="Invalid new page name">
          {errorMessage}
        </div>
      </VirtuallyHidden>
      <Icon.Alert size={16} />
      {errorMessage}
      <div className={c.poke} />
    </div>
  );
};

export default ErrorMessage;
