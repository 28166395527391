import React, { useState, useEffect, createContext, useContext } from 'react';
import storage from 'utils/storage';

const ScheduleContext = createContext();

export const ScheduleProvider = ({ children }) => {
  const [expanded, setExpanded] = useState(() => {
    return storage.local.get('scheduleExpanded', true);
  });

  useEffect(() => {
    storage.local.set('scheduleExpanded', expanded);
  }, [expanded]);

  return (
    <ScheduleContext.Provider value={{ expanded, setExpanded }}>
      {children}
    </ScheduleContext.Provider>
  );
};

export const useScheduleContext = () => {
  const context = useContext(ScheduleContext);

  if (!context) {
    throw new Error(
      'useScheduleContext must be used within a ScheduleProvider'
    );
  }

  return context;
};

export default ScheduleProvider;
