import React from 'react';
import { isStringEmpty } from '@monash/portal-frontend-common/dist/utils/string';
import { Icon, Link, VirtuallyHidden } from '@monash/portal-react';
import c from './page-changer.module.scss';

const PageChanger = ({ direction, link, linkName }) => {
  const classNames = [c.pageChanger, c[direction]].join(' ');
  const screenReaderMsg = !isStringEmpty(linkName)
    ? `Navigate to: "${linkName}" page.`
    : '';
  const IconComponent =
    direction === 'right' ? Icon.ChevronRight : Icon.ChevronLeft;

  return (
    <Link to={link} className={classNames}>
      {screenReaderMsg ? <VirtuallyHidden text={screenReaderMsg} /> : null}
      <IconComponent color="white" size="48" />
    </Link>
  );
};

export default PageChanger;
