import c from '../day.module.scss';
import { format } from 'date-fns';
import { CONTAINER_WIDTH } from 'hooks/use-container-width';

const TimeInfo = ({ event, size }) => {
  const formattedStartTime = format(event.start.time, 'h.mmaaa').replace(
    '.00',
    ''
  );
  const formattedEndTime = format(event.end.time, 'h.mmaaa').replace('.00', '');

  return event.eventKind === 'assessment' ? (
    // assessments display their start time
    <span className={c.dueTime}>{formattedStartTime}</span>
  ) : (
    // exams display their start and end times
    <span className={c.timeInterval}>
      {size === CONTAINER_WIDTH.SMALL ? (
        `${formattedStartTime} - ${formattedEndTime}`
      ) : (
        <>
          <span>{formattedStartTime}</span>
          <span>{formattedEndTime}</span>
        </>
      )}
    </span>
  );
};

export default TimeInfo;
