import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon, IconButton } from '@monash/portal-react';
import { isStringEmpty } from '@monash/portal-frontend-common';

const SortableListItem = ({ children, id, itemLabel, ...itemProps }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges: () => false,
  });

  const style = {
    opacity: isDragging ? 0.2 : undefined,
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  const hasItemLabel = !isStringEmpty(itemLabel);
  const itemAriaLabel = `Sort item${hasItemLabel ? `: ${itemLabel}.` : '.'}`;

  return (
    <li ref={setNodeRef} style={style} {...itemProps}>
      <IconButton
        variant="text"
        mode="card"
        aria-label={itemAriaLabel}
        color="var(--card-text-color)"
        size={20}
        icon={Icon.Drag}
        {...attributes}
        {...listeners}
        ref={setActivatorNodeRef}
        tooltipProps={{ open: false }}
      />
      {children}
    </li>
  );
};

export default SortableListItem;
