import classNames from 'classnames';
import c from './stack.module.scss';

const Stack = ({
  gap,
  flexDirection = 'column',
  alignItems,
  children,
  className,
}) => {
  const classes = classNames(c.stack, className);
  return (
    <div className={classes} style={{ gap, flexDirection, alignItems }}>
      {children}
    </div>
  );
};

export default Stack;
