import React from 'react';
import { isStringEmpty } from '@monash/portal-frontend-common/dist/utils/string';
import ThemeSelector from '../theme-selector/ThemeSelector';
import c from './themes-group.module.scss';

const ThemesGroup = ({ category, themes, groupAriaLabel = null }) => {
  return (
    <div
      tabIndex={0}
      className={c.themesGroup}
      data-key-nav-group={`select-theme-row-${category.toLowerCase()}`}
      aria-label={isStringEmpty(groupAriaLabel) ? null : groupAriaLabel}
    >
      <span>{category === 'Colours' ? 'Colorful' : category}</span>
      <div className={c.themes}>
        {themes.map((theme, i) => (
          <ThemeSelector theme={theme} key={i} />
        ))}
      </div>
    </div>
  );
};
export default ThemesGroup;
