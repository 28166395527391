// Local features flags
export const FEATURES = {
  DISPLAY_TIME_GRID: true,
  FORCE_MOCK_API: false,
};

// Feature flags for feature toggles
export const FEATURE_FLAGS = {
  CRITICAL_AND_NON_CRITICAL_NOTIFICATIONS: false,
  GRADES: false,
  UPDATES: false,
};
