export function getEnvironmentValue({ prod, qa, dev }) {
  if (window.origin.includes('localhost')) {
    return dev;
  }

  if (window.origin.includes('-qa.monash')) {
    return qa;
  }

  return prod;
}
