import React, { createContext, useContext } from 'react';
import useScrollDetection from 'hooks/use-scroll-detection';
import { DELAY_TYPE } from 'constants/delay-type';

const ScrollDetectionContext = createContext();

export const ScrollDetectionProvider = ({ children, scrollRef }) => {
  const { direction, position } = useScrollDetection({
    elementRef: scrollRef,
    delayType: DELAY_TYPE.DEBOUNCE,
    delay: 125,
    threshold: 0,
  });

  return (
    <ScrollDetectionContext.Provider value={{ direction, position, scrollRef }}>
      {children}
    </ScrollDetectionContext.Provider>
  );
};

export const useScrollDetectionContext = () => {
  const context = useContext(ScrollDetectionContext);

  if (!context) {
    throw new Error(
      'useScrollDetectionContext must be used within a ScrollDetectionProvider'
    );
  }

  return context;
};

export default ScrollDetectionProvider;
