import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import { Data } from 'components/providers/data-provider/DataProvider';
import c from './week-selector.module.scss';
import { calculateAreLabelsOverlapping, generateWeeks } from './utils';
import { format } from 'date-fns';

const WeekSelector = ({ selectedDay, setSelectedDay, currentDate, labels }) => {
  const { enrolledTeachingPeriods } = useContext(Data);
  const selectedLabelRef = useRef(null);
  const hoverLabelRefs = useRef([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const isEnrolledInASemester = enrolledTeachingPeriods
    .map((item) => item.calType)
    .some((calType) => ['S1-01', 'S2-01'].includes(calType));

  const weeks = generateWeeks(selectedDay);

  const changeHandler = (index) => {
    const week = weeks[index];
    setSelectedDay(week.startDate);
  };

  return (
    <div
      className={c.weekSelector}
      role="radiogroup"
      aria-label="Week selector"
    >
      {weeks.map((week, i) => {
        const isInTeachingPeriod = enrolledTeachingPeriods.some(
          (teachingPeriod) =>
            week.startDate >= new Date(teachingPeriod.startDate) &&
            week.startDate <= new Date(teachingPeriod.endDate)
        );

        const isSelected =
          new Date(selectedDay) >= week.startDate &&
          new Date(selectedDay) <= week.endDate;

        const isCurrentWeek =
          new Date(currentDate) >= week.startDate &&
          new Date(currentDate) <= week.endDate;

        const areLabelsOverlapping = calculateAreLabelsOverlapping({
          hoverLabel: hoverLabelRefs.current[hoveredIndex],
          selectedLabel: selectedLabelRef.current,
        });

        const classes = classNames(c.bar, {
          [c.isInTeachingPeriod]: isInTeachingPeriod,
          [c.isSelected]: isSelected,
        });

        const finalLabel = isEnrolledInASemester
          ? labels[i]?.display
          : format(new Date(week.startDate), 'MMM d');

        // handle week label overflow for far left and right
        const overflowsLeft = i <= 3;
        const overflowsRight = i >= 48;

        const selectedLabelClasses = classNames(
          c.label,
          { [c.show]: isSelected && !areLabelsOverlapping },
          { [c.overflowsLeft]: overflowsLeft },
          { [c.overflowsRight]: overflowsRight }
        );

        const hoverLabelClasses = classNames(selectedLabelClasses);

        return (
          <div
            key={i}
            className={c.barWrapper}
            onMouseEnter={() => setHoveredIndex(i)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <input
              aria-label={finalLabel}
              type="radio"
              onChange={() => changeHandler(i)}
              className={classes}
              checked={isSelected}
              name="week-selector"
              data-tracking-event={`schedule-week-selector-${i}`}
            />

            <div
              className={selectedLabelClasses}
              ref={(el) => {
                if (isSelected) {
                  selectedLabelRef.current = el;
                }
              }}
            >
              {finalLabel}
            </div>

            <div
              className={hoverLabelClasses}
              ref={(el) => (hoverLabelRefs.current[i] = el)}
            >
              {finalLabel}
            </div>

            {isCurrentWeek && !isSelected && (
              <span className={c.currentWeek}>Current week</span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default WeekSelector;
