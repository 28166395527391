import { transformPageNameForURL } from './utils';

const COPY_LENGTH = '-copy'.length;

export const getDuplicatedPageName = (pages, pageName) => {
  const transformedPageName = transformPageNameForURL(pageName);
  const transformedPageNames = pages
    ?.filter((page) => page.custom)
    .map((page) => transformPageNameForURL(page.name));

  // page name is a new copy (eg. custom copy)
  const isPageNameANewCopy =
    transformedPageName.slice(-COPY_LENGTH) === '-copy';

  // page name is a numbered copy (eg. custom copy 1)
  const numberedCopyRegex = /^(.*)-copy-([1-9][0-9]*)$/;
  const isPageNameANumberedCopy = transformedPageName.match(numberedCopyRegex);

  // page name is not a copy (eg. custom)
  const isPageNameNotACopy = !isPageNameANewCopy && !isPageNameANumberedCopy;

  const isPageNameNewCopyAlreadyInUse = transformedPageNames.includes(
    transformedPageName + '-copy'
  );

  const getTransformedPageNameBody = () => {
    if (isPageNameNotACopy) {
      return transformedPageName;
    }
    if (isPageNameANewCopy) {
      return transformedPageName.slice(0, -COPY_LENGTH);
    }
    if (isPageNameANumberedCopy) {
      return numberedCopyRegex.exec(transformedPageName)[1];
    }
  };

  // filter out numbered copy pages with the same page name
  const allNumberedCopiedPageNames = transformedPageNames.filter((name) =>
    name.match(numberedCopyRegex)
      ? getTransformedPageNameBody() === numberedCopyRegex.exec(name)[1]
      : false
  );

  const sortedDuplicatedIndexes = allNumberedCopiedPageNames
    .map((name) => numberedCopyRegex.exec(name)[2])
    .sort((a, b) => a - b);

  const nextIndex = parseInt(sortedDuplicatedIndexes.slice(-1)) + 1;

  // page name is not a copy (eg. custom)
  if (isPageNameNotACopy) {
    // if no new copy
    if (!isPageNameNewCopyAlreadyInUse) {
      return `${pageName} COPY`;
    }
    // if no numbered copies
    else if (allNumberedCopiedPageNames.length === 0) {
      return `${pageName} COPY 1`;
    }
    // if there are numbered copies
    else {
      return `${pageName} COPY ${nextIndex}`;
    }
  }

  // page name is a new copy (eg. custom copy)
  if (isPageNameANewCopy) {
    const pageNameBody = pageName.slice(0, -COPY_LENGTH);

    if (allNumberedCopiedPageNames.length === 0) {
      return `${pageNameBody} COPY 1`;
    } else {
      return `${pageNameBody} COPY ${nextIndex}`;
    }
  }

  // page name is a numbered copy (eg. custom copy 1)
  if (isPageNameANumberedCopy) {
    // not transformed copied page name case insensitive regex
    const pageNameRegex = /^(.*) copy ([1-9][0-9]*)$/i;
    const pageNameBody = pageNameRegex.exec(pageName)[1];

    return `${pageNameBody} COPY ${nextIndex}`;
  }
};
