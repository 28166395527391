import React, { useCallback, useEffect, useRef } from 'react';

// constant
export const FOCUSABLES_SELECTOR =
  "button, a[href], [tabindex]:not([tabindex='-1'])";

/**
 * SimpleFocusTrap
 * @param {boolean} trapIsOn - if focus trap is enabled
 * @param {string} focusableSelectors - focusable nodes selectors string
 * @param {JSX.Element} children - passed in JSX children
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleFocusTrap = ({
  trapIsOn = false,
  focusableSelectors = FOCUSABLES_SELECTOR,
  children,
}) => {
  const focusTrapRootRef = useRef(null);
  const handleKeyDown = useCallback(
    (e) => {
      // exit if trap is OFF
      if (!trapIsOn) {
        return;
      }

      // exit if trap root node invalid
      const focusTrapRootNode = focusTrapRootRef?.current;
      if (!focusTrapRootNode) {
        return;
      }

      if (e.key === 'Tab') {
        const focusablesInTrapNode = [
          ...focusTrapRootNode.querySelectorAll(focusableSelectors),
        ];

        // exit if no focusable in trap area
        if (focusablesInTrapNode.length === 0) {
          return;
        }

        const firstFocusable = focusablesInTrapNode[0];
        const lastFocusable =
          focusablesInTrapNode[focusablesInTrapNode.length - 1];

        // tab order sequence
        if (!e.shiftKey && lastFocusable === document.activeElement) {
          e.preventDefault();
          firstFocusable.focus();
        }
        // reverse tab order sequence
        else if (e.shiftKey && firstFocusable === document.activeElement) {
          e.preventDefault();
          lastFocusable.focus();
        }
      }
    },
    [focusTrapRootRef, trapIsOn, focusableSelectors]
  );

  useEffect(() => {
    // Notes:
    // use 'capture' phase here to avoid interference
    // from 'target' and 'bubbling' phase listeners
    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [handleKeyDown]);

  return (
    <div ref={focusTrapRootRef} data-focus-trap={trapIsOn ? 'on' : 'off'}>
      {children}
    </div>
  );
};

export default SimpleFocusTrap;
