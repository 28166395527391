import { useSessionStorage } from '@monash/portal-frontend-common';
import { SnackbarDispatcher } from './SnackbarDispatcher';
import c from './popup.module.scss';
import { Switch } from '@monash/portal-react';

const Misc = () => {
  const [forceCodeError, setForceCodeError] = useSessionStorage(
    'forceCodeError',
    false
  );

  return (
    <div className={c.stack}>
      <div>
        <div className={c.label}>Force code error</div>
        <Switch
          on={forceCodeError}
          onClick={() => {
            setForceCodeError(!forceCodeError);
          }}
        />
      </div>

      <hr />

      <div className={c.inputWrapper}>
        <div className={c.label}>Snackbar</div>
        <SnackbarDispatcher />
      </div>
    </div>
  );
};

export default Misc;
