import React from 'react';
import c from './page-item.module.scss';
import { Switch } from '@monash/portal-react';

const PageItem = ({ id, pageName, hiddenPages, setHiddenPages }) => {
  const isPageHidden = hiddenPages?.includes(id);

  const screenReaderLabel = `${isPageHidden ? 'Show' : 'Hide'} ${pageName}`;

  const hidePage = () => {
    setHiddenPages((f) => [...f, id]);
  };

  const showPage = () => {
    setHiddenPages((f) => {
      const index = hiddenPages?.indexOf(id);
      const newHiddenPages = [...f];
      if (index > -1) {
        newHiddenPages.splice(index, 1);
      }
      return newHiddenPages;
    });
  };

  return (
    <div className={c.pageItem}>
      {pageName}
      <Switch
        on={!isPageHidden}
        ariaLabel={screenReaderLabel}
        onClick={isPageHidden ? showPage : hidePage}
      />
    </div>
  );
};

export default PageItem;
