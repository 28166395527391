import React, { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { DragOverlay } from '@dnd-kit/core';
import { arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import {
  Button,
  Modal,
  Alert,
  ModalAction,
  ModalSection,
  useMedia,
  RouterState,
  useResponsiveValue,
} from '@monash/portal-react';
import {
  ImpersonationContext,
  fsUpdateDoc,
} from '@monash/portal-frontend-common';
import { Data } from 'components/providers/data-provider/DataProvider';
import { Page } from 'components/providers/page-provider/PageProvider';
import SortableDragAndDropWrapper, {
  DND_TYPE,
} from 'components/ui/drag-and-drop/SortableDragAndDropWrapper';
import SortableListItem from 'components/ui/drag-and-drop/sortable-list-item/SortableListItem';
import c from './page-management-modal.module.scss';
import PageItem from './page-item/PageItem';
import isEqual from 'lodash.isequal';
import { getRouteOnPageChange } from './utils';

export const MOBILE_RESPONSIVE = [
  {
    mq: '(max-width: 699px)',
    value: 'S',
  },
  {
    mq: '(min-width: 700px)',
    value: 'L',
  },
];

const NODE_ID_MODAL_TITLE = 'pageManagementModalTitle';
const NODE_ID_MODAL_CONTENT = 'pageManagementModalContent';
const MODAL_TITLE = 'Page management';
const MODAL_IGNORE_KEYS = ['ALL'];
const MODAL_FOCUSABLES_SELECTOR = 'button[type="button"]:not([disabled])';

const PageManagementModal = ({ open, setOpen }) => {
  const size = useMedia();
  const { currentUser } = useContext(ImpersonationContext);
  const { setPortalPreferences } = useContext(Data);
  const { redirect, route } = useContext(RouterState);
  const [activeId, setActiveId] = useState(null);
  const { mapPage, pagesData, setIsExpanded, selectedPage } = useContext(Page);

  // responsive
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';
  const ctaButtonSize = isMobile ? 'small' : 'medium';

  // page order
  const [pageOrder, setPageOrder] = useState(pagesData.pageOrder);
  const pageOrderIsUnchanged = isEqual(pagesData.pageOrder, pageOrder);
  const newPages = pageOrder?.map((id, i) => mapPage(id, i)).filter(Boolean);

  // hidden pages
  const [hiddenPages, setHiddenPages] = useState(pagesData.hiddenPages || []);
  const hiddenPagesUnchanged = isEqual(
    pagesData.hiddenPages || [],
    hiddenPages
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [modalIgnoredKeys, setModalIgnoredKeys] = useState([]);
  const [refreshModalFocusablesCount, setRefreshModalFocusablesCount] =
    useState(0);
  const isSaveDisabled =
    isUpdating || (pageOrderIsUnchanged && hiddenPagesUnchanged);

  //  get page name
  const getPageName = (id) => {
    const page = newPages?.find((page) => page.id === id);
    return page?.name;
  };

  // onDragStart
  const activateItem = (e) => {
    setModalIgnoredKeys(MODAL_IGNORE_KEYS);
    setActiveId(e.active.id);
  };

  // onDragCancel
  const deactivateActiveItem = () => {
    setModalIgnoredKeys([]);
    setActiveId(null);
  };

  // onDragEnd
  const swapItemOrder = (e) => {
    const { active, over } = e;
    if (over && active.id !== over.id) {
      setPageOrder((items) => {
        const itemsClone = [...items];
        const oldIndex = itemsClone.indexOf(active.id);
        const newIndex = itemsClone.indexOf(over.id);
        return arrayMove(itemsClone, oldIndex, newIndex);
      });
    }
    deactivateActiveItem();
    setRefreshModalFocusablesCount((prevCount) => prevCount + 1);
  };

  // Update
  const updatePages = () => {
    setIsUpdating(true);
    const id = currentUser.uid;
    const path = `users/${id}`;

    const redirectRoute = getRouteOnPageChange(
      route.path,
      newPages,
      hiddenPages,
      selectedPage
    );
    fsUpdateDoc(path, {
      'preferences.pages.pageOrder': pageOrder,
      'preferences.pages.hiddenPages': hiddenPages,
    })
      .then(() => handleUpdateSuccess(redirectRoute))
      .catch(handleUpdateError)
      .finally(() => setIsUpdating(false));
  };

  const handleUpdateSuccess = (redirectRoute) => {
    setPortalPreferences((f) => {
      const preferences = { ...f };
      preferences.pages.pageOrder = pageOrder;
      preferences.pages.hiddenPages = hiddenPages;
      return preferences;
    });

    // redirect user to the correct page route
    if (route.path !== redirectRoute) {
      redirect(redirectRoute);
    }

    closeModal();

    // Mobile: redirect to the correct page after main re-renders on mobile (make sure it doesn't fight with on screen hook)
    // Scroll to the page on Mobile
    if (isMobile) {
      setIsExpanded(false);
      setTimeout(() => {
        redirect(redirectRoute);
        scrollToElement();
      }, 100);
    }
  };

  const scrollToElement = () => {
    document
      .getElementById('VerticalMain')
      ?.scrollTo({ top: document.getElementById(route.path)?.offsetTop });
  };

  const handleUpdateError = (error) => {
    setUpdateError(true);
    console.warn(
      '[updatePortalPreferences]: api call error, failed to update pages',
      error
    );
  };

  // reset states
  const reset = () => {
    setPageOrder(pagesData.pageOrder);
    setHiddenPages(pagesData.hiddenPages || []);
    setUpdateError(false);
    setRefreshModalFocusablesCount(0);
  };

  // close modal
  const closeModal = () => {
    setOpen(false);
    reset();
  };

  // update page order and hidden page local states when data changes (add page or unhiding pages from another entry point)
  useEffect(() => {
    setPageOrder(pagesData.pageOrder);
    setHiddenPages(pagesData.hiddenPages || []);
  }, [pagesData.pageOrder, pagesData.hiddenPages]);

  useEffect(() => {
    setRefreshModalFocusablesCount((prevCount) => prevCount + 1);
  }, [isSaveDisabled]);

  return (
    <div className={c.pageManagement}>
      <Modal
        open={open}
        onClose={closeModal}
        size={size}
        ignoredKeys={modalIgnoredKeys}
        focusablesSelector={MODAL_FOCUSABLES_SELECTOR}
        refreshFocusables={refreshModalFocusablesCount}
        dismissOnHistoryNav={true}
        ariaLabel={MODAL_TITLE}
        ariaDescribedby={null}
      >
        <ModalSection
          title={MODAL_TITLE}
          titleTabIndex={null}
          ariaLabelledby={NODE_ID_MODAL_TITLE}
          ariaDescribedby={NODE_ID_MODAL_CONTENT}
        >
          <SortableDragAndDropWrapper
            sortableItems={pageOrder}
            onDragStart={activateItem}
            onDragEnd={swapItemOrder}
            onDragCancel={deactivateActiveItem}
            strategy={verticalListSortingStrategy}
            dndType={DND_TYPE.VERTICAL}
          >
            <ul className={c.pages}>
              {pageOrder?.map((id) => {
                const pageName = getPageName(id);
                return (
                  <SortableListItem
                    key={id}
                    id={id}
                    className={c.page}
                    itemLabel={pageName}
                  >
                    <PageItem
                      id={id}
                      pageName={pageName}
                      hiddenPages={hiddenPages}
                      setHiddenPages={setHiddenPages}
                    />
                  </SortableListItem>
                );
              })}
            </ul>
            {createPortal(
              <DragOverlay className={c.DragOverlayWrapper}>
                <SortableListItem id={activeId} className={c.page}>
                  <PageItem
                    id={activeId}
                    pageName={getPageName(activeId)}
                    hiddenPages={hiddenPages}
                    setHiddenPages={setHiddenPages}
                  />
                </SortableListItem>
              </DragOverlay>,
              document.getElementById('root')
            )}
          </SortableDragAndDropWrapper>
        </ModalSection>
        <ModalAction position="center">
          <div className={c.modalAction}>
            {updateError ? (
              <Alert type="error">
                We're having trouble saving your changes right now – please try
                again later
              </Alert>
            ) : null}
            <div className={c.modalButtons}>
              <Button variant="text" size={ctaButtonSize} onClick={closeModal}>
                Cancel
              </Button>
              <Button
                disabled={isSaveDisabled}
                variant="primary"
                size={ctaButtonSize}
                onClick={() => updatePages()}
                loading={isUpdating}
                loadingMessage="Updating pages"
                data-tracking-event="topnav-manage-page-save"
              >
                Save
              </Button>
            </div>
          </div>
        </ModalAction>
      </Modal>
    </div>
  );
};

export default PageManagementModal;
