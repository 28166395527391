import FiveStarGrade from 'components/ui/grades/five-star/FiveStarGrade';
import { getGradeString } from './get-grade-string';

/**
 * renders grade text (use renderGradeList for a full list of grades)
 * @param {Object} data - data from the event
 * @param {Boolean} shortenText - whether to render the full text or a shortened version.
 * @returns {JSX.Element}
 */
export const renderGrade = (data, shortenText) => {
  const gradeFormatted = data?.gradeFormatted;

  // render nothing if no formatted grade
  if (!gradeFormatted) {
    return null;
  }

  // render five star grade if applicable
  if (
    data?.gradeTypeName === 'GRADE_SCALE' &&
    data?.gradeScaleType === 'FIVE_STAR_SCALE'
  ) {
    return <FiveStarGrade starCount={data?.grade} />;
  }

  // render normal label text
  return (
    <span style={{ whiteSpace: 'nowrap' }}>
      {getGradeString(data, shortenText)}
    </span>
  );
};
