import { getMondayOfTheWeek } from 'components/pages/schedule/utils';
import { addDays, addYears, endOfYear, getYear, subSeconds } from 'date-fns';

export const generateWeeks = (selectedDay) => {
  const currentYear = getYear(selectedDay);
  const firstDay = new Date(`Jan 1 ${currentYear} 00:00`);
  const firstMonday = getMondayOfTheWeek(firstDay);

  // Account for the last week of december, which becomes
  // the first week of next year
  const firstMondayOfNextYear = getMondayOfTheWeek(addYears(firstDay, 1));
  const isInFirstWeekOfNextYear =
    selectedDay.toString() === firstMondayOfNextYear.toString();
  const finalFirstMonday = isInFirstWeekOfNextYear
    ? getMondayOfTheWeek(addYears(firstMonday, 1))
    : firstMonday;
  const firstSunday = subSeconds(addDays(finalFirstMonday, 7), 1);

  // If year ends on a sunday there will be 53 weeks instead of 52
  const lastDayOfYear = endOfYear(firstSunday);
  const lastDayIsSunday = lastDayOfYear.getDay() === 0;
  const numberOfWeeks = lastDayIsSunday ? 53 : 52;

  return new Array(numberOfWeeks).fill(null).map((_, i) => {
    return {
      startDate: addDays(finalFirstMonday, 7 * i),
      endDate: addDays(firstSunday, 7 * i),
    };
  });
};

export const calculateAreLabelsOverlapping = ({
  hoverLabel,
  selectedLabel,
}) => {
  if (!hoverLabel || !selectedLabel) {
    return false;
  }

  const hoverBounds = hoverLabel.getBoundingClientRect();
  const selectedBounds = selectedLabel.getBoundingClientRect();

  const noOverlapLeft = hoverBounds.right < selectedBounds.left;
  const noOverlapRight = hoverBounds.left > selectedBounds.right;

  return !(noOverlapLeft || noOverlapRight);
};
