import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { Page } from 'components/providers/page-provider/PageProvider';
import throttle from 'lodash.throttle';
import { SCROLL_POSITION } from 'constants/scroll-position';
import { DELAY_TYPE } from 'constants/delay-type';
import { SCROLL_DIRECTION } from 'constants/scroll-direction';

const useScrollDetection = ({
  selectorStr = null,
  elementRef = null,
  delayType = null,
  delay = 150,
  threshold = 10,
  detectUserScrollOnly = false, // set to true to ignore programmatic scrolling
}) => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [direction, setDirection] = useState(null); // UP, DOWN, or null
  const [position, setPosition] = useState(SCROLL_POSITION.TOP); // TOP, MIDDLE, or BOTTOM
  const hasMounted = useRef(false);
  const { isProgrammaticScrolling, setIsProgrammaticScrolling } =
    useContext(Page);

  // if a query selector string is given, then we retrieve the scroll element directly, otherwise we check if a ref was given
  const scrollElement = selectorStr
    ? document.querySelector(selectorStr)
    : elementRef
    ? elementRef.current
    : document.documentElement;

  const handleScroll = () => {
    if (!scrollElement) {
      return;
    }

    const currentScrollTop = scrollElement.scrollTop;

    if (detectUserScrollOnly && isProgrammaticScrolling) {
      setIsProgrammaticScrolling(false);
      setLastScrollTop(currentScrollTop);
      return;
    }

    const totalHeight = scrollElement.scrollHeight;
    const visibleHeight = scrollElement.clientHeight;

    if (!hasMounted.current) {
      hasMounted.current = true;
      setLastScrollTop(currentScrollTop);
      return;
    }

    if (Math.abs(currentScrollTop - lastScrollTop) < threshold) {
      return;
    }

    const direction =
      currentScrollTop > lastScrollTop
        ? SCROLL_DIRECTION.DOWN
        : SCROLL_DIRECTION.UP;
    let position = SCROLL_POSITION.MIDDLE;

    if (currentScrollTop <= 0) {
      position = SCROLL_POSITION.TOP;
    } else if (currentScrollTop + visibleHeight >= totalHeight) {
      position = SCROLL_POSITION.BOTTOM;
    }

    setDirection(direction);
    setPosition(position);
    setLastScrollTop(currentScrollTop);
  };

  const debouncedHandleScroll = useCallback(debounce(handleScroll, delay), [
    scrollElement,
    lastScrollTop,
    isProgrammaticScrolling,
    hasMounted,
  ]);

  const throttledHandleScroll = useCallback(
    throttle(handleScroll, delay, { leading: false }),
    [scrollElement, lastScrollTop, isProgrammaticScrolling, hasMounted]
  );

  useEffect(() => {
    if (!scrollElement) {
      return;
    }

    switch (delayType) {
      case DELAY_TYPE.DEBOUNCE:
        scrollElement.addEventListener('scroll', debouncedHandleScroll);
        break;
      case DELAY_TYPE.THROTTLE:
        scrollElement.addEventListener('scroll', throttledHandleScroll);
        break;
      default:
        scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      switch (delayType) {
        case DELAY_TYPE.DEBOUNCE:
          scrollElement.removeEventListener('scroll', debouncedHandleScroll);
          break;
        case DELAY_TYPE.THROTTLE:
          scrollElement.removeEventListener('scroll', throttledHandleScroll);
          break;
        default:
          scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollElement, debouncedHandleScroll, throttledHandleScroll]);

  return {
    direction,
    position,
  };
};

export default useScrollDetection;
