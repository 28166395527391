import React, { forwardRef, useContext } from 'react';
import c from './day.module.scss';
import Disclaimer from './disclaimer/Disclaimer';
import classNames from 'classnames';
import {
  FeatureContext,
  capitaliseFirstWord,
} from '@monash/portal-frontend-common';
import getWorkshopText from 'components/utilities/user-events/get-workshop-text';
import Accent from 'components/ui/accent/Accent';
import {
  getTitle,
  formatEventTitleAbbr,
} from 'components/pages/upcoming/utils/utils';
import Placeholder from './placeholder/Placeholder';
import StatusInfo from './status-info/StatusInfo';
import TimeInfo from './time-info/TimeInfo';
import fs from 'styles/font-styles.module.scss';
import { CONTAINER_WIDTH } from 'hooks/use-container-width';
import { renderGrade } from 'components/utilities/user-events/render-grade';

const Day = forwardRef(
  (
    {
      day,
      widgetId,
      isAssessmentCardShown,
      activeAssessmentIndex,
      showAssessmentCard,
      assessments,
      assessmentsRefs,
      currentDate,
      scrollToTodaySuffix,
      size,
      visible,
    },
    ref
  ) => {
    const shouldDisplayCurrentDayPlaceholder =
      day.isToday && day.assessments === null;
    const displayDateClassNames = classNames({
      [c.currentDate]: day.isToday,
    });
    const { featureFlags } = useContext(FeatureContext);

    return (
      <div
        className={c.day}
        ref={ref}
        tabIndex={-1}
        id={day.isToday ? `${widgetId}-${scrollToTodaySuffix}` : null}
      >
        {day.isToday ? <Disclaimer /> : null}
        {shouldDisplayCurrentDayPlaceholder ? (
          <Placeholder day={day} size={size} />
        ) : (
          <li className={c.dayWithAssessments}>
            <h3 className={`${displayDateClassNames} ${fs.medium}`}>
              {day.displayDate}
            </h3>
            <ul className={c.items}>
              {day.assessments.map((item) => {
                const assessmentIndex = assessments.findIndex(
                  (assessment) => assessment.id === item.id
                );
                const titleText = capitaliseFirstWord(item.data?.name) || null;
                const workshopText = getWorkshopText(item.eventType);
                const itemTitle = getTitle(item);
                const itemTitleFormatted = formatEventTitleAbbr(itemTitle, {
                  title: titleText,
                  'aria-label': getTitle(item, true),
                });
                const grade = renderGrade(item.data, true);
                const shouldShowStatus = !featureFlags?.GRADES || !grade;
                const shouldShowGrade =
                  featureFlags?.GRADES && grade && visible;
                const shouldShowGradePlaceholder =
                  featureFlags?.GRADES && grade && !visible;

                return (
                  <button
                    type="button"
                    className={classNames(c.item, {
                      [c.active]:
                        assessmentIndex === activeAssessmentIndex &&
                        isAssessmentCardShown,
                      [c.small]: size === CONTAINER_WIDTH.SMALL,
                    })}
                    ref={assessmentsRefs[assessmentIndex]}
                    key={item.id}
                    onKeyDown={(e) => e.stopPropagation()}
                    onClick={() =>
                      showAssessmentCard(
                        assessmentsRefs[assessmentIndex],
                        {
                          ...item,
                        },
                        assessmentIndex
                      )
                    }
                    aria-haspopup="dialog"
                    aria-expanded={isAssessmentCardShown ? 'true' : 'false'}
                  >
                    <TimeInfo event={item} size={size} />
                    <div className={c.accent}>
                      <Accent unitCode={item.display.unit} size="big" />
                    </div>
                    <div className={c.titleGroup}>
                      <h4 className={`${c.titleText} ${fs.heading} ${fs.sm}`}>
                        {itemTitleFormatted}
                      </h4>
                      {workshopText && <p>{workshopText}</p>}
                    </div>
                    <span className={c.keyInfo}>
                      {shouldShowGrade && grade}
                      {shouldShowGradePlaceholder && (
                        <span className={c.gradePlaceholder}>xxxxx</span>
                      )}
                      {shouldShowStatus && (
                        <StatusInfo event={item} currentDate={currentDate} />
                      )}
                    </span>
                  </button>
                );
              })}
            </ul>
          </li>
        )}
      </div>
    );
  }
);

export default Day;
