import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconButton, Icon } from '@monash/portal-react';
import { isFunction, isStringEmpty } from '@monash/portal-frontend-common';
import useWindowSize from 'hooks/use-window-size';
import SimpleFocusTrap from '../simple-focus-trap/SimpleFocusTrap';
import c from './draggable-window.module.scss';
import { getPositionWithinWindow, getNewPosition } from './utils';
import classNames from 'classnames';

const DraggableWindow = forwardRef(
  (
    {
      onClose,
      isShowing,
      title,
      children,
      initialPosition,
      fullScreen = false,
      // focus trap props
      focusTrapEnabled = false,
      focusTrapSelectors = '',
      // key nav props
      keyboardDismissalEnabled = false,
      // extra props for nodes
      extraRootProps = {},
      extraStyles = {},
    },
    ref
  ) => {
    const [isVisible, setIsVisible] = useState(false);
    const windowRef = useRef();
    const startingTouch = useRef();
    const startingPosition = useRef();
    const closeButtonRef = useRef();
    const [position, setPosition] = useState(initialPosition || { x: 0, y: 0 });
    const windowSize = useWindowSize();
    const isKeydownHandlerRequired = isShowing && keyboardDismissalEnabled;
    const classes = classNames(c.DraggableWindow, { [c.visible]: isVisible });

    useEffect(() => {
      window.addEventListener('popstate', onClose);
      return () => {
        window.removeEventListener('popstate', onClose);
      };
    }, []);

    const handleDraggableWindowKeyDown = useCallback(
      (e) => {
        // keyboard dismissal
        if (
          e.key === 'Escape' &&
          keyboardDismissalEnabled &&
          isFunction(onClose)
        ) {
          onClose();
        }
      },
      [keyboardDismissalEnabled, onClose]
    );

    useEffect(() => {
      if (isKeydownHandlerRequired) {
        // Notes:
        // use 'capture' phase here to avoid interference
        // from 'target' and 'bubbling' phase listeners
        document.addEventListener(
          'keydown',
          handleDraggableWindowKeyDown,
          true
        );
        return () => {
          document.removeEventListener(
            'keydown',
            handleDraggableWindowKeyDown,
            true
          );
        };
      }
    }, [isKeydownHandlerRequired, handleDraggableWindowKeyDown]);

    useEffect(() => {
      const newPosition = getPositionWithinWindow({
        ref: windowRef,
        windowSize,
        position,
      });
      setPosition(newPosition);
      setIsVisible(true);
    }, [windowSize]);

    useEffect(() => {
      if (windowRef.current && isShowing) {
        closeButtonRef?.current?.focus();

        if (!initialPosition) {
          const centeredPosition = {
            x: window.innerWidth / 2 - windowRef.current.offsetWidth / 2,
            y: window.innerHeight / 2 - windowRef.current.offsetHeight / 2,
          };

          setPosition(centeredPosition);
        }
      }
    }, [windowRef, isShowing]);

    const move = (event) => {
      setPosition((position) => {
        return getNewPosition({
          event,
          startingTouch,
          startingPosition,
          position,
          ref: windowRef,
        });
      });
    };

    const release = () => {
      window.removeEventListener('mousemove', move);
      window.removeEventListener('touchmove', move);
      window.removeEventListener('mouseup', release);
      window.removeEventListener('touchend', release);
    };

    const hold = (e) => {
      if (e.button > 0) return; // ignore if NOT from primary button
      e.preventDefault();

      startingTouch.current = {
        x: e.targetTouches?.[0].clientX,
        y: e.targetTouches?.[0].clientY,
      };
      startingPosition.current = {
        x: position.x,
        y: position.y,
      };

      window.addEventListener('mousemove', move);
      window.addEventListener('touchmove', move);
      window.addEventListener('mouseup', release);
      window.addEventListener('touchend', release);
    };

    // reusable window content render
    const renderWindow = () => (
      <div className={c.container} data-fullscreen={fullScreen ? '' : null}>
        <div className={c.header} onMouseDown={hold} onTouchStart={hold}>
          <h3 className={c.title}>{title}</h3>
          <IconButton
            icon={Icon.X}
            aria-label="Close menu"
            color="var(--card-text-color)"
            className={c.closeButton}
            onClick={onClose}
            onKeyDown={(e) => e.stopPropagation()}
            ref={closeButtonRef}
          />
        </div>
        {children}
      </div>
    );

    const mouseDown = (e) => {
      // prevent the edit menu from triggering the parent widget's drag & drop functionalities
      e.stopPropagation();
    };

    return (
      <div
        ref={(el) => {
          windowRef.current = el;

          // handle forwarded ref
          if (ref) {
            if (typeof ref === 'function') {
              // if callback ref is forwarded, call it
              ref(el);
            } else {
              // otherwise, set the forwarded ref to this element
              ref.current = el;
            }
          }
        }}
        className={classes}
        inert={!isShowing ? '' : null}
        data-active={isShowing ? '' : null}
        style={
          fullScreen
            ? { left: 0, top: 0, ...extraStyles }
            : {
                left: `${position.x}px`,
                top: `${position.y}px`,
                ...extraStyles,
              }
        }
        onMouseDown={mouseDown}
        {...extraRootProps}
      >
        {focusTrapEnabled ? (
          <SimpleFocusTrap
            trapIsOn={isShowing}
            focusableSelectors={
              !isStringEmpty(focusTrapSelectors)
                ? focusTrapSelectors
                : undefined
            }
          >
            {renderWindow()}
          </SimpleFocusTrap>
        ) : (
          renderWindow()
        )}
      </div>
    );
  }
);

export default DraggableWindow;
