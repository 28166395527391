import c from './library.module.scss';

const Fees = ({ data, error = false }) => {
  if (error) {
    return <>TODO: insert error messaging</>;
  } else
    return (
      <div className={c.fees}>
        <div className={c.subheading}>Balance</div>
        <div className={c.sum}>${data.total_sum}</div>
      </div>
    );
};

export default Fees;
