export const getOverlap = (windowWidth) => {
  if (windowWidth > 1200) {
    return '140px';
  } else if (windowWidth > 700) {
    return '9vw';
  }
  return '0px';
};

export const calculateClosestPageIndex = ({
  pagePositions,
  mainScrollTop,
  windowHeight = window.innerHeight,
}) => {
  const threshold = windowHeight * 0.5;
  const closestIndex = pagePositions.reduce((acc, curr, index) => {
    if (mainScrollTop > curr - windowHeight + threshold) {
      return index;
    }
    return acc;
  }, 0);
  return closestIndex;
};
