import { Icon } from '@monash/portal-react';
import c from './assessments-warning.module.scss';

const AssessmentsWarning = () => {
  return (
    <div className={c.assessmentsWarning}>
      <Icon.InfoCircle size={18} /> Some assessments may not be shown.
    </div>
  );
};

export default AssessmentsWarning;
