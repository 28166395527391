import React, { useState, useContext, useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { APIContext } from '@monash/portal-frontend-common';
import { RouterState } from '@monash/portal-react';

export const SearchContext = React.createContext();
export const SEARCH_PLACEHOLDER = 'Search links';

const SearchProvider = ({ children }) => {
  const [searchError, setSearchError] = useState(false);

  const { search, searchSuggestions } = useContext(APIContext);
  const { route, redirect } = useContext(RouterState);

  const loadSearchString = () => {
    if (route.data) return route.data;
    const search = new URLSearchParams(window.location.search);
    return search.get('query') || '';
  };

  const [searchTarget, setSearchTarget] = useState(loadSearchString || '');
  const [loadingLinks, setLoadingLinks] = useState(true);
  const [runSearchCallCount, setRunSearchCallCount] = useState(0);
  const [searchResult, setSearchResult] = useState();
  const [searchSuggestionsResult, setSearchSuggestionsResult] = useState();

  const runSearch = (target = searchTarget, goToSearch = true) => {
    target && setSearchTarget(target);
    search(target, [], [], 'link')
      .then((commonSearch) => {
        setSearchResult(commonSearch.results);
        setSearchError(false);
      })
      .catch((error) => {
        setSearchError(error);
      })
      .finally(() => {
        setLoadingLinks(false);
        setRunSearchCallCount((prev) => prev + 1);
      });

    setLoadingLinks(true);
    goToSearch && redirect(`/search?query=${target}`, searchTarget);
  };

  const runSearchSuggestions = useCallback((target) => {
    searchSuggestions(target)
      .then((commonSearch) => {
        setSearchSuggestionsResult(commonSearch);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }, []);

  return (
    <SearchContext.Provider
      value={{
        searchTarget,
        setSearchTarget,
        loadingLinks,
        searchResult,
        runSearch,
        runSearchCallCount,
        runSearchSuggestions,
        searchSuggestionsResult,
        searchError,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
