import { isObject, isStringEmpty } from '@monash/portal-frontend-common';
import { eventTypes } from 'constants/event-types';
import {
  ASSESSMENTS_OPTION_KEY,
  ASSESSMENTS_STATUS_OPTION,
  ASSESSMENTS_TYPE_OPTION,
} from './Assessments';

/**
 * Returns a list of unit codes that have assessments/exams associated with it for a given user's events
 * @param {Array}} userEvents array
 * @returns {Array} array of unit codes that have assessments/exams from the user's events
 */
export const getUnitCodesWithAssessments = (userEvents) => {
  if (!Array.isArray(userEvents) || userEvents.length === 0) return [];

  const assessments = userEvents?.filter((e) => {
    return e.eventKind === 'assessment' || e.eventType === eventTypes.EXAM;
  });

  if (assessments.length === 0) return [];

  const unitCodesWithAssessments = assessments.reduce((acc, curr) => {
    const unitCode = curr?.data?.unitCode || null;

    if (unitCode === null) {
      console.warn('Assessment has no unit code: ', curr);
      return acc;
    }

    // if this unit code is already added to the list, don't add it again
    if (acc.includes(unitCode)) return acc;

    // otherwise, add the unit code to the list
    return [...acc, unitCode];
  }, []);

  return unitCodesWithAssessments;
};

/**
 * Returns a string describing which filter options have been selected
 * @param {Array} options array of options, e.g. 
  [
    {
        "id": "not_open_yet",
        "name": "Not open yet"
    },
    {
        "id": "not_submitted",
        "name": "Not submitted"
    },
    {
        "id": "submitted",
        "name": "Submitted"
    }
  ]
 * @param {Object} optionData object storing the selected options, e.g. 
  {
    "submitted": true,
    "not_open_yet": true,
    "not_submitted": true
  }
 * @param {string} optionName name of set of options, e.g. "Status"
 * @returns {string} description of selected filter options
 */
export const getOptionFilterText = ({ options, optionData, optionName }) => {
  if (!isObject(optionData) || !Array.isArray(options)) return null;

  // basic pluraliser for this specific purpose
  const pluralise = (str) => {
    // if the string doesn't end with 's', append 's'
    if (!/s$/.test(str)) {
      return str + 's';
    }

    // else return string
    return str;
  };

  if (
    (!Object.values(optionData).includes(true) &&
      Object.values(optionData).length === options.length) ||
    options.length === 0
  ) {
    if (isStringEmpty(optionName)) {
      return 'None';
    }
    return `No ${pluralise(optionName.toLowerCase())}`;
  }

  // if all saved options are true, then all options are selected
  if (Object.values(optionData).every((option) => option)) {
    if (isStringEmpty(optionName)) {
      return 'All';
    }
    return `All ${pluralise(optionName.toLowerCase())}`;
  }

  // construct string based on which options are selected
  return options.reduce((acc, curr) => {
    if (optionData[curr.id] || !(curr.id in optionData)) {
      // if option id is not in fs, default to 'on'
      if (isStringEmpty(acc)) {
        return curr.name; // init string
      }
      return acc + ', ' + curr.name; // append to string
    }
    return acc;
  }, '');
};

/**
 * Helper for adding unit codes to the assessment widget's filter options
 * @param {Array} filterOptions
 * @param {Array} unitCodes
 * @returns copy of filter options array which includes the supplied unit codes
 */
export const injectFilterOptionsWithUnits = (filterOptions, unitCodes) => {
  if (!Array.isArray(filterOptions)) {
    console.warn('filterOptions not an array');
    return null;
  }

  if (!Array.isArray(unitCodes) || unitCodes.length === 0) return filterOptions;

  return filterOptions.map((optModule) => {
    // look for 'unit' option module
    if (optModule.key !== ASSESSMENTS_OPTION_KEY.UNIT) return optModule;

    // look inside 'unit' option module
    const unitOptModule = { ...optModule };

    // create options for units
    unitOptModule.options = unitCodes.map((unitCode) => {
      return { id: unitCode, name: unitCode };
    });
    return unitOptModule;
  });
};

/**
 * Calculates whether a given unit code passes the filter.
 * Unit code passes unit filter if:
 * unit code in the option data is true, or
 * unit code is not in the option data (b/c we want to be conservative and default to 'on')
 * @param {string} unitCode unit code to test
 * @param {Object} optionData user's saved filter settings data (for unit code)
 * @returns {Boolean} true if passes, false if doesn't pass
 */
export const passesUnitFilter = (unitCode, optionData) => {
  if (!isObject(optionData)) return true;

  return optionData[unitCode] || !(unitCode in optionData);
};

/**
 * Calculates whether a given assessment type passes the filter
 * @param {string} eventType assessment type to test
 * @param {Object} optionData user's saved filter settings data (for assessment type)
 * @returns true if passes, false if doesn't pass
 */
export const passesTypeFilter = (eventType, optionData) => {
  if (!isObject(optionData)) return true;
  // keys = the possible assessment types returned from the API
  // values = the corresponding option id
  const typeOptionDict = {
    [eventTypes.QUIZ]: ASSESSMENTS_TYPE_OPTION.QUIZ,
    [eventTypes.ASSIGNMENT]: ASSESSMENTS_TYPE_OPTION.ASSIGNMENT,
    [eventTypes.WORKSHOP_SUBMISSION]: ASSESSMENTS_TYPE_OPTION.WORKSHOP,
    [eventTypes.WORKSHOP_ASSESSMENT]: ASSESSMENTS_TYPE_OPTION.WORKSHOP,
    [eventTypes.EXAM]: ASSESSMENTS_TYPE_OPTION.EXAM,
  };

  // if the event type is somehow not in the list of assessment event types, default to 'on'
  if (!(eventType in typeOptionDict)) return true;

  // otherwise, translate the event type to the corresponding option and check if it's 'on' or 'off'
  const optionId = typeOptionDict[eventType];
  return optionData[optionId];
};

/**
 * Util to determine if a submitted assessment passes the grade filter (subset of status filter)
 * @param {string} gradeFormatted formatted assessment grade
 * @param {Object} optionData user's saved filter settings data (for assessment status)
 * @returns true if passes, false if doesn't pass
 */
const passesGradedFilter = (gradeFormatted, optionData) => {
  const isAssessmentGraded = !isStringEmpty(gradeFormatted);

  const isOptionOn = (optionId) => {
    return optionData[optionId] || !(optionId in optionData); // if an option is not found in firestore, i.e. undefined, it is treated as 'on'
  };

  const isGradedOptionOn = isOptionOn(ASSESSMENTS_STATUS_OPTION.GRADED);
  const isSubmittedOptionOn = isOptionOn(ASSESSMENTS_STATUS_OPTION.SUBMITTED);

  // possible scenarios for filters (graded implies submitted):

  // submitted option toggled 'on'
  if (isSubmittedOptionOn) {
    if (isGradedOptionOn) {
      // 1. submitted 'on', graded 'on' => see all submitted assessments (inc. graded)
      return true;
    } else {
      // 2. submitted 'on', graded 'off' => see all submitted assessments that are NOT graded
      return !isAssessmentGraded;
    }
  }

  // submitted option toggled 'off'
  else {
    if (isGradedOptionOn) {
      // 3. submitted 'off', graded 'on' => see only graded submitted assessments
      return isAssessmentGraded;
    } else {
      // 4. submitted 'off', graded 'off' => see no submitted assessments
      return false;
    }
  }
};

/**
 * Calculates whether a given assessment status passes the filter for the basic mutually exclusive statuses, i.e.
 * 'not open yet', 'not submitted', or 'submitted'
 * @param {string} status one of ("NOT_OPEN_YET", "NOT_SUBMITTED", "SUBMITTED")
 * @param {Object} optionData user's saved filter settings data (for assessment status)
 * @returns true if passes, false if doesn't pass
 */
export const passesBasicStatusFilter = (status, optionData) => {
  if (!isObject(optionData)) return true;

  const optionId = ASSESSMENTS_STATUS_OPTION[status];

  return optionData[optionId] || !(optionId in optionData);
};

/**
 * Calculates whether a given assessment status passes the status filter, i.e.
 * 'not open yet', 'not submitted', 'submitted', 'graded'
 * @param {string} status one of ("NOT_OPEN_YET", "NOT_SUBMITTED", "SUBMITTED")
 * @param {string} gradeFormatted formatted assessment grade
 * @param {Object} optionData user's saved filter settings data (for assessment status)
 * @returns true if passes, false if doesn't pass
 */
export const passesStatusFilter = (status, gradeFormatted, optionData) => {
  if (!isObject(optionData)) return true;

  // 'not open yet' and 'not submitted' can be handled by simply looking at the status, but
  // 'submitted' has to be handled separately, because 'graded' implies 'submitted'
  // and therefore there are more scenarios to consider

  return status === 'SUBMITTED'
    ? passesGradedFilter(gradeFormatted, optionData)
    : passesBasicStatusFilter(status, optionData);
};

/**
 * Takes in filter option data and determines if any filters are active.
 * Definition of "active": 
 * If any of the options have been toggled to 'off', then the filter is active.
 * @param {Object} filterData filter option data in firestore, e.g.
  {
    "type": {
        "workshop": true,
        "quiz": true,
        "assignment": true,
        "exam": false
    },
    "status": {
        "not_submitted": true,
        "not_open_yet": true,
        "submitted": false
    },
    "unit": {
        "FIT4006": true,
        "FIT4003": true,
        "FIT4005": false,
        "FIT2014": false,
    }
  }
 * @returns true if filter is active, false if not
 */
export const isFilterActive = (filterData) => {
  if (!isObject(filterData)) return false;

  // iterate through filter options data
  // if there exists some option that is set to false,
  // then the filter is active
  const result = Object.keys(filterData).some((optModuleKey) => {
    const optModule = filterData[optModuleKey];
    if (!isObject(optModule)) return false;

    return Object.keys(optModule).some((option) => {
      return !optModule[option];
    });
  });

  return result;
};
