export const gradeScaleTypes = {
  FIVE_STAR_SCALE: 'FIVE_STAR_SCALE',
  PASS_OR_FAIL: 'PASS_OR_FAIL',
  DEFAULT_COMPETENCE_SCALE: 'DEFAULT_COMPETENCE_SCALE',
  MON_SCALE: 'MON_SCALE',
  DEMONSTRATING_OUTCOMES: 'DEMONSTRATING_OUTCOMES',
  HALF_POINTS_TO_XXX: 'HALF_POINTS_TO_XXX',
  SATISFACTORY: 'SATISFACTORY',
  GRADES_N_HD: 'GRADES_N_HD',
  DISCUSSION_POSTING: 'DISCUSSION_POSTING',
};

export const GRADE_SCALE_TYPES_WITHOUT_LIST = [gradeScaleTypes.FIVE_STAR_SCALE];
