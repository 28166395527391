import React, { forwardRef, memo } from 'react';
import { isSameDay } from 'date-fns';
import { useResponsiveValue } from '@monash/portal-react';
import { isNumber } from '@monash/portal-frontend-common';
import ScheduleItem from '../../schedule-item/ScheduleItem';
import {
  calculateZIndex,
  checkIsLastItemOnPage,
  getDayColumnScreenReaderMsg,
  getTimeIndicatorStyleForGrid,
} from '../utils';
import c from '../calendar.module.scss';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import TimeIndicator from 'components/pages/upcoming/components/TimeIndicator/TimeIndicator';
import { getCurrentDateRange } from './collapsed-multi-day-events/utils';
import {
  getTimeIndicatorData,
  getTimeIndicatorStyle,
} from 'components/pages/upcoming/components/TimeIndicator/utils';
import { isBetweenDates } from 'utils/is-between-dates';

export const CalendarScheduleGrid = memo(
  forwardRef(
    (
      {
        weekDataDays = [],
        currentDate: currentDateProp = null,

        expanded = true,
        calculatedWeekDaysStyles = [],
        scheduleItemRefs = [],
        openScheduleCard,
        numberOfDisplayDays,
        userEvents,
        selectedDay,
      },
      lastItemRef
    ) => {
      const currentDate = new Date(currentDateProp);
      const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
      const compressedItemTiles =
        responsiveSize === 'S' && numberOfDisplayDays === 7;

      const dateRange = getCurrentDateRange(numberOfDisplayDays, selectedDay);
      const currentDateIsInRange = Boolean(
        isBetweenDates(currentDate, dateRange.start, dateRange.end)
      );

      const todaysEvents = userEvents.filter((event) => {
        return (
          isSameDay(currentDate, event.start.time) &&
          isSameDay(currentDate, event.end.time)
        );
      });

      const timeIndicatorData = getTimeIndicatorData({
        events: todaysEvents,
        currentDate,
      });

      const timeIndicatorStyleForGrid =
        getTimeIndicatorStyleForGrid(currentDate);

      let itemCounter = 0;
      return weekDataDays.map((day = {}, dayIndex) => {
        const isToday = isSameDay(day.date, currentDate);

        return (
          <div
            key={dayIndex}
            className={c.scheduleColumn}
            data-key-nav-group={`schedule-column-${dayIndex + 1}`}
            aria-label={getDayColumnScreenReaderMsg(day.date, day.items.length)}
          >
            {isToday && expanded && (
              <TimeIndicator style={timeIndicatorStyleForGrid} />
            )}

            {day.items.map((item, itemIndex) => {
              const itemRef = scheduleItemRefs?.[itemCounter];
              itemCounter++;
              const dayStyles =
                calculatedWeekDaysStyles[dayIndex].items[itemIndex];
              const positioning = expanded
                ? {
                    left: isNumber(dayStyles.leftInPct)
                      ? `${dayStyles.leftInPct}%`
                      : '0',
                    width: isNumber(dayStyles.widthInPct)
                      ? `${dayStyles.widthInPct}%`
                      : '100%',
                    top: isNumber(dayStyles.top)
                      ? `${dayStyles.top}px`
                      : 'auto',
                    position: 'absolute',
                  }
                : {};

              const isLastItemOnPage = checkIsLastItemOnPage(
                calculatedWeekDaysStyles,
                dayStyles.top
              );

              const lastItemExtraProps = isLastItemOnPage
                ? { ref: lastItemRef }
                : {};

              const timeIndicatorDatum = timeIndicatorData?.find(
                ({ index: indicatorIndex }) => itemIndex === indicatorIndex
              );
              const timePercentage = timeIndicatorDatum?.timePercentage;
              const hasTimeIndicator = Boolean(timeIndicatorDatum);
              const isFinished = timeIndicatorDatum?.isFinished;
              const style = getTimeIndicatorStyle({
                timePercentage,
                isFinished,
                isSchedule: true,
              });

              return (
                <div
                  className={c.scheduleItemWrapper}
                  key={item.id}
                  style={{
                    zIndex: calculateZIndex(positioning.left, itemIndex),
                    height: isNumber(dayStyles.height)
                      ? `${dayStyles.height}px`
                      : 'auto',
                    ...positioning,
                  }}
                >
                  {!expanded &&
                    isToday &&
                    hasTimeIndicator &&
                    currentDateIsInRange && <TimeIndicator style={style} />}

                  <button
                    type="button"
                    className={c.scheduleItem}
                    onClick={() => {
                      openScheduleCard(itemRef, { ...item });
                    }}
                    aria-haspopup="dialog"
                    {...lastItemExtraProps}
                    data-tracking-event="schedule-event-item"
                  >
                    <ScheduleItem
                      key={item.id}
                      {...{ item, dayIndex, j: itemIndex, itemRef, expanded }}
                      isCompressed={compressedItemTiles}
                    />
                  </button>
                </div>
              );
            })}
          </div>
        );
      });
    }
  )
);
