import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableGridItem = ({ children, id, gridColumns, keyNavItem }) => {
  const { isDragging, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  const style = {
    opacity: isDragging ? 0.25 : undefined,
    gridColumn: `span ${gridColumns}`,
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
    borderRadius: '20px',
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...listeners}
      role="group"
      tabIndex={0}
      aria-roledescription="Button"
      {...keyNavItem}
    >
      {children}
    </div>
  );
};

export default SortableGridItem;
