import { transformPageNameForURL } from './utils';

export const getPageIdFromRoute = (allPages, path) => {
  if (!allPages || !path) return;
  const pathDirectories = path?.split('/');

  const getPageId = (isPageCustom, pageName) => {
    const selectedPage = allPages?.find(
      (page) =>
        page.custom === isPageCustom &&
        transformPageNameForURL(page.name) ===
          transformPageNameForURL(decodeURI(pageName))
    );

    return selectedPage ? selectedPage.id : null;
  };

  if (pathDirectories[1] === 'page') {
    return getPageId(true, pathDirectories.slice(2).join('/'));
  } else {
    return getPageId(false, pathDirectories[1]);
  }
};
