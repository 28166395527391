import React, { memo } from 'react';
import { format, isSameDay } from 'date-fns';
import c from '../calendar.module.scss';

export const CalendarHeaderDays = memo(
  ({ weekDataDays = [], currentDate = null, expanded = true }) => {
    return (
      <div
        className={c.daysHeader}
        id="weekDays"
        data-expanded={expanded ? 'true' : 'false'}
      >
        {expanded && (
          <div className={c.timezone}>{format(new Date(), 'z')}</div>
        )}
        {weekDataDays.map((day, i) => (
          <div
            key={i}
            className={c.days}
            data-highlight={isSameDay(day.date, currentDate) ? '' : null}
          >
            <span key={'day' + day.date}>{format(day.date, 'eee')}</span>
            <div className={c.date} key={'number' + day.date}>
              {format(day.date, 'd')}
            </div>
          </div>
        ))}
      </div>
    );
  }
);
