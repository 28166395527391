const wallpaperTextColorsList = [
  '#FF5B25',
  '#003567',
  '#E39326',
  '#00838C',
  '#2A1506',
  '#333333',
  '#A32935',
];

export default wallpaperTextColorsList;
