import { Icon } from '@monash/portal-react';
import { isStringEmpty } from '@monash/portal-frontend-common';
import c from './icon-input.module.scss';
import React, { useRef, useState, useEffect } from 'react';
import IconsMenu from './icons-menu/IconsMenu';
import IconDisplay from '../icon-display/IconDisplay';

const IconInput = ({
  iconInput,
  setIconInput,
  currentUrl,
  urlInput,
  isShortcutActive,
}) => {
  const iconButtonRef = useRef();
  const [iconMenuShown, setIconMenuShown] = useState(false);

  // Remove favicon on url input changes
  useEffect(() => {
    if (iconInput?.type === 'favicon') {
      // remove favicon only when input is different from current shortcut or when its a newly added shortcut
      if (currentUrl !== urlInput || isStringEmpty(currentUrl)) {
        setIconInput(null);
      }
    }
  }, [urlInput]);

  // Close icon menu when shortcut is in drag and drop action
  useEffect(() => {
    if (isShortcutActive) {
      setIconMenuShown(false);
    }
  }, [isShortcutActive]);

  return (
    <div className={c.iconInput} data-status={iconInput}>
      <button
        className={c.button}
        onClick={() => setIconMenuShown(true)}
        type="button"
        ref={iconButtonRef}
      >
        {iconInput ? (
          <IconDisplay icon={iconInput} url={urlInput} />
        ) : (
          <Icon.Plus size={24} color="var(--card-text-secondary-color)" />
        )}
      </button>
      {iconMenuShown ? (
        <IconsMenu
          isShown={iconMenuShown}
          setIsShown={setIconMenuShown}
          triggerRef={iconButtonRef}
          urlInput={urlInput}
          iconInput={iconInput}
          setIconInput={setIconInput}
        />
      ) : null}
    </div>
  );
};

export default IconInput;
