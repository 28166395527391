import React, { forwardRef, memo } from 'react';
import { format, isSameDay } from 'date-fns';
import { isStringEmpty } from '@monash/portal-frontend-common/dist/utils/string';
import { getDayColumnScreenReaderMsg } from '../utils';
import c from '../calendar.module.scss';
import { useBorderBackground } from 'hooks/use-border-background';
import { useForceUpdate } from 'hooks/use-force-update';
import { Card } from '@monash/portal-react';

export const CalendarKeyDates = memo(
  forwardRef((props, ref) => {
    const {
      weekDataDays = [],
      flatKeyDates = [],
      keyDateItemRefs = [],
      currentDate = null,
      expanded = true,
      openScheduleCard,
    } = props;

    if (flatKeyDates.length === 0) {
      return null;
    }
    let itemCounter = 0;

    const forceUpdate = useForceUpdate();

    return (
      <div
        ref={ref}
        className={c.keyDates}
        data-expanded={expanded ? 'true' : 'false'}
      >
        {/* Offset the timestamp in grid column without pseudo-element */}
        {expanded && <div />}

        {weekDataDays.map((day = {}, i) => {
          const totalKeyDates = day.keyDates.length;
          const hasMultiple = totalKeyDates > 1;

          return (
            <div
              key={i}
              className={`${c.keyDatesColumn} ${
                hasMultiple ? c.isMultiple : ''
              }`}
              data-key-nav-group={`key-dates-column-${i + 1}`}
              data-highlight={isSameDay(day.date, currentDate) ? '' : null}
              aria-label={getDayColumnScreenReaderMsg(day.date, totalKeyDates)}
            >
              {day.keyDates.map((keyDate, j) => {
                const displayTitle = (keyDate.title || '').trim();
                const keyDateRef = keyDateItemRefs?.[itemCounter];
                itemCounter++;
                const dateText = keyDate.start
                  ? format(keyDate.start, 'EEEE d MMMM yyyy')
                  : '';
                const screenReaderTitle = `
                ${dateText}
                ${!isStringEmpty(dateText) ? ', ' : ''}
                ${displayTitle}.
              `;

                const widthPercentage =
                  (100 * (totalKeyDates - j)) / totalKeyDates;
                const leftPercentage = j === 0 ? 0 : 100 - widthPercentage;

                const positioning = hasMultiple
                  ? {
                      left: `${leftPercentage}%`,
                      width: `${widthPercentage}%`,
                    }
                  : {};

                const borderStyle = useBorderBackground(j > 0, keyDateRef, 2);

                return (
                  <Card
                    key={j}
                    className={c.keyDateItemWrapper}
                    style={{
                      ...positioning,
                      ...borderStyle,
                    }}
                    onTransitionEnd={() => {
                      j > 0 && forceUpdate();
                    }}
                    data-tag="key-date"
                  >
                    <button
                      type="button"
                      className={c.keyDateItem}
                      title={displayTitle}
                      aria-label={screenReaderTitle}
                      aria-haspopup="dialog"
                      ref={keyDateRef}
                      onClick={() => {
                        openScheduleCard(keyDateRef, { ...keyDate });
                      }}
                      data-tracking-event="schedule-key-date"
                    >
                      {displayTitle}
                    </button>
                  </Card>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  })
);
