import React, { forwardRef } from 'react';
import s from './edit-menu-shared.module.scss';
import { Modal, ModalSection } from '@monash/portal-react';

const EditMenuMobileModal = forwardRef(
  (
    {
      isShown,
      setIsShown,
      widgetName,
      escapeFocusRef,
      renderWidgetCard,
      renderAdditionalOptions,
      renderCombinedOptions,
    },
    ref
  ) => {
    const closeMenu = () => {
      escapeFocusRef.current.focus();
      setIsShown(false);
    };

    return (
      <Modal
        ref={ref}
        open={isShown}
        onClose={closeMenu}
        dismissOnHistoryNav={true}
        ariaLabel="Widget options"
        ariaDescribedby={null}
        focusablesSelector='button[type="button"]:not([inert] *), input:not([inert])'
      >
        <ModalSection
          title={widgetName + ' options'}
          titleTabIndex={null}
          ariaLabelledby="widgetOptionsModalTitle"
          ariaDescribedby="widgetOptionsModalContent"
          noPadding
        >
          <div className={s.editMenuMobile}>
            <div className={s.widgetPreview}>
              <div
                className={s.widgetCardContainer}
                inert=""
                aria-hidden="true"
              >
                {renderWidgetCard()}
              </div>
            </div>
            <div className={s.widgetOptions}>
              <div className={s.editItems}>
                {renderAdditionalOptions()}
                {renderCombinedOptions()}
              </div>
            </div>
          </div>
        </ModalSection>
      </Modal>
    );
  }
);

export default EditMenuMobileModal;
