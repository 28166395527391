import React from 'react';
import { Icon } from '@monash/portal-react';
import { getIcon } from '@monash/portal-frontend-common';
import c from './search-result-card.module.scss';

const loadingBarsCount = 4;

const SearchResultsCard = ({ entity, loading }) => {
  if (entity.entityType !== 'link') return null;
  return (
    <div className={c.entity}>
      {loading && (
        <div className={c.fakeLink}>
          <div className={c.icon} />
          <div className={c.name} />
          <div className={c.description}>
            {Array(loadingBarsCount)
              .fill('')
              .map((item, i) => (
                <div className={c.bar} key={i} />
              ))}
          </div>
        </div>
      )}
      {!loading && (
        <a
          className={c.link}
          href={entity.entityContent.url}
          target="_blank"
          rel="noreferrer"
          data-search-result-item={entity.entityType}
          aria-label={`${entity.entityContent.name}. ${entity.entityContent.description}. Opens in a new window`}
        >
          <div className={c.linkHeader}>
            {getIcon(entity.entityContent.app)}
            <div className={c.externalIcon}>
              <Icon.External size={24} color="#333333" />
            </div>
          </div>
          <h3 aria-hidden="true">{entity.entityContent.name}</h3>
          <p aria-hidden="true">{entity.entityContent.description}</p>
        </a>
      )}
    </div>
  );
};

export default SearchResultsCard;
