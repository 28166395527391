import React from 'react';
import c from './placeholder.module.scss';
import pet from '../../pet.svg';
import classNames from 'classnames';
import { CONTAINER_WIDTH } from 'hooks/use-container-width';

const Placeholder = ({ day, size }) => {
  return (
    <div
      className={classNames(c.placeholder, {
        [c.small]: size === CONTAINER_WIDTH.SMALL,
      })}
    >
      <h3 className={c.currentDate}>{day.displayDate}</h3>
      <img src={pet} alt="" />
    </div>
  );
};

export default Placeholder;
