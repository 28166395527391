import { formatNewPageName, transformPageNameForURL } from './utils';

export const validateNewPageName = (pages, pageName, newName) => {
  // current page name
  const transformedPageName = transformPageNameForURL(pageName);

  // new name
  const formattedNewPageName = formatNewPageName(newName);
  const transformedNewPageName = transformPageNameForURL(formattedNewPageName);

  const transformedPageNames = pages
    ?.filter((page) => page.custom)
    .map((page) => transformPageNameForURL(page.name));

  let isNewNameValid;
  let inValidMessage = null;

  if (formattedNewPageName.length === 0) {
    // invalid scenario - empty page name
    isNewNameValid = false;
    inValidMessage = 'Page name can not be empty.';
  }

  // invalid scenario - name length exceeded maximum character limit
  else if (formattedNewPageName.length > 255) {
    isNewNameValid = false;
    inValidMessage = 'Page name has exceeded the maximum 255 characters limit.';
  }

  // invalid scenario - page name already exist
  else if (
    transformedPageName !== transformedNewPageName &&
    transformedPageNames.includes(transformPageNameForURL(formattedNewPageName))
  ) {
    isNewNameValid = false;
    inValidMessage = 'Page name exist. Please use a different name.';
  } else {
    isNewNameValid = true;
  }

  // valid scenario

  return {
    valid: isNewNameValid,
    errorMessage: inValidMessage,
  };
};
