import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import { findStandardEventDisplayClass } from 'components/providers/data-provider/utils';
import { WORKSHOP_EVENT_TYPES } from '../../../constants';

export const getClass = (event) => {
  const { eventType, eventKind } = event;
  if (WORKSHOP_EVENT_TYPES.includes(eventType)) {
    return 'Workshop';
  }
  if (eventKind === 'standard') {
    return findStandardEventDisplayClass(event);
  }
  if (eventKind === 'assessment') {
    return capitaliseFirstWord(event.eventType);
  }
};
