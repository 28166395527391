import React, { useState, useContext, useRef, useEffect } from 'react';
import { Data } from 'components/providers/data-provider/DataProvider';
import {
  Alert,
  Icon,
  LoadingIndicator,
  IconButton,
  Modal,
  ModalSection,
  Toggle,
} from '@monash/portal-react';
import { COLOUR_NAMES } from '../unit-colours-menu/constants';
import { validateDisplayDays } from '../utils';
import { useScheduleContext } from 'components/providers/ScheduleProvider';
import UnitColourMenuContent from '../unit-colours-menu/UnitColourMenuContent';
import UnitColourMenuTrigger from '../unit-colours-menu/UnitColourMenuTrigger';
import c from './schedule-settings-menu.module.scss';
import { NO_UNIT_COLOURS_ERROR } from 'constants/error-messages';

const BackButton = ({ onClick }) => {
  return (
    <button type="button" className={c.backButton} onClick={onClick}>
      <Icon.ArrowLeft color="var(--card-text-color)" />
      Back
    </button>
  );
};

/**
 * ScheduleSettingsMobile
 * Single full screen modal that supports submenu layers.
 *
 * To add new menu layers, define a new menu object of the form:
 * {id: ..., title: ..., content: ...}
 * and add it to the menus array.
 *
 * Make use of the goToMenu and goBack functions to send users to different menu layers.
 * This component should automatically maintain the correct order of menu layers that the
 * user has navigated through if these functions are used as intended.
 *
 * This component also aims to manage focus and initialise/restore focus on elements when
 * navigating between layers. Modify the query selector string for focusable elements as
 * required.
 */
const ScheduleSettingsMobile = (props) => {
  const [shown, setShown] = useState(false);
  const [mounted, setMounted] = useState(false);
  const triggerRef = useRef();
  const menuRef = useRef();
  const headerRef = useRef();

  // --- VARIABLES RELATED TO SETTINGS ---
  // Display toggle variables
  const {
    assessmentFilter,
    setAssessmentFilter,
    daysOptions,
    numberOfDisplayDays,
    setNumberOfDisplayDays,
  } = props;

  // Views toggle variables
  const { expanded, setExpanded } = useScheduleContext();
  const viewOptions = [
    {
      value: 'Hour grid',
      data: true,
      ariaLabel: 'Hour grid',
    },
    {
      value: 'List',
      data: false,
      ariaLabel: 'List',
    },
  ];

  // Unit colours variables
  const {
    unitColours,
    unitColourMappings,
    unitColoursIndicesError,
    unitColourMappingsError,
    loading,
  } = useContext(Data);
  const [shownUnitCode, setShownUnitCode] = useState(null);
  const [shownUnitColour, setShownUnitColour] = useState(null);
  const hasSomeUnitColourError =
    unitColoursIndicesError || unitColourMappingsError;

  // --- SINGLE MODAL MULTI-LAYERED MENU PATTERN ---
  // Define menu IDs here. Each ID should be unique and correspond to a menu "layer"
  const BASE_MENU_ID = 0;
  const UNIT_COLOUR_MENU_ID = 1;

  // Stack to track which menu layer should be displayed to ensure correct back button behaviour
  const [menuIdStack, setMenuIdStack] = useState([BASE_MENU_ID]);

  // Maintain a stack of focus indices to remember which item was most recently focused on before going to a different menu layer
  const [focusIndex, setFocusIndex] = useState(null);
  const [focusIndexStack, setFocusIndexStack] = useState([0]);

  const pushFocusIndex = (index) => {
    setFocusIndexStack((f) => {
      const stack = Array.from(f);
      stack.push(index);
      return stack;
    });
  };

  const popFocusIndex = () => {
    const index = focusIndexStack[focusIndexStack.length - 1];
    setFocusIndexStack((f) => {
      const stack = Array.from(f);
      stack.pop();
      return stack;
    });
    return index;
  };

  // Maintain a state of focusable elements
  const [focusables, setFocusables] = useState(null);

  // Modify the selectors as desired
  // button:not([class*="toggle"]) selects all non toggle option buttons (i.e. selects the unit colour buttons)
  // tabindex]:not([tabindex="-1"]) selects only the outer toggle element and not the inner options
  const focusableSelectors =
    'button:not([class*="toggle"]), [tabindex]:not([tabindex="-1"])';

  // Update focusables whenever the menu mounts/changes
  useEffect(
    () => {
      setFocusables(menuRef.current?.querySelectorAll(focusableSelectors));
    },
    // a mounted state is necessary b/c Modal has a transition into existence
    [mounted, menuIdStack]
  );

  // Update focused element
  useEffect(() => {
    if (focusIndex !== null) {
      focusables?.[focusIndex]?.focus();
    }
  }, [focusables, focusIndex]);

  // Keyboard navigation
  const handleKeyDown = (e) => {
    // tabbing should cycle through the focusable elements
    if (e.code === 'Tab') {
      // exit if no focusables
      if (!focusables || focusables.length === 0) {
        return;
      }

      e.preventDefault();
      const first = 0;
      const last = focusables.length - 1;

      // normal tab
      if (!e.shiftKey) {
        // if we reach the end of the menu, wrap back around to the start
        // else, move the focus to the next item
        focusIndex >= last ? setFocusIndex(first) : setFocusIndex((f) => f + 1);
      }
      // shift tab
      else {
        // if we reach the start of the menu, wrap back around to the end
        // else, move the focus to the previous item
        focusIndex <= first || focusIndex === null
          ? setFocusIndex(last)
          : setFocusIndex((f) => f - 1);
      }
    }

    if (e.code === 'Escape') {
      closeMenu();
    }
  };

  // Navigating between menu layers
  const openMenu = () => {
    // reset states
    setMenuIdStack([BASE_MENU_ID]);
    setFocusIndexStack([0]);
    setShownUnitCode(null);
    setShownUnitColour(null);

    setShown(true);
  };

  const closeMenu = () => {
    setShown(false);
    setMounted(false);
    setFocusIndex(null);
    triggerRef.current.focus();
  };

  const goBack = () => {
    // pop menu id stack
    setMenuIdStack((m) => {
      const stack = Array.from(m);
      stack.pop();
      return stack;
    });

    // pop focus index stack
    const focusIndex = popFocusIndex();
    setFocusIndex(focusIndex);
  };

  const goToMenu = (menuId, prevFocusIndex, newFocusIndex) => {
    // push old focus index and set new focus index
    // by default reset focus index to 0
    prevFocusIndex ? pushFocusIndex(prevFocusIndex) : pushFocusIndex(0);
    newFocusIndex ? setFocusIndex(newFocusIndex) : setFocusIndex(0);

    // push given menu id to stack
    setMenuIdStack((m) => {
      const stack = Array.from(m);
      stack.push(menuId);
      return stack;
    });
  };

  // Define menu layers here. Each menu layer should be an object of the form:
  // {id: ..., title: ..., content: ...}
  const baseMenu = {
    id: BASE_MENU_ID,
    title: <h2 ref={headerRef}>Settings</h2>,
    content: (
      <div className={c.mobileSettingsContainer}>
        {/* days toggle - 1/3/7 days */}
        <div>
          <h2>Days</h2>
          <Toggle
            options={daysOptions}
            mode="card"
            fullWidth
            ariaLabel="Days"
            selected={daysOptions.find(
              (opt) => opt.data === numberOfDisplayDays
            )}
            setSelected={(dayOption) =>
              setNumberOfDisplayDays(validateDisplayDays(dayOption.data))
            }
          />
        </div>

        {/* views toggle - hour grid (expanded)/list (collapsed) */}
        <div>
          <h2>Views</h2>
          <Toggle
            options={viewOptions}
            selected={viewOptions.find((opt) => opt.data === expanded)}
            setSelected={(option) => {
              setExpanded(option.data);
            }}
            mode="card"
            fullWidth
            ariaLabel="Views"
            data-tracking-event={
              'schedule-view-' + `${expanded ? 'collapse' : 'expand'}`
            }
          />
        </div>

        {/* display toggle - all events/assessments */}
        <div>
          <h2>Display</h2>
          <Toggle
            options={['All events', 'Assessments']}
            selected={assessmentFilter}
            setSelected={setAssessmentFilter}
            mode="card"
            fullWidth
            ariaLabel="Display"
            data-tracking-event="schedule-assessment-filter"
          />
        </div>

        {/* edit unit colours */}
        <div>
          <h2 id="unit-colour-menu-heading">Edit unit colour</h2>
          <ul
            className={c.unitsList}
            role="menu"
            aria-labelledby="unit-colour-menu-heading"
          >
            {loading.unitColours ? (
              <div className={c.loading}>
                <LoadingIndicator />
              </div>
            ) : hasSomeUnitColourError ? (
              <Alert type="error">{NO_UNIT_COLOURS_ERROR}</Alert>
            ) : (
              Object.entries(unitColours).map(([unitCode, unitColour], i) => (
                <li key={`unit-${unitCode}`} role="menuitem">
                  <UnitColourMenuTrigger
                    className={c.unitRow}
                    unitCode={unitCode}
                    unitColour={unitColour}
                    aria-label={`Unit code: ${unitCode
                      .split('')
                      .join(' ')}, current colour: ${
                      COLOUR_NAMES[unitColourMappings.indexOf(unitColour)]
                    }`}
                    onClick={(e) => {
                      // store the focusable element index
                      const prevFocusIndex = Array.from(focusables).findIndex(
                        (node) => node.isEqualNode(e.target)
                      );

                      // init focus on selected unit colour
                      const newFocusIndex =
                        unitColourMappings.indexOf(unitColour) + 2; // plus 2 to account for back and close buttons

                      // set unitColour and unitCode
                      setShownUnitCode(unitCode);
                      setShownUnitColour(unitColour);

                      goToMenu(
                        UNIT_COLOUR_MENU_ID,
                        prevFocusIndex,
                        newFocusIndex
                      );
                    }}
                  />
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    ),
  };

  const unitColourMenu = {
    id: UNIT_COLOUR_MENU_ID,
    title: <BackButton onClick={goBack} />,
    content: (
      <UnitColourMenuContent
        unitCode={shownUnitCode}
        unitColour={shownUnitColour}
        postSelectionFunc={goBack}
      />
    ),
  };

  // Include all menu layers in the menus array
  const menus = [baseMenu, unitColourMenu];

  // Show the menu layer that is on top of the stack
  const shownMenuId = menuIdStack[menuIdStack.length - 1];
  const shownMenu = menus.find((menu) => menu.id === shownMenuId);

  const modalTitle = 'Settings';

  return (
    <>
      {/* Settings cog button that triggers menu expansion */}
      <IconButton
        ref={triggerRef}
        onClick={openMenu}
        icon={Icon.Setting}
        className={c.settingsButton}
        mode="canvas"
        aria-label={modalTitle}
        aria-haspopup="dialog"
        aria-expanded={shown ? 'true' : 'false'}
      />

      {/* Settings menu */}
      <Modal
        ref={menuRef}
        open={shown}
        onClose={closeMenu}
        onKeyDown={handleKeyDown}
        onMount={() => {
          setMounted(shown);
        }}
        dismissOnHistoryNav={true}
        ariaLabel={modalTitle}
        ariaDescribedby={null}
      >
        <ModalSection
          title={shownMenu.title}
          titleTabIndex={null}
          ariaLabelledby="scheduleSettingMobileModalTitle"
          ariaDescribedby="scheduleSettingMobileModalContent"
        >
          {shownMenu.content}
        </ModalSection>
      </Modal>
    </>
  );
};

export default ScheduleSettingsMobile;
