import React, { useContext, useEffect, useMemo } from 'react';
import {
  SearchBox,
  SkipToContent,
  SkipToContentItem,
} from '@monash/portal-react';
import c from './searchbar.module.scss';
import {
  SEARCH_PLACEHOLDER,
  SearchContext,
} from 'components/providers/SearchProvider';
import { getIcon } from '@monash/portal-frontend-common';

const SearchBar = ({
  hasClearButton,
  hasCloseButton,
  hasSkipToContent,
  onClose,
}) => {
  const {
    searchTarget,
    setSearchTarget,
    searchResult,
    runSearch,
    runSearchSuggestions,
    searchSuggestionsResult,
  } = useContext(SearchContext);
  const query = useMemo(() => {
    new URLSearchParams(window.location.search).get('query');
  }, []);
  useEffect(() => {
    if (query) {
      runSearch(query, false);
    }
  }, [query]);

  useEffect(() => {
    runSearchSuggestions(searchTarget.trim());
  }, [runSearchSuggestions, searchTarget]);

  useEffect(() => {
    searchSuggestionsResult?.quickLinks &&
      searchSuggestionsResult?.quickLinks.forEach((element) => {
        element.icon = getIcon(element.app, 20);
      });

    searchSuggestionsResult?.links &&
      searchSuggestionsResult?.links.forEach((element) => {
        element.icon = getIcon(element.app, 20);
      });
  }, [searchSuggestionsResult]);

  return (
    <div className={c.searchBar}>
      <SearchBox
        searchString={searchTarget}
        onInput={setSearchTarget}
        onSearch={runSearch}
        onClose={onClose}
        placeholder={SEARCH_PLACEHOLDER}
        quickLinks={searchSuggestionsResult?.quickLinks}
        historySearches={searchSuggestionsResult?.searchHistory}
        links={searchSuggestionsResult?.links}
        searchResult={searchResult}
        skipToContent={
          hasSkipToContent ? (
            <SkipToContent id="skipToContentSearch" search={true}>
              <SkipToContentItem
                text="Search Result"
                skipTo="#searchResultContainer [data-search-result-item]"
                targetTabIndex={null}
                onClose={onClose}
              />
            </SkipToContent>
          ) : null
        }
        hasClearButton={hasClearButton}
        hasCloseButton={hasCloseButton}
      />
    </div>
  );
};

export default SearchBar;
