import { transformPageNameForURL } from './utils';

export const getPageIndexFromRoute = (pages, path) => {
  if (!pages || !path) return;
  const pathDirectories = path?.split('/');

  // If search, deselect any active tab
  if (pathDirectories[1]?.startsWith('search')) {
    return null;
  }

  const getPageIndex = (isPageCustom, pageName) => {
    const pageIndex = pages?.findIndex(
      (page) =>
        page.custom === isPageCustom &&
        transformPageNameForURL(page.name) ===
          transformPageNameForURL(decodeURI(pageName))
    );

    return pageIndex !== -1 ? pageIndex : 0;
  };

  if (pathDirectories[1] === 'page') {
    return getPageIndex(true, pathDirectories.slice(2).join('/'));
  } else {
    return getPageIndex(false, pathDirectories[1]);
  }
};
