import React, { useRef } from 'react';
import { themes } from '@monash/portal-react';
import useKeyNavGroups, {
  KEY_NAV_MODE,
} from '../../../hooks/use-key-nav-groups';
import { getCustomThemeGroupAriaLabel } from '../utils';
import ThemesGroup from './themes-group/ThemesGroup';
import c from './select-theme.module.scss';

const SelectTheme = () => {
  const themeCategories = [
    ...new Set(Object.values(themes).map((theme) => theme.category)),
  ];

  const filterThemesByCategory = (category) => {
    const filteredThemes = Object.entries(themes)
      .filter((entry) => entry[1].category === category)
      .map((entry, i) => entry[0]);

    return filteredThemes;
  };

  // AX keyboard nav group enhancement
  const selectThemeContainerRef = useRef(null);
  useKeyNavGroups({
    rootRef: selectThemeContainerRef,
    groupSelector: '[data-key-nav-group^="select-theme-row"]',
    keyNavMode: KEY_NAV_MODE.BOTH,
  });

  return (
    <div className={c.selectTheme} ref={selectThemeContainerRef}>
      <div className={c.themes}>
        {themeCategories.map((item, i) => (
          <ThemesGroup
            key={i}
            category={item}
            themes={filterThemesByCategory(item)}
            groupAriaLabel={getCustomThemeGroupAriaLabel(
              item,
              i + 1,
              themeCategories.length
            )}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectTheme;
