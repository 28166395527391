import { COURSE_STATUS } from '@monash/portal-frontend-common';

const BASE_COURSES_DATA = [
  {
    // since 2017
    courseCode: 'A2000',
    courseVersionNumber: 1,
    isSelfService: false,
    locationCode: 'CLAYTON',
    courseYear: 3,
  },
  {
    // since 2017
    courseCode: 'E3001',
    courseVersionNumber: 1,
    isSelfService: false,
    locationCode: 'CLAYTON',
    courseYear: 4,
  },
  {
    // since 2017
    courseCode: 'E3009',
    courseVersionNumber: 1,
    isSelfService: false,
    locationCode: 'CLAYTON',
    courseYear: 5,
  },
  {
    // since 2017
    courseCode: 'L3001',
    courseVersionNumber: 1,
    isSelfService: false,
    locationCode: 'CLAYTON',
    courseYear: 4,
  },
];

// Constants: mock courses data
export const MOCK_COURSE_PRESETS = {
  ENROLLED: [
    {
      ...BASE_COURSES_DATA[2],
      status: COURSE_STATUS.ENROLLED,
      enrolmentDate: '2022-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
  ],
  INACTIVE_SINGLE: [
    {
      ...BASE_COURSES_DATA[0],
      status: COURSE_STATUS.INACTIVE,
      enrolmentDate: '2023-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
  ],
  INACTIVE_MULTI: [
    {
      ...BASE_COURSES_DATA[1],
      status: COURSE_STATUS.INACTIVE,
      enrolmentDate: '2018-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
    {
      ...BASE_COURSES_DATA[3],
      status: COURSE_STATUS.INACTIVE,
      enrolmentDate: '2023-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
    {
      ...BASE_COURSES_DATA[0],
      status: COURSE_STATUS.INACTIVE,
      enrolmentDate: '2023-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
    {
      ...BASE_COURSES_DATA[1],
      status: COURSE_STATUS.INACTIVE,
      enrolmentDate: '2020-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
  ],
  INTERMIT_SINGLE: [
    {
      ...BASE_COURSES_DATA[0],
      status: COURSE_STATUS.INTERMIT,
      enrolmentDate: '2023-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
  ],
  INTERMIT_MULTI: [
    {
      ...BASE_COURSES_DATA[2],
      status: COURSE_STATUS.INTERMIT,
      enrolmentDate: '2018-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
    {
      ...BASE_COURSES_DATA[3],
      status: COURSE_STATUS.INTERMIT,
      enrolmentDate: '2023-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
    {
      ...BASE_COURSES_DATA[0],
      status: COURSE_STATUS.INTERMIT,
      enrolmentDate: '2023-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
    {
      ...BASE_COURSES_DATA[1],
      status: COURSE_STATUS.INTERMIT,
      enrolmentDate: '2018-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
  ],
  INACTIVE_INTERMIT: [
    {
      ...BASE_COURSES_DATA[3],
      status: COURSE_STATUS.INACTIVE,
      enrolmentDate: '2023-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
    {
      ...BASE_COURSES_DATA[0],
      status: COURSE_STATUS.INTERMIT,
      enrolmentDate: '2022-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
  ],
  MIXED: [
    {
      ...BASE_COURSES_DATA[0],
      status: COURSE_STATUS.INACTIVE,
      enrolmentDate: '2023-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
    {
      ...BASE_COURSES_DATA[1],
      status: COURSE_STATUS.COMPLETED,
      enrolmentDate: '2018-07-28T14:00:00Z',
      completedDate: '2022-07-28T14:00:00Z',
      isCourseCompleted: true,
    },
    {
      ...BASE_COURSES_DATA[2],
      status: COURSE_STATUS.ENROLLED,
      enrolmentDate: '2022-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
  ],
  COMPLETED: [
    {
      courseCode: '3616',
      courseVersionNumber: 3,
      status: 'COMPLETED',
      isCourseCompleted: true,
      enrolmentDate: '2016-04-25T14:00:00Z',
      completedDate: '2016-06-30T14:00:00Z',
      isSelfService: false,
      locationCode: 'CITY',
      courseYear: 1,
    },
    {
      courseCode: '3611',
      courseVersionNumber: 4,
      status: 'COMPLETED',
      isCourseCompleted: true,
      enrolmentDate: '2016-07-17T14:00:00Z',
      completedDate: '2017-06-19T14:00:00Z',
      isSelfService: false,
      locationCode: 'CITY',
      courseYear: 1,
    },
  ],
  DISCONTIN: [
    {
      ...BASE_COURSES_DATA[2],
      status: COURSE_STATUS.DISCONTIN,
      enrolmentDate: '2022-07-28T14:00:00Z',
      completedDate: null,
      isCourseCompleted: false,
    },
  ],
};

/**
 * getMockCourseData
 * @param {string} statusPreset - course status preset token
 * @returns {?array}
 */
export const getMockCourseData = (statusPreset = '') => {
  const mockCourseData = MOCK_COURSE_PRESETS[statusPreset] || null;
  // mark data as mocked
  if (Array.isArray(mockCourseData)) {
    mockCourseData.forEach((data) => {
      data._isMockData = true;
    });
  }
  return mockCourseData;
};
