import React, { useContext } from 'react';
import { Page } from 'components/providers/page-provider/PageProvider';
import PageDropDown from './page-dropdown/PageDropdown';
import c from './mobile-page-nav.module.scss';

const MobilePageNav = () => {
  const { pages } = useContext(Page);

  return pages ? (
    <div className={c.pageNav} role="navigation" aria-label="Pages">
      <PageDropDown />
    </div>
  ) : null;
};
export default MobilePageNav;
