import React, { useContext } from 'react';
import { ExternalLink } from '@monash/portal-react';
import { Data } from 'components/providers/data-provider/DataProvider';
import { getMapsPoiDeepLinkFromIdentifier } from './get-maps-deep-link';

const UserEventCTAs = ({ eventData = {}, mode = 'canvas' }) => {
  const { mapsMonashLink, mapIds } = useContext(Data);

  const isStandardExamEvent =
    eventData?.eventKind === 'standard' && eventData?.eventType === 'exam';
  const eventArchibusID = eventData?.location?.data?.[0]?.archibusID;
  const hasMapId = eventArchibusID ? !!mapIds[eventArchibusID] : false;
  const elmsLink = eventData?.data?.elmsLink;

  const modeProps = {
    card: {
      mode: 'card',
      size: 'medium',
    },
    canvas: {
      mode: 'canvas',
    },
  };

  return (
    <>
      {/* Non-exam type CTAs */}
      {!isStandardExamEvent && elmsLink && (
        <ExternalLink
          text="Open in Moodle"
          link={elmsLink}
          {...modeProps[mode]}
          variant="primary"
          data-tracking-event="upcoming-user-event-cta"
        />
      )}
      {!isStandardExamEvent && !elmsLink && (
        <ExternalLink
          text="Open Moodle"
          link="https://learning.monash.edu"
          {...modeProps[mode]}
          variant="primary"
          data-tracking-event="upcoming-user-event-cta"
        />
      )}
      {!isStandardExamEvent && eventArchibusID && hasMapId && (
        <ExternalLink
          text="View map"
          onClick={() => mapsMonashLink(eventArchibusID)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              mapsMonashLink(eventArchibusID);
            }
          }}
          {...modeProps[mode]}
          variant="secondary"
          tabIndex={0}
          data-tracking-event="upcoming-user-event-cta"
        />
      )}

      {/* Exam type CTAs */}
      {isStandardExamEvent &&
        (() => {
          const mapsPoiDeepLink =
            getMapsPoiDeepLinkFromIdentifier(eventArchibusID);
          return mapsPoiDeepLink.length > 0 ? (
            <ExternalLink
              text="View maps"
              link={mapsPoiDeepLink}
              variant="primary"
              {...modeProps[mode]}
              data-tracking-event="upcoming-user-event-cta"
            />
          ) : null;
        })()}
    </>
  );
};

export default UserEventCTAs;
