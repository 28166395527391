import { format, isSameDay } from 'date-fns';

export const getDueText = ({ currentDate, dueDate }) => {
  const displayDate = format(new Date(dueDate), 'h.mmaaa d MMMM yyyy');

  if (isSameDay(dueDate, currentDate)) {
    return `Due today, ${displayDate}`;
  }

  return `Due ${displayDate}`;
};
