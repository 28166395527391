import React from 'react';
import c from './additional-options.module.scss';
import Checkboxes from './checkboxes/Checkboxes';
import Text from './text/Text';
import ColourList from './colour-list/ColourList';
import { DropdownWithValue } from '@monash/portal-react';
import OptionSwitches from 'components/pages/custom/widget-container/edit-menu/additional-options/option-switches/OptionSwitches';

const AdditionalOptions = ({ data, updateData, additionalOptions }) => {
  const renderAdditionalOption = (additionalOption) => {
    if (!data) return;

    const optionKey = additionalOption.key;
    const optionData = data[additionalOption.key];
    const optionDefault = additionalOption.default;
    const optionName = additionalOption.name;

    switch (additionalOption.editType) {
      case 'checkbox':
        return (
          <Checkboxes
            data={optionData}
            updateData={updateData}
            optionKey={optionKey}
            optionName={optionName}
            options={additionalOption.options}
          />
        );
      case 'text':
        return (
          <Text
            optionData={optionData}
            updateData={updateData}
            optionKey={optionKey}
            optionName={optionName}
            options={additionalOption.options}
            optionDefault={optionDefault}
          />
        );
      case 'colour':
        return (
          <ColourList
            data={optionData}
            updateData={updateData}
            optionKey={optionKey}
            optionName={optionName}
            options={additionalOption.options}
          />
        );
      case 'dropdown':
        return (
          <DropdownWithValue
            value={optionData?.value}
            data={additionalOption.options}
            onChange={(value) => {
              updateData(
                optionKey,
                additionalOption.options?.find(
                  (option) => value === option.value
                )
              );
            }}
          />
        );
      case 'switches':
        return (
          <OptionSwitches
            data={optionData}
            updateData={updateData}
            optionKey={optionKey}
            optionName={optionName}
            options={additionalOption.options}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className={c.additionalOptions}>
      {additionalOptions?.map((additionalOption, i) => {
        return additionalOption.editType === null ? null : (
          <div className={c.item} key={i}>
            <label>{additionalOption.name}</label>
            {renderAdditionalOption(additionalOption)}
          </div>
        );
      })}
    </div>
  );
};

export default AdditionalOptions;
