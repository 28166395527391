import NotesModule from './notes/Notes';
import MPassModule from './m-pass/MPass';
import AssessmentsModule from './assessments/Assessments';
import ImportantDatesModule from './important-dates/ImportantDates';
import ShortcutsModule from './shortcuts/Shortcuts';
import LibraryModule from './library/Library';
import NewsModule from './news/News';
import ClockModule from './clock/Clock';
import ResultsModule from './results/Results';

export const widgetDirectory = {
  0: ShortcutsModule,
  1: NotesModule,
  2: MPassModule,
  3: AssessmentsModule,
  4: ImportantDatesModule,
  5: ResultsModule,
  6: NewsModule,
  7: LibraryModule,
  8: ClockModule,
};

export const getWidgetNameByTypeId = (typeId) => {
  return widgetDirectory[typeId]?.name || '';
};
