import React from 'react';
import c from './sticky-container.module.scss';

const StickyRightContainer = ({ children }) => {
  return (
    <div className={c.stickyContainer} aria-hidden="true">
      {children}
    </div>
  );
};

export default StickyRightContainer;
