import { LoadingIndicator } from '@monash/portal-react';
import c from './loading-wrapper.module.scss';

const LoadingWrapper = () => {
  return (
    <div className={c.loadingWrapper}>
      <LoadingIndicator color="var(--canvas-text-color)" />
    </div>
  );
};

export default LoadingWrapper;
