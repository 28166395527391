import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  forwardRef,
  useLayoutEffect,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import { useResponsiveValue } from '@monash/portal-react';
import { isDomNodeType } from '@monash/portal-frontend-common';
import { getCurrentDate } from '../../../providers/data-provider/utils';
import { getScheduleGridLayoutCalculations, isMobileDevice } from './utils';
import { CalendarHeaderDays } from './components/CalendarHeaderDays';
import { CalendarKeyDates } from './components/CalendarKeyDates';
import { CalendarScheduleTimeGrid } from './components/CalendarScheduleTimeGrid';
import { CalendarScheduleGrid } from './components/CalendarScheduleGrid';
import { HiddenEventsIndicator } from './components/HiddenEventsIndicator';
import ScheduleCard, {
  SCHEDULE_CARD_INSTANCE,
} from '../schedule-card/ScheduleCard';
import { useScheduleContext } from 'components/providers/ScheduleProvider';
import useKeyNavGroups from '../../../../hooks/use-key-nav-groups';
import CollapsedMultiDayEvents from './components/collapsed-multi-day-events/CollapsedMultiDayEvents';
import { isSameDay } from 'date-fns';
import c from './calendar.module.scss';
export const MOBILE_RESPONSIVE = [
  {
    mq: '(max-width: 699px)',
    value: 'S',
  },
  {
    mq: '(min-width: 700px)',
    value: 'L',
  },
];

const Calendar = forwardRef((props, ref) => {
  const {
    weekData = [],
    isSchedulePage,
    numberOfDisplayDays,
    selectedDay,
    userEvents,
  } = props;

  const scrollingContainerRef = useRef(null);
  const keyDatesContainerRef = useRef(null);

  const currentDate = getCurrentDate();
  // Prep flattened data by types
  const flatKeyDates = useMemo(
    () => weekData.flatMap((d) => d.keyDates),
    [weekData]
  );
  const flatSchedule = useMemo(() => {
    return weekData.flatMap((d) => d.items);
  }, [weekData]);

  const startTimes = useMemo(() => {
    return (
      flatSchedule?.map((item) => {
        const d = new Date(item.start.time);
        return d.getHours() + d.getMinutes() / 100; // = hours.minutes, make into string later
      }) || []
    );
  }, [flatSchedule]);

  // Init calendar clickable items refs for details popup
  const scheduleItemRefs = useMemo(
    () => flatSchedule?.map(() => React.createRef()),
    [weekData]
  );
  const keyDateItemRefs = useMemo(
    () => flatKeyDates?.map(() => React.createRef()),
    [weekData]
  );

  const { expanded } = useScheduleContext();

  // Prep schedule grid layout/styles related props
  const { calculatedWeekDaysStyles } = useMemo(
    () =>
      getScheduleGridLayoutCalculations({
        weekDataDays: weekData,
        startTimes,
        expanded,
      }),
    [weekData, startTimes, expanded]
  );

  // Set scrollbar width

  useEffect(() => {
    // if use mobile device remove scrollbar width

    const htmlElement = document.querySelector('html');
    if (htmlElement && isDomNodeType(htmlElement)) {
      if (isMobileDevice()) {
        htmlElement.classList.add('is-mobile-device');
      } else {
        htmlElement.classList.remove('is-mobile-device');
      }
    }
  }, []);

  // Set number of columns for use in CSS
  useLayoutEffect(() => {
    document.documentElement.style.setProperty(
      '--cal-number-of-column',
      weekData.length
    );
  }, [expanded, weekData]);

  const [shown, setShown] = useState(false);
  const [scheduleItemRef, setScheduleItemRef] = useState(null);
  const [displayData, setDisplayData] = useState(null);

  const openScheduleCard = useCallback((item, itemData) => {
    setScheduleItemRef({ ...item });
    setShown(true);
    setDisplayData(itemData);
  }, []);

  // Get a row element, to calculate its height
  const rowRef = useRef(null);
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);

  // Hidden events indicator
  const [showIndicator, setShowIndicator] = useState(false);
  const lastItemRef = useRef(null);

  useEffect(() => {
    setShowIndicator(isSchedulePage);
  }, [isSchedulePage]);

  useLayoutEffect(() => {
    const isTopOfPage =
      scrollingContainerRef.current &&
      scrollingContainerRef.current.scrollTop === 0;

    if (rowRef?.current && isTopOfPage) {
      const rowHeight = rowRef?.current.offsetHeight;
      const NUMBER_OF_HOURS = 7; // 7AM
      const PADDING = 30;
      scrollingContainerRef.current.scrollTo({
        top: rowHeight * NUMBER_OF_HOURS - PADDING,
      });
    }
  }, [isSchedulePage, responsiveSize, scrollingContainerRef]);

  useKeyNavGroups({
    rootRef: ref,
    groupSelector: '[data-key-nav-group^="schedule-column"]',
  });
  useKeyNavGroups({
    rootRef: keyDatesContainerRef,
    groupSelector: '[data-key-nav-group^="key-dates-column"]',
  });

  return (
    <div
      className={c.calendarBorderContainer}
      data-expanded={expanded ? 'true' : 'false'}
    >
      <CalendarHeaderDays
        weekDataDays={weekData}
        currentDate={currentDate}
        expanded={expanded}
      />

      <CalendarKeyDates
        ref={keyDatesContainerRef}
        weekDataDays={weekData}
        currentDate={currentDate}
        flatKeyDates={flatKeyDates}
        keyDateItemRefs={keyDateItemRefs}
        openScheduleCard={openScheduleCard}
        expanded={expanded}
      />
      <div className={c.body} ref={scrollingContainerRef}>
        <div className={c.wrapper} data-expanded={expanded ? 'true' : 'false'}>
          <div>
            <div className={c.activeDayGrid}>
              {/* to account for the extra div in expanded view for the hours column */}
              {expanded && <div />}

              {weekData.map((day) => {
                if (isSameDay(day.date, currentDate)) {
                  return <div className={c.activeDay} key={day.date} />;
                }
                return <div key={day.date} />;
              })}
            </div>
            {!expanded && (
              <CollapsedMultiDayEvents
                numberOfDisplayDays={numberOfDisplayDays}
                selectedDay={selectedDay}
                openScheduleCard={openScheduleCard}
              />
            )}

            {expanded && <div className={c.keyline} />}

            <div
              id="scheduleBodyContainer"
              className={c.schedule}
              ref={ref}
              data-expanded={expanded ? 'true' : 'false'}
            >
              <CalendarScheduleTimeGrid rowRef={rowRef} />

              {/* Offset the timestamp in grid column without pseudo-element */}
              {expanded && <div />}

              <CalendarScheduleGrid
                weekDataDays={weekData}
                currentDate={currentDate}
                expanded={expanded}
                calculatedWeekDaysStyles={calculatedWeekDaysStyles}
                scheduleItemRefs={scheduleItemRefs}
                openScheduleCard={openScheduleCard}
                ref={lastItemRef}
                numberOfDisplayDays={numberOfDisplayDays}
                userEvents={userEvents}
                selectedDay={selectedDay}
                gs
              />
            </div>
          </div>
        </div>

        {/* Need to create a portal to the document.body, to remove the
      Schedule card from the schedules stacking context. Being inside the
      schedule was causing the card to appear underneath the header */}
        {createPortal(
          <ScheduleCard
            instance={SCHEDULE_CARD_INSTANCE.CALENDAR}
            shown={shown}
            setShown={setShown}
            scheduleItemRef={scheduleItemRef}
            displayData={displayData}
          />,
          document.body
        )}
      </div>

      {expanded && showIndicator && (
        <HiddenEventsIndicator
          lastItemRef={lastItemRef}
          weekData={weekData}
          responsiveSize={responsiveSize}
        />
      )}
    </div>
  );
});

export default Calendar;
