import React, { useContext, useRef } from 'react';
import c from './shortcuts.module.scss';
import preview from './assets/preview.png';
import empty from './assets/empty.svg';
import WidgetIcon from '../../widget-library/WidgetIcon';
import { Button, Icon } from '@monash/portal-react';
import { WidgetContext } from 'components/providers/WidgetProvider';
import IconDisplay from './components/icon-display/IconDisplay';
import EditShortcuts from './edit-shortcuts/EditShortcuts';

const Shortcuts = ({
  widgetId,
  inEditMode,
  data,
  updateData,
  escapeSettingActionsFocusRef,
}) => {
  const shortcuts = Array.isArray(data?.shortcuts) ? data?.shortcuts : [];
  const {
    editShortcuts,
    editShortcutsActiveWidget,
    editShortcutsEscapeFocusRef,
  } = useContext(WidgetContext);
  const shouldDisplayURL = data?.display?.find((id) => id === 'url');
  const editShortcutsButtonRef = useRef();

  return (
    <div className={c.shortCuts}>
      {editShortcutsActiveWidget === widgetId ? (
        <EditShortcuts
          widgetId={widgetId}
          inEditMode={inEditMode}
          updateData={updateData}
          currentShortcuts={shortcuts}
          escapeFocusRef={
            editShortcutsEscapeFocusRef || escapeSettingActionsFocusRef
          }
        />
      ) : null}
      {shortcuts?.length ? (
        <div className={c.shortcutsList}>
          {shortcuts.map((shortcut, i) => (
            <a
              key={i}
              className={c.shortcut}
              href={shortcut.url}
              target="_blank"
              rel="noreferrer"
              onKeyDown={(e) => e.stopPropagation()}
            >
              {shortcut.icon ? (
                <IconDisplay icon={shortcut.icon} url={shortcut.url} />
              ) : null}
              <div className={c.content}>
                <p className={c.shortcutName}>{shortcut.name}</p>
                {shouldDisplayURL ? (
                  <p className={c.shortcutUrl}>{shortcut.url}</p>
                ) : null}
              </div>
            </a>
          ))}
        </div>
      ) : (
        <div
          className={c.emptyShortcuts}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <div className={c.graphic}>
            <img src={empty} alt="" />
            <p>No shortcuts yet.</p>
          </div>
          <div className={c.editShortcuts}>
            <Button
              ref={editShortcutsButtonRef}
              variant="text"
              size="small"
              icon={<Icon.Pencil />}
              onClick={() => {
                editShortcuts(widgetId, editShortcutsButtonRef);
              }}
              mode="card"
            >
              Add/Edit shortcuts
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const ShortcutsModule = {
  component: Shortcuts,
  name: 'Shortcuts',
  icon: WidgetIcon.Shortcuts,
  previewImage: preview,
  description: 'Save links for quick and easy access',
  additionalOptions: [
    {
      key: 'shortcuts',
      editType: null,
      default: [],
    },
    {
      key: 'display',
      name: 'Display',
      editType: 'checkbox',
      default: [],
      options: [{ id: 'url', name: 'URL' }],
    },
    {
      key: 'name',
      name: 'Name',
      editType: 'text',
      default: 'Shortcuts',
    },
  ],
  additionalActions: [
    {
      action: 'editShortcuts',
      icon: <Icon.Pencil size={20} />,
      text: 'Add/Edit shortcuts',
    },
  ],
};

export default ShortcutsModule;
