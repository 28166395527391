export const pageIds = {
  UPCOMING: 'UPCOMING',
  SCHEDULE: 'SCHEDULE',
  FORYOU: 'SAMPLE',
  UPDATES: 'UPDATES',
};

export const DEFAULT_PAGE_NAMES = {
  UPCOMING: 'UPCOMING',
  SCHEDULE: 'SCHEDULE',
  UPDATES: 'UPDATES',
};

export const MAX_PAGES_NUMBER = 69;
