import { forwardRef } from 'react';
import c from './warning-box.module.scss';
import classNames from 'classnames';

const WarningBox = forwardRef(({ children, className, ...restProps }, ref) => {
  const classes = classNames(c.warningBox, className);
  return (
    <div className={classes} {...restProps} ref={ref}>
      {children}
    </div>
  );
});

export default WarningBox;
