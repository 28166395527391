import { deepClone } from '@monash/portal-frontend-common';
import format from 'date-fns/format';

/**
 * formatImportantDates
 * Takes data from the important dates API and formats it, providing
 * each important start and end date's year, month, date, day of week, and description,
 * as well as bundling important dates that occur on the same day together.
 *
 * @param {array} importantDates - list of important dates from API
 * @returns {array}
 */
export const formatImportantDates = (
  importantDates,
  enrolledTeachingPeriods
) => {
  if (!Array.isArray(importantDates) || !importantDates.length) return [];

  const result = [];

  deepClone(importantDates).forEach((item) => {
    const existing = result.filter(
      (existing) =>
        existing.date === item.date && existing.endDate === item.endDate
    );

    if (existing.length) {
      const existingIndex = result.indexOf(existing[0]);
      result[existingIndex].description = result[
        existingIndex
      ].description.concat(item.description);
    } else {
      if (typeof item.description === 'string')
        item.description = [item.description];
      result.push(item);
    }
  });

  return result.map((item) => {
    const date = {
      description: item.description.map((desc) =>
        formatDescription(desc, enrolledTeachingPeriods, item.cohort)
      ),
      dayOfWeek: format(new Date(item.date), 'EEE'),
      date: format(new Date(item.date), 'dd'),
      month: format(new Date(item.date), 'MMMM yyyy'),
      fullDate: format(new Date(item.date), 'PPPP'),
    };

    if (item.endDate) {
      const endDate = {
        endDayOfWeek: format(new Date(item.endDate), 'EEE'),
        endDate: format(new Date(item.endDate), 'dd'),
        endMonth: format(new Date(item.endDate), 'MMMM yyyy'),
        fullEndDate: format(new Date(item.endDate), 'PPPP'),
      };
      Object.assign(date, endDate);
    }

    return date;
  });
};

/**
 * messageContainsTeachingPeriod
 * Checks if any given important date message contains any of
 * the user's enrolled teaching periods.
 *
 * @param {string} message - message to check the teaching periods against
 * @param {array} teachingPeriods - array containing the user's enrolled teaching periods
 * @returns {boolean}
 */
export const messageContainsTeachingPeriods = (message, teachingPeriods) => {
  if (!Array.isArray(teachingPeriods) || typeof message !== 'string') return;

  // format teaching periods
  const formattedTeachingPeriods = teachingPeriods.map((item) => `(${item})`);

  return formattedTeachingPeriods.some((item) => message.includes(item));
};

/**
 * formatDescription
 * Checks if any given sentence within an important date message mentions one of
 * the user's enrolled teaching periods.
 *
 * @param {string} importantDateDescription - important date description string from API
 * @param {array} enrolledTeachingPeriods - array containing the user's enrolled teaching periods
 * @returns {object}
 */
export const formatDescription = (
  importantDateDescription,
  enrolledTeachingPeriods,
  cohort
) => {
  if (!importantDateDescription) return { title: undefined, body: [], cohort };

  const sentenceArray = importantDateDescription
    .replace(/([.?!:])\s*(?=[A-Z0-9])/g, '$1|')
    .split('|');

  const title = sentenceArray.find((item) => item.endsWith(':'));
  const body = messageContainsTeachingPeriods(
    importantDateDescription,
    enrolledTeachingPeriods
  )
    ? importantDateDescription
        .replace(title, '')
        .trim()
        .split(/\((.*?)\)/)
    : [importantDateDescription.replace(title, '').trim()];

  return { title, body, cohort };
};
