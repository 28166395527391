import React, { useContext } from 'react';
import { lightOrDark } from './utils';
import { Theme } from 'components/providers/theme-provider/ThemeProvider';
import { themes } from '@monash/portal-react';

const WidgetIcon = ({ children }) => {
  return <>{children}</>;
};

export default WidgetIcon;

// colour variables
const iconColours = () => {
  const defaultThemes = Object.values(themes)
    .filter((item) => item.category === 'Defaults')
    .map((item) => item.name);
  const { selectedTheme, customThemeVariables } = useContext(Theme);

  // default values
  if (defaultThemes.includes(selectedTheme)) {
    // Original multi-coloured icons
    const cardColour = customThemeVariables.cardColor
      ? customThemeVariables.cardColor
      : '#ffffff';
    const lightMode = lightOrDark(cardColour) === 'light';
    return {
      // standard colours
      iconBackground: cardColour,
      iconBorder: lightMode ? '#E5E5E5' : cardColour,

      // dynamic colours
      dBlue300: lightMode ? '#91BDED' : '#FFFFFF',
      dBlue400: lightMode ? '#5893DA' : '#BFDBFE',
      dGreen600: lightMode ? '#429A46' : '#99E5A0',
      dGreen700: lightMode ? '#38853C' : '#BBF7D0',
      dOrange400: lightMode ? '#FF9800' : '#FFFFFF',
      dPurple700: lightMode ? '#764EA6' : '#BA9EDB',
      dTeal600: lightMode ? '#0D8294' : '#2DD4BF',
      dYellow500: lightMode ? '#EAB308' : '#FACC15',

      // static colours
      sYellow400: '#FACC15',
      sOrange400: '#FF9800',

      // icon specific colours
      mPassBlue400: lightMode ? '#5893DA' : '#FFFFFF',
    };
  } else {
    const bgColour = customThemeVariables.cardColor
      ? customThemeVariables.cardColor
      : 'none';
    const iconColour = customThemeVariables.cardCtaColor
      ? customThemeVariables.cardCtaColor
      : '#000000';
    // Match icons to theme
    return {
      // standard colours
      iconBackground: bgColour,
      iconBorder: bgColour,

      // dynamic colours
      dBlue300: iconColour,
      dBlue400: iconColour,
      dGreen600: iconColour,
      dGreen700: iconColour,
      dOrange400: iconColour,
      dPurple700: iconColour,
      dTeal600: iconColour,
      dYellow500: iconColour,

      // static colours
      sYellow400: iconColour,
      sOrange400: iconColour,

      // icon specific colours
      mPassBlue400: iconColour,
    };
  }
};

WidgetIcon.Assessments = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="40"
      height="40"
      rx="4"
      stroke="none"
      fill={colours.iconBackground}
    />
    <g clipPath="url(#clip0_12247_840)">
      <rect
        x="12"
        y="12"
        width="16"
        height="18"
        rx="1"
        stroke={colours.dGreen600}
        strokeWidth="2"
      />
      <path
        d="M16 21H16.01"
        stroke={colours.dOrange400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 21H24"
        stroke={colours.dOrange400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 25H16.01"
        stroke={colours.dOrange400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 25H24"
        stroke={colours.dOrange400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 12C16 11.2044 16.281 10.4413 16.781 9.87868C17.2811 9.31607 17.9594 9 18.6667 9H21.3333C22.0406 9 22.7189 9.31607 23.219 9.87868C23.719 10.4413 24 11.2044 24 12C24 12.7956 23.719 13.5587 23.219 14.1213C22.7189 14.6839 22.0406 15 21.3333 15H18.6667C17.9594 15 17.2811 14.6839 16.781 14.1213C16.281 13.5587 16 12.7956 16 12Z"
        fill={colours.dGreen700}
        stroke="none"
      />
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
    <defs>
      <clipPath id="clip0_12247_840">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

WidgetIcon.Clock = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke="none"
      fill={colours.iconBackground}
    />
    <path
      d="M20 30C25.5229 30 30 25.5229 30 20C30 14.4772 25.5229 10 20 10C14.4771 10 10 14.4772 10 20C10 25.5229 14.4771 30 20 30Z"
      stroke={colours.dTeal600}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M19.8797 14.6768L19.8791 20.1812L23.7652 24.0673"
      stroke={colours.dOrange400}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
  </svg>
);

WidgetIcon.Results = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke="none"
      fill={colours.iconBackground}
    />
    <rect
      x="10"
      y="12"
      width="20"
      height="16"
      rx="1"
      stroke={colours.dBlue400}
      strokeWidth="2"
    />
    <path
      d="M22.75 25.0557L21.3104 29.8545C21.1928 30.2462 21.6002 30.5889 21.966 30.406L24.5 29.139L27.034 30.406C27.3998 30.5889 27.8072 30.2462 27.6896 29.8545L26.25 25.0557"
      fill={colours.sOrange400}
      stroke="none"
    />
    <path
      d="M14 16H26"
      stroke={colours.dOrange400}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 20.5H17"
      stroke={colours.dOrange400}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 22.8518C21 23.7801 21.3687 24.6703 22.0251 25.3267C22.6815 25.9831 23.5717 26.3518 24.5 26.3518C25.4283 26.3518 26.3185 25.9831 26.9749 25.3267C27.6312 24.6703 28 23.7801 28 22.8518C28 21.9235 27.6312 21.0333 26.9749 20.3769C26.3185 19.7206 25.4283 19.3518 24.5 19.3518C23.5717 19.3518 22.6815 19.7206 22.0251 20.3769C21.3687 21.0333 21 21.9235 21 22.8518Z"
      fill={colours.sYellow400}
      stroke="none"
    />
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
  </svg>
);

WidgetIcon.ImportantDates = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke="none"
      fill={colours.iconBackground}
    />
    <g clipPath="url(#clip0_12247_879)">
      <path
        d="M11 12.5C11 12.1022 11.158 11.7206 11.4393 11.4393C11.7206 11.158 12.1022 11 12.5 11H27.5C27.8978 11 28.2794 11.158 28.5607 11.4393C28.842 11.7206 29 12.1022 29 12.5V27.5C29 27.8978 28.842 28.2794 28.5607 28.5607C28.2794 28.842 27.8978 29 27.5 29H12.5C12.1022 29 11.7206 28.842 11.4393 28.5607C11.158 28.2794 11 27.8978 11 27.5V12.5Z"
        stroke={colours.dYellow500}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 10V14"
        stroke={colours.dBlue300}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 10V14"
        stroke={colours.dBlue300}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 23C14 22.4477 14.4477 22 15 22H17C17.5523 22 18 22.4477 18 23V25C18 25.5523 17.5523 26 17 26H15C14.4477 26 14 25.5523 14 25V23Z"
        fill={colours.dOrange400}
        stroke={colours.dOrange400}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="10"
        y1="18"
        x2="30"
        y2="18"
        stroke={colours.dBlue300}
        strokeWidth="2"
      />
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
    <defs>
      <clipPath id="clip0_12247_879">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

WidgetIcon.Library = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke="none"
      fill={colours.iconBackground}
    />
    <path
      d="M13 12C13 11.4477 13.4477 11 14 11H24C25.6569 11 27 12.3431 27 14V25C27 26.6569 25.6569 28 24 28H14C13.4477 28 13 27.5523 13 27V12Z"
      stroke={colours.dGreen600}
      strokeWidth="2"
    />
    <path
      d="M15 31C15 31.5523 15.4477 32 16 32C16.5523 32 17 31.5523 17 31H15ZM15 10V31H17V10H15Z"
      fill={colours.sYellow400}
      stroke="none"
    />
    <path
      d="M20 15H23"
      stroke={colours.dOrange400}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 19H23"
      stroke={colours.dOrange400}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
  </svg>
);

WidgetIcon.MPass = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="3.5"
      stroke="none"
      fill={colours.iconBackground}
    />
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
    <ellipse
      cx="19.6346"
      cy="11"
      rx="2.42249"
      ry="2.99999"
      stroke="none"
      fill={colours.sYellow400}
    />
    <path
      d="M20.8458 8H19.6346V8.63636H20.8458V8Z"
      stroke="none"
      fill={colours.sYellow400}
    />
    <ellipse
      cx="20.6439"
      cy="11"
      rx="2.42249"
      ry="2.99999"
      stroke="none"
      fill={colours.sOrange400}
    />
    <path
      d="M13.8336 15.0658L14.112 14.8699L14.1479 14.5315L14.4131 12.03C14.4165 11.9974 14.4262 11.985 14.4318 11.9788C14.4406 11.969 14.4569 11.9574 14.4805 11.9511C14.5041 11.9448 14.524 11.9467 14.5364 11.9507C14.5444 11.9533 14.559 11.9592 14.5783 11.9857L15.7594 13.6083L16.0443 13.9997L16.5182 13.9013C16.9959 13.8021 17.4915 13.7499 18 13.7499H22C26.0041 13.7499 29.25 16.9958 29.25 20.9999C29.25 23.4993 27.9859 25.7037 26.0586 27.0084L25.7637 27.208L25.732 27.5627C25.7276 27.6122 25.718 27.6632 25.7023 27.7155L25.1023 29.7155C25.0072 30.0327 24.7152 30.25 24.384 30.25H23.616C23.2848 30.25 22.9928 30.0327 22.8977 29.7155L22.6183 28.7844L22.458 28.2499H21.9H19.1H18.542L18.3817 28.7844L18.1023 29.7155C18.0072 30.0327 17.7152 30.25 17.384 30.25H16.616C16.2848 30.25 15.9928 30.0327 15.8977 29.7155L15.4032 28.0673L15.3055 27.7415L14.996 27.6004C13.3124 26.8328 11.9733 25.4392 11.2767 23.7185L11.087 23.2499H10.5815H10.5C10.0858 23.2499 9.75 22.9141 9.75 22.4999V19.4999C9.75 19.0857 10.0858 18.7499 10.5 18.7499H10.5815H11.087L11.2767 18.2813C11.8037 16.9793 12.6988 15.8641 13.8336 15.0658Z"
      stroke={colours.dBlue400}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="15" cy="19" r="1" stroke="none" fill={colours.mPassBlue400} />
  </svg>
);

WidgetIcon.News = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke="none"
      fill={colours.iconBackground}
    />
    <path
      d="M20 11V11C25.3443 15.8099 25.3443 24.1901 20 29V29"
      stroke={colours.dOrange400}
      strokeWidth="2"
    />
    <path
      d="M20 11V11C14.6557 15.8099 14.6557 24.1901 20 29V29"
      stroke={colours.dOrange400}
      strokeWidth="2"
    />
    <path
      d="M12 17L28 17"
      stroke={colours.dBlue400}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12 23L28 23"
      stroke={colours.dBlue400}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="20" cy="20" r="9" stroke={colours.dBlue400} strokeWidth="2" />
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
  </svg>
);

WidgetIcon.Notes = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="3.5"
      stroke="none"
      fill={colours.iconBackground}
    />
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
    <g clipPath="url(#clip0_12246_638)">
      <path
        d="M11 28V12C11 11.4477 11.4477 11 12 11H24.5858L29 15.4142V28C29 28.5523 28.5523 29 28 29H12C11.4477 29 11 28.5523 11 28Z"
        stroke={colours.sYellow400}
        strokeWidth="2"
      />
      <path
        d="M15 18H25"
        stroke={colours.dOrange400}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 23H21"
        stroke={colours.dOrange400}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 10V14C25 14.2652 25.1054 14.5196 25.2929 14.7071C25.4804 14.8946 25.7348 15 26 15H30"
        stroke="none"
        fill={colours.dOrange400}
      />
    </g>
    <defs>
      <clipPath id="clip0_12246_638">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

WidgetIcon.Shortcuts = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke="none"
      fill={colours.iconBackground}
    />
    <path
      d="M13.8572 25.6445C12.5303 26.274 11 25.3063 11 23.8375V11C11 9.89543 11.8954 9 13 9H23C24.1046 9 25 9.89543 25 11V23.8375C25 25.3063 23.4697 26.274 22.1428 25.6445L18.8572 24.0858C18.3147 23.8285 17.6853 23.8285 17.1428 24.0858L13.8572 25.6445Z"
      stroke={colours.dOrange400}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M18.8572 30.6445C17.5303 31.274 16 30.3063 16 28.8375V16C16 14.8954 16.8954 14 18 14H28C29.1046 14 30 14.8954 30 16V28.8375C30 30.3063 28.4697 31.274 27.1428 30.6445L23.8572 29.0858C23.3147 28.8285 22.6853 28.8285 22.1428 29.0858L18.8572 30.6445Z"
      fill={colours.iconBackground}
      stroke={colours.dPurple700}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
  </svg>
);

WidgetIcon.Weather = ({ colours = iconColours() }) => (
  <svg
    aria-hidden="true"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="3.5"
      stroke="none"
      fill={colours.iconBackground}
    />
    <rect
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="4"
      stroke={colours.iconBorder}
    />
    <circle cx="22" cy="17" r="7" fill={colours.sYellow400} />
    <path
      d="M11.4886 24.1955L12.0478 23.8906L12.008 23.2549C12.0027 23.1708 12 23.0858 12 23C12 20.7909 13.7909 19 16 19C17.0286 19 17.9645 19.3869 18.6737 20.0248L19.1145 20.4213L19.6738 20.2248C20.0875 20.0795 20.5334 20 21 20C22.5773 20 23.9432 20.9128 24.595 22.2435L24.7751 22.6112L25.1614 22.747C26.5249 23.2265 27.5 24.5257 27.5 26.05C27.5 27.9389 26.0035 29.4791 24.1324 29.5484H24.1241H24.1106L24.097 29.5487C24.0648 29.5496 24.0325 29.55 24 29.55C23.9675 29.55 23.9352 29.5496 23.903 29.5487L23.8894 29.5484H23.8759H12.9773H12.9611L12.9449 29.5489C12.9135 29.5499 12.8819 29.5504 12.8502 29.5504C11.2761 29.5504 10 28.2743 10 26.7002C10 25.6209 10.5996 24.6801 11.4886 24.1955Z"
      fill="white"
      stroke={colours.dBlue400}
      strokeWidth="2"
    />
  </svg>
);
