import { format, isSameDay } from 'date-fns';

export const getTimeOrDueDate = (event) => {
  const { eventKind } = event;
  const startTime = event?.start?.time;
  const endTime = event?.end?.time;
  const start = format(startTime, 'h.mmaaa').replace('.00', '');
  const end = format(endTime, 'h.mmaaa').replace('.00', '');
  const diff = start.slice(-2) !== end.slice(-2);

  const isMultiDayEvent = !isSameDay(startTime, endTime);

  if (isMultiDayEvent) {
    return event.display.showTime('collapsedMultidayLong');
  }
  if (eventKind === 'standard') {
    return `${diff ? start : start.slice(0, -2)}–${end}`;
  }
  if (eventKind === 'assessment') {
    return format(startTime, 'h.mmaaa, EEEE d MMMM yyyy').replace('.00', '');
  }
};
