import classnames from 'classnames';
import { Accordion } from '@monash/portal-react';
import c from './grades-list.module.scss';
import fs from 'styles/font-styles.module.scss';
import { renderGrade } from '../render-grade';
import { useState } from 'react';

export const GradesList = ({ items, data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const textClasses = classnames(fs.body, c.text, { [c.isHidden]: isOpen });

  return (
    <>
      <Accordion
        className={c.gradesAccordion}
        useCustomStyles
        hasExpandAll={false}
        items={items}
        onClick={onClick}
        expandedIndexes={isOpen ? [0] : []}
      />
      <div className={textClasses}>{renderGrade(data, true)}</div>
    </>
  );
};
