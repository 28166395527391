export const eventTypes = {
  CLASS: 'class',
  EXAM: 'exam',
  QUIZ: 'quiz',
  ASSIGNMENT: 'assignment',
  WORKSHOP_SUBMISSION: 'workshop submission',
  WORKSHOP_ASSESSMENT: 'workshop assessment',
};

export const WORKSHOP_EVENT_TYPES = [
  eventTypes.WORKSHOP_SUBMISSION,
  eventTypes.WORKSHOP_ASSESSMENT,
];

export const STANDARD_EVENT_TYPES = [eventTypes.CLASS, eventTypes.EXAM];

export const ASSESSMENT_EVENT_TYPES = [
  eventTypes.QUIZ,
  eventTypes.ASSIGNMENT,
  eventTypes.WORKSHOP_SUBMISSION,
  eventTypes.WORKSHOP_ASSESSMENT,
];
