import React from 'react';
import c from './library.module.scss';
import {
  Accordion,
  ExternalLink,
  Icon,
  IconButton,
} from '@monash/portal-react';
import { format, isValid } from 'date-fns';
import {
  capitaliseFirstWord,
  capitaliseAllWords,
  replaceUnderscoresWithSpaces,
} from '@monash/portal-frontend-common';
import ItemList from './ItemList';
import { parseAuthor, parseTitle } from './utils';
import LibraryItemThumbnail from './LibraryItemThumbnail';

const RequestTile = ({ data }) => {
  const title = parseTitle(data.title);
  const thumbnail = data.thumbnails?.thumbnail;
  const requestStatus = data.request_status;
  const pickupLocation = data.pickup_location;

  return (
    <>
      <LibraryItemThumbnail thumbnail={thumbnail} title={title} />

      <span className={c.itemInfo}>
        {title && <span className={c.title}>{title}</span>}

        <span className={c.requestInfo}>
          {requestStatus && (
            <span>
              Status:{' '}
              {capitaliseAllWords(replaceUnderscoresWithSpaces(requestStatus))}
            </span>
          )}

          {pickupLocation && (
            <span className={c.pickup}>
              Pick up: {capitaliseAllWords(pickupLocation)}
            </span>
          )}
        </span>
      </span>
    </>
  );
};

const RequestPopup = ({ data, setShown, triggerRef }) => {
  const title = parseTitle(data.title);
  const author = parseAuthor(data.author);
  const dueBackDate = new Date(data.due_back_date);
  const requestStatus = data.request_status;
  const pickupLocation = data.pickup_location;
  const requestDate = new Date(data.request_date);
  const category = data.item_policy?.desc;
  const requestType = data.request_type;
  const expiryDate = new Date(data.expiry_date);

  const detailsSection = [
    {
      title: <div>View details</div>,
      content: (
        <div className={c.detailsContent}>
          {category && (
            <div>
              <div className={c.subheading}>Item category</div>
              <div className={c.text}>{capitaliseFirstWord(category)}</div>
            </div>
          )}

          {requestType && (
            <div>
              <div className={c.subheading}>Request type</div>
              <div className={c.text}>
                {capitaliseAllWords(replaceUnderscoresWithSpaces(requestType))}
              </div>
            </div>
          )}

          {isValid(dueBackDate) && (
            <div>
              <div className={c.subheading}>Due date</div>
              <div className={c.text}>{format(dueBackDate, 'd MMMM yyyy')}</div>
            </div>
          )}

          {isValid(requestDate) && (
            <div>
              <div className={c.subheading}>Request date</div>
              <div className={c.text}>{format(requestDate, 'd MMMM yyyy')}</div>
            </div>
          )}

          {isValid(expiryDate) && (
            <div>
              <div className={c.subheading}>Expiry date</div>
              <div className={c.text}>{format(expiryDate, 'd MMMM yyyy')}</div>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={c.popup}>
      <div className={c.header}>
        <h2 className={c.heading}>Requests</h2>
        <IconButton
          icon={Icon.X}
          size={24}
          color="inherit"
          className={c.close}
          onClick={() => {
            setShown(false);
            triggerRef.current.focus();
          }}
          aria-label="Close"
        />
      </div>

      <div className={c.content}>
        {title && (
          <div>
            <div className={c.subheading}>Title</div>
            <div className={c.itemName}>{title}</div>
          </div>
        )}

        {author && (
          <div>
            <div className={c.subheading}>Author</div>
            <div className={c.text}>{author}</div>
          </div>
        )}

        {requestStatus && (
          <div>
            <div className={c.subheading}>Status</div>
            <div className={c.text}>
              {capitaliseAllWords(replaceUnderscoresWithSpaces(requestStatus))}
            </div>
          </div>
        )}

        {pickupLocation && (
          <div>
            <div className={c.subheading}>Pick up</div>
            <div className={c.text}>{capitaliseAllWords(pickupLocation)}</div>
          </div>
        )}

        <Accordion
          items={detailsSection}
          useCustomStyles
          hasExpandAll={false}
          className={c.detailsAccordion}
        />
      </div>

      <div className={c.action}>
        <ExternalLink
          text="Open in Library"
          link="https://www.monash.edu/library"
          variant="primary"
        />
      </div>
    </div>
  );
};

const Requests = ({ data, error = false }) => {
  const items = data.user_request;

  if (error) {
    return <>TODO: insert error messaging</>;
  } else
    return (
      <ItemList
        ItemTile={RequestTile}
        ItemPopup={RequestPopup}
        itemsData={items}
      />
    );
};

export default Requests;
