import { isStringEmpty } from '@monash/portal-frontend-common';

/**
 * getMapsPoiDeepLinkFromIdentifier
 * @param {string} identifier - MazeMap POI identifier string (archibusID)
 * @returns {string}
 */
export const getMapsPoiDeepLinkFromIdentifier = (identifier) => {
  if (isStringEmpty(identifier)) {
    return '';
  }
  return `https://maps.monash.edu/?sharepoitype=identifier&sharepoi=${identifier}`;
};
