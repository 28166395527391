import { Accordion } from '@monash/portal-react';
import { getOptionFilterText } from '../../utils';
import OptionSwitches from '../../../../widget-container/edit-menu/additional-options/option-switches/OptionSwitches';
import c from './filter-option-mobile.module.scss';
import fs from 'styles/font-styles.module.scss';

const FilterOptionMobile = ({ data, updateData, optionsModule }) => {
  const optionKey = optionsModule.key;
  const options = optionsModule.options;
  const optionName = optionsModule.name;
  return (
    <Accordion
      useCustomStyles
      className={c.filterAccordion}
      hasExpandAll={false}
      items={[
        {
          title: (
            <span className={`${fs.body} ${c.text}`}>
              {getOptionFilterText({
                options,
                optionData: data,
                optionName,
              })}
            </span>
          ),
          content: (
            <OptionSwitches
              data={data}
              updateData={updateData}
              optionKey={optionKey}
              options={options}
              optionName={optionName}
            />
          ),
        },
      ]}
    />
  );
};

export default FilterOptionMobile;
