import React, { useState, useCallback } from 'react';
import { TextField } from '@monash/portal-react';
import debounce from 'lodash.debounce';

const INPUT_DEBOUNCE = 200;

const Text = ({
  optionKey,
  optionName,
  optionData,
  updateData,
  optionDefault,
}) => {
  const [value, setValue] = useState(optionData || optionDefault);

  const debouncedUpdateData = useCallback(
    debounce((value) => updateData(optionKey, value), INPUT_DEBOUNCE),
    [optionKey, updateData]
  );

  const handleOnChange = (e) => {
    setValue(e.target.value);
    debouncedUpdateData(e.target.value);
  };

  return (
    <TextField
      onBlur={() => updateData(optionKey, value)}
      value={value}
      placeholder={optionKey}
      errorIcon={null}
      errorMsg={null}
      onChange={handleOnChange}
      aria-label={optionName}
    />
  );
};

export default Text;
