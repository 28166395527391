import {
  differenceInCalendarDays,
  differenceInMinutes,
  endOfDay,
  format,
  isDate,
} from 'date-fns';
import { isNumber } from '@monash/portal-frontend-common/dist/utils/number';
import { ROW_BASE_HEIGHT, ROW_MIN_HEIGHT } from './constant';
import { getDisplayTime } from '../utils';

export const differenceFromEarliest = (start, startTimes) => {
  const baseline = Math.min(...startTimes)
    .toFixed(2)
    .split('.')
    .map((x) => parseInt(x)); // = [hours, minutes]
  return differenceInMinutes(
    start,
    new Date(start).setHours(baseline[0], baseline[1] || 0)
  );
};

/**
 * calculateOffset
 * @param {number} num - start time of the first object of the day, in hh.mm notation
 * @returns {number}
 */
const calculateOffset = (num) => {
  // convert .mm into a percentage of the hour - e.g. 0.3 = 30 mins turns into 0.5 (50% of hour)
  const time = Math.floor(num) + (num % 1) / 0.6;
  return ROW_BASE_HEIGHT * time;
};

/**
 * getScheduleGridLayoutCalculations
 * @param {array} weekDataDays - week days data
 * @param {array} startTimes - start times for all items for the week
 * @param {boolean} expanded - if calendar view is expanded
 * @returns {object}
 */
export const getScheduleGridLayoutCalculations = ({
  weekDataDays = null,
  startTimes = null,
  expanded = true,
} = {}) => {
  if (!Array.isArray(weekDataDays) || !Array.isArray(startTimes)) {
    return {
      calculatedWeekDaysStyles: [],
      maxDayItemDistanceToTop: 0,
    };
  }

  // A flat array with all calculated items' distance to day header
  const calculatedWeekDaysItemsDistancesToTop = [0];

  // Calculate schedule item cards style in numbers (unit: px)
  const calculatedWeekDaysStyles = weekDataDays.map((day = {}) => {
    const itemStyles = day.items.map((item = {}) => {
      const isAssessmentEvent = item.eventKind === 'assessment';

      // Work out item left, width, top to render
      let leftInPct = 0; // default
      let widthInPct = 100; // default
      let rowHeight = 100; // default - multiplier for item heights in expanded vs list view
      let minHeight = 72; // default - multiplier for assessment items in expanded vs list view
      if (expanded) {
        leftInPct = 100 * item.offset;
        widthInPct = 100 - leftInPct;
        rowHeight = ROW_BASE_HEIGHT;
        minHeight = ROW_MIN_HEIGHT;
      }

      // Work out item top to render:
      const offset = calculateOffset(Math.min(...startTimes));
      const itemStart = getDisplayTime(item.start);
      const itemStyleTop =
        (differenceFromEarliest(itemStart, startTimes) / 60) * rowHeight +
        offset;

      // Work out item height to render:
      // Notes:
      // - calculatedItemHeight: supposed height based on event duration
      // - itemStyleHeight: final height applied to item styles
      // - assessments are fixed to only allow 'due time' and 'heading'
      const endDayTime = Number(endOfDay(item.start.time)) + 1;
      const croppedEndTime = Math.min(item.end.time, endDayTime);

      const calculatedItemHeight =
        (differenceInMinutes(croppedEndTime, item.start.time) / 60 || 0.5) *
        rowHeight;
      const itemStyleHeight = isAssessmentEvent
        ? minHeight
        : Math.max(calculatedItemHeight, minHeight);

      // push day item distances to top into flat array
      if (expanded) {
        calculatedWeekDaysItemsDistancesToTop.push(
          Math.ceil(
            (isNumber(itemStyleTop) ? itemStyleTop : 0) +
              (isNumber(itemStyleHeight) ? itemStyleHeight : 0)
          )
        );
      }

      // item data
      return {
        top: itemStyleTop,
        height: itemStyleHeight,
        leftInPct,
        widthInPct,
      };
    });

    // day data
    return {
      date: day.date,
      items: [...itemStyles],
    };
  });

  // Work out max day column vertical span among the week when view is expanded
  // Notes: this is used to match the dynamic heights of the week's schedule items
  const maxDayItemDistanceToTop = expanded
    ? Math.max(...calculatedWeekDaysItemsDistancesToTop)
    : 0;
  return {
    calculatedWeekDaysStyles,
    maxDayItemDistanceToTop,
  };
};

/**
 * calculateZIndex
 * Calculate overlapping rules for items occurring at the same time
 * @param {string} leftPos - string indicating percentage of tile the item takes up
 * @param {number} iterator
 * @returns {number}
 */
export const calculateZIndex = (leftPos, iterator) => {
  if (leftPos) {
    const num = leftPos.match(/\d+/g);
    // if the item is the only item occurring at that time(left = 0), return z-index of 1
    // otherwise, iterate over all items occurring at the same time, adding 1 to their z-indexes
    return num <= 0 ? 1 : iterator + 1;
  }

  return 1;
};

/**
 * checkIsLastItemOnPage
 * Takes an item style against an array and returns true/false depending on if it is the largest value.
 * @param {array} weekDaysStyles - array of styles
 * @param {number} top - one individual style
 * @returns {boolean}
 */
export const checkIsLastItemOnPage = (weekDaysStyles, top) => {
  // check for invalid inputs
  if (
    !Array.isArray(weekDaysStyles) ||
    weekDaysStyles.length < 1 ||
    !isNumber(top)
  ) {
    return false;
  }

  if (weekDaysStyles && top) {
    const topValues = weekDaysStyles
      .map((item) => item.items.map((item) => item.top))
      .flat();
    return top >= Math.max(...topValues);
  }
};

/**
 * getDayColumnScreenReaderMsg
 * @param {object} date - day column date object
 * @param {number|null} eventsNum - number of day events
 * @returns {string}
 */
export const getDayColumnScreenReaderMsg = (date = null, eventsNum = null) => {
  if (!isDate(date) || !isNumber(eventsNum)) {
    return '';
  }
  return `${eventsNum === 0 ? 'No' : eventsNum} event${
    eventsNum > 1 ? 's' : ''
  }, ${format(date, 'EEEE, d MMMM')}`;
};

/**
 * getCalendarTitle
 * @param {array} currentDays - current day
 * @returns {string}
 */
export const getCalendarTitle = (currentDays, shortenTitle) => {
  if (!currentDays) {
    return;
  }

  const monthFormat = shortenTitle ? 'MMM' : 'MMMM';
  const startMonth = format(currentDays?.[0]?.date, monthFormat);
  const endMonth = format(
    currentDays?.[currentDays.length - 1]?.date,
    monthFormat
  );
  const year = format(currentDays?.[currentDays.length - 1]?.date, 'yyyy');

  return startMonth === endMonth
    ? `${startMonth} ${year}`
    : `${startMonth}-${endMonth} ${year}`;
};

/**
 * getCalendarTitle
 * Calculates the value needed to position collapsed multiday events on the grid
 * @returns {string}
 */
export const calculateGridColumnValue = (
  dateRange,
  event,
  numberOfDisplayDays
) => {
  const startPosition =
    differenceInCalendarDays(event.start.time, dateRange.start) + 1;
  const endPosition =
    differenceInCalendarDays(event.end.time, dateRange.start) + 2;

  return `${Math.max(startPosition, 1)} / ${Math.min(
    endPosition,
    numberOfDisplayDays + 1
  )}`;
};

export const getTimeIndicatorStyleForGrid = (currentDate) => {
  const hours = currentDate?.getHours();
  const minutes = currentDate?.getMinutes() / 60;
  const totalHours = hours + minutes;
  const INDICATOR_HEIGHT = 2;
  return {
    top: `${totalHours * ROW_BASE_HEIGHT - INDICATOR_HEIGHT / 2}px`,
  };
};

/**
 * isMobileDevice
 * Get if the device is a mobile device
 * @returns {boolean}
 */
// Set scrollbar width
export const isMobileDevice = (ua = window.navigator.userAgent) => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    ua
  );
};
