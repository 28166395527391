import React from 'react';
import c from './icon-display.module.scss';
import { getIcon } from '@monash/portal-frontend-common';

const IconDisplay = ({ icon, url }) => {
  const renderIcon = () => {
    switch (icon.type) {
      case 'emoji':
        return icon.character;
      case 'appIcon':
        return getIcon(icon.app, 32);
      case 'favicon':
        return (
          <img
            src={`https://www.google.com/s2/favicons?domain=${url}&sz=256`}
            alt=""
          />
        );
      default:
        return null;
    }
  };

  return <div className={c.icon}>{renderIcon()}</div>;
};

export default IconDisplay;
