export const matchIcons = (icon1, icon2) => {
  if (!icon1 || !icon2) return false;
  if (icon1.type !== icon2.type) return false;

  switch (icon1.type) {
    case 'emoji':
      return icon1.character === icon2.character;
    case 'appIcon':
      return icon1.app === icon2.app;
    case 'favicon':
      return icon1.type === icon2.type;
    default:
      return false;
  }
};

export const getShortcut = (id, shortcuts) =>
  shortcuts.find((shortcut) => shortcut.id === id);

export const getShortcutIndex = (id, shortcuts) =>
  shortcuts.findIndex((shortcut) => shortcut.id === id);

export const formatUrl = (urlInput) => {
  const url = urlInput.trim();
  const httpsProtocol = 'https://';
  const httpProtocol = 'http://';

  if (url.substr(0, httpsProtocol.length) === httpsProtocol) {
    return url;
  } else if (url.substr(0, httpProtocol.length) === httpProtocol) {
    return httpsProtocol + url.substring(httpProtocol.length);
  } else {
    return httpsProtocol + url;
  }
};
