// Gets events that match by their uniqueId "prefix".
// Example below two are matching by prefix:
// U2061694_C12098089_W1
// U2061694_C12098089_W2
// The workshop submission will end in W1,
// and the workshop assessment will end in W2
export const getLinkedWorkshopEvents = ({ event, allEvents }) => {
  const idPrefix = event.data?.uniqueId?.split('_W')[0];
  return allEvents?.filter((event) =>
    event.data?.uniqueId?.startsWith(idPrefix)
  );
};
