const cardCtaColorsList = [
  { name: 'white', value: '#ebebeb', border: 'var(--card-bg-color)' },
  { name: 'black', value: '#1c1c1c' },
  { name: 'red', value: '#FF073A' },
  { name: 'blue', value: '#475F94' },
  { name: 'yellow', value: '#FFAB0F' },
  { name: 'green', value: '#32BF84' },
  { name: 'purple', value: '#212D96' },
  { name: 'slate', value: '#718199' },
];

export default cardCtaColorsList;
