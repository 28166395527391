import { isColorCloserToBlack } from 'utils/is-color-closer-to-black';
import ChannelIcon from './ChannelIcon';
import { themes } from '@monash/portal-react';
import React from 'react';

const SQUIZ_URL = 'https://monash-search.clients.funnelback.com/s/search.html?';

/**
 * Retrieves news items from squiz according to preferences and permissions
 * @param {Array} channelsList array of channel id's that we want to retrieve
 * @param {Object} profileData data from student profile API
 * @param {Array} userCourses Array of user courses derived from current enrolments API
 * @returns Array of news item data
 */
export const getNewsData = async (channelsList, profileData, userCourses) => {
  if (!Array.isArray(channelsList) || channelsList.length === 0) return [];

  const nonProd = process.env.REACT_APP_ENV !== 'prod';
  const domestic = profileData?.citizenshipType === 'Domestic';
  const enrolledCampus = userCourses
    .filter((x) => ['ENROLLED', 'INACTIVE', 'INTERMIT'].includes(x.status))
    .map((x) => x.locationCode.toLowerCase());

  const query = [
    'form=json',
    'sort=dmetapublished',
    `profile=mobile-cms-json${nonProd ? '-uat_preview' : ''}`,
    `collection=monash~sp-mobile-cms${nonProd ? '-uat' : ''}`,
    `f.Domestic%7Cdomestic=${domestic ? 't' : 'f'}`,
  ];
  enrolledCampus?.forEach((campus) =>
    query.push(`f.Campus%7Ccampus=${campus}`)
  );
  channelsList.forEach((channel) =>
    query.push(`f.Channel+Name%7CchannelName=${channel}`)
  );
  const news = await fetch(SQUIZ_URL + query.join('&')).then((r) => r.json());

  return news.results;
};

export const getChannelInfo = (channelName, selectedTheme) => {
  const universityLogo = isColorCloserToBlack(
    themes[selectedTheme].variables['--card-text-color']
  ) ? (
    <ChannelIcon.University color="var(--card-text-color)" />
  ) : (
    <ChannelIcon.UniversityReverse color="var(--card-text-color)" />
  );

  const channelNameDirectory = {
    monash_university: {
      name: 'Monash University',
      icon: universityLogo,
    },
    student_portal: {
      name: 'Student Portal Team',
      icon: universityLogo,
    },
    international_student_news: {
      name: 'International Student News',
      icon: <ChannelIcon.InternationalStudentNews />,
    },
  };

  if (channelNameDirectory[channelName]) {
    return channelNameDirectory[channelName];
  }

  return {
    name: 'Monash News',
    icon: universityLogo,
  };
};
