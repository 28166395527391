import React from 'react';

import c from '../error-pages-shared.module.scss';
import { ExternalLink } from '@monash/portal-react';

const INTERMIT_COURSE_ADVICE_URL =
  'https://www.monash.edu/students/study-support/course-advice';

export const IntermitMessage = () => {
  return (
    <>
      <div className={c.heading}>You're on a break</div>
      <p>
        You won't see any classes or assessments here right now because you're
        on intermission.
      </p>
      <p>
        Don't forget to check your enrolment and make any changes you need to
        before the start of your next teaching period. For course planning tools
        and key dates, take a look at our{' '}
        <a
          className={c.linkText}
          href={INTERMIT_COURSE_ADVICE_URL}
          target="_blank"
          rel="noreferrer"
          aria-description=". Opens in a new window"
        >
          course advice page
        </a>
        .
      </p>
      <div className={c.actions}>
        <ExternalLink
          text="Course advice"
          link={INTERMIT_COURSE_ADVICE_URL}
          variant="primary"
          mode="canvas"
        />
      </div>
    </>
  );
};
