import React from 'react';
import { IllustrationError } from '@monash/portal-react';
import c from './local-error.module.scss';

const LocalError = ({
  title = 'Sorry, we are currently having trouble retrieving data.',
  message = (
    <p>
      Please try again later or alternatively you can visit{' '}
      <a
        href="https://www.monash.edu"
        target="_blank"
        rel="noreferrer"
        aria-description=". Opens in a new window"
      >
        monash.edu
      </a>
    </p>
  ),
}) => {
  // TODO: team to decide and implement proper way for live screen reader announcements
  return (
    <div className={c.container}>
      <div className={c.illustration}>
        <IllustrationError />
      </div>
      <h1>{title}</h1>
      {message}
    </div>
  );
};
export default LocalError;
