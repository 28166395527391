import { getDisplayStatus } from 'components/utilities/user-events/render-status';
import { endOfDay, startOfDay } from 'date-fns';

// Finds the next day with events, which could be today, or a
// day in the future. Returns the events of the day, minus the
// events after the current time (with the exception of
// non-submitted assessments).
export const filterNextDay = (events, currentDate) => {
  if (!events?.length) return [];

  const todayStart = new Date(currentDate).setHours(0, 0, 0, 0);
  const nextEvent = events.find((event) => {
    const displayStatus = getDisplayStatus({ status: event?.data.status });
    const isNotSubmitted = event?.data.status && displayStatus !== 'SUBMITTED';

    // Pick up any events that aren't submitted, but are earlier today
    if (event.end.time > todayStart && isNotSubmitted) {
      return true;
    }

    return event.end.time > currentDate;
  });

  if (!nextEvent) return [];

  const isToday = (event) => {
    // Cases (with multiday events in mind):
    // 1. Next event starts/started on/before today:
    //      Should filter to find events that fall on today
    // 2. Next event starts on a future day:
    //      Should filter to find events that fall on the day that the next event starts on

    const eventDayStart = startOfDay(event.start.time);
    const eventDayEnd = endOfDay(event.end.time);

    if (nextEvent.start.time <= endOfDay(currentDate)) {
      return eventDayStart <= currentDate && currentDate <= eventDayEnd;
    } else {
      const nextEventDayStart = startOfDay(nextEvent.start.time);
      return (
        eventDayStart <= nextEventDayStart && nextEventDayStart <= eventDayEnd
      );
    }
  };

  const todaysEvents = events.filter(isToday);
  const visibleEvents = todaysEvents.filter((event) => {
    const displayStatus = getDisplayStatus({ status: event?.data.status });
    const isNotSubmitted = event?.data.status && displayStatus !== 'SUBMITTED';
    return event.end.time > currentDate || isNotSubmitted;
  });
  return visibleEvents;
};
