import { pageIds } from '../../../constants';
import FY01 from '../../../assets/images/page-banner/for-you/1.svg';
import FY02 from '../../../assets/images/page-banner/for-you/2.svg';
import FY03 from '../../../assets/images/page-banner/for-you/3.svg';
import FY04 from '../../../assets/images/page-banner/for-you/4.svg';
import FY05 from '../../../assets/images/page-banner/for-you/5.svg';
import UP01 from '../../../assets/images/page-banner/upcoming/1.svg';
import SH01 from '../../../assets/images/page-banner/schedule/1.svg';
const { FORYOU, UPCOMING, SCHEDULE } = pageIds;

const content = {
  [FORYOU]: [
    {
      title: 'Welcome to the Student Portal',
      description: `If you've been using my.monash, this is now your go-to portal for everything you need.`,
      backgroundColor: '#e0f6ff',
      backgroundImage: FY01,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/1.json'),
    },
    {
      title: 'Your home has custom pages',
      description:
        "That's the page you're on now! You can create, rearrange and rename all these pages, and add widgets (which let you take notes, see your assessments, check your M-Pass balance and more).",
      backgroundColor: '#e0f6ff',
      backgroundImage: FY02,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/2.json'),
    },
    {
      title: 'Decorate your home',
      description:
        'Match your background to your style and mood. Choose colours to personalise your home by clicking on your avatar and selecting.',
      backgroundColor: '#e0f6ff',
      backgroundImage: FY03,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/3.json'),
    },
    {
      title: 'Check your profile',
      description:
        'Click your avatar at the top-right of the page to access your profile. From there, you can change your avatar, update your pronouns, and check your personal details, WAM/GPA and course progress.',
      backgroundColor: '#e0f6ff',
      backgroundImage: FY04,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/4.json'),
    },
    {
      title: 'App launcher for easy access',
      description:
        'Open the app launcher to access useful University resources. If you’re looking for something, click on the magnifying glass on the top-right of the page to search through Monash links.',
      backgroundColor: '#e0f6ff',
      backgroundImage: FY05,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/5.json'),
    },
  ],
  [UPCOMING]: [
    {
      title: 'These are your upcoming activities',
      description:
        'Upcoming classes, assignments and important dates show up on this page.',
      backgroundColor: '#e0f6ff',
      backgroundImage: UP01,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/upcoming/1.json'),
    },
  ],
  [SCHEDULE]: [
    {
      title: 'This is your schedule',
      description:
        'View your week or day at a glance.  Click on an activity’s tile to get more information (like its location, description, staff or Moodle link).',
      backgroundColor: '#e0f6ff',
      backgroundImage: SH01,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/schedule/1.json'),
    },
  ],
};

const collegeContent = {
  [FORYOU]: [
    {
      title: 'Welcome to the Student Portal',
      description: `If you've been using my.monash, this is now your go-to portal for everything you need.`,
      backgroundColor: '#e0f6ff',
      backgroundImage: FY01,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/1.json'),
    },
    {
      title: 'Your home has custom pages',
      description:
        "That's the page you're on now! You can create, rearrange and rename all these pages, and add widgets (which let you take notes, see your important dates, check your M-Pass balance and more).",
      backgroundColor: '#e0f6ff',
      backgroundImage: FY02,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/2.json'),
    },
    {
      title: 'Decorate your home',
      description:
        'Match your background to your style and mood. Choose colours to personalise your home by clicking on your avatar and selecting.',
      backgroundColor: '#e0f6ff',
      backgroundImage: FY03,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/3.json'),
    },
    {
      title: 'Check your profile',
      description:
        'Click your avatar at the top-right of the page to access your profile. From there, you can change your avatar, update your pronouns, and check your personal details, WAM/GPA and course progress.',
      backgroundColor: '#e0f6ff',
      backgroundImage: FY04,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/4.json'),
    },
    {
      title: 'App launcher for easy access',
      description:
        'Open the app launcher to access useful University resources. If you’re looking for something, click on the magnifying glass on the top-right of the page to search through Monash links.',
      backgroundColor: '#e0f6ff',
      backgroundImage: FY05,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/for-you/5.json'),
    },
  ],
  [UPCOMING]: [
    {
      title: 'No upcoming activities yet',
      description:
        'This is where you will see your upcoming activities - we are working on it.',
      backgroundColor: '#e0f6ff',
      backgroundImage: UP01,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/upcoming/1.json'),
    },
  ],
  [SCHEDULE]: [
    {
      title: 'No schedule yet',
      description:
        'This is where you will see your schedule - we are working on it.',
      backgroundColor: '#e0f6ff',
      backgroundImage: UP01,
      theme: 'default',
      animation: require('../../../assets/lotties/page-banner/schedule/1.json'),
    },
  ],
};

export const getPageBannerData = (pageId) => {
  return content[pageId];
};

export const getPageBannerCollegeData = (pageId) => {
  return collegeContent[pageId];
};
