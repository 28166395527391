import { useCallback, useEffect } from 'react';
import { isDomNodeType, isStringEmpty } from '@monash/portal-frontend-common';

// constants
const KEY_NAV_DIR = {
  NONE: 'NONE',
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};
const GRID_BOUND = {
  T: 'T', // top
  B: 'B', // bottom
  L: 'L', // left
  R: 'R', // right
};

// utils
const getCellProps = ({
  itemId = null,
  itemsCount = null,
  colCount = null,
} = {}) => {
  const cellX = itemId % colCount > 0 ? itemId % colCount : colCount;
  const cellY = Math.ceil(itemId / colCount);
  const rows = Math.ceil(itemsCount / colCount);
  let cellBounds = ''; // TRBL
  if (cellY === 1) cellBounds += GRID_BOUND.T;
  if (cellY === rows) cellBounds += GRID_BOUND.B;
  if (cellX === 1) cellBounds += GRID_BOUND.L;
  if (cellX === colCount) cellBounds += GRID_BOUND.R;
  return {
    id: itemId,
    cols: colCount,
    rows,
    cellX,
    cellY,
    cellBounds,
  };
};

const useKeyNavGrid = ({
  rootRef = null,
  rootSelector = '',
  cellSelector = '',
  colCount = null,
  isDisabled = false,
}) => {
  const handleKeyDown = useCallback(
    (e) => {
      // exit if not from supported keys
      if (
        e.key !== 'ArrowLeft' &&
        e.key !== 'ArrowRight' &&
        e.key !== 'ArrowUp' &&
        e.key !== 'ArrowDown'
      ) {
        return;
      }
      // exit with invalid column count
      if (!Number.isFinite(colCount) || colCount < 1) return;

      // work out nav direction
      let navDir = '';
      if (e.key === 'ArrowLeft') {
        navDir = KEY_NAV_DIR.LEFT;
      } else if (e.key === 'ArrowRight') {
        navDir = KEY_NAV_DIR.RIGHT;
      } else if (e.key === 'ArrowUp') {
        navDir = KEY_NAV_DIR.UP;
      } else if (e.key === 'ArrowDown') {
        navDir = KEY_NAV_DIR.DOWN;
      } else {
        navDir = KEY_NAV_DIR.NONE;
      }

      // get root node
      const rootNode = isStringEmpty(rootSelector)
        ? rootRef?.current
        : document.querySelector(rootSelector);
      if (!isDomNodeType(rootNode)) return;
      // get cell nodes
      let cellNodes = rootNode.querySelectorAll(cellSelector);
      if (cellNodes.length === 0) return;
      cellNodes = Array.from(cellNodes);
      // get activeElement
      const activeNode = document.activeElement;
      if (activeNode === null) return;
      const rootContainsActiveNode = rootNode.contains(activeNode);
      if (!rootContainsActiveNode) return;
      // get current cell node
      const currentCellNode = activeNode.closest(cellSelector);
      if (currentCellNode === null) return;
      const currentCellIndex = cellNodes.indexOf(currentCellNode); // assumes nodes are wrapped by rows
      if (currentCellIndex === -1) return;
      const currentCellId = currentCellIndex + 1;
      const currentCellProps = getCellProps({
        itemId: currentCellId,
        itemsCount: cellNodes.length,
        colCount,
      });

      // ignore disallowed nav directions on bounds
      const { cellBounds } = currentCellProps;
      if (
        (navDir === KEY_NAV_DIR.LEFT && cellBounds.includes(GRID_BOUND.L)) ||
        (navDir === KEY_NAV_DIR.RIGHT && cellBounds.includes(GRID_BOUND.R)) ||
        (navDir === KEY_NAV_DIR.UP && cellBounds.includes(GRID_BOUND.T)) ||
        (navDir === KEY_NAV_DIR.DOWN && cellBounds.includes(GRID_BOUND.B))
      ) {
        return;
      }

      // work out nav target cell
      let targetCellId = null;
      if (navDir === KEY_NAV_DIR.LEFT) targetCellId = currentCellId - 1;
      if (navDir === KEY_NAV_DIR.RIGHT) targetCellId = currentCellId + 1;
      if (navDir === KEY_NAV_DIR.UP) targetCellId = currentCellId - colCount;
      if (navDir === KEY_NAV_DIR.DOWN) targetCellId = currentCellId + colCount;
      if (targetCellId < 1 || targetCellId > cellNodes.length) {
        console.warn(
          '[useKeyNavGrid]: onKeyDown: Out of bound targetCellId:',
          targetCellId
        );
        return;
      }

      // nav target node
      const targetCellNode = cellNodes[targetCellId - 1];
      cellNodes.forEach((cellNode) => {
        cellNode.setAttribute(
          'tabindex',
          cellNode === targetCellNode ? '0' : '-1'
        );
      });
      targetCellNode.focus();
    },
    [rootRef?.current, rootSelector, cellSelector, colCount]
  );

  useEffect(() => {
    if (!isDisabled) {
      // Notes:
      // use 'capture' phase here to avoid interference
      // from 'target' and 'bubbling' phase listeners
      document.addEventListener('keydown', handleKeyDown, true);
      return () => {
        document.removeEventListener('keydown', handleKeyDown, true);
      };
    }
  }, [isDisabled, handleKeyDown]);
};

export default useKeyNavGrid;
