import React from 'react';
import c from './five-star-grade.module.scss';
import { Icon } from '@monash/portal-react';

const MAX_STAR_COUNT = 5;

const FiveStarGrade = ({ starCount }) => {
  return (
    <span
      className={c.fiveStarGrade}
      role="img"
      aria-label={`Rating: ${starCount} out of ${MAX_STAR_COUNT}stars`}
    >
      {[...Array(MAX_STAR_COUNT).keys()].map((star, i) =>
        i + 1 <= starCount ? (
          <Icon.StarFilled key={i} size={20} />
        ) : (
          <Icon.Star key={i} size={20} />
        )
      )}
    </span>
  );
};

export default FiveStarGrade;
