import { isNumber } from '@monash/portal-frontend-common';
import {
  startOfWeek,
  differenceInCalendarWeeks,
  endOfWeek,
  addDays,
  isMonday,
  previousMonday,
  isSameDay,
  differenceInDays,
  lastDayOfWeek,
  differenceInCalendarDays,
  endOfDay,
  startOfDay,
  startOfYear,
  addYears,
} from 'date-fns';

export const getDisplayTime = (timeObj) => {
  if (isNumber(timeObj.displayShift)) {
    return timeObj.time + timeObj.displayShift;
  }
  return timeObj.time;
};

export const formatWeeks = (
  userEvents,
  assessmentFilter,
  relevantKeyDates = [],
  currentDate
) => {
  // If currentDate is 26 Dec or later, then it's the first week of the next year
  const isDecember26OrLater =
    currentDate.getMonth?.() === 11 && currentDate.getDate() >= 26;
  const firstDay = isDecember26OrLater
    ? startOfWeek(currentDate, { weekStartsOn: 1 })
    : startOfWeek(startOfYear(currentDate), { weekStartsOn: 1 });
  const lastDay = startOfWeek(addYears(firstDay, 1), { weekStartsOn: 1 });
  const numberOfWeeks =
    differenceInCalendarWeeks(lastDay, firstDay, { weekStartsOn: 1 }) + 1;

  // fill in 'numberOfWeeks' worth of weeks
  const calendar = [];
  let weekStart = firstDay;
  for (let i = 0; i < numberOfWeeks; i++) {
    const w = {
      start: weekStart,
      end: endOfWeek(weekStart, { weekStartsOn: 1 }),
      days: [],
    };

    // construct days in the given week
    let tempDay = weekStart;
    let itemCount = 0;
    let keyDateCount = 0;
    for (let d = 0; d < 7; d++) {
      const nextDay = addDays(tempDay, 1);
      const daysEvents = (userEvents || [])
        .filter((x) => x.start.time >= tempDay && x.start.time <= nextDay)
        .filter((x) =>
          assessmentFilter === 'Assessments'
            ? x.eventKind === 'assessment'
            : true
        )
        .map((x) => {
          return { ...x, weekIndex: itemCount++ };
        });

      // stack events that overlap
      const items = daysEvents.map((item, a) => {
        let onTop = 0; // overlaps anything
        let onText = -1; // overlaps text significantly (first 30 mins)
        let onTextOutOf = 0; // how many others share this overlap

        const minDuration = 1800000; // 30 mins
        const itemStart = getDisplayTime(item.start);
        const itemEnd = item.end.time;

        daysEvents.forEach((event, e) => {
          const eventStart = getDisplayTime(event.start);
          const eventEnd = event.end.time;
          // in regard to a given event, item is...
          const after = a >= e;
          const itemTouchEventStart = itemEnd > eventStart;
          const itemTouchEventEnd = itemStart < eventEnd;
          const colliding =
            itemStart <= eventStart ? itemTouchEventStart : itemTouchEventEnd;
          const collidingWithText =
            Math.abs(eventStart - itemStart) <= minDuration;

          onTop += after && colliding && !collidingWithText;
          onText += after && colliding && collidingWithText;
          onTextOutOf += colliding && collidingWithText;
        });
        item.offset = (onText ? onText / onTextOutOf : 0) + 0.1 * onTop;
        return item;
      });

      // add in any key dates
      const keyDates = relevantKeyDates
        .filter((kd) => kd.start >= tempDay && kd.start <= nextDay)
        // only show unique key dates
        .reduce((acc, kd) => {
          if (!acc.some((unique) => unique.title === kd.title)) {
            return [...acc, kd];
          } else {
            return acc;
          }
        }, [])
        .map((kd) => {
          return {
            ...kd,
            weekIndex: keyDateCount++,
          };
        });

      w.days.push({
        date: tempDay,
        keyDates,
        items,
      });
      tempDay = nextDay;
    }
    calendar.push(w);
    weekStart = tempDay;
  }
  return calendar;
};

export const formatDays = (
  userEvents,
  assessmentFilter,
  relevantKeyDates = []
) => {
  if (userEvents.length) {
    // find total scope of the calendar
    const firstUserEvent = startOfWeek(new Date(userEvents[0]?.start.time), {
      weekStartsOn: 1,
    });
    const firstKeyDate = startOfWeek(new Date(relevantKeyDates[0]?.start), {
      weekStartsOn: 1,
    });

    const lastUserEvent = lastDayOfWeek(
      new Date(userEvents[userEvents.length - 1]?.start.time),
      {
        weekStartsOn: 1,
      }
    );

    const lastKeyDate = lastDayOfWeek(
      new Date(relevantKeyDates[relevantKeyDates.length - 1]?.start),
      {
        weekStartsOn: 1,
      }
    );

    const firstDay =
      firstUserEvent > firstKeyDate ? firstKeyDate : firstUserEvent;

    const lastDay = lastKeyDate > lastUserEvent ? lastKeyDate : lastUserEvent;

    // For flat schedule
    const allDays = [];
    // construct days in the given week
    let tempDay = firstDay;
    let itemCount = 0;
    let keyDateCount = 0;

    for (let d = 0; d < differenceInDays(lastDay, firstDay) + 1; d++) {
      const nextDay = addDays(tempDay, 1);
      const daysEvents = userEvents
        .filter((x) => x.start.time >= tempDay && x.start.time < nextDay)
        .filter((x) =>
          assessmentFilter === 'Assessments'
            ? x.eventKind === 'assessment'
            : true
        )
        .map((x) => {
          return { ...x, weekIndex: itemCount++ };
        });
      // stack events that overlap
      const items = daysEvents.map((item, a) => {
        let onTop = 0; // overlaps anything
        let onText = -1; // overlaps text significantly (first 30 mins)
        let onTextOutOf = 0; // how many others share this overlap

        const minDuration = 1800000; // 30 mins
        const itemStart = getDisplayTime(item.start);
        const itemEnd = item.end.time;

        daysEvents.forEach((event, e) => {
          const eventStart = getDisplayTime(event.start);
          const eventEnd = event.end.time;
          // in regard to a given event, item is...
          const after = a >= e;
          const itemTouchEventStart = itemEnd > eventStart;
          const itemTouchEventEnd = itemStart < eventEnd;
          const colliding =
            itemStart <= eventStart ? itemTouchEventStart : itemTouchEventEnd;
          const collidingWithText =
            Math.abs(eventStart - itemStart) <= minDuration;

          onTop += after && colliding && !collidingWithText;
          onText += after && colliding && collidingWithText;
          onTextOutOf += colliding && collidingWithText;
        });
        item.offset = (onText ? onText / onTextOutOf : 0) + 0.1 * onTop;
        return item;
      });

      // add in any key dates
      const keyDates = relevantKeyDates
        .filter((kd) => kd.start >= tempDay && kd.start <= nextDay)
        // only show unique key dates
        .reduce((acc, kd) => {
          if (!acc.some((unique) => unique.title === kd.title)) {
            return [...acc, kd];
          } else {
            return acc;
          }
        }, [])
        .map((kd) => {
          return {
            ...kd,
            weekIndex: keyDateCount++,
          };
        });
      allDays.push({
        date: tempDay,
        keyDates,
        items,
      });
      tempDay = nextDay;
    }
    return allDays;
  }
};

export const getCurrentWeekIndex = (weeks, currentDate) => {
  const current = weeks.findIndex(
    (w) => currentDate >= w.start && currentDate < w.end
  );
  return current > 0 ? current : 0;
};

export const getDayIndex = (days, currentDate) => {
  const current = days?.findIndex((day) => {
    return isSameDay(currentDate, day.date);
  });

  return current >= 0 ? current : null;
};

export const getMondayOfTheWeek = (date) => {
  if (isMonday(date)) {
    return date;
  } else {
    return previousMonday(date);
  }
};

export const fillBlankDays = (days, targetDate, numberOfDisplayDays) => {
  return new Array(numberOfDisplayDays).fill(null).map((_, i) => {
    const currentDay = addDays(targetDate, i);
    const dayIndex = getDayIndex(days, currentDay);

    if (dayIndex !== null) {
      return days[dayIndex];
    }
    return { date: currentDay, items: [], keyDates: [] };
  });
};

export const getCurrentDays = (numberOfDisplayDays, days, currentDate) => {
  switch (numberOfDisplayDays) {
    case 7: {
      const mondayOfCurrentDate = getMondayOfTheWeek(currentDate);
      return fillBlankDays(days, mondayOfCurrentDate, 7);
    }
    case 3: {
      return fillBlankDays(days, currentDate, 3);
    }
    case 1: {
      return fillBlankDays(days, currentDate, 1);
    }
  }
};

// For events that start and end on different days, this function
// will split the event into multiple events per day.
export const createMultiDayEvents = (userEvents) => {
  if (!userEvents) {
    return null;
  }

  const multiDayEvents = userEvents.filter(
    (event) => !isSameDay(event.start.time, event.end.time)
  );

  const uuids = multiDayEvents.map((event) => event.uuid);

  const expandedMultiDayEvents = multiDayEvents.flatMap((event) => {
    const differenceInDays = differenceInCalendarDays(
      event.end.time,
      event.start.time
    );

    const firstDay = {
      ...event,
      start: {
        ...event.start,
        time: event.start.time,
      },
      end: { ...event.end, time: Number(endOfDay(event.start.time)) },
    };

    const numberOfMiddleDays = differenceInDays - 1;
    const middleDays = new Array(numberOfMiddleDays)
      .fill(null)
      .map((_, index) => {
        const targetDay = addDays(event.start.time, index + 1);
        return {
          ...event,
          start: {
            ...event.start,
            time: Number(startOfDay(targetDay)),
          },
          end: { ...event.end, time: Number(endOfDay(targetDay)) },
          hideEventHeader: true,
        };
      });

    const endDay = {
      ...event,
      start: {
        ...event.start,
        time: Number(startOfDay(event.end.time)),
      },
      end: { ...event.end, time: event.end.time },
      hideEventHeader: true,
    };

    return [firstDay, ...middleDays, endDay];
  });

  return {
    uuids,
    events: expandedMultiDayEvents,
  };
};

// checks that the number of display days is valid, otherwise returns a default number
export const validateDisplayDays = (numberOfDisplayDays) => {
  const defaultDisplayDays = 7;
  const validDays = [1, 3, 7];
  if (validDays.includes(numberOfDisplayDays)) {
    return numberOfDisplayDays;
  } else {
    return defaultDisplayDays;
  }
};
