import React, { useRef } from 'react';
import { useOnOutsideClick } from '@monash/portal-frontend-common';
import { Card, Icon, IconButton, Switch } from '@monash/portal-react';
import { getDisplayDate } from '../../../utilities/user-events/get-display-date';
import { useMenuFocus } from 'hooks/use-menu-focus';
import c from './listing-heading.module.scss';

const ListingHeading = ({
  currentDate,
  showLocation,
  setShowLocation,
  headingId,
}) => {
  const itemRefs = useRef([]);
  const triggerRef = useRef();
  const locationCardRef = useRef(null);
  const onClick = () => {
    setShowLocation(!showLocation);
  };

  const { handleKeyDown, setIsShown, isShown } = useMenuFocus({
    triggerRef,
    menuWrapperRef: locationCardRef,
    itemNodes: itemRefs.current,
  });

  useOnOutsideClick({
    refs: [triggerRef, locationCardRef],
    fn: () => setIsShown(false),
  });

  const menuPopupTitle = 'Settings';

  return (
    <div className={c.listingHeading} onKeyDown={handleKeyDown}>
      <h2 id={headingId}>{getDisplayDate(currentDate, currentDate)}</h2>
      <IconButton
        ref={triggerRef}
        onClick={() => setIsShown((prevIsShown) => !prevIsShown)}
        variant="text"
        icon={Icon.Setting}
        className={c.settingsButton}
        aria-label={menuPopupTitle}
        aria-haspopup="menu"
        data-tracking-event="upcoming-setting"
        mode="canvas"
      />
      {isShown && (
        <Card
          className={c.locationCard}
          shadow
          ref={locationCardRef}
          tabIndex={-1}
          role="menu"
          aria-label={menuPopupTitle}
        >
          Location
          <Switch
            on={showLocation}
            ariaLabel="Location"
            onClick={onClick}
            ref={(e) => (itemRefs.current[0] = e)}
          />
        </Card>
      )}
    </div>
  );
};

export default ListingHeading;
