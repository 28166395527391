/**
 * calculateCroppedPixels
 * Notes:
 * - Calculates if an element is cropped off the page
 * - left|right are ignored as they will never crop based on current calendar design
 * @param {object} ref - react element ref
 * @param {object} win - global window object
 * @returns {object} object of numbers of pixels that are being cropped
 */
export const calculateCroppedPixels = (ref, win = window) => {
  if (!ref?.current) {
    return {
      top: 0,
      bottom: 0,
    };
  }

  const bounds = ref.current.getBoundingClientRect();

  return {
    top: bounds.top < 0 ? Math.abs(bounds.top) : 0,
    bottom:
      bounds.bottom > win.innerHeight ? bounds.bottom - win.innerHeight : 0,
  };
};
