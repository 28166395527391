import React, { useContext, useRef, useState } from 'react';
import {
  Button,
  Icon,
  IconButton,
  Toggle,
  useResponsiveValue,
} from '@monash/portal-react';
import { useScheduleContext } from 'components/providers/ScheduleProvider.jsx';
import ScheduleSettingsDesktop from '../../schedule-settings/ScheduleSettingsDesktop';
import ScheduleSettingsMobile from '../../schedule-settings/ScheduleSettingsMobile';
import MiniCalendar from './miniCalendar/MiniCalendar';
import { getCalendarTitle } from '../utils';
import CalendarControls from './CalendarControls';
import WeekSelector from './weekSelector/WeekSelector';
import fs from 'styles/font-styles.module.scss';
import c from '../../schedule.module.scss';
import { AccessibilityContext } from '@monash/portal-frontend-common';

const MOBILE_RESPONSIVE = [
  {
    mq: '(max-width: 799px)',
    value: 'S',
  },
  {
    mq: '(min-width: 800px)',
    value: 'L',
  },
];

const TodayButton = ({
  responsiveSize,
  isSchedulePage,
  setSelectedDay,
  currentDate,
  sliderRef,
}) => {
  return (
    <Button
      aria-label="Snap back to current week"
      tabIndex={isSchedulePage ? 0 : -1}
      onClick={() => {
        setSelectedDay(currentDate);
        sliderRef.current?.focus();
      }}
      variant="text"
      mode="canvas"
      data-tracking-event="schedule-today"
      size={responsiveSize === 'S' ? 'small' : 'medium'}
    >
      Today
    </Button>
  );
};

export const CalendarHeader = ({
  className,
  weeks = [],
  currentDate,
  assessmentFilter = 'All events',
  setAssessmentFilter,
  labels = [],
  isSchedulePage = false,
  sliderRef,
  daysOptions,
  numberOfDisplayDays,
  setNumberOfDisplayDays,
  selectedDay,
  setSelectedDay,
  currentDays,
}) => {
  const { setAppLivePoliteMsg } = useContext(AccessibilityContext);

  const { expanded, setExpanded } = useScheduleContext();

  // Monthly month calendar for user to navigate month quickly
  const [isMiniCalendarOpen, setIsMiniCalendarOpen] = useState(false);
  const triggerRef = useRef();

  // Month and year of current week
  const title = getCalendarTitle(currentDays);
  const shortTitle = getCalendarTitle(currentDays, true);

  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const showSlider = Boolean(numberOfDisplayDays === 7 && weeks.length);

  // Aria-labels/tooltip content
  const calendarViewLabel = `${expanded ? 'Collapse' : 'Expand'} calendar view`;
  const renderDesktopControls = () => {
    return (
      <div className={c.headerControls}>
        <TodayButton
          isSchedulePage={isSchedulePage}
          setSelectedDay={setSelectedDay}
          currentDate={currentDate}
          sliderRef={sliderRef}
        />

        <IconButton
          aria-label={calendarViewLabel}
          icon={expanded ? Icon.ArrowsMinimize : Icon.ArrowsMaximize}
          tabIndex={isSchedulePage ? 0 : -1}
          onClick={() => {
            // provide message to user about the new calendar view state, based on what the previous one was
            setAppLivePoliteMsg(
              `Calendar view ${expanded ? 'collapsed' : 'expanded'}`
            );

            setExpanded(!expanded);
          }}
          mode="canvas"
          className={c.iconButton}
          variant="text"
          data-tracking-event={`schedule-view-${
            expanded ? 'collapse' : 'expand'
          }`}
        />

        <Toggle
          options={['All events', 'Assessments']}
          selected={assessmentFilter}
          setSelected={setAssessmentFilter}
          ariaLabel="Display"
          data-tracking-event="schedule-assessment-filter"
        />

        <ScheduleSettingsDesktop
          daysOptions={daysOptions}
          numberOfDisplayDays={numberOfDisplayDays}
          setNumberOfDisplayDays={setNumberOfDisplayDays}
        />
      </div>
    );
  };

  const renderMobileControls = () => {
    return (
      <div className={c.headerControls}>
        <TodayButton
          responsiveSize={responsiveSize}
          isSchedulePage={isSchedulePage}
          setSelectedDay={setSelectedDay}
          currentDate={currentDate}
          sliderRef={sliderRef}
        />

        <ScheduleSettingsMobile
          daysOptions={daysOptions}
          numberOfDisplayDays={numberOfDisplayDays}
          setNumberOfDisplayDays={setNumberOfDisplayDays}
          assessmentFilter={assessmentFilter}
          setAssessmentFilter={setAssessmentFilter}
        />
      </div>
    );
  };

  return (
    <>
      <div
        className={className}
        role="region"
        aria-label="Schedule calendar toolbar"
      >
        <div className={c.navigation}>
          <div className={c.headerWeeks}>
            <CalendarControls
              numberOfDisplayDays={numberOfDisplayDays}
              setSelectedDay={setSelectedDay}
              selectedDay={selectedDay}
            />
            <button
              type="button"
              ref={triggerRef}
              className={c.title}
              onClick={() => {
                setIsMiniCalendarOpen(!isMiniCalendarOpen);
              }}
              aria-haspopup="dialog"
              aria-expanded={isMiniCalendarOpen ? 'true' : 'false'}
            >
              <div>
                <h2
                  aria-label={title}
                  data-tracking-event="schedule-header-weeks"
                  className={`${fs.heading} ${fs.md}`}
                >
                  {shortTitle}
                </h2>
                <div
                  className={c.viewMiniCalendar}
                  data-hide={isMiniCalendarOpen ? 'true' : 'false'}
                  data-tracking-event="schedule-mini-calendar"
                >
                  <Icon.Today color="var(--canvas-text-color)" />
                </div>
              </div>
            </button>
          </div>

          {responsiveSize === 'S'
            ? renderMobileControls()
            : renderDesktopControls()}
        </div>
      </div>
      <MiniCalendar
        open={isMiniCalendarOpen}
        setOpen={setIsMiniCalendarOpen}
        triggerRef={triggerRef}
        weeks={weeks}
        numberOfDisplayDays={numberOfDisplayDays}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />
      {showSlider && responsiveSize !== 'S' && (
        <WeekSelector
          labels={labels}
          currentDate={currentDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />
      )}
    </>
  );
};

export default CalendarHeader;
