import { Button } from '@monash/portal-react';
import PageContainer from '../PageContainer';
import Stack from 'components/ui/stack/Stack';
import fs from 'styles/font-styles.module.scss';
import UpdateCard from './components/UpdateCard';
import { getCurrentDate } from 'components/providers/data-provider/utils';
import { useEffect, useState } from 'react';
import { fsCollectionListener } from '@monash/portal-frontend-common';
import c from './updates.module.scss';
import { isBetweenDates } from 'utils/is-between-dates';

const Updates = () => {
  const currentDate = getCurrentDate();
  const [dueSoonList, setDueSoonList] = useState([]);
  const [updatesList, setUpdatesList] = useState([]);

  useEffect(() => {
    const getUpdates = async () => {
      fsCollectionListener('updates', (snapshot) => {
        const updatesFromFirestore = snapshot.docs.map((doc) => doc.data());
        const updatesWithoutDrafts = updatesFromFirestore.filter(
          (update) => update.status !== 'draft'
        );

        const dueSoon = updatesWithoutDrafts.filter((update) =>
          isBetweenDates(Date.now(), update.startDate, update.endDate)
        );

        const updates = updatesWithoutDrafts.filter(
          (update) =>
            !update.startDate ||
            !isBetweenDates(Date.now(), update.startDate, update.endDate)
        );

        setDueSoonList(dueSoon);
        setUpdatesList(updates);
      });
    };
    getUpdates();
  }, []);

  return (
    <PageContainer>
      {Boolean(dueSoonList.length) && (
        <>
          <h2 className={`${c.heading} ${fs.md}`}>Due soon</h2>

          <Stack className={c.updatesWrapper} gap="1.6rem">
            {dueSoonList.map((update) => (
              <UpdateCard
                key={update.title}
                currentDate={currentDate}
                dueDate={update.endDate}
                isDueSoon
                {...update}
              />
            ))}
          </Stack>
        </>
      )}

      {Boolean(updatesList.length) && (
        <>
          <h2 className={`${c.heading} ${fs.md}`}>Updates</h2>

          <Stack className={c.updatesWrapper} gap="1.6rem">
            {updatesList.map((update) => (
              <UpdateCard
                key={update.title}
                currentDate={currentDate}
                dueDate={update.endDate}
                {...update}
              />
            ))}
          </Stack>
        </>
      )}

      <div className={c.loadMoreWrapper}>
        <Button mode="canvas" variant="secondary">
          Load more updates
        </Button>
      </div>
    </PageContainer>
  );
};

export default Updates;
