import { Icon } from '@monash/portal-react';
import c from './unit-colour-menu.module.scss';
import React, { useContext } from 'react';
import { Data } from 'components/providers/data-provider/DataProvider';
import { COLOUR_NAMES } from './constants';
import {
  APIContext,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { UPDATE_UNIT_COLOURS_ERROR } from 'constants/error-messages';
import { patchUnitColoursObject } from './utils';

const UnitColourMenuContent = ({
  unitCode,
  unitColour,
  className,
  postSelectionFunc, // Optional function to run after selecting a colour. Useful for optionally closing the menu/going back to previous menu after selection
}) => {
  const { unitColourMappings, unitColoursIndices, setUnitColoursIndices } =
    useContext(Data);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);

  const { updateUnitColours } = useContext(APIContext);
  const { addSnackbar } = useSnackbar();

  const handleSelectColour = (colourIndex) => {
    const newUnitColourIndices = patchUnitColoursObject({
      currentUnitColoursIndices: unitColoursIndices,
      selectedUnitCode: unitCode,
      selectedColourIndex: colourIndex,
    });

    if (!newUnitColourIndices) {
      console.warn('unable to update unit colours');
      resetAppLiveMsgs();
      addSnackbar({
        message: UPDATE_UNIT_COLOURS_ERROR,
        type: 'error',
      });
    }
    // attempt to write to firestore
    else {
      updateUnitColours(newUnitColourIndices)
        .then(() => {
          // successful write, update local state as well
          setUnitColoursIndices(newUnitColourIndices);
        })
        .catch((error) => {
          // unsuccessful, show snackbar error
          console.warn(
            '[updateUnitColours]: api call error, cannot update unit colours',
            error
          );
          resetAppLiveMsgs();
          addSnackbar({
            message: UPDATE_UNIT_COLOURS_ERROR,
            type: 'error',
          });
        });
    }

    postSelectionFunc && postSelectionFunc();
  };

  return (
    <ul
      className={
        className ? [c.colourPalette, className].join(' ') : c.colourPalette
      }
      role="menu"
    >
      {unitColourMappings.map((colour, i) => (
        <li key={`colour-${i}`} role="menuitem">
          <button
            type="button"
            className={c.colourSquare}
            style={{ backgroundColor: colour }}
            onClick={() => {
              handleSelectColour(i);
            }}
            aria-label={COLOUR_NAMES[i]}
            aria-current={colour === unitColour}
          >
            {colour === unitColour && <Icon.Check color="white" size="50%" />}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default UnitColourMenuContent;
