import { captureMessage } from '@sentry/react';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import {
  ROW_BASE_HEIGHT,
  ROW_MIN_HEIGHT,
} from 'components/pages/schedule/calendar/constant';
import {
  format,
  differenceInMinutes,
  formatDuration,
  intervalToDuration,
  startOfDay,
} from 'date-fns';
import { nanoid } from 'nanoid';
import storage from 'utils/storage';

export const formatLocation = (location) => {
  const order = [
    location.roomId,
    location.roomName,
    location.floorName,
    location.buildingName,
    location.streetAddress?.addressLine1,
    location.streetAddress?.addressLine2,
    location.streetAddress?.addressLine3,
    location.campusName,
    location.description,
  ];
  const display = order.filter(Boolean).join(', ');

  return display || '—';
};

export const formatDescription = (data) => {
  const order = [
    // allocate
    data.staff && { key: 'Staff', val: data.staff },

    // moodle
    data.status && { key: 'Status', val: data.status },
    data.weight && { key: 'Weight', val: data.weight },
    data.numberOfAttempts && {
      key: 'Attempts allowed',
      val: data.numberOfAttempts,
    },
  ];
  const display = order.filter((x) => !!x);
  return display;
};

/**
 * findStandardEventDisplayClass
 * @param {object} event object
 * @returns {string}
 */
export const findStandardEventDisplayClass = ({
  eventType,
  data = {},
} = {}) => {
  const DEFAULT_NO_VALUE = 'missing';
  let displayClass = '';
  // class
  if (eventType === 'class') {
    displayClass = capitaliseFirstWord(data.classType) || DEFAULT_NO_VALUE;
  }
  // exam
  else if (eventType === 'exam') {
    displayClass = data.activityType || DEFAULT_NO_VALUE;
  }
  // else
  else {
    displayClass = DEFAULT_NO_VALUE;
  }
  return displayClass.replace('*', '');
};

export const formatTimeDistance = (targetDate, currentDate) => {
  const coarseTimeTill = intervalToDuration({
    start: targetDate,
    end: startOfDay(currentDate),
  });
  const timeTill = intervalToDuration({
    start: targetDate,
    end: currentDate,
  });

  const diffMins = differenceInMinutes(
    new Date(targetDate),
    new Date(currentDate)
  );

  const isMoreThan2Days = diffMins > 60 * 24 * 2;
  const isLessThanAMinute = diffMins < 1;

  if (isMoreThan2Days) {
    return formatDuration(coarseTimeTill, {
      format: ['years', 'months', 'days'],
    });
  }
  if (isLessThanAMinute) {
    return 'less than a minute';
  }

  return formatDuration(timeTill, {
    format: ['days', 'hours', 'minutes'],
  });
};

export const getTestDate = () => {
  const testDateInMillis = parseInt(sessionStorage.getItem('testDate'));
  return !isNaN(testDateInMillis) ? getCurrentDate() : false;
};

export const getCurrentDate = () => {
  // Notes:
  // - tries to find and use valid 'testDate' from sessionStorage
  // - otherwise use real machine date
  const testDateInMillis = parseInt(storage.session.get('testDate'));
  return !isNaN(testDateInMillis) ? testDateInMillis : Date.now();
};

export const getUseFakeData = () => {
  const fakeData = storage.session.get('fakeData');
  return Boolean(fakeData) && JSON.parse(fakeData);
};

export const formatEvents = (userEvents, currentDate) => {
  return userEvents
    .map((event) => {
      let display = {};
      const startTime = event?.start?.time;
      const endTime = event?.end?.time;

      if (event.eventKind === 'standard') {
        // allocate+
        display = {
          unit: event.data.unitCode || 'missing',
          subtitle: event.data.unitTitle,
          class: findStandardEventDisplayClass(event),
          showTime: (type) => {
            const start = format(startTime, 'h.mmaaa').replace('.00', '');
            const end = format(endTime, 'h.mmaaa').replace('.00', '');
            const diff = start.slice(-2) !== end.slice(-2);
            const formatDate = (date, formattingString) =>
              format(date, formattingString).replace('.00', '');

            switch (type) {
              case 'distance':
                return `Starts in ${formatTimeDistance(
                  startTime,
                  currentDate
                )}`;
              case 'time':
                return `${diff ? start : start.slice(0, -2)} - ${end}`;
              case 'collapsedMultiday':
                return `${formatDate(
                  startTime,
                  'h.mmaaa d MMM'
                )} - ${formatDate(endTime, 'h.mmaaa d MMM')}`;
              case 'collapsedMultidayLong':
                return `${formatDate(
                  startTime,
                  'h.mmaaa d MMMM'
                )} - ${formatDate(endTime, 'h.mmaaa d MMMM')}`;
              default:
                return format(endTime, 'h.mmaaa');
            }
          },
          location: event.location?.data
            ? formatLocation(
                Array.isArray(event.location.data)
                  ? event.location.data[0]
                  : event.location.data
              )
            : '—',
          description: formatDescription(event.data),
        };
      } else if (event.eventKind === 'assessment') {
        // moodle
        display = {
          unit: event.data.unitCode || 'missing',
          subtitle: event.data.name,
          class: capitaliseFirstWord(event.eventType) || 'missing',
          showTime: (
            type,
            timeFormat = 'h.mmaaa, EEEE d MMMM yyyy',
            defaultFormat = 'h.mmaaa'
          ) => {
            // based on type or distance (eg. if < now+24 hours ? distance : time)
            switch (type) {
              case 'distance':
                return `Due in ${formatTimeDistance(startTime, currentDate)}`;
              case 'time':
                return format(startTime, timeFormat).replace('.00', '');
              default:
                return format(startTime, defaultFormat);
            }
          },
          location: null,
          description: formatDescription(event.data),
        };
      }

      // add time offset for display
      if (startTime === endTime) {
        // based on schedule (for now) smnallest possible item card
        // can refactor here later if we want different behavior per event type
        const minDuration = (ROW_MIN_HEIGHT / ROW_BASE_HEIGHT) * 3600000;
        event.start.displayShift = -1 * minDuration;
      }

      // Extensions
      // Make the extensionDueDate the new start/end time.
      const extensionDueDate = event?.data?.extensionDueDate;
      if (extensionDueDate) {
        event.data.originalDueDate = event.start.time;
        event.start.time = extensionDueDate.time;
        event.end.time = extensionDueDate.time;
      }

      return { ...event, display, id: nanoid() };
    })
    .sort((a, b) => a.start.time - b.start.time);
};

// util function to extract unit titles from academic results api data
export const getUnitTitles = (academicResultsData) => {
  if (
    !academicResultsData ||
    !academicResultsData.courses ||
    !academicResultsData.courses.length
  ) {
    return {};
  }

  const unitTitles = {};

  for (const course of academicResultsData.courses) {
    if (course.unitAttempts) {
      for (const unit of course.unitAttempts) {
        if (unit.unitCode) {
          unitTitles[unit.unitCode] = unit.title;
        }
      }
    }
  }

  return unitTitles;
};

export const calculateUserEvents = async ({
  useFakeData,
  getUserEvents,
  enrolledUnits,
}) => {
  if (!useFakeData) {
    return getUserEvents(enrolledUnits.map((e) => e.unitOfferingId));
  }

  const mockEventsImport = await import('./mock-events');
  const mockEvents = mockEventsImport.default;

  return mockEvents;
};

export const removeNonExistentWidgets = (preferences) => {
  // remove floating widgets / unresolved ids in widgetOrder
  const allWidgetIds = Object.keys(preferences.pages.widgets);
  let inUse = [];
  const customPageIds = Object.keys(preferences.pages.customPages);

  customPageIds.forEach((pageId) => {
    preferences.pages.customPages[pageId].widgetOrder.forEach((id, i) => {
      if (!allWidgetIds.includes(id)) {
        // page is asking for a widget that doesnt exist
        captureMessage(`Bad widget data, removing widget ${id} from ${pageId}`);
        preferences.pages.customPages[pageId].widgetOrder.splice(i, 1);
      }
    });
    inUse = [...inUse, ...preferences.pages.customPages[pageId].widgetOrder];
  });
  allWidgetIds.forEach((id) => {
    if (!inUse.includes(id)) {
      // widget is not on a page
      captureMessage(`Bad widget data, removing ${id} from widgets list`);
      delete preferences.pages.widgets[id];
    }
  });
  return preferences;
};
