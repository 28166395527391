import {
  AppLauncherPad,
  portalLink,
  UserMenu,
  UserInitialSection,
  UserInitialsItem,
  isStringEmpty,
  ImpersonationContext,
  NotificationBadge,
} from '@monash/portal-frontend-common';

import Themer from 'components/themer/Themer';
import React, { useContext, useState, useRef } from 'react';
import c from './nav.module.scss';
import {
  Icon,
  useResponsiveValue,
  Logo,
  IconButton,
  RouterState,
  themes,
  Button,
} from '@monash/portal-react';
import { Page } from 'components/providers/page-provider/PageProvider';
import PageNav from './page-nav/PageNav';
import MobilePageNav from './mobile-page-nav/MobilePageNav';
import { SCROLL_DIRECTION } from 'constants/scroll-direction';
import useScrollDetection from 'hooks/use-scroll-detection';
import { DELAY_TYPE } from 'constants/delay-type';
import { SCROLL_POSITION } from 'constants/scroll-position';
import { Data } from 'components/providers/data-provider/DataProvider';
import {
  SEARCH_PLACEHOLDER,
  SearchContext,
} from 'components/providers/SearchProvider';
import classNames from 'classnames';
import { useFeatureFlags } from 'hooks/use-feature-flags';
import { USER_TYPE } from '../../../constants';
import { Theme } from 'components/providers/theme-provider/ThemeProvider';
import { isColorCloserToBlack } from 'utils/is-color-closer-to-black';

export const MOBILE_RESPONSIVE = [
  {
    mq: '(max-width: 699px)',
    value: 'S',
  },
  {
    mq: '(min-width: 700px)',
    value: 'L',
  },
];

const Nav = () => {
  const featureFlags = useFeatureFlags();

  const { avatar, profileData } = useContext(Data);
  const [showThemer, setShowThemer] = useState(false);
  const { currentUser } = useContext(ImpersonationContext);
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';

  const scrollElemSelectorStr = '#VerticalMain';

  const { direction, position } = useScrollDetection({
    selectorStr: scrollElemSelectorStr,
    delayType: DELAY_TYPE.THROTTLE,
    delay: 200,
    threshold: 10,
    detectUserScrollOnly: true,
  });

  const condensed =
    (direction === SCROLL_DIRECTION.DOWN && position !== SCROLL_POSITION.TOP) ||
    position === SCROLL_POSITION.BOTTOM;

  // Search
  const { redirect, route } = useContext(RouterState);
  const { searchTarget } = useContext(SearchContext);
  const searchPageUrl = `/search?query=${
    !isStringEmpty(searchTarget) ? searchTarget : ''
  }`;

  // AX - set up trigger ref
  const { backgroundStyle } = useContext(Page);

  // ref for pageNav responsive behaviours
  const navMiddleRef = useRef();

  // cache condition for reuse
  const shouldHideTopNav = isMobile && condensed;

  // get user type for app launcher
  const userType = currentUser?.is?.MonashCollege
    ? USER_TYPE.COLLEGE
    : USER_TYPE.GENERAL;

  // Get canvas text color
  const { selectedTheme } = useContext(Theme);

  const shouldUseDefaultLogo = isColorCloserToBlack(
    themes[selectedTheme].variables['--canvas-text-color']
  );

  // Get the Logo variant
  const getLogo = () => {
    if (isMobile) {
      return shouldUseDefaultLogo ? <Logo.Shield /> : <Logo.ShieldReverse />;
    } else {
      return shouldUseDefaultLogo ? (
        <Logo.MonashStacked />
      ) : (
        <Logo.MonashStackedReverse />
      );
    }
  };
  return (
    <div
      className={classNames(c.navContainer, {
        [c.hideTopNav]: shouldHideTopNav,
      })}
      id="topNavContainer"
      role="banner"
    >
      <div className={c.backgroundContainer}>
        <div
          className={c.background}
          style={
            responsiveSize === 'S'
              ? { ...backgroundStyle, width: '100vw', transform: 'unset' }
              : backgroundStyle
          }
        />
      </div>

      <div className={c.nav} id="topNav">
        <div className={c.left}>
          <a href="/" aria-label="home" data-tracking-event="topnav-home-logo">
            {getLogo()}
          </a>
        </div>
        {responsiveSize !== 'S' && (
          <div className={c.middle} ref={navMiddleRef}>
            <PageNav navMiddleRef={navMiddleRef} />
          </div>
        )}

        <div className={c.right}>
          <div className={c.navButton}>
            {featureFlags?.CRITICAL_AND_NON_CRITICAL_NOTIFICATIONS ? (
              <NotificationBadge />
            ) : null}
            <div
              className={c.searchContainer}
              role="navigation"
              aria-label="Search"
            >
              {isMobile ? (
                <IconButton
                  id="search"
                  aria-label="Search"
                  className={c.searchButtonMobile}
                  onClick={() => {
                    redirect(searchPageUrl);
                  }}
                  icon={Icon.Search}
                  data-tracking-event="topnav-search"
                />
              ) : (
                <Button
                  size="medium"
                  id="search"
                  aria-label="Search"
                  icon={<Icon.Search />}
                  onClick={() => {
                    redirect(searchPageUrl);
                  }}
                  mode="canvas"
                  variant="primary"
                  className={c.searchButton}
                  data-tracking-event="topnav-search"
                >
                  <span>{searchTarget || SEARCH_PLACEHOLDER}</span>
                </Button>
              )}
            </div>
            <AppLauncherPad type={userType} />
          </div>
          <div>
            <UserMenu
              avatarData={avatar}
              profileData={profileData}
              data-trackinge-event="topnav-user-menu"
            >
              <UserInitialSection>
                <UserInitialsItem
                  href={portalLink('profile')}
                  text="View my profile"
                  icon={<Icon.Id />}
                />
                <UserInitialsItem
                  href={portalLink('profile', 'course-progress')}
                  text="Course progress"
                  icon={<Icon.Ready />}
                />
              </UserInitialSection>
              <UserInitialSection>
                <UserInitialsItem
                  onClick={() => {
                    setShowThemer(true);
                  }}
                  text="Themer"
                  icon={<Icon.Wand />}
                  rightIcon={<Icon.Copy />}
                />
              </UserInitialSection>
            </UserMenu>
            {showThemer && <Themer {...{ showThemer, setShowThemer }} />}
          </div>
        </div>
      </div>

      {isMobile && !route.path.startsWith('/search') && (
        <div className={c.middle}>
          <MobilePageNav />
        </div>
      )}
    </div>
  );
};

export default Nav;
