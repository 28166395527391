import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { combineThemeWithFallbacks, themes } from '@monash/portal-react';
import { APIContext } from '@monash/portal-frontend-common';
import { Data } from '../data-provider/DataProvider';
import storage from 'utils/storage';
import { combineThemeWithCustomThemeVariables } from './utils';

export const Theme = React.createContext();

const ThemeProvider = ({ children }) => {
  // portal preferences from firestore
  const { portalPreferences, updatePortalPreferences } = useContext(Data);

  // local copy of the preferences, as we're not listening to firestore changes, we're use this state for local updates
  const [localPreferences, setLocalPreferences] = useState(
    storage.local.get('localPreferences')
  );
  const [updatePreferencesError, setUpdatePreferencesError] = useState(false);

  const updatePreferences = (updates) => {
    // writes to local first, then sets local storage and writes to firestore
    setLocalPreferences((f) => {
      const update = { ...f, ...updates };
      storage.local.set('localPreferences', update);

      // use updateS here becaue we dont want to override other custom page data
      updatePortalPreferences(updates)
        .then(() => setUpdatePreferencesError(false))
        .catch((error) => setUpdatePreferencesError(error));
      return update;
    });
  };

  // selected theme
  // uses the theme name to load a theme from themes.js
  // checks local storage first to improve load times
  const [selectedTheme, setSelectedTheme] = useState(
    localPreferences?.theme?.name || 'default'
  );

  // select theme
  const selectTheme = (themeName) => {
    // sets theme name
    setSelectedTheme(themeName);

    // overrides all custom theme variables with the ones from the selected theme
    const customTheme = resetAllCustomAttributes(themeName);

    // updates back to firestore
    updatePreferences({ theme: themes[themeName], customTheme });
  };

  // listens for changes from data provider re: portal preferences then writes to local state
  useEffect(() => {
    if (portalPreferences?.theme) {
      storage.local.set('localPreferences', portalPreferences);
      setLocalPreferences(portalPreferences);
      setSelectedTheme(portalPreferences.theme.name);

      // applies custom theme variables if found
      if (portalPreferences?.customTheme?.inUse) {
        applyAllCustomAttributes(
          Object.keys(portalPreferences.customTheme),
          Object.values(portalPreferences.customTheme)
        );
      }
    }
  }, [portalPreferences]);

  // wallpaper
  const [wallpaperList, setWallpaperList] = useState([]);
  const { getWallpaperList } = useContext(APIContext);

  useEffect(() => {
    // populate list of wallpapers from firebase storage bucket
    getWallpaperList()
      .then((data) => {
        setWallpaperList(data);
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }, []);

  // custom theme variables
  const [customThemeVariables, setCustomThemeVariables] = useState({
    // flag for if themes are using custom variables or the default theme
    inUse: false,
  });

  const setCustomVariable = (attribute, variable) => {
    setCustomThemeVariables((f) => {
      return { ...f, [attribute]: variable };
    });
  };

  // custom theme variables

  const customList = [
    { attribute: 'background', variable: '--canvas-bg-color' },
    { attribute: 'cardColor', variable: '--card-bg-color' },
    { attribute: 'cardCtaColor', variable: '--card-cta-bg-color' },
    { attribute: 'textColor', variable: '--card-text-color' },
    { attribute: 'headingColor', variable: '--canvas-text-color' },
    { attribute: 'canvasCtaTextColor', variable: '--canvas-cta-text-color' },
  ];

  const applyAllCustomAttributes = (keys, values) => {
    keys.forEach((key, i) => {
      setCustomVariable([key], values[i]);
    });
  };

  const resetAllCustomAttributes = (theme) => {
    // overrides custom variables with those from the selected theme
    const defaultCustomAttributes = { inUse: false };

    customList.forEach((item) => {
      defaultCustomAttributes[item.attribute] =
        themes[theme].variables[item.variable];
    });

    // apply all default custom attributes
    setCustomThemeVariables(defaultCustomAttributes);

    // return default attributes so that they can be written to firestore
    return defaultCustomAttributes;
  };

  const updateCustomTheme = (items, inUse = true) => {
    // updates are batched because of the 1 write per second limitation of firestore
    const update = {};

    // set if custom variables are in use
    items.push({ attribute: 'inUse', value: inUse });

    items.forEach((item, i) => {
      setCustomVariable([item.attribute], item.value);
      update[item.attribute] = item.value;
    });

    const customTheme = localPreferences?.customTheme || {};

    updatePreferences({ customTheme: { ...customTheme, ...update } });
  };

  // update meta theme color (for notch background colour)

  const updateThemeColourMeta = (colour) => {
    const themeColourMetaTag = document.querySelector(
      "head meta[name='theme-color']"
    );

    if (CSS.supports('color', colour)) {
      // valid colour
      themeColourMetaTag.setAttribute('content', colour);
    } else {
      // invalid colour, set theme-color according to system preference (light/dark mode)
      window.matchMedia('(prefers-color-scheme: dark)').matches
        ? themeColourMetaTag.setAttribute('content', '#000000')
        : themeColourMetaTag.setAttribute('content', '#FEFEFE'); // not using white (#FFFFFF) bc it seems to be ignored sometimes
    }
  };

  useEffect(() => {
    if (customThemeVariables.inUse) {
      const bgColour = customThemeVariables.background;
      updateThemeColourMeta(bgColour);
    } else {
      const bgColour = themes[selectedTheme]?.variables['--canvas-bg-color'];
      updateThemeColourMeta(bgColour);
    }
  }, [selectedTheme, customThemeVariables]);

  const themeWithCustomVariables = combineThemeWithCustomThemeVariables(
    themes[selectedTheme] || themes.default,
    customThemeVariables
  );
  const currentTheme = combineThemeWithFallbacks(themeWithCustomVariables);

  const style = (
    // joins all the theme variables and custom variables together
    // TODO: update shift color to accept linear-gradient
    <style>
      {currentTheme.fontURL && `@import url("${currentTheme.fontURL}");`}
      {`
        :root {${Object.keys(currentTheme.variables)
          .map((key) => {
            return `${key}: ${currentTheme.variables[key]}`;
          })
          .join(';')};
      `}
    </style>
  );

  // render

  return (
    <Theme.Provider
      value={{
        selectTheme,
        selectedTheme,
        customThemeVariables,
        setCustomVariable,
        wallpaperList,
        updateCustomTheme,
        updatePreferencesError,
      }}
    >
      {/* style */}
      {style}

      {/* children */}
      {children}
    </Theme.Provider>
  );
};

export default ThemeProvider;
