import React from 'react';
import c from './widget-error.module.scss';
import { Icon } from '@monash/portal-react';
import { swapAnchorForExternalLink } from '@monash/portal-frontend-common';

const WidgetError = ({ message, className, ...props }) => {
  const classes = [c.widgetError, className].join(' ');

  return (
    <div className={classes} {...props}>
      <Icon.XCircle />
      <div className={c.message}>{swapAnchorForExternalLink(message)}</div>
    </div>
  );
};

export default WidgetError;
