import React from 'react';
import c from './option-switches.module.scss';
import { Switch } from '@monash/portal-react';
import { isObject } from '@monash/portal-frontend-common';
/**
 * Set of switches for widget additional options. Useful when assuming all options are on by default.
 *
 * This component stores switch options in firestore as an object, with keys representing the option, and the
 * corresponding value as true if the switch is 'on' or false if the option is 'off',
 *
 * If a key is not found in firestore, then we consider the corresponding option as 'on'.
 * This ensures that options that are added are on by default, without the need for updating firestore documents
 * with a script.
 */
const OptionSwitches = ({
  optionKey,
  optionName,
  options,
  data,
  updateData,
}) => {
  if (!isObject(data)) return;

  // flip the boolean for the id that was just clicked on
  const getNewData = (selected, id) => {
    const newOptionData = {
      ...data,
      [id]: !selected,
    };
    return newOptionData;
  };

  return options.map((option) => {
    const id = option.id;
    const selected = data[id] || !(id in data); // default to on if option data is not found
    const screenReaderLabel = `${optionName}: ${option.name}`;
    return (
      <div className={c.checkboxes} key={id}>
        <Switch
          ariaLabel={screenReaderLabel}
          on={selected}
          onClick={() => updateData(optionKey, getNewData(selected, id))}
        />
        <span>{option.name}</span>
      </div>
    );
  });
};
export default OptionSwitches;
