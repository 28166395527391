import { formatDuration, intervalToDuration } from 'date-fns';

/**
 * Get human readable duration between two times
 * @param {string} start - start time
 * @param {string} end - end time
 */
export function getDuration(start, end) {
  const duration = intervalToDuration({
    start,
    end,
  });

  return formatDuration(duration, { format: ['days', 'hours', 'minutes'] });
}
