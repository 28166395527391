import {
  Button,
  Modal,
  ModalAction,
  ModalSection,
  useResponsiveValue,
} from '@monash/portal-react';
import { useState } from 'react';
import brokenFlower from '../../../assets/images/errors/broken-flower.svg';
import c from './portal-preferences-error-modal.module.scss';
import { MOBILE_RESPONSIVE } from '../main/Main';

const PortalPreferencesErrorModal = ({ open }) => {
  const [hasDismissed, setHasDismissed] = useState(false);
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);

  return (
    <Modal open={open && !hasDismissed} onClose={() => setHasDismissed(true)}>
      <ModalSection title="Uh oh, something went wrong">
        <div className={c.content}>
          <img className={c.image} src={brokenFlower} alt="" />
          <h2>Sorry, we can't retrieve your preferences</h2>
          <p className={c.text}>
            We're having trouble connecting to the server right now - but don't
            worry, your data and settings will be restored one we've resolve the
            issue.
          </p>
        </div>
      </ModalSection>

      <ModalAction position="right">
        <Button
          onClick={() => setHasDismissed(true)}
          size={responsiveSize === 'S' ? 'small' : 'medium'}
        >
          OK
        </Button>
      </ModalAction>
    </Modal>
  );
};

export default PortalPreferencesErrorModal;
