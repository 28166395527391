import { getMondayOfTheWeek } from '../../../utils';
import { addDays, endOfDay, startOfDay } from 'date-fns';

export const getCurrentDateRange = (numberOfDisplayDays, currentDate) => {
  switch (numberOfDisplayDays) {
    case 7: {
      const mondayOfCurrentDate = getMondayOfTheWeek(currentDate);
      return {
        start: startOfDay(mondayOfCurrentDate),
        end: endOfDay(addDays(mondayOfCurrentDate, 6)),
      };
    }
    case 3: {
      return {
        start: startOfDay(currentDate),
        end: endOfDay(addDays(currentDate, 2)),
      };
    }
    case 1: {
      return {
        start: startOfDay(currentDate),
        end: endOfDay(currentDate),
      };
    }
  }
};

export const hasOverlap = (left, right) => {
  const startsInCurrentRange =
    left.start < right.end && left.start > right.start;
  const endsInCurrentRange = left.end < right.end && left.end > right.start;
  const startsBeforeAndEndsAfter =
    left.start < right.start && left.end > right.end;

  return startsInCurrentRange || endsInCurrentRange || startsBeforeAndEndsAfter;
};
