const cardColoursList = [
  {
    name: 'white',
    value: '#ffffff',
    contrast: '#000',
    border: 'var(--card-border-color)',
  },
  { name: 'black', value: '#333333', contrast: '#fff' },
  { name: 'red', value: '#FEA993', contrast: '#000' },
  { name: 'blue', value: '#9DBCD4', contrast: '#000' },
  { name: 'yellow', value: '#FDDC5C', contrast: '#000' },
  { name: 'green', value: '#D8DCD6', contrast: '#000' },
  { name: 'purple', value: '#9198D4', contrast: '#000' },
  { name: 'slate', value: '#cbd5e1', contrast: '#000' },
];

export default cardColoursList;
