import { Icon } from '@monash/portal-react';
import classNames from 'classnames';
import c from './render-grades-list.module.scss';

export const renderGradesList = (gradesList, grade) => {
  return gradesList?.map((gradeListItem) => {
    const isAchievedGrade = gradeListItem === grade;

    const classes = classNames(c.grade, {
      [c.highlighted]: isAchievedGrade,
    });
    return (
      <div className={classes} key={gradeListItem}>
        {gradeListItem}
        {isAchievedGrade && <Icon.Check />}
      </div>
    );
  });
};
