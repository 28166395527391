import { Icon, RouterState, useResponsiveValue } from '@monash/portal-react';
import { Page } from 'components/providers/page-provider/PageProvider';
import { Data } from 'components/providers/data-provider/DataProvider';
import { nanoid } from 'nanoid';
import { useContext, useState } from 'react';
import PageManagementModal from './page-management-modal/PageManagementModal';
import Menu from 'components/ui/menu/Menu';
import {
  APIContext,
  AccessibilityContext,
  ImpersonationContext,
  deepClone,
  fsDocRef,
  fsUpdateDoc,
  fsWriteBatch,
} from '@monash/portal-frontend-common';
import { transformPageNameForURL } from 'components/utilities/pages/utils';
import {
  getDuplicatedPageName,
  getNewPageName,
  validateNewPageName,
} from 'components/utilities/pages';
import { MAX_PAGES_NUMBER } from '../../../../../constants';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import c from './page-settings-menu.module.scss';

export const MOBILE_RESPONSIVE = [
  {
    mq: '(max-width: 699px)',
    value: 'S',
  },
  {
    mq: '(min-width: 700px)',
    value: 'L',
  },
];

const ARIA_LABEL = 'Page management options';

const PageSettingsMenu = ({ onPagePlaceholder = false }) => {
  const { currentUser } = useContext(ImpersonationContext);
  const { getDefaultPage } = useContext(APIContext);
  const [managingPages, setManagingPages] = useState(false);
  const { redirect } = useContext(RouterState);
  const { setPortalPreferences } = useContext(Data);
  const { allPages, pagesData } = useContext(Page);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { addSnackbar } = useSnackbar();
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);

  const scrollToElement = (path) => {
    document
      .getElementById('VerticalMain')
      ?.scrollTo({ top: document.getElementById(path)?.offsetTop });
  };

  // fs user document
  const userId = currentUser.uid;
  const userPath = `users/${userId}`;

  // reaching page limit
  const reachedPageLimit = allPages?.length >= MAX_PAGES_NUMBER;
  const addReachedPageLimitSnackBar = () => {
    resetAppLiveMsgs();
    addSnackbar({
      message: "You've reached the custom page limit (67/67)",
      type: 'error',
    });
  };

  // add a new page
  const addPage = () => {
    if (reachedPageLimit) {
      // show error message and terminate function
      addReachedPageLimitSnackBar();
      return;
    }

    const newPageId = nanoid();
    const newPageName = getNewPageName(allPages);
    const newPages = deepClone(pagesData);

    // add new custom page
    newPages.customPages = {
      ...newPages.customPages,
      [newPageId]: { name: newPageName, widgetOrder: [] },
    };

    // update page order with new page as the last page
    newPages.pageOrder = [...newPages.pageOrder, newPageId];

    fsUpdateDoc(userPath, {
      'preferences.pages': newPages,
    })
      .then(() => handleAddPageSuccess(newPages, newPageName))
      .catch(handleAddPageError);
  };

  // add a FOR YOU default page
  const addForYouPage = () => {
    if (reachedPageLimit) {
      // show error message and terminate function
      addReachedPageLimitSnackBar();
      return;
    }

    getDefaultPage()
      .then((userDefaultPage) => {
        const batch = fsWriteBatch();

        // widgets
        const defaultWidgets = userDefaultPage?.widgets;
        const newWidgets = {};
        const newWidgetOrder = [];

        // for each default widget
        userDefaultPage?.page.widgetOrder.forEach((widgetId) => {
          // create new id
          const newWidgetId = nanoid();

          // add id to newWidgetOrder
          newWidgetOrder.push(newWidgetId);

          // if there is default data, set batch action to set widget data
          if (defaultWidgets[widgetId].data) {
            const widgetDataDocPath = `users/${currentUser.uid}/widgets/${newWidgetId}`;
            const doc = fsDocRef(widgetDataDocPath);
            batch.set(doc, defaultWidgets[widgetId].data);
          }

          // add widget data to new widgets (type and size)
          newWidgets[newWidgetId] = {
            size: defaultWidgets[widgetId].size,
            typeId: defaultWidgets[widgetId].typeId,
          };
        });

        // page
        const newPageId = nanoid();

        // create new page name
        const defaultPageName = userDefaultPage?.page?.name;
        const validateDefaultForYouPageName = validateNewPageName(
          allPages,
          null,
          defaultPageName
        );
        // use default page name if it is valid, otherwise duplicate default page name
        const newPageName = validateDefaultForYouPageName.valid
          ? defaultPageName
          : getDuplicatedPageName(allPages, defaultPageName);

        // pages
        const newPages = deepClone(pagesData);

        // add new custom page
        newPages.customPages = {
          ...newPages.customPages,
          [newPageId]: { name: newPageName, widgetOrder: newWidgetOrder },
        };

        // add new widgets
        newPages.widgets = { ...newPages.widgets, ...newWidgets };

        // add new page to page order
        newPages.pageOrder = [...newPages.pageOrder, newPageId];

        // update pages
        const preferencesDoc = fsDocRef(`users/${currentUser.uid}`);
        batch.update(preferencesDoc, {
          'preferences.pages': newPages,
        });

        // commit batch actions
        batch
          .commit()
          .then(() => handleAddPageSuccess(newPages, newPageName))
          .catch(handleAddPageError);
      })
      .catch(handleAddPageError);
  };

  const handleAddPageSuccess = (newPages, newPageName) => {
    setPortalPreferences((f) => {
      resetAppLiveMsgs();
      addSnackbar({
        message: `${newPageName} page has been added.`,
        type: 'success',
      });
      return { ...f, pages: newPages };
    });
    redirect(`/page/${transformPageNameForURL(newPageName)}`);

    // Scroll to the page on Mobile
    if (responsiveSize === 'S') {
      setTimeout(() => {
        scrollToElement(`/page/${transformPageNameForURL(newPageName)}`);
      }, 100);
    }
  };

  const handleAddPageError = (error) => {
    resetAppLiveMsgs();
    addSnackbar({
      message: "We can't add a new page right now – please try again later",
      type: 'error',
    });
    console.warn('Api call error, failed to add page', error);
  };

  const menuItems = [
    {
      icon: <Icon.Plus size={20} />,
      text: 'Add new page',
      function: addPage,
      trackingLabel: 'topnav-add-a-page',
    },
    {
      icon: <Icon.FilePlus size={20} />,
      text: 'Add default page (FOR YOU)',
      function: addForYouPage,
      trackingLabel: 'topnav-add-a-default-page',
    },
    {
      icon: <Icon.DragDrop2 size={20} />,
      text: 'Page management',
      haspopup: 'dialog',
      function: () => {
        setManagingPages(true);
      },
      trackingLabel: 'topnav-manage-pages',
    },
  ];

  return (
    <div className={c.pageSettingsMenu}>
      <PageManagementModal open={managingPages} setOpen={setManagingPages} />
      {onPagePlaceholder ? (
        <Menu
          items={menuItems}
          label="Page management"
          ariaLabel={ARIA_LABEL}
          mode="canvas"
          buttonVariant="secondary"
          icon={<Icon.EditTabs />}
          trackingLabel="page-placeholder-page-management-setting"
        />
      ) : (
        <Menu
          items={menuItems}
          ariaLabel={ARIA_LABEL}
          mode="canvas"
          buttonVariant="icon"
          icon={Icon.EditTabs}
          trackingLabel="topnav-page-management-setting"
        />
      )}
    </div>
  );
};

export default PageSettingsMenu;
