import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
} from 'react';
import classNames from 'classnames';
import { Card } from '@monash/portal-react';
import { isFunction, isNumber } from '@monash/portal-frontend-common';
import { calculateCroppedPixels } from './utils';
import c from './relative-panel.module.scss';

export const RelativePanel = ({
  wrapper: Wrapper = Card,
  extraWrapperProps = {},
  offset = 0,
  shown,
  relativeElementRef, // the element relative to
  children,
  wrapperClassNames = [],
  handleKeyDown,
  // auto dismiss
  dismissOnHistoryNav = false,
  onDismiss = null,
  // static styles
  borderRadius = '0',
  minWidthNum = 0,
  panelRef,
  overlap = true,
}) => {
  const [styles, setStyles] = useState({});
  const [isTransitioningPositions, setIsTransitioningPositions] =
    useState(false);
  const [heightTransitionEnded, setHeightTransitionEnded] = useState(false);
  const relativeElementBounds =
    relativeElementRef?.current?.getBoundingClientRect();

  // Auto dismissal on history nav
  const handleHistoryNav = useCallback(
    (e) => {
      // exit if no auto dismissal on history nav, or panel is hidden
      if (!dismissOnHistoryNav || !shown) {
        return;
      }
      // call valid on close handler
      if (isFunction(onDismiss)) {
        onDismiss(true);
      }
    },
    [shown, dismissOnHistoryNav, onDismiss]
  );

  useEffect(() => {
    window.addEventListener('popstate', handleHistoryNav);
    return () => {
      window.removeEventListener('popstate', handleHistoryNav);
    };
  }, [handleHistoryNav]);

  // Calculates dynamic position related styles
  const calculateTransform = () => {
    // returns empty styles when either element ref is null
    if (!panelRef?.current || !relativeElementRef?.current) {
      return {};
    }
    const panelBounds = panelRef?.current?.getBoundingClientRect();

    // Vertical boundary
    const touchesBottom =
      relativeElementBounds?.top + panelBounds.height > window.innerHeight;

    const touchesTop = relativeElementBounds?.bottom - panelBounds.height < 0;

    const isGreaterThanWindowHeight = panelBounds?.height > window.innerHeight;

    // Horizontal boundary
    const isPanelFit =
      panelBounds?.width + window.innerWidth * 0.08 <=
        window.innerWidth - relativeElementBounds.width &&
      (relativeElementBounds.left >= panelBounds?.width + offset * 2 ||
        window.innerWidth -
          relativeElementBounds.width -
          relativeElementBounds.left >=
          panelBounds?.width);

    // get panel horizontal positioning styles
    const panelPosLeft = relativeElementBounds.right + offset;
    const isPanelOffscreenWithPosLeft =
      panelPosLeft + minWidthNum > window.innerWidth;

    const panelPosRight = isPanelOffscreenWithPosLeft
      ? window.innerWidth - relativeElementBounds.left + offset
      : 'null';
    const panelHorizontalPosStyles = {
      left: !isPanelOffscreenWithPosLeft ? `${panelPosLeft}px` : 'auto',
      right: isPanelOffscreenWithPosLeft ? `${panelPosRight}px` : 'auto',
    };

    const panelHorizontalOverlapPosStyles = {
      left: 'auto',
      right: offset,
    };

    // get relative element vertical cropping details
    const relativeElementCropped = calculateCroppedPixels(relativeElementRef);

    // Get top position
    const panelPosTop = touchesBottom
      ? {
          top:
            relativeElementBounds?.top -
            panelBounds?.height +
            relativeElementBounds?.height -
            relativeElementCropped.bottom,
        }
      : {
          top: relativeElementBounds?.top + relativeElementCropped.top,
        };

    // Get top reset position
    const panelPosTopReset = { top: window.innerHeight * 0.05 };

    if (overlap) {
      // When the panel doesn't fit horizontally and vertically
      if (
        ((touchesTop && touchesBottom) || isGreaterThanWindowHeight) &&
        !isPanelFit
      ) {
        return {
          ...panelHorizontalOverlapPosStyles,
          ...panelPosTopReset,
          maxHeight: isNumber(panelPosTopReset.top)
            ? `calc(100vh - ${panelPosTopReset.top + offset}px)`
            : '90vh',
        };
      }
      // When the panel doesn't fit horizontally: Panel overlap
      if (!isPanelFit) {
        return {
          ...panelHorizontalOverlapPosStyles,
          ...panelPosTop,
          maxHeight: isNumber(panelPosTop.top)
            ? `calc(100vh - ${panelPosTop.top + offset}px)`
            : '90vh',
        };
      }
    }
    // When the panel doesn't fit vertically: Scrollable panel view
    if ((touchesTop && touchesBottom) || isGreaterThanWindowHeight) {
      return {
        ...panelHorizontalPosStyles,
        ...panelPosTopReset,
        maxHeight: isNumber(panelPosTopReset.top)
          ? `calc(100vh - ${panelPosTopReset.top + offset}px)`
          : '90vh',
      };
    }

    // Regular panel view
    return {
      ...panelHorizontalPosStyles,
      ...panelPosTop,
      maxHeight: isNumber(panelPosTop.top)
        ? `calc(100vh - ${panelPosTop.top + offset}px)`
        : '90vh',
    };
  };

  useLayoutEffect(() => {
    setStyles({ ...styles, ...calculateTransform() });
  }, [
    shown,
    relativeElementBounds?.x,
    relativeElementBounds?.y,
    heightTransitionEnded,
    relativeElementRef?.current,
  ]);

  const classes = classNames(
    c.overlay,
    { [c.transitionPositions]: isTransitioningPositions },
    { [c.hidden]: !shown },
    wrapperClassNames
  );

  return (
    <Wrapper
      className={classes}
      style={{
        ...styles,
        borderRadius,
        minWidth: `${minWidthNum}px`,
      }}
      ref={panelRef}
      tabIndex={-1}
      inert={shown ? null : ''}
      shadow
      onKeyDown={handleKeyDown}
      onTransitionEnd={() => {
        setIsTransitioningPositions(shown);
      }}
      {...extraWrapperProps}
    >
      <div
        className={c.container}
        // in case panel gets resized (e.g. opening accordion), recalculate styling
        onTransitionEnd={(event) => {
          if (event.propertyName === 'height') {
            setHeightTransitionEnded((t) => !t);
          }
        }}
      >
        {children}
      </div>
    </Wrapper>
  );
};

export default RelativePanel;
