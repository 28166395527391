// Calculate the color distance to white
const distanceToWhite = (r, g, b) => {
  const whiteR = 255;
  const whiteG = 255;
  const whiteB = 255;
  return Math.sqrt(
    Math.pow(r - whiteR, 2) + Math.pow(g - whiteG, 2) + Math.pow(b - whiteB, 2)
  );
};

// Calculate the color distance to black
const distanceToBlack = (r, g, b) => {
  const blackR = 0;
  const blackG = 0;
  const blackB = 0;
  return Math.sqrt(
    Math.pow(r - blackR, 2) + Math.pow(g - blackG, 2) + Math.pow(b - blackB, 2)
  );
};

// Convert hex code to rgb
const hexToRgb = (hex) => {
  hex = hex.replace(/^#/, '');

  let bigint;

  if (hex.length === 3) {
    bigint = parseInt(hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2], 16);
  } else if (hex.length === 6) {
    bigint = parseInt(hex, 16);
  } else {
    throw new Error('Invalid hex code');
  }

  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
};

// Get if the color is closer to black
export const isColorCloserToBlack = (color) => {
  const rgb = color && hexToRgb(color.toLowerCase());

  const distWhite = distanceToWhite(rgb.r, rgb.g, rgb.b);
  const distBlack = distanceToBlack(rgb.r, rgb.g, rgb.b);

  return distWhite > distBlack;
};
