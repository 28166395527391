import React, { useContext } from 'react';
import { Page } from 'components/providers/page-provider/PageProvider';
import PageSettingsMenu from './page-settings-menu/PageSettingsMenu';
import Tabs from '../tabs/Tabs.jsx';
import c from './page-nav.module.scss';

const PageNav = ({ navMiddleRef }) => {
  const { pages } = useContext(Page);

  return pages ? (
    <div className={c.pageNav} role="navigation" aria-label="Pages">
      <Tabs navMiddleRef={navMiddleRef} />
      <PageSettingsMenu />
    </div>
  ) : null;
};
export default PageNav;
