import React, {
  useState,
  useRef,
  useLayoutEffect,
  forwardRef,
  useEffect,
} from 'react';
import { isFunction } from '@monash/portal-frontend-common';
import c from './positionAwareMenuV2.module.scss';

export const PositionAwareMenuV2 = forwardRef(
  (
    {
      // basic props
      shown,
      className,
      children,
      onReposition,
      onKeyDown,

      // dynamic position props (via CSS transform)
      adjustHorizontal = true,
      adjustVertical = true,
      horizontalAlign = 'left', // horizontal align preference
      verticalAlign = 'down', // vertical align preference
      offsetX = 0, // horizontal offset applied with auto adjust
      offsetY = 0, // vertical offset applied with auto adjust

      // static position props (via direct pass to styles)
      fixed = false, // not recommended to use
      top, // css 'top' position style
      left, // css 'left' position style
      marginTop, // css 'margin-top' style
      marginLeft, // css 'margin-left' style

      // auto dismiss
      dismissOnHistoryNav = false,
      onDismiss = null,
    },
    ref
  ) => {
    const [isAlignedLeft, setIsAlignedLeft] = useState(true);
    const [isAlignedDown, setIsAlignedDown] = useState(true);
    const containerRef = useRef(null);

    const getCSSTranslation = () => {
      const offsetXCSSStr = `${Math.abs(offsetX)}px`;
      const offsetYCSSStr = `${Math.abs(offsetY)}px`;

      // horizontal
      const translateXForAlignedLeft =
        horizontalAlign === 'left' ? '0px' : `calc(100% - ${offsetXCSSStr})`;
      const translateXForAlignedRight =
        horizontalAlign === 'right' ? '0px' : `calc(-100% + ${offsetXCSSStr})`;
      const translateX = isAlignedLeft
        ? translateXForAlignedLeft
        : translateXForAlignedRight;

      // vertical
      const translateYForAlignedDown =
        verticalAlign === 'down' ? '0px' : `calc(100% + ${offsetYCSSStr})`;
      const translateYForAlignedUp =
        verticalAlign === 'up'
          ? `-${offsetYCSSStr}`
          : `calc(-100% - ${offsetYCSSStr})`;
      const translateY = isAlignedDown
        ? translateYForAlignedDown
        : translateYForAlignedUp;

      return `translate(${translateX}, ${translateY})`;
    };

    useLayoutEffect(() => {
      if (!shown || !containerRef.current) {
        return;
      }

      const bounds = containerRef.current.getBoundingClientRect();
      const x = isAlignedLeft ? bounds.x : bounds.x + bounds.width + offsetX;
      const y = isAlignedDown ? bounds.y : bounds.y + bounds.height + offsetY;

      let shouldAlignLeft, shouldAlignDown;
      if (horizontalAlign === 'right') {
        shouldAlignLeft = x - bounds.width < 0;
      } else if (horizontalAlign === 'left') {
        shouldAlignLeft = !(x + bounds.width > window.innerWidth);
      }
      if (verticalAlign === 'up') {
        shouldAlignDown = y - bounds.height < 0;
      } else if (verticalAlign === 'down') {
        shouldAlignDown = !(y + bounds.height > window.innerHeight);
      }
      adjustHorizontal && setIsAlignedLeft(shouldAlignLeft);
      adjustVertical && setIsAlignedDown(shouldAlignDown);

      // Callback to run effects whenever repositioned
      if (onReposition) {
        onReposition(shouldAlignLeft, shouldAlignDown);
      }
    }, [shown]);

    // auto dismissal on history nav
    useEffect(() => {
      const onPopState = (e) => {
        onDismiss(e);
      };
      if (dismissOnHistoryNav && shown && isFunction(onDismiss)) {
        window.addEventListener('popstate', onPopState);
      }
      // detach event handler regardless if it was attached
      return () => {
        setTimeout(() => {
          window.removeEventListener('popstate', onPopState);
        }, 0);
      };
    }, [dismissOnHistoryNav, shown, onDismiss]);

    return (
      <div
        ref={ref}
        className={[c.overlay, className].join(' ')}
        style={{
          left,
          top,
          marginTop,
          marginLeft,
          position: fixed ? 'fixed' : 'absolute',
          right: horizontalAlign === 'right' ? '0' : null,
          bottom: verticalAlign === 'up' ? '0' : null,
          // applies transform at here to avoid triggering scrollbars
          transform: getCSSTranslation(),
        }}
        onKeyDown={onKeyDown}
      >
        <div className={c.container} ref={containerRef}>
          {shown && children}
        </div>
      </div>
    );
  }
);

export default PositionAwareMenuV2;
