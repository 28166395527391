// widgets
export const getColumnSpan = (size, widgetSize) =>
  size !== 'S' && widgetSize === 'L' ? 2 : 1;

// Resize delay time for
// widget transition animation(1000ms)
// page transition animation(600ms)
export const RESIZE_DELAY = 1200;

// blueprint
export const getColumns = (width, size) => {
  const gap = 30;
  const borderOffset = 1;

  switch (size) {
    case 'L':
      return [
        0,
        (width - 3 * gap) / 8,
        (width - 3 * gap) / 4,
        (width - 3 * gap) / 4 + gap,
        ((width - 3 * gap) / 8) * 3 + gap,
        (width - 3 * gap) / 2 + gap,
        (width - 3 * gap) / 2 + gap * 2,
        (width - 3 * gap) / 2 + gap * 2 + (width - 3 * gap) / 8,
        ((width - 3 * gap) / 4) * 3 + gap * 2,
        ((width - 3 * gap) / 4) * 3 + gap * 3,
        ((width - 3 * gap) / 8) * 7 + gap * 3,
        width,
      ].map((item) => item - borderOffset);
    case 'M':
      return [
        0,
        (width - gap) / 4,
        (width - gap) / 2,
        (width - gap) / 2 + gap,
        ((width - gap) / 4) * 3 + gap,
        width,
      ].map((item) => item - borderOffset);
    default:
      return [0, width / 2, width].map((item) => item - borderOffset);
  }
};

export const getRows = (boardRef) => {
  if (
    !boardRef.current?.children ||
    typeof boardRef.current?.children?.[Symbol.iterator] !== 'function'
  )
    return [];
  const borderOffset = 1;
  const boardTop = boardRef.current?.getBoundingClientRect().top;
  const boardItemRefs = Array.from(boardRef.current?.children);

  const rows = new Set();
  boardItemRefs?.forEach((item) => {
    rows.add(item.getBoundingClientRect().top - boardTop - borderOffset);
    rows.add(item.getBoundingClientRect().bottom - boardTop - borderOffset);
  });
  return [...rows];
};

export const assignKeyNavItem = (id, widgetName = '') => {
  return {
    'data-key-nav-group': `select-widget-${id}`,
    'aria-label': `${widgetName} widget.`,
    tabIndex: 0,
  };
};
