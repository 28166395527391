import React, { useContext, useState } from 'react';
import {
  Button,
  LoadingIndicator,
  TextField,
  useResponsiveValue,
} from '@monash/portal-react';
import DraggableWindow from 'components/ui/draggable-window/DraggableWindow';
import c from './edit-clock-background.module.scss';
import { WidgetContext } from 'components/providers/WidgetProvider';
import { isUrl } from '@monash/portal-frontend-common';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';

const EditClockBackground = ({ data, updateData, escapeFocusRef }) => {
  const { editClockBackgroundActiveWidget, editClockBackground } =
    useContext(WidgetContext);
  const dataBackgroundUrl = data?.backgroundURL;
  const [urlInput, setUrlInput] = useState(dataBackgroundUrl || '');
  const [previewImageURL, setPreviewImageURL] = useState(null);
  const [urlValidation, setUrlValidation] = useState(null);
  const [checkingImage, setCheckingImage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // save button should be disabled if url is invalid, validating(checking image) or no validation results and no URL data
  const shouldSaveButtonBeDisabled =
    urlValidation === false ||
    checkingImage ||
    (urlValidation === null && !dataBackgroundUrl);
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';

  const removeBackground = () => {
    updateData('backgroundURL', null);
    deactivateWidget();
  };

  const saveBackground = () => {
    if (urlValidation !== null) {
      updateData('backgroundURL', urlInput);
    }
    deactivateWidget();
  };

  // deactivate widget from editing shortcuts in provider
  const deactivateWidget = () => editClockBackground(null);

  // draggable window initial position
  const getTriggerRefPosition = () => {
    if (!escapeFocusRef?.current) return;
    const position = escapeFocusRef.current.getBoundingClientRect();
    return { x: position.right, y: position.bottom };
  };

  const headingText = 'Edit background';

  // reset preview URL for reloading validation preview
  const resetPreviewURL = () => setPreviewImageURL(null);

  // validate URL input
  const validateUrlInput = (value) => {
    // input hasn't changed
    if (value === previewImageURL) return;
    resetPreviewURL();

    if (!value) {
      // no input
      setUrlValidation(false);
      setErrorMessage('URL is required');
    } else if (!isUrl(value)) {
      // input is not a valid url
      setUrlValidation(false);
      setErrorMessage('No image found');
    } else {
      // input is a valid url, start validation
      setCheckingImage(true);
      setPreviewImageURL(value);
    }
  };

  const handlePreviewOnLoad = () => {
    setErrorMessage(null);
    setUrlValidation(true);
    setCheckingImage(false);
  };

  const handlePreviewOnError = () => {
    setErrorMessage('No image found');
    setUrlValidation(false);
    setCheckingImage(false);
  };

  return (
    <div className={c.editClockBackground}>
      <DraggableWindow
        fullScreen={isMobile}
        title={headingText}
        isShowing={editClockBackgroundActiveWidget}
        focusTrapEnabled={true}
        focusTrapSelectors="button:not([disabled]), input[type=text], input[type=url]"
        keyboardDismissalEnabled={true}
        onClose={() => {
          deactivateWidget();
          escapeFocusRef.current?.focus();
        }}
        initialPosition={getTriggerRefPosition()}
        extraRootProps={{
          role: 'dialog',
          'aria-label': headingText,
        }}
      >
        <div className={c.editClockBackground}>
          <div className={c.content}>
            <div className={c.item}>
              <label>URL</label>
              {/* "validate" image URL using a hidden preview */}
              {previewImageURL ? (
                <img
                  style={{ display: 'none' }}
                  src={previewImageURL}
                  onError={handlePreviewOnError}
                  onLoad={handlePreviewOnLoad}
                  alt="Error check"
                />
              ) : null}
              <TextField
                value={urlInput}
                type="url"
                placeholder="https://www.example.com/image.jpg"
                onBlur={(e) => validateUrlInput(e.target.value)}
                onPaste={(e) =>
                  validateUrlInput(e.clipboardData.getData('Text'))
                }
                onChange={(e) => {
                  setUrlInput(e.target.value);
                }}
                error={() => !checkingImage && urlValidation === false}
                errorMsg={errorMessage}
              />
            </div>
            {checkingImage ? (
              <div className={c.loading}>
                Checking image
                <LoadingIndicator />
              </div>
            ) : null}
          </div>
          <div className={c.actions}>
            <Button
              variant="text"
              size="small"
              disabled={!dataBackgroundUrl}
              onClick={removeBackground}
              mode="card"
            >
              Remove
            </Button>
            <div>
              <Button variant="text" size="small" onClick={deactivateWidget}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="small"
                disabled={shouldSaveButtonBeDisabled}
                onClick={saveBackground}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </DraggableWindow>
    </div>
  );
};

export default EditClockBackground;
