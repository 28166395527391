import { Button, Switch, Tabs, Toggle } from '@monash/portal-react';
import {
  SESSION_STORAGE_KEYS,
  useSessionStorage,
} from '@monash/portal-frontend-common';
import { useState } from 'react';
import { MOCK_COURSE_PRESETS } from '../../providers/data-provider/mock-courses';
import c from './popup.module.scss';
import FeatureToggles from './FeatureToggles';
import Misc from './Misc';

const mapping = {
  Yes: true,
  No: false,
};

const reverseMapping = {
  true: 'Yes',
  false: 'No',
};

const Popup = ({ isShowing }) => {
  const viewOptions = ['Yes', 'No'];
  const [fakeData, setFakeData] = useSessionStorage('fakeData', true);
  const [testDate, setTestDate] = useSessionStorage('testDate');
  const [timeMultiplier, setTimeMultiplier] = useSessionStorage(
    'timeMultiplier',
    1
  );
  const currentDate = testDate ? new Date(testDate) : new Date();

  const [view, setView] = useState(reverseMapping[fakeData]);
  const [year, setYear] = useState(currentDate.getFullYear());
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const [day, setDay] = useState(currentDate.getDate());
  const [hours, setHours] = useState(currentDate.getHours());
  const [minutes, setMinutes] = useState(currentDate.getMinutes());
  const [seconds, setSeconds] = useState(currentDate.getSeconds());
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = ['Data', 'Features', 'Misc'];

  const [fakeCourseDataType, setFakeCourseDataType] =
    useSessionStorage('fakeCourseDataType');
  const [showPageBanners, setShowPageBanners] = useSessionStorage(
    SESSION_STORAGE_KEYS.MOCKS.SHOW_PAGE_BANNERS
  );

  const updateFakeCourseDataTypeValue = (e) => {
    const newVal = e.target?.value || '';
    setFakeCourseDataType(newVal);
  };

  const updateFakeDataValue = (value) => {
    setFakeData(mapping[value]);
    setView(value);
  };

  const onSetClick = () => {
    const date = new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds
    ).getTime();
    setTestDate(date);
    window.location.reload();
  };

  return (
    <div className={`${c.popupWrapper} ${!isShowing && c.isHidden}`}>
      <div className={c.popup}>
        <div className={c.tabs}>
          <Tabs
            tabs={tabs}
            selected={selectedTabIndex}
            onChange={(i) => {
              setSelectedTabIndex(i);
            }}
          />
        </div>
        {selectedTabIndex === 0 && (
          <div className={c.inputGroups}>
            <div className={c.inputGroup}>
              <div className={c.label}>Mock courses</div>
              <select
                value={fakeCourseDataType}
                onChange={updateFakeCourseDataTypeValue}
                tabIndex={-1}
              >
                <option value="">No Mock</option>
                {Object.keys(MOCK_COURSE_PRESETS).map((preset, index) => {
                  return (
                    <option
                      key={`mock-course-data-preset-${index + 1}`}
                      value={preset}
                    >
                      {preset}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className={c.inputGroup}>
              <div className={c.label}>Use mock data</div>
              <Toggle
                options={viewOptions}
                selected={view}
                setSelected={updateFakeDataValue}
                tabIndex={-1}
              />
            </div>

            <div className={c.inputWrapper}>
              <div className={c.label}>Show page banners</div>
              <Switch
                on={showPageBanners}
                onClick={() => {
                  setShowPageBanners(!showPageBanners);
                }}
              />
            </div>

            <div className={c.inputGroup}>
              <div className={c.label}>Current date</div>

              <div className={c.dateInputs}>
                <div className={c.inputWrapper}>
                  <label htmlFor="test-harness-ui-input-year">Year</label>
                  <input
                    id="test-harness-ui-input-year"
                    type="number"
                    min="1"
                    max="9999"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    tabIndex={-1}
                  />
                </div>

                <div className={c.inputWrapper}>
                  <label htmlFor="test-harness-ui-input-month">Month</label>
                  <input
                    id="test-harness-ui-input-month"
                    type="number"
                    value={month}
                    min="1"
                    max="12"
                    onChange={(e) => setMonth(e.target.value)}
                    tabIndex={-1}
                  />
                </div>

                <div className={c.inputWrapper}>
                  <label htmlFor="test-harness-ui-input-day">Day</label>
                  <input
                    id="test-harness-ui-input-day"
                    type="number"
                    value={day}
                    min="1"
                    max="31"
                    onChange={(e) => setDay(e.target.value)}
                    tabIndex={-1}
                  />
                </div>

                <div className={c.inputWrapper}>
                  <label htmlFor="test-harness-ui-input-hours">Hours</label>
                  <input
                    id="test-harness-ui-input-hours"
                    type="number"
                    value={hours}
                    min="0"
                    max="23"
                    onChange={(e) => setHours(e.target.value)}
                    tabIndex={-1}
                  />
                </div>

                <div className={c.inputWrapper}>
                  <label htmlFor="test-harness-ui-input-minutes">Minutes</label>
                  <input
                    id="test-harness-ui-input-minutes"
                    type="number"
                    value={minutes}
                    min="0"
                    max="59"
                    onChange={(e) => setMinutes(e.target.value)}
                    tabIndex={-1}
                  />
                </div>

                <div className={c.inputWrapper}>
                  <label htmlFor="test-harness-ui-input-seconds">Seconds</label>
                  <input
                    id="test-harness-ui-input-seconds"
                    type="number"
                    value={seconds}
                    min="0"
                    max="59"
                    onChange={(e) => setSeconds(e.target.value)}
                    tabIndex={-1}
                  />
                </div>
              </div>
            </div>

            <div className={c.inputGroup}>
              <label
                className={c.label}
                htmlFor="test-harness-ui-input-time-multiplier"
              >
                Time multiplier
              </label>

              <div className={c.inputWrapper}>
                <input
                  id="test-harness-ui-input-time-multiplier"
                  type="number"
                  value={timeMultiplier}
                  min="1"
                  max="100"
                  onChange={(e) => setTimeMultiplier(e.target.value)}
                  tabIndex={-1}
                />
              </div>
            </div>

            <Button
              className={c.set}
              size="medium"
              onClick={onSetClick}
              tabIndex={-1}
            >
              Set
            </Button>
          </div>
        )}
        {selectedTabIndex === 1 && <FeatureToggles />}
        {selectedTabIndex === 2 && <Misc />}
      </div>
    </div>
  );
};

export default Popup;
