import ILL01 from '../../assets/images/global-banner/research.svg';
// import ILL02 from '../../assets/images/global-banner/grades.svg';
import ILL03 from '../../assets/images/global-banner/addForYouPage.svg';

const GLOBAL_BANNERS = [
  {
    title: 'Find your research related links from the app launcher',
    description: `Access myDevelopment and other valuable resource in just a few clicks`,
    backgroundColor: '#133053',
    // 17/7/2024
    startDate: 1721138400000,
    // 5/8/2024
    endDate: 1722780000000,
    theme: 'dark',
    illustration: ILL01,
    isNew: true,
  },
  // {
  //   title: 'Grades now available 👀',
  //   description: `If your assessment has been graded, you'll now see your unweighted grade in the assessment widget. And if you'd like to hide it from peeking eyes, you can!`,
  //   backgroundColor: '#133053',
  //   // 6/8/2024
  //   startDate: 1722866400000,
  //   // 20/8/2024
  //   endDate: 1724076000000,
  //   theme: 'dark',
  //   illustration: ILL02,
  //   isNew: true,
  // },
  {
    title: 'Add a default page (For you)',
    description: `You can now restore or add another default page by clicking on the page management options!`,
    backgroundColor: '#133053',
    // 8/8/2024 00:00:01
    startDate: 1723039201000,
    // 29/8/2024 23:59:59
    endDate: 1724939999000,
    theme: 'dark',
    illustration: ILL03,
    isNew: true,
  },
];

const COLLEGE_GLOBAL_BANNERS = [
  {
    title: 'Add a default page (For you)',
    description: `You can now restore or add another default page by clicking on the page management options!`,
    backgroundColor: '#133053',
    // 8/8/2024 00:00:01
    startDate: 1723039201000,
    // 29/8/2024 23:59:59
    endDate: 1724939999000,
    theme: 'dark',
    illustration: ILL03,
    isNew: true,
  },
];

export const getGlobalBannerData = () => GLOBAL_BANNERS;
export const getGlobalBannerCollegeData = () => COLLEGE_GLOBAL_BANNERS;
