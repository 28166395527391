import React, { useContext, useEffect } from 'react';
import c from './important-dates.module.scss';
import WidgetIcon from '../../widget-library/WidgetIcon';
import preview from './preview.png';
import { Data } from 'components/providers/data-provider/DataProvider';
import { formatImportantDates } from './utils';
import { APIContext, useSessionStorage } from '@monash/portal-frontend-common';
import { LoadingIndicator } from '@monash/portal-react';
import DateInfo from './date-info/DateInfo';

const SINGLE_DATE_MONTH_LABEL_WIDTH = 45;
const DATE_PERIOD_MONTH_LABEL_WIDTH = 65;

const ImportantDates = ({ onSelectedPage, setError, typeId }) => {
  const { enrolledTeachingPeriods } = useContext(Data);
  const { getImportantDates } = useContext(APIContext);
  const [importantDates, setImportantDates] = useSessionStorage(
    `widgetType:${typeId}`
  );
  const hasDatePeriod = importantDates?.find((date) => date.endDate);
  const dateInfoWidth = hasDatePeriod
    ? DATE_PERIOD_MONTH_LABEL_WIDTH
    : SINGLE_DATE_MONTH_LABEL_WIDTH;

  useEffect(() => {
    // fetch data when widget type data doesn't exist
    if (onSelectedPage && !importantDates?.length) {
      getImportantDates()
        .then((r) => {
          if (!r?.length) {
            setError('We currently have no important dates to display.');
          } else {
            setImportantDates(r);
          }
        })
        .catch((error) => {
          console.warn(
            '[getImportantDates]: api call error, failed to get important dates.',
            error
          );
          setError(
            "We can't retrieve important dates right now – please check back later."
          );
        });
    }
  }, [onSelectedPage]);

  const formattedImportantDates = formatImportantDates(
    importantDates,
    enrolledTeachingPeriods
  );

  const months = [
    ...new Set(formattedImportantDates?.map((item) => item.month)),
  ];

  if (!importantDates) {
    return (
      <div className={c.loading}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <ul className={c.ImportantDates}>
      {/* Important dates - rendered by month */}
      {months.map((month, i) => (
        <li key={`${month}-${i}`}>
          {/* Sticky month label */}
          <span
            className={c.monthLabel}
            aria-hidden="true"
            style={{
              width: `${dateInfoWidth}px`,
            }}
          >
            <div className={c.stickyContainer}>{month.substring(0, 3)}</div>
          </span>

          <ul
            className={c.monthList}
            aria-label={`${month} important dates`}
            tabIndex="0"
          >
            {/* Render items from one month at a time */}
            {formattedImportantDates
              .filter((item) => item.month === month)
              .map((item, dateIndex) => {
                const dateId = importantDates[dateIndex].id;
                return (
                  <li className={c.importantDateItem} key={dateId}>
                    <DateInfo
                      dateItem={item}
                      firstItem={dateIndex === 0}
                      dateInfoWidth={dateInfoWidth}
                    />
                    {/* Display descriptions belonging to the same day in the same container */}
                    <div className={c.descriptionContainer}>
                      <div className={c.descriptionGroup}>
                        {item.description.map((description, i) => (
                          <div
                            className={c.description}
                            key={`${dateId}-description-${i}`}
                          >
                            <p key={i}>
                              {description.title && (
                                <strong>{description.title} </strong>
                              )}
                              {description.body.map((item, i) =>
                                enrolledTeachingPeriods
                                  .map((item) => item.calType)
                                  .includes(item) ? (
                                  <span
                                    className={c.highlightedLabel}
                                    key={`${dateId}-body-${i}`}
                                  >
                                    {item}
                                  </span>
                                ) : (
                                  item
                                )
                              )}
                            </p>
                            {description.cohort && (
                              <span className={c.highlightedLabel}>
                                {description.cohort}
                              </span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </li>
      ))}
    </ul>
  );
};

const ImportantDatesModule = {
  component: ImportantDates,
  name: 'Important dates',
  icon: WidgetIcon.ImportantDates,
  previewImage: preview,
  additionalOptions: null,
  description: 'Keep track of key dates',
};

export default ImportantDatesModule;
