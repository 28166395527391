import { isNumber } from '@monash/portal-frontend-common';

export const SCHEDULE_ITEM_SIZE_TIERS = {
  // zero duration,
  // important/key dates,
  // one liners
  TIER_0_0: '0',
  // assessments,
  // class based on hours (default minimum)
  TIER_0_5: '0.5',
  // class based on hours
  TIER_1_0: '1',
  TIER_1_5: '1.5',
  TIER_2_0: '2',
  TIER_3_0: '3',
};

/**
 * getItemSizeTier
 * @param {number|null} hours - schedule item duration: hours
 * @param {number|null} minutes - schedule item duration: minutes
 * @param {boolean} isAssessment - is schedule item an assessment event kind
 * @returns {string} item size tier token string
 */
export const getItemSizeTier = ({
  hours = null,
  minutes = null,
  isAssessment = false,
} = {}) => {
  // default and assessments tier
  if (!isNumber(hours) || !isNumber(minutes) || isAssessment) {
    return SCHEDULE_ITEM_SIZE_TIERS.TIER_0_5;
  }

  // duration based tiers
  const durationInMinutes = hours * 60 + minutes;
  let sizeTier = SCHEDULE_ITEM_SIZE_TIERS.TIER_0_5;
  if (durationInMinutes > 30 && durationInMinutes <= 60) {
    sizeTier = SCHEDULE_ITEM_SIZE_TIERS.TIER_1_0;
  } else if (durationInMinutes > 60 && durationInMinutes <= 90) {
    sizeTier = SCHEDULE_ITEM_SIZE_TIERS.TIER_1_5;
  } else if (durationInMinutes > 90 && durationInMinutes < 180) {
    sizeTier = SCHEDULE_ITEM_SIZE_TIERS.TIER_2_0;
  } else if (durationInMinutes >= 180) {
    sizeTier = SCHEDULE_ITEM_SIZE_TIERS.TIER_3_0;
  }
  return sizeTier;
};
