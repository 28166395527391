import {
  Button,
  Icon,
  IconButton,
  useResponsiveValue,
} from '@monash/portal-react';
import React, { useRef, useState } from 'react';
import c from './header.module.scss';
import PageHeading from './page-heading/PageHeading';
import SettingsMenu from './settings-menu/SettingsMenu';
import WidgetLibraryDesktop from '../widget-library/WidgetLibraryDesktop';
import WidgetLibraryMobile from '../widget-library/WidgetLibraryMobile';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import useFilteredWidgetDirectory from 'hooks/use-widget-directory';

const EDIT_BUTTON_DESC =
  'Edit layout contains sortable widgets. To pick up a sortable widget, press the space bar. While sorting, use the arrow keys to move the widget. Press space again to drop the widget in its new position, or press escape to cancel.';

const Header = ({
  inEditMode,
  setInEditMode,
  pageId,
  pageData,
  updatingWidgetOrderStatus,
  setUpdatingWidgetOrderStatus,
  setNewWidgetId,
  setWidgetOrder,
  isPageEmpty,
}) => {
  const [isRenaming, setIsRenaming] = useState(false);
  const widgetDirectory = useFilteredWidgetDirectory();
  const editButtonDescription = inEditMode ? null : EDIT_BUTTON_DESC;
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const label = inEditMode ? 'Finish editing' : 'Edit layout';

  // ref
  const headerRef = useRef();

  // scroll to header (if header is already in view, the block prop ensures no unnecessary scroll is performed)
  const scrollToHeader = () => {
    headerRef?.current?.scrollIntoView({
      block: 'nearest',
      behavior: 'instant',
    });
  };

  const editButtonProps = {
    variant: 'text',
    mode: 'canvas',
    disabled: isPageEmpty && !inEditMode,
    onClick: () => {
      setInEditMode((f) => !f);
      setUpdatingWidgetOrderStatus('initial');
    },
    'aria-label': label,
    'aria-description': editButtonDescription,
    'data-tracking-event': 'custom-edit-page',
  };

  const editButton =
    responsiveSize !== 'S' ? (
      <Button
        className={c.button}
        size="medium"
        icon={!inEditMode ? <Icon.Pencil /> : <Icon.Check />}
        {...editButtonProps}
      >
        {label}
      </Button>
    ) : (
      <IconButton
        size={24}
        icon={!inEditMode ? Icon.Pencil : Icon.Check}
        {...editButtonProps}
      />
    );

  const widgetLibraryProps = {
    pageId,
    setNewWidgetId,
    setWidgetOrder,
    widgetDirectory,
    scrollToHeader,
  };

  return (
    <div className={c.header} ref={headerRef}>
      <PageHeading
        pageId={pageId}
        pageName={pageData?.name}
        isRenaming={isRenaming}
        setIsRenaming={setIsRenaming}
      />
      <div
        className={c.actions}
        aria-label="Widget board toolbar"
        role="region"
      >
        {updatingWidgetOrderStatus !== 'initial' && (
          <span
            className={c.savingIndicator}
            data-status={updatingWidgetOrderStatus}
            aria-hidden="true"
          >
            Saving...
          </span>
        )}

        {!inEditMode &&
          (responsiveSize === 'S' ? (
            <WidgetLibraryMobile {...widgetLibraryProps} />
          ) : (
            <WidgetLibraryDesktop {...widgetLibraryProps} />
          ))}

        {editButton}

        <SettingsMenu
          pageId={pageId}
          pageData={pageData}
          setIsRenaming={setIsRenaming}
        />
      </div>
    </div>
  );
};

export default Header;
