import React, { useEffect, useCallback, useRef } from 'react';
import {
  Icon,
  Modal,
  ModalSection,
  useResponsiveValue,
} from '@monash/portal-react';
import c from './news-card.module.scss';
import RelativePanel from 'components/ui/relative-panel/RelativePanel';
import SimpleFocusTrap from 'components/ui/simple-focus-trap/SimpleFocusTrap';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import { useOnOutsideClick } from '@monash/portal-frontend-common';

const NewsCard = ({ shown, setShown, returnRef, displayData }) => {
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);

  const handleClose = useCallback(
    (ignoreTriggerFocus = false) => {
      if (!ignoreTriggerFocus) {
        const goBackTo = returnRef?.current;
        goBackTo?.focus();
      }
      setShown(false);
    },
    [returnRef]
  );

  const handleDismiss = useCallback(() => {
    // ignore trigger focus on auto dismiss
    handleClose(true);
  }, [handleClose]);

  const containerRef = useRef();
  const closeButtonRef = useRef();

  useOnOutsideClick({
    selectors: '[data-tag=news-popup]',
    fn: handleClose,
  });

  const onKeyDownContainer = useCallback(
    (e) => {
      if (shown) {
        if (e.code === 'Escape') {
          e.preventDefault();
          handleClose();
        }
      }
    },
    [handleClose, shown]
  );

  const onKeyDownX = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleClose();
    }
  };

  useEffect(() => {
    if (shown && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [shown, closeButtonRef.current]);

  const renderDesktopView = () => {
    return (
      <SimpleFocusTrap trapIsOn={shown}>
        <RelativePanel
          relativeElementRef={returnRef}
          shown={shown}
          offset={10}
          borderRadius="16px"
          minWidthNum={360}
          wrapperClassNames={[c.relativePanelCard]}
          panelRef={containerRef}
          handleKeyDown={onKeyDownContainer}
          dismissOnHistoryNav={true}
          onDismiss={handleDismiss}
          extraWrapperProps={{
            role: 'dialog',
            'aria-label': displayData?.ariaHeading,
          }}
        >
          {displayData && (
            <div className={c.newsCard} data-tag="news-popup">
              <div className={`${c.newsHeader} ${c.desktopCardPadding}`}>
                {displayData.header}
                <button
                  type="button"
                  ref={closeButtonRef}
                  aria-label="close"
                  className={c.closeButton}
                  onClick={handleClose}
                  onKeyDown={onKeyDownX}
                >
                  <Icon.X />
                </button>
              </div>
              <div
                className={`${c.content} ${c.desktopCardPadding}`}
                tabIndex={0}
              >
                {displayData.image}
                {displayData.title}
                {displayData.content}
              </div>
              <span className={c.desktopCardPadding}>{displayData.link}</span>
            </div>
          )}
        </RelativePanel>
      </SimpleFocusTrap>
    );
  };

  const renderMobileView = () => {
    return (
      <Modal open={shown} onClose={handleClose} dismissOnHistoryNav={true}>
        {displayData && (
          <ModalSection
            ariaLabelledby=""
            ariaDescribedby=""
            title={<div className={c.newsHeader}>{displayData.header}</div>}
          >
            <div className={c.newsCard}>
              {!!displayData.image && (
                <span className={c.mobileCardPadding}>{displayData.image}</span>
              )}
              <span className={c.mobileCardPadding}>{displayData.title}</span>
              <span className={c.mobileCardPadding}>{displayData.content}</span>
              <span className={c.mobileCardPadding}>{displayData.link}</span>
            </div>
          </ModalSection>
        )}
      </Modal>
    );
  };

  return responsiveSize === 'S' ? renderMobileView() : renderDesktopView();
};

export default NewsCard;
