import React, { useContext } from 'react';
import c from './page-container.module.scss';
import { Page } from 'components/providers/page-provider/PageProvider';
import BannerCarousel from 'components/ui/banner-carousel/BannerCarousel';

import {
  ImpersonationContext,
  deepClone,
  fsUpdateDoc,
  AccessibilityContext,
} from '@monash/portal-frontend-common';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { Data } from 'components/providers/data-provider/DataProvider';
import {
  getPageBannerCollegeData,
  getPageBannerData,
} from 'components/utilities/pages';
import classNames from 'classnames';

const PageContainer = ({
  children,
  className,
  pageId,
  loading,
  type = 'default',
  ...restProps
}) => {
  const { addSnackbar } = useSnackbar();
  const { currentUser } = useContext(ImpersonationContext);
  const {
    pagesData,
    activeGlobalBanners,
    globalBannerShownPageId,
    visiblePagesPageOrder,
  } = useContext(Page);
  const { setPortalPreferences, currentDate } = useContext(Data);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const isFirst = pageId === visiblePagesPageOrder?.[0];

  // page banners
  const pageBanners = pagesData?.pageBanners;
  const pageBannerShown = pageBanners && pageBanners[pageId];
  const pageBannerData = currentUser?.is?.MonashCollege
    ? getPageBannerCollegeData(pageId)
    : getPageBannerData(pageId);

  const dismissPageBanner = () => {
    const id = currentUser.uid;
    const path = `users/${id}`;
    const newPages = { ...pagesData };
    newPages.pageBanners[pageId] = false;
    fsUpdateDoc(path, {
      'preferences.pages': newPages,
    })
      .then(() => handleDismissPageBannerSuccess(newPages))
      .catch(handleDismissBannerError);
  };

  const handleDismissPageBannerSuccess = (newPages) => {
    setPortalPreferences((f) => {
      return { ...f, pages: newPages };
    });
  };

  // global banners
  const globalBannerShown = globalBannerShownPageId === pageId;

  const dismissGlobalBanner = () => {
    const id = currentUser.uid;
    const path = `users/${id}`;

    fsUpdateDoc(path, {
      'preferences.globalBanners.home.lastDismissed': currentDate,
    })
      .then(() => handleDismissGlobalBannerSuccess())
      .catch(handleDismissBannerError);
  };

  const handleDismissGlobalBannerSuccess = () => {
    setPortalPreferences((f) => {
      const newPreferences = deepClone(f);

      // if user has dismissed global banners before, override the current global banner local preferences
      if (newPreferences.globalBanners) {
        newPreferences.globalBanners.home.lastDismissed = currentDate;
        return newPreferences;
      } else {
        // else create global banner local preferences
        const initialGlobalBannersPreferencesInHome = {
          globalBanners: {
            home: {
              lastDismissed: currentDate,
            },
          },
        };
        return { ...newPreferences, ...initialGlobalBannersPreferencesInHome };
      }
    });
  };

  // common
  const handleDismissBannerError = (error) => {
    resetAppLiveMsgs();
    addSnackbar({
      message:
        'We are unable to dismiss the banner right now - try again later',
      type: 'error',
    });
    console.warn(
      '[updatePortalPreferences]: api call error, failed to dismiss banner',
      error
    );
  };

  const classes = classNames(c.PageContainer, c[type], className, {
    [c.isFirst]: isFirst,
  });

  return (
    <div className={classes} {...restProps}>
      {!loading && globalBannerShown && (
        <BannerCarousel
          data={activeGlobalBanners}
          onDismiss={dismissGlobalBanner}
          dismissDataTrackingEvent="banner-global-dismiss"
          pageId={pageId}
        />
      )}

      {!loading && !globalBannerShown && pageBannerShown && (
        <BannerCarousel
          data={pageBannerData}
          onDismiss={dismissPageBanner}
          dismissDataTrackingEvent="banner-dismiss"
          pageId={pageId}
        />
      )}
      {children}
    </div>
  );
};

export default PageContainer;
