export const calculatePageBgStyles = (numberOfPages, selectedPage) => {
  const style = {
    background: 'var(--canvas-bg-color)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    width: `${100 + 5 * numberOfPages}vw`,
    transform: `translate(-${5 * selectedPage}vw, 0)`,
  };

  return style;
};
