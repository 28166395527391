import React, { createContext, useEffect, useRef, useState } from 'react';

export const WidgetContext = createContext();

const WidgetProvider = ({ children }) => {
  // universal
  // Note: use this sparingly, make sure at any given time, only one update source is setting this value
  // will cause unexpected side effects when having multiple simultaneous update sources
  const [isGroupKeyNavDisabled, setIsGroupKeyNavDisabled] = useState(false);

  // shortcuts dialogs
  const [editShortcutsActiveWidget, setEditShortcutsActiveWidget] =
    useState(null);
  const [editShortcutsEscapeFocusRef, setEditShortcutsEscapeFocusRef] =
    useState(null);
  const editShortcuts = (widgetId, ref) => {
    setEditShortcutsActiveWidget(widgetId);
    setEditShortcutsEscapeFocusRef(ref);
  };

  // clock dialog
  const [editClockBackgroundActiveWidget, setEditClockBackgroundActiveWidget] =
    useState(null);

  // assessments filter dialog
  const [
    editAssessmentsFilterActiveWidget,
    setEditAssessmentsFilterActiveWidget,
  ] = useState(null);
  const assessmentsFilterEscapeRef = useRef(null);

  // disable widgets group key nav if
  // - any additional actions dialog is shown
  useEffect(() => {
    const allAdditionalDialogsClosed =
      !editShortcutsActiveWidget &&
      !editClockBackgroundActiveWidget &&
      !editAssessmentsFilterActiveWidget;
    setIsGroupKeyNavDisabled(!allAdditionalDialogsClosed);
  }, [
    editShortcutsActiveWidget,
    editClockBackgroundActiveWidget,
    editAssessmentsFilterActiveWidget,
  ]);

  return (
    <WidgetContext.Provider
      value={{
        // universal
        isGroupKeyNavDisabled,
        setIsGroupKeyNavDisabled,
        // edit shortcuts dialog
        editShortcutsActiveWidget,
        editShortcuts,
        editShortcutsEscapeFocusRef,
        // edit clock bg dialog
        editClockBackgroundActiveWidget,
        editClockBackground: setEditClockBackgroundActiveWidget,
        // edit assessments filter dialog
        editAssessmentsFilterActiveWidget,
        editAssessmentsFilter: setEditAssessmentsFilterActiveWidget,
        assessmentsFilterEscapeRef,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};

export default WidgetProvider;
