import { Icon } from '@monash/portal-react';
import { lightOrDark } from 'components/pages/custom/widget-library/utils';
import c from './colour-list.module.scss';

const ColourList = ({ optionKey, optionName, options, data, updateData }) => {
  const getContrastColour = (value) => {
    const colour = value.startsWith('var') // Get colour value of items using CSS variables
      ? getComputedStyle(document.documentElement).getPropertyValue(
          value.replace(/var\((--.*?)\)/, '$1') // regex to extract variable name from var(--x) format
        )
      : value;

    return lightOrDark(colour) === 'light' ? '#333333' : '#FFFFFF';
  };

  return (
    <ul className={c.colourPalette} role="menu">
      {options.map((item) => {
        const selected = item === options.find((item) => data === item.id);
        const contrastColour = getContrastColour(item.value);
        const screenReaderLabel = `${optionName}: ${item.id}${
          selected ? ', selected' : ''
        }`;

        return (
          <li key={item.value}>
            <button
              className={c.colourSquare}
              type="button"
              // TODO: come up with styling for 'auto' option
              style={{
                backgroundColor: item.value,
                borderColor: item.id === 'white' && 'var(--card-border-color)',
                outlineColor: contrastColour,
              }}
              onClick={() => updateData(optionKey, item.id)}
              role="menuitem"
              aria-label={screenReaderLabel}
            >
              {selected && <Icon.Check color={contrastColour} />}
            </button>
          </li>
        );
      })}
    </ul>
  );
};
export default ColourList;
