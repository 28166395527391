import { ExternalLink } from '@monash/portal-react';
import WidgetBlock from './WidgetBlock';

const WidgetEncumbrance = () => {
  return (
    <WidgetBlock>
      You have a block (an encumbrance)
      <ExternalLink
        text="Encumbrance check"
        link="https://www.monash.edu/students/support/connect/encumbrances?chatbot=open&hiddenMessage=remove%20encumbrance"
        mode="card"
        variant="text"
      />
    </WidgetBlock>
  );
};

export default WidgetEncumbrance;
