import React from 'react';
import c from './errorScreen.module.scss';
import { IllustrationError, Logo } from '@monash/portal-react';
import brokenFlowers from '../../../assets/images/errors/broken-flower.svg';

export const ErrorScreen = ({
  heading,
  message,
  illustration,
  children = null,
}) => {
  const getIllustration = () => {
    switch (illustration) {
      case 'brokenFlowers':
        return <img className={c.brokenFlowers} src={brokenFlowers} alt="" />;
      default:
        return (
          <div className={c.illustrationError}>
            <IllustrationError />
          </div>
        );
    }
  };

  return (
    <div className={c.errorScreen}>
      <div className={c.logoWrapper}>
        <Logo.MonashStacked color="white" />
      </div>

      <div className={c.container}>
        <div className={c.illustration} aria-hidden="true">
          {getIllustration()}
        </div>

        {heading && <h1 className={c.heading}>{heading}</h1>}
        {message && <p className={c.message}>{message}</p>}
        {children}
      </div>
    </div>
  );
};

export default ErrorScreen;
