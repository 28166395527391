import {
  isFunction,
  isNumber,
  isStringEmpty,
} from '@monash/portal-frontend-common';
import c from './cell.module.scss';

export const CELL_VARIANTS = {
  // regular
  HEADING: 'HEADING',
  TH: 'TH',
  // button
  NAV: 'NAV',
  DAY: 'DAY',
};

const Cell = ({
  className,
  variant,
  children,
  // data props
  today,
  initialDay,
  displayDate,
  notCurrentMonth,
  notInteractive,
  isSelected,
  // handler props
  onClick = null,
  onMouseOver = null,
  onMouseLeave = null,
  hoveringDate,
  // AX props
  tabIndex = null,
  title = '',
  ariaLabel = '',
}) => {
  const shouldRenderButton =
    (variant === CELL_VARIANTS.NAV || variant === CELL_VARIANTS.DAY) &&
    !notInteractive &&
    isFunction(onClick);
  const Element = shouldRenderButton ? 'button' : 'div';

  // attributes/modifiers
  let attrType = null;
  const attrDisabled = null;

  // update attributes/modifiers based on variant
  switch (variant) {
    case CELL_VARIANTS.HEADING:
      break;
    case CELL_VARIANTS.TH:
      break;
    case CELL_VARIANTS.NAV:
      attrType = shouldRenderButton ? 'button' : null;
      break;
    case CELL_VARIANTS.DAY:
      attrType = shouldRenderButton ? 'button' : null;
      break;
    default:
  }

  // AX
  const hasAriaLabel = !isStringEmpty(ariaLabel);
  const hasTitle = !isStringEmpty(title);

  return (
    <Element
      type={attrType}
      tabIndex={isNumber(tabIndex) ? tabIndex : null}
      className={className ? [c.cell, className].join(' ') : c.cell}
      data-variant={variant || null}
      data-initial-day={variant === CELL_VARIANTS.DAY && initialDay ? '' : null}
      data-display-date={displayDate ? '' : null}
      data-not-interactive={notInteractive ? '' : null}
      data-not-current-month={notCurrentMonth ? '' : null}
      data-hovering-date={hoveringDate ? '' : null}
      disabled={attrDisabled}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      title={hasTitle ? title : null}
      aria-label={hasAriaLabel ? ariaLabel : null}
    >
      <div
        className={c.text}
        data-selected={isSelected ? '' : null}
        data-today={today ? '' : null}
        aria-hidden={hasAriaLabel ? 'true' : null}
      >
        {children}
      </div>
    </Element>
  );
};
export default Cell;
