import c from './widget-block.module.scss';
import fs from 'styles/font-styles.module.scss';
import duck from './sleeping-duck.svg';

/**
 * Base template for widget "blocked" view. Can supply children for custom messaging
 */
const WidgetBlock = ({ children }) => {
  return (
    <div className={`${c.container} ${fs.bold}`}>
      <img src={duck} alt="" width="120" height="82" />
      <div className={c.messaging}>{children}</div>
    </div>
  );
};

export default WidgetBlock;
