import { transformPageNameForURL } from './utils';

export const getRouteFromPage = (page) => {
  if (!page) return '/';

  const pageNameForPath = transformPageNameForURL(page?.name);

  if (page?.custom) {
    return `/page/${pageNameForPath}`;
  } else {
    return `/${pageNameForPath}`;
  }
};
