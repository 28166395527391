import {
  getDisplayStatus,
  getDisplayStatusComponent,
} from 'components/utilities/user-events/render-status';
import { formatDuration, intervalToDuration } from 'date-fns';

const StatusInfo = ({ event, currentDate }) => {
  const duration = intervalToDuration({
    start: event.start.time,
    end: event.end.time,
  });

  return (
    <span>
      {event.eventKind === 'assessment'
        ? // assessments display their status
          getDisplayStatusComponent({
            status: getDisplayStatus({
              status: event.data?.status,
              currentDate,
              submissionStartDate: event.data?.submissionStartDate?.time,
            }),
            mode: 'card',
          })
        : // exams display their duration
          formatDuration(duration)}
    </span>
  );
};

export default StatusInfo;
