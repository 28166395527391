import React from 'react';

import c from '../error-pages-shared.module.scss';
import { ExternalLink } from '@monash/portal-react';

export const CompletedMessage = () => {
  return (
    <>
      <div className={c.heading}>Congratulations!</div>
      <p>
        You're now course completed! Check out your graduation options, and
        order a proof of qualification letter (if you need one).
      </p>

      <div className={c.actions}>
        <ExternalLink
          text="Graduations"
          link=" https://www.monash.edu/students/admin/graduations"
          variant="primary"
          mode="canvas"
        />
      </div>
    </>
  );
};
