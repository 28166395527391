import { COURSE_STATUS } from '@monash/portal-frontend-common';

const STATUS_HIERARCHY = [
  COURSE_STATUS.ENROLLED,
  COURSE_STATUS.INTERMIT,
  COURSE_STATUS.INACTIVE,
  COURSE_STATUS.COMPLETED,
  COURSE_STATUS.DISCONTIN,
];

export const getHighestCourseStatus = (courses) => {
  if (!courses) {
    return null;
  }

  return STATUS_HIERARCHY.reduce((acc, curr) => {
    if (acc) {
      return acc;
    }

    if (courses.some((course) => course.status === curr)) {
      return curr;
    }

    return null;
  }, null);
};
