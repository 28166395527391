import React, { useEffect, useState } from 'react';

export const Transition = ({
  children,
  show,
  enterClass = '',
  exitClass = '',
  delay = 300,
}) => {
  const [mounted, setMounted] = useState(true);
  const [className, setClassName] = useState(null);

  useEffect(() => {
    setTimeout(() => setMounted(show), show ? 0 : delay);
    setClassName(show ? enterClass : exitClass);
  }, [delay, enterClass, exitClass, show]);

  // render
  return (
    <>
      {(mounted || show) &&
        React.Children.map(children, (child, i) => {
          return React.cloneElement(child, {
            className: [child.props.className, className].join(' '),
          });
        })}
    </>
  );
};

export default Transition;
