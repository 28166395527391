import { deepClone } from '@monash/portal-frontend-common';

/**
 * Util to patch the unit colours indices object with a new colour index choice
 * @param {*} currentUnitColoursIndices existing unit colours indices object to patch
 * @param {*} selectedUnitCode the associated unit code to patch
 * @param {*} selectedColourIndex the new colour index to patch with
 * @returns new unit colours indices object with updated unit colours according to input
 */
export const patchUnitColoursObject = ({
  currentUnitColoursIndices,
  selectedUnitCode,
  selectedColourIndex,
}) => {
  if (currentUnitColoursIndices?.units?.length) {
    const unitColoursIndices = deepClone(currentUnitColoursIndices);

    for (let i = 0; i < unitColoursIndices.units.length; i++) {
      const unit = currentUnitColoursIndices.units[i];
      const unitCode = unit.unitCode;

      if (unitCode === selectedUnitCode) {
        unitColoursIndices.units[i] = {
          colourIndex: selectedColourIndex,
          unitCode,
        };
        return unitColoursIndices;
      }
    }
  }

  return false;
};
