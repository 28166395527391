import { isAfter, isBefore } from 'date-fns';

/**
 * isBetweenDates
 * @param {Date} day
 * @param {Date} start
 * @param {Date} end
 * @returns {boolean}
 */
export const isBetweenDates = (day, start, end) => {
  if (day === start || day === end) {
    return true;
  }
  return isBefore(day, end) && isAfter(day, start);
};
