import { Alert, Card } from '@monash/portal-react';
import c from './widget-floating-error.module.scss';
import React from 'react';
const WidgetFloatingError = ({ error }) => {
  return (
    <div className={c.widgetFloatingError}>
      <Card className={c.errorCard} shadow>
        <Alert type="error">{error}</Alert>
      </Card>
    </div>
  );
};

export default WidgetFloatingError;
