import { formatTimeDistance } from 'components/providers/data-provider/utils';

const defaultPrefix = {
  standard: 'Starts in',
  assessment: 'Due in',
};

export const getTimeUntil = ({
  event,
  currentDate,
  prefixString = defaultPrefix,
}) => {
  const { eventKind } = event;

  if (eventKind === 'standard') {
    return `${prefixString.standard} ${formatTimeDistance(
      event.start?.time,
      currentDate
    )}`;
  }
  if (eventKind === 'assessment') {
    return `${prefixString.assessment} ${formatTimeDistance(
      event.end?.time,
      currentDate
    )}`;
  }
};
