import React, { useLayoutEffect, useRef, useState } from 'react';
import {
  isDomNodeType,
  isStringEmpty,
  useOnOutsideClick,
} from '@monash/portal-frontend-common';
import { Button, IconButton } from '@monash/portal-react';
import PositionAwareMenuV2 from '../positionAwareMenuV2/PositionAwareMenuV2';
import { useMenuFocus } from 'hooks/use-menu-focus';
import c from './menu.module.scss';
const MENU_ITEM_SELECTOR = 'button[type="button"][role="menuitem"]';
const ICON_BUTTON_SIZE = 24;

const Menu = ({
  items,
  icon,
  label,
  mode,
  buttonVariant,
  ariaLabel,
  trackingLabel,
}) => {
  const triggerRef = useRef();
  const menuContainerRef = useRef();
  const [itemNodes, setItemNodes] = useState([]);
  const isTriggerIconButton = buttonVariant === 'icon';
  const triggerButtonWidth = isTriggerIconButton
    ? ICON_BUTTON_SIZE
    : triggerRef?.current?.getBoundingClientRect().width;
  const triggerButtonHeight = isTriggerIconButton
    ? ICON_BUTTON_SIZE
    : triggerRef?.current?.getBoundingClientRect().height;

  const { handleKeyDown, setIsShown, isShown } = useMenuFocus({
    triggerRef,
    menuWrapperRef: menuContainerRef,
    itemNodes,
  });

  const closeMenu = () => {
    setIsShown(false);
  };

  const onClickHandler = (e) => {
    setIsShown((prevIsShown) => !prevIsShown);
    e.stopPropagation();
  };

  useOnOutsideClick({
    refs: [triggerRef, menuContainerRef],
    fn: closeMenu,
  });

  useLayoutEffect(() => {
    if (isShown && isDomNodeType(menuContainerRef.current)) {
      const menuItemsNodes =
        menuContainerRef.current.querySelectorAll(MENU_ITEM_SELECTOR);
      setItemNodes([...Array.from(menuItemsNodes)]);
    }
  }, [isShown]);

  return (
    <div className={c.menu} onKeyDown={handleKeyDown}>
      {isTriggerIconButton ? (
        <IconButton
          onClick={onClickHandler}
          ref={triggerRef}
          variant="text"
          size={ICON_BUTTON_SIZE}
          mode={mode}
          icon={icon}
          aria-haspopup="menu"
          aria-label={ariaLabel}
          data-tracking-event={trackingLabel}
        />
      ) : (
        <Button
          variant={buttonVariant}
          mode={mode}
          icon={icon}
          data-tracking-event={trackingLabel}
          ref={triggerRef}
          size="medium"
          onClick={onClickHandler}
          aria-haspopup="menu"
        >
          {label}
        </Button>
      )}

      <PositionAwareMenuV2
        shown={isShown}
        offsetX={triggerButtonWidth}
        offsetY={triggerButtonHeight}
        dismissOnHistoryNav={true}
        onDismiss={closeMenu}
      >
        <ul
          className={c.menuList}
          ref={menuContainerRef}
          tabIndex={-1}
          role="menu"
          aria-label={ariaLabel}
        >
          {items.map((item, i) => {
            const itemHasPopup = !isStringEmpty(item.haspopup);
            return (
              <button
                key={i}
                type="button"
                className={c.menuListItem}
                onClick={(event) => {
                  item.function(event);
                  setIsShown(false);
                }}
                style={{
                  color: `var(${
                    item.attention
                      ? '--color-intent-attention'
                      : '--card-text-color'
                  })`,
                }}
                role="menuitem"
                aria-haspopup={itemHasPopup ? item.haspopup : null}
                data-tracking-event={item.trackingLabel}
              >
                {item.icon}
                {item.text}
              </button>
            );
          })}
        </ul>
      </PositionAwareMenuV2>
    </div>
  );
};

export default Menu;
