import React from 'react';
import c from './date-info.module.scss';
import classNames from 'classnames';

const DateInfo = ({ dateItem, firstItem, dateInfoWidth }) => {
  const isPeriod = dateItem.endDate;

  const classes = classNames(c.dateItem, {
    [c.period]: isPeriod,
    [c.firstItem]: firstItem,
  });

  const ariaLabel = isPeriod
    ? `${dateItem.fullEndDate} to ${dateItem.fullEndDate}`
    : dateItem.fullDate;

  const renderDate = (dateOfWeek, date) => (
    <div className={c.date}>
      <p className={c.dayOfWeek}>{dateOfWeek}</p>
      <p className={c.dateText}>{date}</p>
    </div>
  );

  return (
    <div
      className={classes}
      aria-label={ariaLabel}
      style={{ width: `${dateInfoWidth}px` }}
    >
      {isPeriod ? (
        <>
          {renderDate(dateItem.dayOfWeek, dateItem.date)}
          <div className={c.date}>
            <p className={c.dayOfWeek}>{dateItem.endDayOfWeek}</p>
            <p className={c.dateText}>- {dateItem.endDate}</p>
            {dateItem.month !== dateItem.endMonth && (
              <p className={c.endMonthText}>
                {dateItem.endMonth.substring(0, 3)}
              </p>
            )}
          </div>
        </>
      ) : (
        <>{renderDate(dateItem.dayOfWeek, dateItem.date)}</>
      )}
    </div>
  );
};

export default DateInfo;
