import React from 'react';
import c from './page-name-input.module.scss';

const PageNameInput = ({
  pageName,
  pageNameInput,
  finishRenaming,
  resetInput,
  onChange,
}) => {
  // on key press
  const handleOnKeyDown = (e) => {
    // finish input event when enter is pressed
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      finishRenaming();
    }
    // cancel input event changes when shift + tab or ESC is pressed
    if ((e.keyCode === 9 && e.shiftKey === true) || e.keyCode === 27) {
      e.preventDefault();
      resetInput();
    }
  };

  // replicate value for growing text area
  const handleInputForReplicatingValue = (e) =>
    e.target.parentNode.setAttribute('data-replicated-value', e.target.value);

  return (
    <div className={c.pageNameInput}>
      <textarea
        autoFocus={true}
        placeholder={pageName}
        onChange={onChange}
        value={pageNameInput}
        rows={1}
        onBlur={finishRenaming}
        onKeyDown={handleOnKeyDown}
        onInput={handleInputForReplicatingValue}
      />
    </div>
  );
};

export default PageNameInput;
