import React, { useCallback, useState } from 'react';
import c from './widget-library.module.scss';
import { isNumber } from '@monash/portal-frontend-common';
import { calculateYOffset } from './utils';
import { VirtuallyHidden } from '@monash/portal-react';

const WidgetPreview = ({
  previewTypeId,
  widgetDirectory,
  widgetList,
  itemRefs,
  triggerRef,
}) => {
  const showPreviewPanel = isNumber(previewTypeId);
  const [previewElementRect, setPreviewElementRect] = useState(null);
  const previewWidget = widgetList.find(
    (item) => Number(item[0]) === previewTypeId
  )?.[1];

  const previewRef = useCallback((e) => {
    if (e) {
      setPreviewElementRect(e?.getBoundingClientRect());
    }
  }, []);
  const previewPanelExtraProps = showPreviewPanel ? { ref: previewRef } : {};
  const previewAnchorRect = itemRefs
    .find((item) => item[0] === previewTypeId)?.[1]
    ?.current?.getBoundingClientRect();

  // Reposition preview panel
  const previewOffsetX = previewAnchorRect ? previewAnchorRect?.width - 10 : 0;
  const previewOffsetY = calculateYOffset(
    previewElementRect,
    previewAnchorRect,
    triggerRef?.current?.getBoundingClientRect()
  );

  return (
    <>
      {/* render virtually hidden widget preview images for caching */}
      <VirtuallyHidden aria-hidden="true">
        {widgetList.map((wgt, i) => {
          return (
            <img
              key={`wgt-preview-cache-${i + 1}`}
              src={widgetDirectory[wgt[0]]?.previewImage}
              alt=""
            />
          );
        })}
      </VirtuallyHidden>
      <div
        className={c.previewPanel}
        data-show={showPreviewPanel ? 'true' : 'false'}
        style={{
          right: previewOffsetX,
          top: previewOffsetY,
        }}
        {...previewPanelExtraProps}
      >
        <p className={c.descriptionText}>{previewWidget?.description}</p>
        <div className={c.imgContainer}>
          <img
            alt=""
            src={previewWidget?.previewImage}
            key={previewWidget?.description}
          />
        </div>
      </div>
    </>
  );
};

export default WidgetPreview;
