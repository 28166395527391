// Returns a position where the panel will not be outside
// of window bounds
export const getPositionWithinWindow = ({ ref, windowSize, position }) => {
  const bounds = ref.current?.getBoundingClientRect();
  let xDifference = 0;
  let yDifference = 0;
  // add addtional padding on the reposition

  const windowWidth = windowSize.width || window.innerWidth;
  const windowHeight = windowSize.height || window.innerHeight;

  if (bounds.right > windowWidth) {
    xDifference = bounds.right - windowWidth + 8;
  }

  if (bounds.bottom > windowHeight) {
    yDifference = bounds.bottom - windowHeight + 8;
  }

  return {
    x: position.x - xDifference,
    y: position.y - yDifference,
  };
};

export const getNewPosition = ({
  event,
  startingTouch,
  startingPosition,
  position,
  ref,
}) => {
  const startingX = startingTouch.current.x;
  const startingY = startingTouch.current.y;

  const movement = {
    x: event.targetTouches?.[0].clientX - startingX,
  };

  const pos = event.targetTouches
    ? {
        x: startingPosition.current.x + movement.x,
        y:
          startingPosition.current.y +
          event.targetTouches[0].clientY -
          startingY,
      }
    : {
        x: position.x + event.movementX,
        y: position.y + event.movementY,
      };

  if (pos.x + ref.current.offsetWidth > window.innerWidth) {
    pos.x = window.innerWidth - ref.current.offsetWidth;
  }

  if (pos.x < 0) {
    pos.x = 0;
  }

  if (pos.y + ref.current.offsetHeight > window.innerHeight) {
    pos.y = window.innerHeight - ref.current.offsetHeight;
  }

  if (pos.y < 0) {
    pos.y = 0;
  }

  return { x: pos.x, y: pos.y };
};
