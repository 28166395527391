import React from 'react';
import c from './popup.module.scss';
import { useSessionStorage } from '@monash/portal-frontend-common';
import { Switch } from '@monash/portal-react';
import { FEATURE_FLAGS } from '../../../constants/features';

const FEATURE_FLAG_NAMES = {
  CRITICAL_AND_NON_CRITICAL_NOTIFICATIONS:
    'Critical and non-critical notifications',
  GRADES: 'Grades',
  UPDATES: 'Updates',
};

const FeatureToggles = () => {
  const [featureFlags, setFeatureFlags] = useSessionStorage(
    'featureFlags',
    FEATURE_FLAGS
  );
  return (
    <div className={c.featureFlagsWrapper}>
      {Object.entries(featureFlags).map(([key, value], i) => (
        <div key={i}>
          <div className={c.label}>{FEATURE_FLAG_NAMES[key] || key}</div>
          <Switch
            on={value}
            onClick={() =>
              setFeatureFlags((f) => ({
                ...f,
                [key]: !value,
              }))
            }
          />
        </div>
      ))}
    </div>
  );
};

export default FeatureToggles;
