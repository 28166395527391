import React from 'react';
import c from './notes.module.scss';
import NoteIcon from './NoteIcon.jsx';

const BubbleMenu = ({ editor }) => {
  const noteMenuColour = '#333';

  const menuList = [
    {
      type: 'bold',
      label: 'Bold',
      icon: <NoteIcon.Bold color={noteMenuColour} />,
      function: () => editor.chain().focus().toggleBold().run(),
    },
    {
      type: 'italic',
      label: 'Italic',
      icon: <NoteIcon.Italic color={noteMenuColour} />,
      function: () => editor.chain().focus().toggleItalic().run(),
    },
    {
      type: 'strike',
      label: 'Strike through',
      icon: <NoteIcon.Strike color={noteMenuColour} />,
      function: () => editor.chain().focus().toggleStrike().run(),
    },
    {
      type: 'heading',
      label: 'Heading',
      icon: <NoteIcon.Heading color={noteMenuColour} />,
      function: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
    },
    {
      type: 'paragraph',
      label: 'Paragraph',
      icon: <NoteIcon.Paragraph color={noteMenuColour} />,
      function: () => editor.chain().focus().setParagraph().run(),
    },
    {
      type: 'bulletList',
      label: 'List',
      icon: <NoteIcon.List color={noteMenuColour} />,
      function: () => editor.chain().focus().toggleBulletList().run(),
    },
    {
      type: 'orderedList',
      label: 'Numbers list',
      icon: <NoteIcon.NumbersList color={noteMenuColour} />,
      function: () => editor.chain().focus().toggleOrderedList().run(),
    },
    {
      type: 'taskList',
      label: 'Check List',
      icon: <NoteIcon.CheckList color={noteMenuColour} />,
      function: () => editor.chain().focus().toggleTaskList().run(),
    },
  ];
  return (
    <div className={c.bubbleMenu}>
      {menuList.map((item) => (
        <button
          key={item.type}
          type="button"
          onKeyDown={(e) => e.stopPropagation()}
          onClick={() => {
            item.function();
          }}
          className={editor.isActive(item.type) ? c.isActive : ''}
          aria-label={item.label}
        >
          {item.icon}
        </button>
      ))}
    </div>
  );
};

export default BubbleMenu;
