import {
  ASSESSMENT_EVENT_TYPES,
  WORKSHOP_EVENT_TYPES,
} from '../../../../constants';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';
import { findStandardEventDisplayClass } from 'components/providers/data-provider/utils';
import { endOfDay, format, startOfDay } from 'date-fns';
import { isStringEmpty } from '@monash/portal-frontend-common/dist/utils/string';

export const formatFinalAssessment = (activity) => {
  if (typeof activity === 'string') {
    const activityType = activity.toLowerCase();
    if (activityType.includes('eexam') || activityType.includes('e-exam')) {
      return 'eExam';
    } else {
      return 'Final assessment';
    }
  }
};

export const getClass = (event) => {
  const { eventType, eventKind } = event;
  if (WORKSHOP_EVENT_TYPES.includes(eventType)) {
    return 'Workshop';
  }
  if (eventKind === 'standard') {
    return findStandardEventDisplayClass(event);
  }
  if (eventKind === 'assessment') {
    return capitaliseFirstWord(event.eventType);
  }
};

export const getDueTimeString = (time, eventType) => {
  if (ASSESSMENT_EVENT_TYPES.includes(eventType)) {
    return `Due at ${format(time, 'h.mmaaa')}`.replace('.00', '');
  }
};
export const getListingTime = (event) => {
  const { eventType, start, display } = event;

  if (eventType) {
    if (eventType === 'exam' || eventType === 'class') {
      return display.showTime('time')?.replace(/ - /, '–');
    } else {
      return getDueTimeString(start.time, eventType) || 'Time not found';
    }
  } else {
    return 'Time not found';
  }
};

export const getTitle = (event, isForScreenReader = false) => {
  const { eventKind, eventType, data } = event;
  const unitCode = data?.unitCode;
  const unitCodeForScreenReader = isStringEmpty(unitCode)
    ? ''
    : unitCode.split('').join(' ');
  const unitCodeText = isForScreenReader ? unitCodeForScreenReader : unitCode;

  if (eventKind === 'assessment') {
    return `${unitCodeText}: ${data?.name}`;
  }

  if (eventType) {
    if (eventType === 'exam') {
      return `${unitCodeText}: ${formatFinalAssessment(
        data.activityTypeCategory
      )}`;
    } else {
      return `${unitCodeText}: ${getClass(event)}`;
    }
  }
};

export const formatEventTitleAbbr = (eventTitle, abbrProps) => {
  const splitTitle =
    typeof eventTitle === 'string' ? eventTitle.split(/:(.+)/, 2) : '';

  if (splitTitle.length) {
    return (
      <>
        <abbr {...abbrProps}>{(splitTitle[0] += ':')}</abbr>
        {splitTitle[1]}
      </>
    );
  } else return splitTitle;
};
/**
 * Returns true if there are events on the given day, else false
 * @param {Array} events array of user events or a single event data object
 * @param {Date} date date to check
 * @returns {boolean}
 */
export const eventsOnDay = (events, date) => {
  if (!events) return false;

  const eventsArray = Array.isArray(events) ? events : [events];

  if (!eventsArray.length) return false;

  // for any event, if:
  // event start day <= given date <= event end day
  // then there is at least one event on the given date's day
  const givenDate = new Date(date);

  return eventsArray.some((event) => {
    if (!event.start || !event.end) {
      return false;
    }

    const eventStartDay = startOfDay(event.start.time);
    const eventEndDay = endOfDay(event.end.time);

    if (eventStartDay <= givenDate && givenDate <= eventEndDay) {
      return true;
    }

    return false;
  });
};
