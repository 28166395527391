import { isStringEmpty } from '@monash/portal-frontend-common';

/**
 * Parses a given title from the library API. Removes author information from the title.
 * @param {string} title name of the item in the form: "[title] / [author(s)]"
 * @returns {string} title with the author information removed
 */
export const parseTitle = (title) => {
  if (!title) {
    return;
  }

  // Remove '/' and anything that comes after it
  const titleWithNoAuthor = title.replace(/\/.*/, '');
  return titleWithNoAuthor.trim();
};

/**
 * Parses a given author from the library API. Removes trailing commas/periods from the author string.
 * @param {string} author author of the item
 * @returns {string} parsed author string
 */
export const parseAuthor = (author) => {
  if (!author) {
    return;
  }

  // Remove trailing periods or commas
  const parsedAuthor = author.trim().replace(/[.,]+$/, '');
  return parsedAuthor;
};

/**
 * Validates that given string exists
 * @param {string} string string to be validated
 * @param fallback fallback value if string is non-truthy or empty
 */
export const validateString = (string, fallback = '-') => {
  return !string || isStringEmpty(string) ? fallback : string;
};

/**
 * Detect if all loans, requests, and fees have errors
 * @param libraryData response from getLibraryData API
 * @returns true if all error loans, requests, and fees have errors. false if not
 */
export const hasAllErrors = (libraryData) => {
  return (
    libraryData instanceof Object &&
    libraryData.errorFlags instanceof Object &&
    !Object.values(libraryData.errorFlags).some((flag) => flag === 0)
  );
};

/**
 * Detect if at least one of loans, requests, and fees have error(s)
 * @param libraryData response from getLibraryData API
 * @returns true if at least one of loans, requests, and fees have error(s). false if not
 */
export const hasAnyErrors = (libraryData) => {
  return (
    libraryData instanceof Object &&
    libraryData.errorFlags instanceof Object &&
    Object.values(libraryData.errorFlags).some((flag) => flag !== 0)
  );
};
