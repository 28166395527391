import React, { useState, useEffect, useContext, useMemo } from 'react';
import * as Sentry from '@sentry/browser';
import {
  APIContext,
  useSessionStorage,
  fsGetDoc,
} from '@monash/portal-frontend-common';
import backUpPages from './backup-pages.js';
import mockKeyDates, { mockCurrentTPs } from './mock-key-dates';
import { getMockCourseData } from './mock-courses';

import { nanoid } from 'nanoid';
import {
  getCurrentDate,
  getUseFakeData,
  formatEvents,
  getUnitTitles,
  calculateUserEvents,
  removeNonExistentWidgets,
} from './utils';
import { filterNextDay } from './utils/filterNextDay.js';
import { getUserEventsError } from './utils/getUserEventsError.js';

export const Data = React.createContext();

const DataProvider = ({ children }) => {
  const {
    getUserEvents,
    getUnitColours,
    getCurrentEnrolments,
    getWeeks,
    getKeyDates,
    getImportantDates,
    getUnitColourMappings,
    getPortalPreferences,
    updatePortalPreferences,
    getAvatar,
    getProfileData,
    getAcademicResults,
  } = useContext(APIContext);

  const [fakeCourseDataType] = useSessionStorage('fakeCourseDataType');
  const [enrolledTeachingPeriods, setEnrolledTeachingPeriods] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const [userEvents, setUserEvents] = useState([]);
  const [userEventsError, setUserEventsError] = useState(false);
  const [unitColoursIndices, setUnitColoursIndices] = useState();
  const [unitColoursIndicesError, setUnitColoursIndicesError] = useState(false);
  const [unitColourMappings, setUnitColourMappings] = useState([]);
  const [unitColourMappingsError, setUnitColourMappingsError] = useState(false);
  const [unitColours, setUnitColours] = useState({});
  const [mapIds, setMapIds] = useState({});
  const [semesterWeeks, setSemesterWeeks] = useState(null);
  const [relevantKeyDates, setRelevantKeyDates] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [importantDates, setImportantDates] = useState([]);
  const [gradeScaleTypes, setGradeScaleTypes] = useState({});
  const [unitTitles, setUnitTitles] = useState({});
  const [academicResults, setAcademicResults] = useState();
  const [academicResultsError, setAcademicResultsError] = useState(false);

  // loading
  const loadingAll = useMemo(() => {
    return {
      userEvents: true,
      preferences: true,
      unitColours: true,
      weeks: true,
      unitColoursIndices: true,
      unitColourMappings: true,
      avatar: true,
      profile: true,
      libraryData: true,
      unitTitles: true,
    };
  }, []);
  const [loading, setLoading] = useState(loadingAll);
  const [errors, setErrors] = useState({});
  const currentDate = getCurrentDate();

  // preferences
  const [portalPreferences, setPortalPreferences] = useState(null);
  useEffect(() => {
    getPortalPreferences()
      .then((r) => {
        setPortalPreferences(removeNonExistentWidgets(r));
      })
      .catch((error) => {
        setErrors({ ...errors, portalPreferences: error });
        setPortalPreferences({ pages: backUpPages });
        Sentry.captureException(error);
      })
      .finally(
        setLoading((f) => {
          return { ...f, preferences: false };
        })
      );
  }, []);

  const handleUpdatePortalPreferences = (update) => {
    setPortalPreferences((f) => {
      return { ...f, ...update };
    });
    return updatePortalPreferences(update);
  };

  // profile / avatar
  useEffect(() => {
    getProfileData()
      .then((r) => {
        // enrich preferredName
        if (r.preferredName && r.lastName) {
          r.preferredFullName = `${r.preferredName} ${r.lastName}`;
        }
        setProfileData(r);
      })
      .catch((error) => {
        setErrors({ ...errors, profileData: error });
        Sentry.captureException(error);
        console.warn(
          '[getProfileData]: api call error, failed to get profile data.',
          error
        );
      })
      .finally(
        setLoading((f) => {
          return { ...f, profile: false };
        })
      );
  }, []);

  useEffect(() => {
    getAvatar()
      .then((r) => {
        setAvatar(r);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(
        setLoading((f) => {
          return { ...f, avatar: false };
        })
      );
  }, []);

  // events & currentEnrolment dependant calls
  const mazeMapConvert = async (id) => {
    const data = await fetch(
      `https://api.mazemap.com/api/pois/?identifier=${id}&campuscollectiontag=monashuni`,
      { method: 'GET' }
    )
      .then((response) => {
        if (!response.ok) {
          console.error('Errored response:', response);
          throw new Error('Mazemap API call failed');
        }

        return response.json();
      })
      .catch((error) => {
        Sentry.captureException(error);
      });

    if (data?.pois?.[0]?.poiId) {
      setMapIds((f) => {
        return { ...f, [id]: data.pois[0].poiId };
      });
      return data.pois[0].poiId;
    } else {
      return null;
    }
  };

  const getMazeMap = async (locationId) => {
    return mapIds[locationId] || (await mazeMapConvert(locationId));
  };

  const mapsMonashLink = async (locationId) => {
    // get poiId from mazemap api
    const poiID = await getMazeMap(locationId);
    // get geolocation from browser and go to link
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const url = `https://maps.monash.edu/#v=1&level=1&starttype=point&start=${pos.coords.longitude}%2C${pos.coords.latitude}&desttype=poi&dest=${poiID}`;
        window.open(url, '_blank');
      },
      () => {
        console.warn('Current location not found');
      },
      { enableHighAccuracy: true }
    );
  };

  const getMockKeyDates = async () => {
    return mockKeyDates;
  };

  // get unit titles
  useEffect(() => {
    getAcademicResults()
      .then((r) => {
        setAcademicResults(r);
        const unitTitles = getUnitTitles(r);
        setUnitTitles(unitTitles);
      })
      .catch((error) => {
        console.warn(
          '[getAcademicResults]: api call error, cannot retrieve academic results data',
          error
        );
        Sentry.captureException(error);
        setAcademicResultsError(true);
      })
      .finally(
        setLoading((f) => {
          return { ...f, unitTitles: false };
        })
      );
  }, []);

  useEffect(() => {
    async function load() {
      try {
        setLoading((l) => {
          return { ...l, ...loadingAll };
        });

        const { currentEnrolments } = await getCurrentEnrolments();

        // userCourses
        const courseEnrolments = fakeCourseDataType
          ? getMockCourseData(fakeCourseDataType)
          : currentEnrolments?.courseEnrolments;

        if (Array.isArray(courseEnrolments) && courseEnrolments.length) {
          setUserCourses([...courseEnrolments]);
        }

        const enrolledUnits = currentEnrolments?.unitEnrolments.filter(
          (u) => u.status === 'ENROLLED'
        );

        const enrolledTeachingPeriods =
          currentEnrolments?.teachingPeriods?.filter((teachingPeriod) => {
            return enrolledUnits.some((enrolledUnit) => {
              return (
                enrolledUnit.calType === teachingPeriod.calType &&
                enrolledUnit.ciSequenceNumber.toString() ===
                  teachingPeriod.ciSequenceNumber.toString()
              );
            });
          });

        setEnrolledTeachingPeriods(enrolledTeachingPeriods);

        // userEvents -> Today, Upcoming
        const useFakeData = getUseFakeData();
        const userEvents = await calculateUserEvents({
          useFakeData,
          getUserEvents,
          enrolledUnits,
        });

        setUserEventsError(getUserEventsError(userEvents));

        const filteredEvents = userEvents.events.filter(
          (x) => x.start.time > 0
        );

        const events = formatEvents(filteredEvents, currentDate);
        setUserEvents(events);
        setLoading((l) => {
          return { ...l, userEvents: false };
        });

        // preload mazemaps ids
        const locationIds = {};
        events.forEach((e) => {
          const id = e.location?.data?.[0]?.archibusID;
          if (id && !locationIds[id]) {
            locationIds[id] = true;
            mazeMapConvert(id);
          }
        });

        // Unit colours indices
        getUnitColours(enrolledUnits.map((e) => e.unitCode))
          .then((unitColoursIndices) => {
            setUnitColoursIndices(unitColoursIndices);
            setUnitColoursIndicesError(false);
          })
          .catch((error) => {
            Sentry.captureException(error);
            console.warn('getUnitColours API call error', error);
            setUnitColoursIndicesError(true);
          })
          .finally(() => {
            setLoading((l) => {
              return { ...l, unitColoursIndices: false };
            });
          });

        // Unit colour mappings
        getUnitColourMappings()
          .then((colourMappings) => {
            setUnitColourMappings(colourMappings.Rainbow); // Hardcoded to rainbow due to no foreseeable plans for other unit colour themes currently
            setUnitColourMappingsError(false);
          })
          .catch((error) => {
            Sentry.captureException(error);
            console.warn('getUnitColourMappings API call error', error);
            setUnitColourMappingsError(true);
          })
          .finally(() => {
            setLoading((l) => {
              return { ...l, unitColourMappings: false };
            });
          });

        // Weeks
        const nonSemesterEnrolment = enrolledUnits.some(
          (unit) => !['S1-01', 'S2-01'].includes(unit.calType)
        );
        if (enrolledUnits.length && !nonSemesterEnrolment) {
          const weeks = await getWeeks();
          setSemesterWeeks(weeks);
        }
        setLoading((l) => {
          return { ...l, weeks: false };
        });

        // Key dates
        const keyDates = useFakeData
          ? await getMockKeyDates()
          : await getKeyDates().catch((error) => {
              Sentry.captureException(error);
            });
        const currentTPs = useFakeData
          ? mockCurrentTPs
          : enrolledUnits
              .map((unit) => unit.calType)
              .reduce((acc, tp) => {
                if (!acc.some((unique) => unique === tp)) {
                  return [...acc, tp];
                } else {
                  return acc;
                }
              }, []);
        setRelevantKeyDates(
          keyDates
            ?.filter((kd) => currentTPs.includes(kd.teaching_period))
            .map((kd) => {
              return { ...kd, keyDate: true, id: nanoid() };
            })
        );
        getImportantDates()
          .then((r) => setImportantDates(r))
          .catch((error) => {
            Sentry.captureException(error);
          });
      } catch (error) {
        Sentry.captureException(error);
      }
    }

    load();
  }, [getCurrentEnrolments, getUnitColours, getUserEvents, loadingAll]);

  // Setting user's unit colours
  useEffect(() => {
    if (loading.unitColoursIndices || loading.unitColourMappings) {
      return;
    }

    const colours = {};

    if (unitColoursIndices?.units?.length && unitColourMappings?.length) {
      unitColoursIndices.units.forEach(
        (x) => (colours[x.unitCode] = unitColourMappings[x.colourIndex])
      );
    }

    setLoading((l) => {
      return { ...l, unitColours: false };
    });
    setUnitColours(colours);
  }, [unitColoursIndices, unitColourMappings]);

  useEffect(() => {
    const getGradeScalesTypes = async () => {
      const dataDoc = await fsGetDoc('appResources/grades');
      const data = dataDoc.data();
      setGradeScaleTypes(data?.gradeScaleTypeValues);
    };
    getGradeScalesTypes();
  }, []);

  return (
    <Data.Provider
      value={{
        enrolledTeachingPeriods: enrolledTeachingPeriods || [],
        userCourses,
        userEvents,
        userEventsError,
        gradeScaleTypes,
        upcomingEvents: filterNextDay(userEvents, currentDate),
        unitColours,
        unitColoursIndicesError,
        unitColourMappingsError,
        unitColoursIndices,
        setUnitColoursIndices,
        unitColourMappings,
        mapIds,
        mapsMonashLink,
        mazeMapConvert,
        loading,
        currentDate,
        portalPreferences,
        setPortalPreferences,
        updatePortalPreferences: handleUpdatePortalPreferences,
        semesterWeeks,
        relevantKeyDates,
        avatar,
        profileData,
        importantDates,
        unitTitles,
        academicResults,
        academicResultsError,
        errors,
      }}
    >
      {children}
    </Data.Provider>
  );
};

export default DataProvider;
