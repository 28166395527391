import React, { useContext, useState, useEffect, useMemo } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import Custom from 'components/pages/custom/Custom';
import { Data } from '../data-provider/DataProvider';
import Upcoming from 'components/pages/upcoming/Upcoming';
import Schedule from 'components/pages/schedule/Schedule';
import Updates from 'components/pages/updates/Updates';
import { calculatePageBgStyles } from '../utils';
import { DEFAULT_PAGE_NAMES, pageIds } from '../../../constants';
import { RouterState } from '@monash/portal-react';
import GenericError from 'components/utilities/error/GenericError';
import { getPageIdFromRoute } from 'components/utilities/pages/get-page-id-from-route';
import {
  FeatureContext,
  ImpersonationContext,
} from '@monash/portal-frontend-common';
import {
  getGlobalBannerCollegeData,
  getGlobalBannerData,
} from 'components/utilities/get-global-banner-data';
import { transformPageNameForURL } from 'components/utilities/pages';

export const Page = React.createContext();

const PageProvider = ({ children }) => {
  const { portalPreferences, currentDate } = useContext(Data);
  const { currentUser } = useContext(ImpersonationContext);
  const { UPCOMING, SCHEDULE, UPDATES } = pageIds;
  const { route, redirect } = useContext(RouterState);
  const { featureFlags } = useContext(FeatureContext);

  const pagesData = portalPreferences?.pages && {
    ...portalPreferences?.pages,
    pageOrder: portalPreferences?.pages?.pageOrder?.filter((pageId) => {
      if (!featureFlags?.UPDATES && pageId === UPDATES) {
        return false;
      }

      return true;
    }),
  };

  const hiddenPages = pagesData?.hiddenPages;

  // states
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [bgRefHandler, setBgRefHandler] = useState();
  const [backgroundStyle, setBackgroundStyle] = useState();
  const [isMobileDropDownExpanded, setIsMobileDropDownExpanded] =
    useState(false);

  const visiblePagesPageOrder =
    pagesData?.pageOrder &&
    [...pagesData?.pageOrder].filter((id) => !hiddenPages?.includes(id));

  const selectedPage = selectedPageId
    ? visiblePagesPageOrder?.findIndex((id) => id === selectedPageId)
    : null;

  // refs
  const pageRefs = useMemo(
    () =>
      visiblePagesPageOrder?.map(() => {
        return React.createRef();
      }),
    [visiblePagesPageOrder]
  );

  // to avoid detecting programmatic scroll, set this to true when using code to cause scroll event (e.g. when calling scrollTo)
  const [isProgrammaticScrolling, setIsProgrammaticScrolling] = useState(false);

  // map pages
  const mapPage = (id, i) => {
    switch (id) {
      case UPCOMING: {
        const name = DEFAULT_PAGE_NAMES[UPCOMING];

        return {
          id,
          name,
          path: transformPageNameForURL(name),
          custom: false,
          page: () => (
            <SentryErrorBoundary fallback={<GenericError />}>
              <Upcoming i={i} redirect={redirect} />
            </SentryErrorBoundary>
          ),
        };
      }
      case SCHEDULE: {
        const name = DEFAULT_PAGE_NAMES[SCHEDULE];

        return {
          id,
          name,
          path: transformPageNameForURL(name),
          custom: false,
          page: () => (
            <SentryErrorBoundary fallback={<GenericError />}>
              <Schedule i={i} />,
            </SentryErrorBoundary>
          ),
        };
      }
      case UPDATES: {
        const name = DEFAULT_PAGE_NAMES[UPDATES];

        return {
          id,
          name,
          path: transformPageNameForURL(name),
          custom: false,
          page: () => (
            <SentryErrorBoundary fallback={<GenericError />}>
              <Updates i={i} />
            </SentryErrorBoundary>
          ),
        };
      }
      default: {
        const customPage = portalPreferences?.pages.customPages[id];

        if (!customPage) {
          return;
        }

        return {
          id,
          name: customPage.name,
          path: `/page/${transformPageNameForURL(customPage.name)}`,
          custom: true,
          page: () => (
            <SentryErrorBoundary fallback={<GenericError />}>
              <Custom pageId={id} i={i} selected={i === selectedPage} />
            </SentryErrorBoundary>
          ),
        };
      }
    }
  };

  const mappedVisiblePages = visiblePagesPageOrder
    ?.map((id, i) => mapPage(id, i))
    .filter(Boolean);

  const mappedAllPages = pagesData?.pageOrder
    ?.map((id, i) => {
      return {
        ...mapPage(id, i),
        hidden: hiddenPages?.includes(id),
      };
    })
    .filter(Boolean);

  // hidden pages
  const allPagesHidden =
    pagesData &&
    pagesData?.hiddenPages?.length === pagesData?.pageOrder?.length;

  const onHiddenPage = selectedPageId && hiddenPages?.includes(selectedPageId);

  // global banners
  const globalBannersData = currentUser?.is?.MonashCollege
    ? getGlobalBannerCollegeData()
    : getGlobalBannerData();

  const lastDismissedTime =
    portalPreferences?.globalBanners?.home?.lastDismissed || 0;

  // active global banners
  const activeGlobalBanners = globalBannersData.filter(
    (banner) =>
      lastDismissedTime < banner.startDate &&
      currentDate > banner.startDate &&
      currentDate < banner.endDate
  );

  // global banner should be shown on the first visible page only
  const globalBannerShownPageId =
    activeGlobalBanners.length !== 0 ? visiblePagesPageOrder?.[0] : null;

  useEffect(() => {
    setBackgroundStyle(
      calculatePageBgStyles(mappedVisiblePages?.length, selectedPage)
    );
  }, [mappedVisiblePages?.length, selectedPage]);

  useEffect(() => {
    const pathDirectories = route.path?.split('/');

    if (pagesData) {
      const pageId = getPageIdFromRoute(mappedAllPages, route.path);

      // set selected page id from route if page exists
      if (pageId) {
        setSelectedPageId(pageId);

        // set selected page id to null when user is on a search result page
      } else if (pathDirectories?.[1].startsWith('search')) {
        setSelectedPageId(null);

        //  set selected page id to first visible page id if user is on landing page or on a non existing page
      } else {
        setSelectedPageId(visiblePagesPageOrder?.[0]);
      }
    }
  }, [pagesData, pagesData?.pageOrder, route.path]);

  return (
    <Page.Provider
      value={{
        pages: mappedVisiblePages,
        allPages: mappedAllPages,
        allPagesHidden,
        onHiddenPage,
        mapPage,
        pagesData,
        activeGlobalBanners,
        globalBannerShownPageId,
        selectedPage,
        selectedPageId,
        setSelectedPageId,
        visiblePagesPageOrder,
        bgRefHandler,
        setBgRefHandler,
        backgroundStyle,
        pageRefs,
        isProgrammaticScrolling,
        setIsProgrammaticScrolling,
        isExpanded: isMobileDropDownExpanded,
        setIsExpanded: setIsMobileDropDownExpanded,
      }}
    >
      {children}
    </Page.Provider>
  );
};

export default PageProvider;
