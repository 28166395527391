import Status from './Status';

export const getDisplayStatusComponent = ({ status, mode = 'canvas' }) => {
  const display = {
    NOT_OPEN_YET: 'Not open yet',
    NOT_SUBMITTED: <Status color="yellow" text="Not submitted" mode={mode} />,
    SUBMITTED: <Status color="green" text="Submitted" mode={mode} />,
  };
  return display[status] || display.NOT_OPEN_YET;
};

// Maps statuses from the API to one of the 3 statuses
// in `displayStatusComponents` above.
export const getDisplayStatus = ({
  status,
  currentDate,
  submissionStartDate,
}) => {
  switch (status) {
    case 'NOT_AVAILABLE':
    case 'SETUP': {
      return 'NOT_OPEN_YET';
    }
    case 'NOT_REVIEWED':
    case 'REOPENED':
    case 'INPROGRESS': {
      return 'NOT_SUBMITTED';
    }
    case 'SUBMITTED':
    case 'REVIEWED':
    case 'FINISHED': {
      return 'SUBMITTED';
    }
    case 'NEW':
    case 'NOT_SUBMITTED':
    case 'DRAFT': {
      if (currentDate < submissionStartDate) {
        return 'NOT_OPEN_YET';
      } else {
        return 'NOT_SUBMITTED';
      }
    }
  }
};
