import { EncumbranceNotification } from '@monash/portal-frontend-common';
import c from './notification.module.scss';

export const Notification = ({ type = 'info', children }) => {
  if (type === 'encumbrance')
    return (
      <div className={c.notification}>
        <EncumbranceNotification />
      </div>
    );

  return (
    <div className={`${c.notification} notification--${type}`}>{children}</div>
  );
};

export default Notification;
