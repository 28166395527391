import React, { useState, useEffect } from 'react';
import useOnScreen from 'hooks/use-onscreen';
import { useBorderBackground } from 'hooks/use-border-background';
import { useForceUpdate } from 'hooks/use-force-update';
import c from './schedule-item-card.module.scss';
import classNames from 'classnames';

const ScheduleItemCard = ({
  id,
  children,
  i,
  j,
  onClick,
  columnSpan = 1,
  style,
  containerStyle,
  addClass,
  itemRef,
  overlapping = false,
  unroundedLeft,
  unroundedRight,
  expanded,
  ...restProps
}) => {
  const onScreen = useOnScreen({ ref: itemRef, rootMargin: '0px 0px 0px 0px' });
  const [still, setStill] = useState(false);
  const backgroundStyles = useBorderBackground(overlapping, itemRef, 2);
  const forceUpdate = useForceUpdate();

  const cardClasses = classNames(
    c.Card,
    addClass,
    { [c.unroundedLeft]: unroundedLeft },
    { [c.unroundedRight]: unroundedRight }
  );

  useEffect(() => {
    forceUpdate();
  }, [j]);

  // card keyboard handler
  const onCardKeyDown = (e) => {
    if (e.key === 'Enter') {
      // replicate onClick handler
      setStill(true);
    }
  };

  return (
    <div
      className={c.classContainer}
      tabIndex="-1"
      style={{
        ...(expanded ? backgroundStyles : null),
        ...containerStyle,
      }}
      ref={itemRef}
      onTransitionEnd={() => {
        forceUpdate();
      }}
      {...restProps}
    >
      <div
        id={id}
        onKeyDown={onCardKeyDown}
        onClick={(e) => {
          setStill(true);
        }}
        className={cardClasses}
        data-active=""
        style={{
          transitionDelay: `${onScreen && !still ? i / 6 : '0'}s`,
          gridColumn: `span ${columnSpan}`,
          ...style,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ScheduleItemCard;
