import React from 'react';
import c from './checkboxes.module.scss';
import { Switch } from '@monash/portal-react';

// NOTE: PREFER OptionSwitches COMPONENT. THIS COMPONENT IS KEPT B/C IT IS STILL CURRENTLY IN USE
const Checkboxes = ({ optionKey, optionName, options, data, updateData }) => {
  const getNewData = (selected, index, id) => {
    const newOptionData = [...data];
    if (selected) {
      newOptionData.splice(index, 1);
    } else {
      newOptionData.push(id);
    }
    return newOptionData;
  };

  return options.map((item, i) => {
    const index = data?.findIndex((id) => id === item.id);
    const selected = index !== -1;
    const screenReaderLabel = `${optionName}: ${item.name}`;
    return (
      <div className={c.checkboxes} key={i}>
        <Switch
          ariaLabel={screenReaderLabel}
          on={selected}
          onClick={() =>
            updateData(optionKey, getNewData(selected, index, item.id))
          }
        />
        <span>{item.name}</span>
      </div>
    );
  });
};

export default Checkboxes;
