import { useRef } from 'react';
import { useSessionStorage } from '@monash/portal-frontend-common';
import storage from 'utils/storage';

// Returns an updated date, even when using a test date.
// Time can be sped up via the Time multiplier in the
// configuration panel
const useLiveDate = () => {
  const anchor = useRef(Date.now());
  const [timeMultiplier] = useSessionStorage('timeMultiplier', 1);

  return {
    get: () => {
      const testDateInMillis = parseInt(storage.session.get('testDate'));
      const difference = Date.now() - anchor.current;

      return !isNaN(testDateInMillis)
        ? testDateInMillis + difference * timeMultiplier
        : Date.now();
    },
  };
};

export default useLiveDate;
