import { WidgetContext } from 'components/providers/WidgetProvider';
import DraggableWindow from 'components/ui/draggable-window/DraggableWindow';
import React, { useContext, useId, useRef } from 'react';
import c from './edit-assessments-filter.module.scss';
import fs from 'styles/font-styles.module.scss';
import { ASSESSMENTS_OPTION_KEY } from '../Assessments';
import { isObject } from '@monash/portal-frontend-common';
import FilterOption from './filter-option/FilterOption';
import { useResponsiveValue } from '@monash/portal-react';
import { MOBILE_RESPONSIVE } from 'components/ui/main/Main';
import FilterOptionMobile from './filter-option-mobile/FilterOptionMobile';

const EditAssessmentsFilter = ({
  optionData,
  updateData,
  filterOptions,
  escapeFocusRef,
}) => {
  const {
    editAssessmentsFilterActiveWidget,
    editAssessmentsFilter,
    assessmentsFilterEscapeRef,
  } = useContext(WidgetContext);

  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';

  const TITLE = 'Assessments filters';
  const MENU_WIDTH = '280px';
  const escapeRef = useRef(
    assessmentsFilterEscapeRef?.current || escapeFocusRef?.current
  );
  const id = useId();

  const resetAllOptions = () => {
    const DEFAULT_VALUE = true;
    if (!isObject(optionData)) {
      console.warn('filter option data not found');
      return;
    }

    // iterate through filter option keys (unit, type, status)
    const defaultData = Object.keys(optionData).reduce((acc, optionKey) => {
      const savedData = optionData[optionKey];
      if (!isObject(savedData)) {
        console.warn(`Unable to reset option: ${optionKey}`);
        return acc;
      }
      // iterate through saved option data from fs and set all values to true
      const nestedFilterData = Object.keys(savedData).reduce((acc, curr) => {
        acc[curr] = DEFAULT_VALUE;
        return acc;
      }, {});
      return { ...acc, [optionKey]: nestedFilterData };
    }, {});
    updateData(ASSESSMENTS_OPTION_KEY.FILTER, defaultData);
  };

  // update fs data for individual filter option
  const updateFilterData = (key, value) => {
    updateData(ASSESSMENTS_OPTION_KEY.FILTER, { ...optionData, [key]: value });
  };

  // deactivate widget from editing filters in provider
  const deactivateWidget = () => editAssessmentsFilter(null);

  // draggable window initial position
  const getTriggerRefPosition = () => {
    if (!escapeRef?.current) return;
    const position = escapeRef.current.getBoundingClientRect();
    return { x: position.right, y: position.bottom };
  };

  const handleClose = () => {
    deactivateWidget();
    escapeRef.current?.focus();
    assessmentsFilterEscapeRef.current = null;
  };

  // escape and focus on trigger ref
  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.code === 'Escape') {
      handleClose();
    }
  };

  return (
    <DraggableWindow
      title={TITLE}
      onClose={handleClose}
      isShowing={editAssessmentsFilterActiveWidget}
      extraStyles={{ width: MENU_WIDTH }}
      initialPosition={getTriggerRefPosition()}
      focusTrapEnabled={true}
      fullScreen={isMobile}
      keyboardDismissalEnabled={true}
      extraRootProps={{
        role: 'dialog',
        'aria-label': TITLE,
      }}
    >
      <div className={c.container} onKeyDown={handleKeyDown}>
        <div className={c.filterOptions}>
          {filterOptions.map((optModule) => {
            const optModuleName = optModule.name;
            const labelId = `${id}-${optModuleName}`;
            return (
              <div className={c.optionSection} key={optModule.key}>
                <div id={labelId} className={`${fs.label} ${c.label}`}>
                  {optModuleName}
                </div>
                {isMobile ? (
                  <FilterOptionMobile
                    data={optionData?.[optModule.key]}
                    updateData={updateFilterData}
                    optionsModule={optModule}
                  />
                ) : (
                  <FilterOption
                    data={optionData?.[optModule.key]}
                    updateData={updateFilterData}
                    optionsModule={optModule}
                    labelId={labelId}
                  />
                )}
              </div>
            );
          })}
        </div>
        <button
          type="button"
          className={c.selectAllButton}
          onClick={resetAllOptions}
        >
          Reset filters
        </button>
      </div>
    </DraggableWindow>
  );
};
export default EditAssessmentsFilter;
