import { IconButton } from '@monash/portal-react';
import React, { useEffect, useState } from 'react';
import { useMutationObserver } from 'ahooks';

const ScrollToElement = ({
  //  props: icon and elementIdSuffix
  props,
  name,
  iconColour,
  error,
  widgetId,
  widgetScrollContainerRef,
}) => {
  // get element by widget id prefix and suffix (for specific action)
  const elementIdPrefix = widgetId;
  const elementId = `${elementIdPrefix}-${props.elementIdSuffix}`;
  const [element, setElement] = useState();

  // init scroll target element after first DOM render
  useEffect(() => {
    setElement(document.getElementById(elementId));
  }, []);

  // in-widget elements can change/be removed from DOM during runtime,
  // so listen to the widget scroll container for changes to the target element
  useMutationObserver(
    () => {
      setElement(document.getElementById(elementId));
    },
    widgetScrollContainerRef,
    { childList: true, subtree: true }
  );

  const scrollToElement = () => {
    if (!element) return;
    const currentScroll = widgetScrollContainerRef?.current?.scrollTop;
    const anchor =
      element.getBoundingClientRect().top -
      (widgetScrollContainerRef?.current?.getBoundingClientRect().top -
        currentScroll);
    widgetScrollContainerRef?.current?.scrollTo({ top: anchor });
    element.focus();
  };

  const disabled = !element || error;

  return (
    <IconButton
      onClick={() => scrollToElement()}
      aria-label={name}
      icon={props.icon}
      color={disabled ? 'var(--card-cta-disabled-text-color)' : iconColour}
      disabled={disabled}
    />
  );
};

export default ScrollToElement;
