import { format } from 'date-fns';
import { getTimeOrDueDate } from 'components/utilities/user-events/get-time-or-due-date';
import cs from './upcoming-listing-shared.module.scss';
import fs from 'styles/font-styles.module.scss';
const DueDateOrTime = ({
  isAssessment,
  largeTimeDisplay,
  event,
  stylesheet,
}) => {
  const originalDueDate = event.data?.originalDueDate;

  // For assessments that had an extension. The due date is substituted for the new
  // due date, whilst the original due date is saved into originalDueDate.
  if (originalDueDate) {
    return (
      <>
        <div>
          <div
            className={stylesheet.label}
            data-testid="due-date-or-time-label"
          >
            Due date
          </div>

          <div className={cs.oldTime} data-testid="due-date-or-time-value">
            {format(originalDueDate, 'h.mmaaa, EEEE d MMMM yyyy').replace(
              '.00',
              ''
            )}
          </div>
        </div>

        <div>
          <div
            className={stylesheet.label}
            data-testid="due-date-or-time-label"
          >
            New due date
          </div>
          <div
            className={largeTimeDisplay ? `${fs.body} ${fs.md} ${fs.bold}` : ''}
            data-testid="due-date-or-time-value"
          >
            {getTimeOrDueDate(event)}
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <div className={stylesheet.label} data-testid="due-date-or-time-label">
        {isAssessment ? 'Due date' : 'Time'}
      </div>
      <div
        className={largeTimeDisplay ? `${fs.body} ${fs.md} ${fs.bold}` : ''}
        data-testid="due-date-or-time-value"
      >
        {getTimeOrDueDate(event)}
      </div>
    </div>
  );
};

export default DueDateOrTime;
