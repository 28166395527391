import {
  MISSING_ALL_ASSESSMENTS_ERROR,
  MISSING_CLASSES_AND_EXAMS_ERROR,
  MISSING_CLASSES_ERROR,
  MISSING_CLASSES_EXAMS_ASSESSMENTS_ERROR,
  MISSING_EXAMS_ERROR,
  MISSING_SOME_ASSESSMENTS_ERROR,
} from 'constants/error-messages';

/**
 * Returns simplified error object for userEvents backend API response
 * @param {*} userEvents response from backend API getUserEvents
 * @returns {Object} of the form:
 * {
 *    missingClasses: true/false,
 *    missingExams: true/false,
 *    missingSomeAssessments: true/false,
 *    missingAllAssessments: true/false,
 *  }
 */
export const getUserEventsError = (userEvents) => {
  // classes
  const hasClassError = userEvents?.errorFlags?.classes !== 0 || false;

  // exams
  const hasExamError = userEvents?.errorFlags?.exams !== 0 || false;

  // assessments
  // If we get any 429/500 errors for assessments...

  // 1. If we're missing at least one assessment due to 429/500 error(s),
  // but we still have some valid assessments to show,
  // then, we're missing SOME assessments

  // 2. If we're missing at least one assessment due to 429/500 error(s),
  // and we have no valid assessments to show,
  // then, we're missing ALL assessments

  const hasMissingAssessments =
    userEvents?.errorFlags?.assessmentsDetailed?.some(
      (assessment) => [429, 500].includes(assessment.errorFlag) // 429/500 = potentially reachable later, 400/404 = doesn't exist in Moodle, ignore
    ) || false;

  const hasSomeAssessments =
    userEvents?.events?.some((event) => event.eventKind === 'assessment') ||
    false;

  const errors = {
    missingClasses: hasClassError,
    missingExams: hasExamError,
    missingSomeAssessments: hasMissingAssessments && hasSomeAssessments,
    missingAllAssessments: hasMissingAssessments && !hasSomeAssessments,
  };

  return errors;
};

/**
 * Returns JSX error message according to userEventsError
 * @param {Object} userEventsError object of the form:
 * {
 *    missingClasses: true/false,
 *    missingExams: true/false,
 *    missingSomeAssessments: true/false,
 *    missingAllAssessments: true/false,
 *  }
 * @returns JSX error message
 */
export const getUserEventsErrorMessage = (userEventsError) => {
  if (!(userEventsError instanceof Object)) {
    return;
  }

  const missingClasses = userEventsError.missingClasses;
  const missingExams = userEventsError.missingExams;
  const missingSomeAssessments = userEventsError.missingSomeAssessments;
  const missingAllAssessments = userEventsError.missingAllAssessments;

  // 1. only missing classes
  if (
    missingClasses &&
    !missingExams &&
    !missingSomeAssessments &&
    !missingAllAssessments
  ) {
    return MISSING_CLASSES_ERROR;
  }

  // 2. only missing exams
  if (
    !missingClasses &&
    missingExams &&
    !missingSomeAssessments &&
    !missingAllAssessments
  ) {
    return MISSING_EXAMS_ERROR;
  }

  // 3. only missing classes and exams
  if (
    missingClasses &&
    missingExams &&
    !missingSomeAssessments &&
    !missingAllAssessments
  ) {
    return MISSING_CLASSES_AND_EXAMS_ERROR;
  }

  // 4. missing either (classes and/or exams}, and missing assessments
  if (
    (missingClasses || missingExams) &&
    (missingSomeAssessments || missingAllAssessments)
  ) {
    return MISSING_CLASSES_EXAMS_ASSESSMENTS_ERROR;
  }

  // 5. missing only some assessments
  if (
    !missingClasses &&
    !missingExams &&
    missingSomeAssessments &&
    !missingAllAssessments
  ) {
    return MISSING_SOME_ASSESSMENTS_ERROR;
  }

  // 6. missing only all assessments
  if (
    !missingClasses &&
    !missingExams &&
    !missingSomeAssessments &&
    missingAllAssessments
  ) {
    return MISSING_ALL_ASSESSMENTS_ERROR;
  }

  return false;
};
