import React, { useContext, useRef } from 'react';
import {
  AccessibilityContext,
  ImpersonationContext,
  deepClone,
  fsDocRef,
  fsWriteBatch,
  isStringEmpty,
  useOnOutsideClick,
} from '@monash/portal-frontend-common';
import DraggableWindow from 'components/ui/draggable-window/DraggableWindow';
import AdditionalOptions from './additional-options/AdditionalOptions';
import s from './edit-menu-shared.module.scss';
import { Icon, useResponsiveValue } from '@monash/portal-react';
import { Data } from 'components/providers/data-provider/DataProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { nanoid } from 'nanoid';
import { Page } from 'components/providers/page-provider/PageProvider';
import EditMenuMobileModal from './EditMenuMobileModal';
import { ID_SR_PAGE_HEADING, MOBILE_RESPONSIVE } from 'components/ui/main/Main';

const MENU_WIDTH = 280;

const EditMenu = ({
  isShown,
  widgetName,
  setIsShown,
  widgetHeaderRef,
  escapeFocusRef,
  additionalOptions,
  additionalActions,
  data,
  updateData,
  widget,
  setWidgetOrder,
  widgetIndex,
  pageId,
  setIsDeleteWidgetModalShown,
  renderWidgetCard,
  confirmDeleteWidgetOnMobile,
  hasAdditionalOptionsInEditMenu,
}) => {
  const containerRef = useRef();
  const modalRef = useRef();
  const { currentUser } = useContext(ImpersonationContext);

  const { setPortalPreferences } = useContext(Data);
  const { pagesData } = useContext(Page);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { addSnackbar } = useSnackbar();
  const responsiveSize = useResponsiveValue(MOBILE_RESPONSIVE);
  const isMobile = responsiveSize === 'S';
  const isShownForMobile = isShown && isMobile;
  const isShownForDesktop = isShown && !isMobile;

  const handleUpdateSuccess = (newPages) => {
    setWidgetOrder(newPages.customPages[pageId].widgetOrder);
    setPortalPreferences((f) => {
      resetAppLiveMsgs();
      addSnackbar({
        message: `${widgetName} widget has been duplicated.`,
        type: 'success',
      });
      return { ...f, pages: newPages };
    });
  };

  const handleUpdateError = (error, consoleErrorMsg, snackbarErrorMsg) => {
    resetAppLiveMsgs();
    addSnackbar({
      message: snackbarErrorMsg,
      type: 'error',
    });
    console.warn(consoleErrorMsg, error);
  };

  // duplicate
  const duplicateWidget = () => {
    const batch = fsWriteBatch();
    const newWidgetId = nanoid();
    const newWidget = {
      [newWidgetId]: {
        size: widget.size,
        typeId: widget.typeId,
      },
    };

    // duplicate data
    if (data) {
      const widgetDoc = fsDocRef(
        `users/${currentUser.uid}/widgets/${newWidgetId}`
      );
      batch.set(widgetDoc, data);
    }

    // update pages
    const newPages = deepClone(pagesData);
    newPages.widgets = { ...newPages.widgets, ...newWidget };
    newPages.customPages[pageId].widgetOrder.splice(
      widgetIndex + 1,
      0,
      newWidgetId
    );

    const preferencesDoc = fsDocRef(`users/${currentUser.uid}`);
    batch.update(preferencesDoc, {
      'preferences.pages': newPages,
    });

    batch
      .commit()
      .then(() => handleUpdateSuccess(newPages))
      .catch((err) =>
        handleUpdateError(
          err,
          '[updatePortalPreferences]: api call error, failed to duplicate widget',
          "We're not able to duplicate the widget right now – please try again later"
        )
      )
      .finally(() => {
        document.querySelector(`#${ID_SR_PAGE_HEADING}`)?.focus();
      });
  };

  useOnOutsideClick({
    refs: [modalRef, containerRef],
    fn: () => {
      setIsShown(false);
    },
  });

  const handleKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'Escape') {
      setIsShown(false);
      escapeFocusRef.current?.focus();
    }
  };

  const getTriggerRefPosition = () => {
    const position = widgetHeaderRef.current?.getBoundingClientRect();
    const xOffset =
      position?.x + MENU_WIDTH > window.innerWidth ? MENU_WIDTH : 0;
    return { x: position?.right - xOffset, y: position?.bottom };
  };

  const headingText = (
    <>
      <span className={s.headingText}>{widgetName}</span>
      <span>&nbsp;options</span>
    </>
  );

  // delete widget
  const deleteWidget = isMobile
    ? confirmDeleteWidgetOnMobile
    : () => {
        setIsDeleteWidgetModalShown(true);
      };

  const defaultActions = [
    {
      icon: <Icon.Copy size={20} />,
      text: 'Duplicate widget',
      onClick: duplicateWidget,
      datTrackingEvent: 'custom-widget-duplicate',
    },
    {
      icon: <Icon.Trash size={20} />,
      haspopup: 'dialog',
      text: 'Delete widget',
      attention: true,
      onClick: deleteWidget,
      notDismissMenuOnClick: isMobile,
      datTrackingEvent: 'custom-widget-delete',
    },
  ];

  const combinedActions = additionalActions
    ? [...additionalActions, ...defaultActions]
    : defaultActions;

  const renderAdditionalOptions = () =>
    hasAdditionalOptionsInEditMenu ? (
      <AdditionalOptions
        data={data}
        updateData={updateData}
        additionalOptions={additionalOptions}
      />
    ) : null;

  const renderCombinedOptions = () =>
    combinedActions
      ? combinedActions.map((item) => {
          const itemHasPopup = !isStringEmpty(item.haspopup);
          return (
            <button
              key={item.text}
              type="button"
              className={s.menuListItem}
              onClick={(e) => {
                item.onClick(e);
                !item.notDismissMenuOnClick && setIsShown(false);
              }}
              style={{
                color: item.attention
                  ? 'var(--color-intent-attention)'
                  : 'var(--card-text-color)',
              }}
              role="menuitem"
              aria-haspopup={itemHasPopup ? item.haspopup : null}
            >
              {item.icon}
              {item.text}
            </button>
          );
        })
      : null;

  return (
    <div className={s.editMenuContainer}>
      <EditMenuMobileModal
        ref={modalRef}
        isShown={isShownForMobile}
        renderWidgetCard={renderWidgetCard}
        setIsShown={setIsShown}
        widgetName={widgetName}
        escapeFocusRef={escapeFocusRef}
        renderAdditionalOptions={renderAdditionalOptions}
        renderCombinedOptions={renderCombinedOptions}
      />
      {isShownForDesktop && (
        <DraggableWindow
          ref={containerRef}
          title={headingText}
          isShowing={isShown}
          focusTrapEnabled={true}
          keyboardDismissalEnabled={true}
          onClose={() => {
            setIsShown(false);
            escapeFocusRef.current?.focus();
          }}
          initialPosition={getTriggerRefPosition()}
          extraRootProps={{
            role: 'dialog',
            'aria-label': `${widgetName} options`,
          }}
          extraStyles={{ maxWidth: MENU_WIDTH }}
        >
          <div className={s.editMenu} onKeyDown={handleKeyDown}>
            <div className={s.editItems}>
              {renderAdditionalOptions()}
              {renderCombinedOptions()}
            </div>
          </div>
        </DraggableWindow>
      )}
    </div>
  );
};

export default EditMenu;
