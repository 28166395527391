import React from 'react';
import c from './placeholder.module.scss';
import nothingToSee from './nothing-to-see.png';
import hiddenPage from './hidden-page.png';
import PageSettingsMenu from 'components/ui/nav/page-nav/page-settings-menu/PageSettingsMenu';

const Placeholder = ({ onHiddenPage }) => {
  return (
    <div className={c.placeholder}>
      <img
        aria-hidden="true"
        alt=""
        src={onHiddenPage ? hiddenPage : nothingToSee}
      />
      <div className={c.content}>
        <h1>{onHiddenPage ? 'This page is hidden' : 'Nothing to see here'}</h1>
        <div className={c.body}>
          <p>You can add and un-hide pages using page management</p>
          <PageSettingsMenu onPagePlaceholder={true} />
        </div>
      </div>
    </div>
  );
};

export default Placeholder;
