import c from './library.module.scss';

const ThumbnailPlaceholder = () => {
  return (
    <svg
      width="100"
      height="140"
      viewBox="0 0 100 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="100"
        height="140"
        rx="8"
        fill="var(--card-cta-active-bg-color-alt)"
      />
      <path
        d="M16 -2C16 -2.55228 16.4477 -3 17 -3H35C35.5523 -3 36 -2.55228 36 -2V25.0914C36 25.8981 35.094 26.3728 34.4308 25.9136L26.5692 20.471C26.2268 20.2339 25.7732 20.2339 25.4308 20.471L17.5692 25.9136C16.906 26.3728 16 25.8981 16 25.0914V-2Z"
        fill="var(--card-bg-color)"
      />
    </svg>
  );
};

const LibraryItemThumbnail = ({ thumbnail, title }) => {
  return (
    <div className={c.itemThumbnailContainer}>
      {thumbnail ? (
        <img src={thumbnail} alt={title} />
      ) : (
        <ThumbnailPlaceholder />
      )}
    </div>
  );
};

export default LibraryItemThumbnail;
