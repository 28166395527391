import React, { useState, useEffect } from 'react';
import { Button, Icon } from '@monash/portal-react';
import c from '../calendar.module.scss';
import useOnScreen from 'hooks/use-onscreen';

export const HiddenEventsIndicator = ({
  lastItemRef,
  weekData,
  responsiveSize,
}) => {
  const weekHasItems =
    Object.entries(weekData)
      .map((item) => item[1].items)
      .flat().length > 0;

  const onScreen = useOnScreen({
    ref: lastItemRef,
    threshold: 0.3, // if 30% of an item is visible, it counts as being on screen
    deps: [weekData],
    noInternalDependency: false,
  });

  const scrollToHiddenItem = () => {
    if (lastItemRef) {
      lastItemRef.current?.scrollIntoView({
        block: 'end',
        behavior: 'smooth',
      });
    }
  };

  const [isItemAbove, setIsItemAbove] = useState(false);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // If the item is off-screen, check if it is above or below the viewport
    if (weekHasItems) {
      if (!onScreen) {
        const itemTop = lastItemRef?.current?.getBoundingClientRect()?.y;
        const windowHeight = window.innerHeight * 0.5;
        setIsItemAbove(itemTop < windowHeight);
      }

      setShow(!onScreen);
    } else {
      setShow(false);
    }
  }, [isItemAbove, onScreen, weekHasItems]);

  // If the indicator is not being shown, disable it
  // Disabled should always be the opposite boolean of show
  useEffect(() => {
    // Set a slight delay to allow scrollIntoView to complete
    // Setting the disabled state too early aborts the scroll halfway
    const timer = setTimeout(() => {
      setDisabled(!show);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  // Mobile responsive design
  const desktopIcon = isItemAbove ? <Icon.ArrowUp /> : <Icon.ArrowDown />;
  const mobileIcon = isItemAbove ? <Icon.ChevronUp /> : <Icon.ChevronDown />;
  const icon = responsiveSize === 'S' ? mobileIcon : desktopIcon;

  return (
    <div
      className={c.hiddenEventsIndicator}
      data-show={show ? 'true' : 'false'}
      data-mobile={responsiveSize === 'S' ? 'true' : 'false'}
    >
      <Button
        className={c.hiddenEventsButton}
        variant="text"
        size="small"
        icon={icon}
        iconPosition="right"
        aria-label="Scroll to off-screen event"
        disabled={disabled}
        onClick={scrollToHiddenItem}
      >
        {responsiveSize !== 'S' && <p aria-hidden="true">More events</p>}
      </Button>
    </div>
  );
};
