import { Icon } from '@monash/portal-react';
import c from './submenu-trigger.module.scss';
import classNames from 'classnames';
import { forwardRef, useId } from 'react';

const SubmenuTrigger = forwardRef(
  ({ text, className, labelId, ...buttonProps }, ref) => {
    const textId = useId();
    return (
      <button
        ref={ref}
        className={classNames(c.trigger, className)}
        type="button"
        aria-labelledby={`${labelId} ${textId}`}
        {...buttonProps}
      >
        <span id={textId} className={c.text}>
          {text}
        </span>
        <Icon.ChevronRight />
      </button>
    );
  }
);

export default SubmenuTrigger;
