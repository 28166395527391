import React, { forwardRef } from 'react';
import {
  Button,
  Modal,
  Alert,
  ModalAction,
  ModalSection,
  useMedia,
} from '@monash/portal-react';
import c from './delete-widget-modal.module.scss';

const NODE_ID_MODAL_TITLE = 'deleteWidgetModalTitle';
const NODE_ID_MODAL_CONTENT = 'deleteWidgetModalContent';

const DeleteWidgetModal = forwardRef(
  (
    {
      open,
      widgetName,
      deleteWidget,
      isDeletingWidget,
      deleteWidgetError,
      closeDeleteWidgetModal,
    },
    ref
  ) => {
    const size = useMedia();
    const title = `Delete ${widgetName} widget`;

    return (
      <Modal
        open={open}
        onClose={closeDeleteWidgetModal}
        size={size}
        ref={ref}
        dismissOnHistoryNav={true}
        ariaLabel={title}
        ariaDescribedby={null}
        stopPropagationOnDefaultKeyDown={true}
      >
        <ModalSection
          title={title}
          titleTabIndex={null}
          ariaLabelledby={NODE_ID_MODAL_TITLE}
          ariaDescribedby={NODE_ID_MODAL_CONTENT}
        >
          <div className={c.deleteWidget}>
            <p>Your changes will be lost.</p>
          </div>
        </ModalSection>
        <ModalAction position="center">
          <div className={c.modalAction}>
            {deleteWidgetError ? (
              <Alert type="error">
                Sorry, we're having{' '}
                <strong>trouble deleting your widget right now</strong> – come
                back and try again later.
              </Alert>
            ) : null}
            <div className={c.modalButtons}>
              <Button
                variant="text"
                size="medium"
                onClick={closeDeleteWidgetModal}
              >
                Cancel
              </Button>
              <Button
                variant="delete"
                size="medium"
                onClick={deleteWidget}
                loading={isDeletingWidget}
                loadingMessage="Deleting widget"
                disabled={isDeletingWidget}
                aria-describedby={NODE_ID_MODAL_CONTENT}
              >
                Delete
              </Button>
            </div>
          </div>
        </ModalAction>
      </Modal>
    );
  }
);

export default DeleteWidgetModal;
