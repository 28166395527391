import { eventTypes } from '../../../constants';

const getWorkshopText = (eventType) => {
  if (eventType === eventTypes.WORKSHOP_SUBMISSION) {
    return 'Submission phase';
  }
  if (eventType === eventTypes.WORKSHOP_ASSESSMENT) {
    return 'Assessment phase';
  }
};

export default getWorkshopText;
