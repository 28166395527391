import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Icon, themes, IconButton, Tag } from '@monash/portal-react';
import {
  AccessibilityContext,
  isFunction,
  RenderAnimation,
} from '@monash/portal-frontend-common';
import { pageIds } from '../../../constants';
import useOnScreen from 'hooks/use-onscreen';
import c from './banner-carousel.module.scss';

const BannerCarousel = ({
  data,
  onDismiss,
  pageId,
  dismissDataTrackingEvent,
}) => {
  const { setAppLiveAssertiveMsg } = useContext(AccessibilityContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const activeSlide = data[activeIndex];
  const bannerRef = useRef();
  const bannerOnScreen = useOnScreen({ ref: bannerRef });
  const { UPCOMING } = pageIds;
  const isOnUpcomingPage = UPCOMING === pageId;

  // conditions
  const slideCount = data.length;
  const onFirstSlide = activeIndex === 0;
  const onFinalSlide = activeIndex === slideCount - 1;
  const hasMultipleSlides = slideCount > 1;

  // nav
  const previousSlide = () => setActiveIndex((f) => (f -= 1));
  const nextSlide = () => setActiveIndex((f) => (f += 1));
  const dismissBannerCarousel = () => {
    if (hasMultipleSlides) setAppLiveAssertiveMsg('');
    if (isFunction(onDismiss)) onDismiss();
  };

  const DISMISS_BANNER_TEXT = 'Got it';
  const NEXT_BANNER_TEXT = 'Next';
  const PREVIOUS_BANNER_TEXT = 'Previous';

  // play animation when banner is on screen
  useEffect(() => {
    if (bannerOnScreen) {
      setShowAnimation(true);
    }
  }, [bannerOnScreen]);

  // live announce upon active slide updates
  useEffect(() => {
    if (hasMultipleSlides) {
      setAppLiveAssertiveMsg(`Slide ${activeIndex + 1}: ${activeSlide.title}`);
    }
  }, [activeIndex, hasMultipleSlides]);

  return (
    <div
      role="region"
      aria-label={`${hasMultipleSlides ? 'Carousel' : 'Banner'}: ${
        activeSlide.title
      }`}
      className={`${c.bannerContainer} ${isOnUpcomingPage ? c.upcoming : ''}`}
      style={themes[activeSlide.theme].variables}
    >
      <div
        className={c.banner}
        ref={bannerRef}
        style={{
          backgroundColor:
            activeSlide.backgroundColor && activeSlide.backgroundColor,
        }}
      >
        <div
          key={activeSlide.title}
          className={c.background}
          style={{
            backgroundImage:
              activeSlide.backgroundImage &&
              `url(${activeSlide.backgroundImage})`,
          }}
        />
        <IconButton
          className={c.dismiss}
          icon={Icon.X}
          size={24}
          onClick={dismissBannerCarousel}
          mode="card"
          data-tracking-event={dismissDataTrackingEvent}
          aria-label="Close"
        />
        <div className={c.main}>
          {activeSlide.animation && (
            <div className={c.animationContainer}>
              {showAnimation ? (
                <RenderAnimation
                  className={c.animation}
                  lottieFile={activeSlide.animation}
                />
              ) : null}
            </div>
          )}
          {activeSlide.illustration && (
            <div
              className={c.illustrationContainer}
              style={{
                backgroundImage: `url(${activeSlide.illustration})`,
              }}
            />
          )}
          <div className={c.content}>
            {/* Note: as per AX requirements, slide controls are positioned above content */}
            <div className={c.nav}>
              {/* view previous banner */}
              {hasMultipleSlides && (
                <IconButton
                  className={c.iconButton}
                  icon={Icon.ChevronLeft}
                  disabled={onFirstSlide}
                  variant="secondary"
                  onClick={previousSlide}
                  aria-label={PREVIOUS_BANNER_TEXT}
                  data-tracking-event={`banner-${PREVIOUS_BANNER_TEXT.toLowerCase()}`}
                />
              )}

              {/* view next banner */}
              {hasMultipleSlides && !onFinalSlide && (
                <IconButton
                  className={c.iconButton}
                  icon={Icon.ChevronRight}
                  variant="primary"
                  onClick={nextSlide}
                  aria-label={NEXT_BANNER_TEXT}
                  data-tracking-event={`banner-${NEXT_BANNER_TEXT.toLowerCase()}`}
                />
              )}

              {/* dismiss banner */}
              {hasMultipleSlides && onFinalSlide && (
                <Button
                  variant="primary"
                  size="medium"
                  onClick={dismissBannerCarousel}
                  aria-label={DISMISS_BANNER_TEXT}
                  data-tracking-event={`banner-${DISMISS_BANNER_TEXT.toLowerCase()}`}
                >
                  {DISMISS_BANNER_TEXT}
                </Button>
              )}
            </div>
            <div className={c.text}>
              {activeSlide.isNew && <Tag color="blue" text="New!" />}
              <span className={c.title}>{activeSlide.title}</span>
              <p>{activeSlide.description}</p>
              {hasMultipleSlides ? (
                <div className={c.dots}>
                  {data.map((slide, i) => (
                    <div
                      key={i}
                      className={`${c.dot} ${
                        i === activeIndex ? c.filled : null
                      }`}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerCarousel;
