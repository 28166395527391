import React from 'react';
import c from './empty-page.module.scss';
import { Icon } from '@monash/portal-react';

const EmptyPage = () => {
  return (
    <div className={c.emptyPage}>
      <div className={c.content}>
        <p>The page is empty</p>
        <p className={c.line}>
          Click
          <span className={c.editButton}>
            <Icon.Add /> <span>Add widget</span>
          </span>
          to start customising!
        </p>
      </div>
    </div>
  );
};

export default EmptyPage;
