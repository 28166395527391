import {
  getPageIndexFromRoute,
  getRouteFromPageIndex,
} from 'components/utilities/pages';
import { getRouteFromPage } from 'components/utilities/pages/get-route-from-page';

export const getRouteOnPageChange = (
  currentRoute,
  newPages,
  newHiddenPages,
  selectedPage
) => {
  const newPageIndex =
    currentRoute === '/' ? 0 : getPageIndexFromRoute(newPages, currentRoute);
  const newPageRoute = getRouteFromPageIndex(newPages, newPageIndex);

  // no visible page is selected (eg. all pages are hidden, current page is hidden, on search page)
  if (selectedPage === null) {
    const firstVisiblePage = [...newPages].find(
      (page) => !newHiddenPages.includes(page.id)
    );
    return getRouteFromPage(firstVisiblePage);
  }

  if (!newHiddenPages.includes(newPages[newPageIndex].id)) {
    // if page will not be hidden
    return newPageRoute;

    // if page will be hidden
  } else {
    const previousVisiblePage = [...newPages]
      .slice(0, newPageIndex)
      .reverse()
      .find((page) => !newHiddenPages.includes(page.id));

    const nextVisiblePage = [...newPages]
      .slice(newPageIndex + 1, newPages.length)
      .find((page) => !newHiddenPages.includes(page.id));

    // return route to the first visible previous page
    if (previousVisiblePage) {
      return getRouteFromPage(previousVisiblePage);
      // if no previous page is visible, return route to the next visible page
    } else if (nextVisiblePage) {
      return getRouteFromPage(nextVisiblePage);
      // return route to landing page (fall back)
    }
  }

  return '/';
};
