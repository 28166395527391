import React from 'react';

import c from '../error-pages-shared.module.scss';
import { ExternalLink } from '@monash/portal-react';

export const NoUpcomingEvents = () => {
  return (
    <>
      <div className={c.heading}>You currently have no upcoming activities</div>
      <p>
        Some assessments may not appear here, so you should check Moodle as
        well.
      </p>

      <div className={c.actions}>
        <ExternalLink
          text="Open Moodle"
          link="https://learning.monash.edu"
          mode="canvas"
          variant="primary"
        />
      </div>
    </>
  );
};
