import React, { memo } from 'react';
import { FEATURES } from '../../../../../constants/features';
import { TIME_GRID_LABELS } from '../constant';
import c from '../calendar.module.scss';
import AssessmentsWarning from 'components/ui/AssessmentsWarning/AssessmentsWarning';

export const CalendarScheduleTimeGrid = memo(({ rowRef }) => {
  if (!FEATURES.DISPLAY_TIME_GRID) {
    return null;
  }

  return (
    <div className={c.timeGridWrapper} aria-hidden="true">
      {TIME_GRID_LABELS.map((label, i) => (
        <div key={i} className={c.row} ref={rowRef}>
          <div className={c.label}>{label}</div>
        </div>
      ))}

      <div className={c.warningWrapper}>
        <AssessmentsWarning />
      </div>
    </div>
  );
});
