import { isObject } from '@monash/portal-frontend-common';

/**
 * formatDefaultData
 * For any widgets that have configurable options, take the key-value pairs of the
 * default options, and format it into an object.
 *
 * @param {array} additionalOptions - array containing a widget's options
 * @returns {object}
 */
export const formatDefaultData = (additionalOptions) => {
  if (
    !additionalOptions ||
    !Array.isArray(additionalOptions) ||
    !additionalOptions.length
  )
    return;

  const keys = additionalOptions.map((item) => [item.key]);
  const defaultValue = additionalOptions.map((item) => item.default);

  return Object.fromEntries(
    keys.map((key, index) => [key, defaultValue[index]])
  );
};

/**
 * calculateYOffset
 * Calculate the vertical offset of the preview panel.
 * The panel will align itself to the top border of the parent item if it fits on the screen.
 * Otherwise, it will align itself to the bottom border of the parent item.
 * Unless the window height is smaller than the preview height, in which case
 * It will align to the top of the widget library.
 *
 * @param {object} elementRect - the bounding client rect of the preview panel
 * @param {object} parentRect - the bounding client rect of the widget library item
 * @param {object} triggerRect - the bounding client rect of the Add Widget button - used to get top offset
 * @returns {number}
 */
export const calculateYOffset = (elementRect, parentRect, triggerRect) => {
  const WIDGET_LIBRARY_TOP = 52; // top of widget library popup
  const previewPanelHeight = elementRect?.height;
  const topOffset = triggerRect?.y;
  const windowHeight = window.innerHeight - topOffset;
  const yOffset = parentRect?.y - topOffset;

  // return 0 if any inputs are invalid
  if (!previewPanelHeight || !topOffset || !windowHeight || !parentRect)
    return 0;

  // if the page is too small, align to top of widget library popup
  if (windowHeight <= previewPanelHeight) return WIDGET_LIBRARY_TOP;

  // otherwise, align to the top or bottom border of its parent item
  return yOffset + previewPanelHeight <= windowHeight
    ? yOffset
    : yOffset - (previewPanelHeight - parentRect?.height);
};

/**
 * getRGBValues
 * Takes an RGB or HEX code and ouputs an object
 * containing its red, green, and blue values.
 *
 * @param {string} colour - a RGB or HEX code
 * @returns {object}
 */
export const getRGBValues = (colour) => {
  // colour must be a string that is a colour code
  if (
    typeof colour !== 'string' ||
    (!colour.startsWith('#') && !colour.startsWith('rgb'))
  )
    return;

  const rgb = {
    r: 0,
    g: 0,
    b: 0,
  };

  // check colour format
  if (colour.startsWith('rgb')) {
    // rgb
    colour = colour.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    rgb.r = Number(colour[1]);
    rgb.g = Number(colour[2]);
    rgb.b = Number(colour[3]);
  } else {
    // hex
    colour = +(
      '0x' + colour.slice(1).replace(colour.length < 5 && /./g, '$&$&')
    );

    rgb.r = colour >> 16;
    rgb.g = (colour >> 8) & 255;
    rgb.b = colour & 255;
  }

  return rgb;
};

/**
 * lightOrDark
 * Check if the given colour is light or dark using the
 * HSP colour model
 *
 * @param {string} colour - a RGB or HEX code
 * @returns {string}
 */
export const lightOrDark = (colour) => {
  const rgbColour = getRGBValues(colour);
  if (!rgbColour) return;

  // HSP colour model from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (rgbColour.r * rgbColour.r) +
      0.587 * (rgbColour.g * rgbColour.g) +
      0.114 * (rgbColour.b * rgbColour.b)
  );

  // Determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

/**
 * Returns array, with each element as a key-value pair in the form of [widget module id, widget module],
 * alphabetically sorted by widget module name
 * @param {Object} widgetDirectory widget directory object
 * @returns  {Array}
 */
export const getWidgetList = (widgetDirectory) => {
  if (!isObject(widgetDirectory)) {
    console.warn('widget directory is not an object');
    return [];
  }

  return Object.entries(widgetDirectory).sort((a, b) => {
    const widgetNameA = a[1].name;
    const widgetNameB = b[1].name;
    if (widgetNameA < widgetNameB) {
      return -1;
    }
    if (widgetNameA > widgetNameB) {
      return 1;
    }
    return 0;
  });
};
