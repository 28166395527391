import { isDomNodeType } from '@monash/portal-frontend-common';
import { useCallback, useEffect, useRef, useState } from 'react';

export const CONTAINER_WIDTH = {
  SMALL: 'small',
  LARGE: 'large',
};

export const BOX_SIZE_MODE = {
  BORDER: 'borderBoxSize',
  CONTENT: 'contentBoxSize',
};

/**
 * Hook for tracking the width of a container in the DOM
 *
 * @param sizes array of size objects, with each object having a name and maxWidth in px (maxWidth is omitted for largest size)
 * e.g.
 *  [
 *    {name: "small", maxWidth: 600},
 *    {name: "medium", maxWidth: 1200},
 *    {name: "large"}
 *  ]
 * @param boxSizeMode the box size that is used for the resize observer (borderBoxSize or contentBoxSize)
 * @param containerRef optionally, supply a ref for the container that is being tracked, instead of using the callback ref that is supplied by this hook.
 * Note: if possible, avoid supplying your own containerRef, and use the containerCallbackRef to ensure that the size state is properly initialised
 * on mount, to avoid potential 'flickering'.
 *
 * @returns an object with the following attributes:
 * containerCallbackRef: a ref to place on the element that you want to track
 * size: a size state
 */
const useContainerWidth = ({
  sizes = [
    { name: CONTAINER_WIDTH.SMALL, maxWidth: 600 },
    { name: CONTAINER_WIDTH.LARGE },
  ],
  boxSizeMode = BOX_SIZE_MODE.BORDER,
  containerRef,
}) => {
  const [size, setSize] = useState(CONTAINER_WIDTH.SMALL);
  const ref = useRef();

  const getSize = (containerSize) => {
    if (!Array.isArray(sizes) || sizes.length === 0) {
      console.warn('useContainerWidth: sizes array is invalid');
      return;
    }

    const size = sizes.reduce((acc, currentSize) => {
      if (containerSize > acc.maxWidth) {
        return currentSize;
      } else {
        return acc;
      }
    }, sizes[0]);

    return size?.name;
  };

  // callback ref to init size on mount
  const containerCallbackRef = useCallback((el) => {
    const containerSize = el?.clientWidth;
    setSize(getSize(containerSize));
    if (!ref.current) {
      ref.current = el;
    }
  }, []);

  // get size on resize
  const resizeObserver = new ResizeObserver((entry) => {
    const containerSize = entry[0]?.[boxSizeMode]?.[0]?.inlineSize || 0;
    setSize(getSize(containerSize));
  });

  useEffect(() => {
    const containerElem = containerRef ? containerRef.current : ref.current;
    if (isDomNodeType(containerElem)) {
      resizeObserver.observe(containerElem);
    } else {
      console.warn('useContainerWidth: containerElem is not a DOM element');
    }

    return () => resizeObserver.disconnect();
  }, [ref, containerRef]);

  return { containerCallbackRef, size };
};

export default useContainerWidth;
