import { format } from 'date-fns';

export const getDisplayDate = (currentDate, eventDate) => {
  const todayStart = new Date(currentDate).setHours(0, 0, 0, 0);
  const diffHours = (eventDate - todayStart) / 3600000;
  const dayLabel =
    diffHours < 0
      ? ''
      : diffHours < 24
      ? 'Today, '
      : diffHours < 48
      ? 'Tomorrow, '
      : '';
  const formattedDate = format(eventDate, 'EEEE d MMMM');
  return `${dayLabel}${formattedDate}`;
};
