import React from 'react';
import {
  capitaliseFirstWord,
  isFunction,
  isNumber,
  isStringEmpty,
} from '@monash/portal-frontend-common';
import classNames from 'classnames';
import { format, intervalToDuration } from 'date-fns';
import { getItemSizeTier } from './schedule-item.utils';
import {
  getTitle,
  formatEventTitleAbbr,
} from 'components/pages/upcoming/utils/utils';
import Accent from 'components/ui/accent/Accent';
import ScheduleItemCard from '../schedule-item-card/ScheduleItemCard';
import c from './schedule-item.module.scss';
import { getClass } from 'components/utilities/user-events/get-class';

const ScheduleItem = ({
  dayIndex = 0,
  j = 0,
  item = {},
  itemRef,
  expanded = true,
  isCollapsedMultiDay = false,
  isCompressed = false,
  ...restProps
}) => {
  const { showTime, unit, location } = item?.display || {};

  // Prep view models: common
  const itemEventKind = item?.eventKind || '';
  const isAssessmentEvent = itemEventKind === 'assessment';
  const hasStartTime = isNumber(item?.start?.time);
  const hasEndTime = isNumber(item?.end?.time);
  const duration =
    hasStartTime && hasEndTime
      ? intervalToDuration({
          start: item.start.time,
          end: item.end.time,
        })
      : { noDuration: true, hours: 0, minutes: 0 };
  const itemSizeTier = getItemSizeTier({
    hours: duration.hours,
    minutes: duration.minutes,
    isAssessment: isAssessmentEvent,
  });

  // Prep view models: time
  const displayTime = isFunction(showTime)
    ? `${isAssessmentEvent ? 'Due at ' : ''}${showTime('time')}`
    : '';
  const hasDisplayTime = !isStringEmpty(displayTime);

  // Prep view models: heading
  const unitCode = !isStringEmpty(unit) ? unit : '';
  const unitTitle =
    capitaliseFirstWord(item.data?.unitTitle || item.data?.name) || '';
  const displayUnitHeading = getTitle(item);
  const displayUnitHeadingFormatted = formatEventTitleAbbr(displayUnitHeading);
  const screenReaderUnitHeading = getTitle(item, true);

  // Prep view models: location
  const shouldDisplayLocationInline = parseFloat(itemSizeTier) <= 0.5;
  const hasDisplayLocation = isAssessmentEvent
    ? false
    : !isStringEmpty(location);

  // Prep view models: screen reader
  const dateText = hasStartTime
    ? format(item.start.time, 'EEEE d MMMM yyyy')
    : '';
  const timeText = hasDisplayTime ? displayTime.replace('-', 'to') : '';
  let screenReaderTitle = '';
  screenReaderTitle += `${dateText}${!isStringEmpty(dateText) ? ', ' : ''}`;
  screenReaderTitle += `${timeText}${!isStringEmpty(timeText) ? ', ' : ''}`;
  screenReaderTitle += `${screenReaderUnitHeading}`;
  screenReaderTitle += unitTitle ? `: ${unitTitle}` : '';
  screenReaderTitle += '.';

  const displayEventHeader = !item.hideEventHeader || dayIndex === 0;

  const renderCompressedView = () => {
    const [unitCodeLetters, unitCodeNumbers] = unit.match(/[0-9]+|[a-zA-Z]+/g);

    return (
      <div className={c.unitHeadingWrapperCompressed}>
        <div className={c.unitHeadingAccent}>
          <Accent unitCode={unitCode} size="sml" orientation="horizontal" />
        </div>
        <h3 className={c.unitHeading} aria-hidden="true">
          <abbr
            title={`${displayUnitHeading}${unitTitle ? `: ${unitTitle}` : ''}`}
          >
            {`${unitCodeLetters}\n${unitCodeNumbers}`}
          </abbr>
        </h3>
        <div className={c.classType}>{getClass(item)}</div>
      </div>
    );
  };

  return (
    <ScheduleItemCard
      i={dayIndex + 1}
      j={j}
      addClass={c.ScheduleItemCardWrapper}
      itemRef={itemRef}
      expanded={expanded}
      overlapping={item.offset > 0 && expanded}
      data-tag="schedule-item-card"
      {...restProps}
    >
      <div
        className={classNames(c.ScheduleItem, {
          [c.collapsedMultiDay]: isCollapsedMultiDay,
          [c.compressed]: isCompressed && !isCollapsedMultiDay,
        })}
        data-event-kind={itemEventKind}
        data-size-tier={itemSizeTier}
      >
        {displayEventHeader && (
          <>
            {/* 0. Screen Reader Text */}
            {/* 1. Time */}

            {hasDisplayTime && !expanded && !isCollapsedMultiDay ? (
              <div
                className={c.displayTime}
                title={displayTime}
                aria-hidden="true"
              >
                {displayTime?.replace(/ - /, '–')}
              </div>
            ) : null}

            {isCompressed && !isCollapsedMultiDay ? (
              renderCompressedView()
            ) : (
              <>
                {/* 2. Unit heading */}
                <div className={c.unitHeadingWrapper}>
                  <div className={c.unitHeadingAccent}>
                    <Accent unitCode={unitCode} size="sml" />
                  </div>
                  <h3 className={c.unitHeading} aria-label={screenReaderTitle}>
                    {displayUnitHeadingFormatted}
                  </h3>
                  {hasDisplayLocation &&
                  shouldDisplayLocationInline &&
                  !isCollapsedMultiDay ? (
                    <div className={c.displayLocation} title={location}>
                      {location}
                    </div>
                  ) : null}

                  {/* 2.1 Time for collapsed multiday */}
                  {isCollapsedMultiDay ? (
                    <div
                      className={c.displayTime}
                      title={displayTime}
                      aria-hidden="true"
                    >
                      {showTime('collapsedMultiday')}
                    </div>
                  ) : null}
                </div>

                {/* 3. location */}
                {hasDisplayLocation &&
                !shouldDisplayLocationInline &&
                !isCollapsedMultiDay ? (
                  <div className={c.displayLocation} title={location}>
                    {location}
                  </div>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
    </ScheduleItemCard>
  );
};

export default ScheduleItem;
