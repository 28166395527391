import React from 'react';
import { ExternalLink } from '@monash/portal-react';
import c from '../error-pages-shared.module.scss';

const WES_URL = 'https://my.monash.edu.au/wes/';

export const InactiveMessage = () => {
  return (
    <>
      <div className={c.heading}>Nothing to see right now</div>
      <p>
        Check the{' '}
        <a
          className={c.linkText}
          href={WES_URL}
          target="_blank"
          rel="noreferrer"
        >
          Web Enrolment system (WES)
        </a>{' '}
        to make sure you've successfully enrolled in units (you should see a
        transaction number beginning with U) for the current teaching period.
      </p>

      <div className={c.actions}>
        <ExternalLink
          text="Go to WES"
          link={WES_URL}
          variant="primary"
          mode="canvas"
        />
      </div>
    </>
  );
};
