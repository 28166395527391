import React, {
  forwardRef,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Page } from 'components/providers/page-provider/PageProvider';
import {
  isDomNodeType,
  useOnOutsideClick,
} from '@monash/portal-frontend-common';
import { Button, Icon, RouterState } from '@monash/portal-react';
import PositionAwareMenuV2 from 'components/ui/positionAwareMenuV2/PositionAwareMenuV2';
import { useMenuFocus } from 'hooks/use-menu-focus';
import { getRouteFromPageIndex } from 'components/utilities/pages';
import c from './hidden-tabs-menu.module.scss';
import classNames from 'classnames';

const MENU_ITEM_SELECTOR = 'button[type="button"][role="menuitem"]';

const HiddenTabsMenu = forwardRef(({ hiddenTabs }, ref) => {
  const { redirect } = useContext(RouterState);
  const { pages, selectedPage } = useContext(Page);
  const menuContainerRef = useRef();
  const [itemNodes, setItemNodes] = useState([]);

  const { handleKeyDown, setIsShown, isShown } = useMenuFocus({
    triggerRef: ref,
    menuWrapperRef: menuContainerRef,
    itemNodes,
  });

  const closeMenu = () => {
    setIsShown(false);
  };

  useOnOutsideClick({
    refs: [ref, menuContainerRef],
    fn: closeMenu,
  });

  const isSelectedTabHidden = hiddenTabs
    .map((item) => item.index)
    .includes(selectedPage);

  useLayoutEffect(() => {
    if (isShown && isDomNodeType(menuContainerRef.current)) {
      const menuItemsNodes =
        menuContainerRef.current.querySelectorAll(MENU_ITEM_SELECTOR);
      setItemNodes([...Array.from(menuItemsNodes)]);
    }
  }, [isShown]);

  const menuPopupTitle = 'Remaining tabs';

  const numberButton = classNames(c.numberButton, {
    [c.underline]: isSelectedTabHidden,
  });

  return (
    <div className={c.hiddenTabsMenu} onKeyDown={handleKeyDown}>
      <Button
        onClick={() => setIsShown((shown) => !shown)}
        ref={ref}
        className={numberButton}
        variant="text"
        size="small"
        mode="canvas"
        aria-haspopup="menu"
        aria-label={menuPopupTitle}
      >
        + {hiddenTabs.length}
      </Button>

      <PositionAwareMenuV2
        shown={isShown}
        offsetX={24}
        offsetY={24}
        dismissOnHistoryNav={true}
        onDismiss={closeMenu}
      >
        <ul
          ref={menuContainerRef}
          className={c.hiddenTabsList}
          tabIndex={-1}
          role="menu"
          aria-label={menuPopupTitle}
        >
          {hiddenTabs.map((item, i) => (
            <button
              key={i}
              type="button"
              className={c.hiddenTab}
              onClick={() => redirect(getRouteFromPageIndex(pages, item.index))}
              role="menuitem"
              aria-label={`Navigate to ${item.name} page.`}
            >
              <span>{item.name}</span>
              {selectedPage === item.index ? <Icon.Check /> : null}
            </button>
          ))}
        </ul>
      </PositionAwareMenuV2>
    </div>
  );
});

export default HiddenTabsMenu;
