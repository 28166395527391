import throttle from 'lodash.throttle';
import React from 'react';
import {
  DndContext,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  KeyboardSensor,
  useSensor,
  useSensors,
  rectIntersection,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { capitaliseFirstWord } from '@monash/portal-frontend-common';

// DND: Constants
export const DND_TYPE = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  DEFAULT: 'DEFAULT', // 2D, both vertical and horizontal
  CUSTOM_WIDGETS: 'CUSTOM_WIDGETS', // Custom page widgets
};
const MIN_DND_SIZE = 40; // min size to trigger sensor updates
const MEASURING = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};
const ACTIVATION_CONSTRAINT = {
  delay: 150,
  tolerance: MIN_DND_SIZE,
};

// DND: SR Accessibility
const getScreenReaderInstructions = (staticSortableObjectName) => {
  const object = staticSortableObjectName || 'item';
  return {
    draggable: `To pick up a sortable ${object}, press the space bar. While sorting, use the arrow keys to move the ${object}. Press space again to drop the ${object} in its new position, or press escape to cancel.`,
  };
};
const getScreenReaderAnnouncements = (
  staticSortableObjectName,
  customAnnouncements
) => {
  return {
    onDragStart({ active }) {
      const totalItems = active.data.current.sortable.items.length;
      const activeItemPos = active.data.current.sortable.index + 1;
      return `Sorting started for ${
        staticSortableObjectName || 'object' + activeItemPos
      } at position: ${activeItemPos} of ${totalItems}`;
    },
    onDragOver({ active, over }) {
      // Notes: workaround for library defect with the first onDragOver event where
      // the 'active' and 'over' items are the same item
      if (active.id === over?.id) {
        return;
      }
      const totalItems = active.data.current.sortable.items.length;
      const activeItemPos = active.data.current.sortable.index + 1;
      const hasOverItem = over !== null;
      const overItemPos = hasOverItem
        ? over.data.current.sortable.index + 1
        : null;
      return `Sorting ${staticSortableObjectName || 'object' + activeItemPos}${
        hasOverItem
          ? ` has moved to position: ${overItemPos} of ${totalItems}.`
          : '.'
      }`;
    },
    onDragEnd({ active, over }) {
      const totalItems = active.data.current.sortable.items.length;
      const activeItemPos = active.data.current.sortable.index + 1;
      const hasOverItem = over !== null;
      const overItemPos = hasOverItem
        ? over.data.current.sortable.index + 1
        : null;
      return `Sorting ended for ${
        staticSortableObjectName || 'object' + activeItemPos
      }${
        hasOverItem
          ? `, has been dropped into position: ${overItemPos} of ${totalItems}.`
          : '.'
      }`;
    },
    onDragCancel({ active }) {
      const totalItems = active.data.current.sortable.items.length;
      const activeItemPos = active.data.current.sortable.index + 1;
      return `Sorting cancelled. ${
        capitaliseFirstWord(staticSortableObjectName) || 'Object'
      } was dropped and returned to position: ${activeItemPos} of ${totalItems}.`;
    },
    ...customAnnouncements,
  };
};

// DND: Coordinates Getters
const getCoordinatesGetterForHorizontalList = (e, args) => {
  switch (e.code) {
    case 'ArrowRight':
    case 'ArrowLeft':
      e.preventDefault();
      e.stopPropagation();
  }
  const { currentCoordinates } = args;
  const delta = MIN_DND_SIZE;
  switch (e.code) {
    case 'ArrowRight':
      return {
        ...currentCoordinates,
        x: currentCoordinates.x + delta,
      };
    case 'ArrowLeft':
      return {
        ...currentCoordinates,
        x: currentCoordinates.x - delta,
      };
  }
  return undefined;
};
const getCoordinatesGetterForVerticalList = (e, args) => {
  switch (e.code) {
    case 'ArrowDown':
    case 'ArrowUp':
      e.preventDefault();
      e.stopPropagation();
  }
  const { currentCoordinates } = args;
  const delta = MIN_DND_SIZE;
  switch (e.code) {
    case 'ArrowDown':
      return {
        ...currentCoordinates,
        y: currentCoordinates.y + delta,
      };
    case 'ArrowUp':
      return {
        ...currentCoordinates,
        y: currentCoordinates.y - delta,
      };
  }
  return undefined;
};
const getCoordinatesGetter = (e, args) => {
  switch (e.code) {
    case 'ArrowDown':
    case 'ArrowUp':
    case 'ArrowRight':
    case 'ArrowLeft':
      e.preventDefault();
      e.stopPropagation();
  }
  const { currentCoordinates } = args;
  const delta = MIN_DND_SIZE;
  switch (e.code) {
    case 'ArrowDown':
      return {
        ...currentCoordinates,
        y: currentCoordinates.y + delta,
      };
    case 'ArrowUp':
      return {
        ...currentCoordinates,
        y: currentCoordinates.y - delta,
      };
    case 'ArrowRight':
      return {
        ...currentCoordinates,
        x: currentCoordinates.x + delta,
      };
    case 'ArrowLeft':
      return {
        ...currentCoordinates,
        x: currentCoordinates.x - delta,
      };
  }
  return undefined;
};
const getCoordinateGetterByType = (type = DND_TYPE.DEFAULT) => {
  switch (type) {
    case DND_TYPE.HORIZONTAL:
      return getCoordinatesGetterForHorizontalList;
    case DND_TYPE.VERTICAL:
      return getCoordinatesGetterForVerticalList;
    default:
      return getCoordinatesGetter;
  }
};

// DND: Collision Detection
const throttledRectIntersection = throttle(rectIntersection, 70, {
  leading: true,
});
const getCollisionDetection = (dndType = DND_TYPE.DEFAULT) => {
  if (dndType === DND_TYPE.CUSTOM_WIDGETS) {
    // Note: use throttled collision detection to avoid high frequency UI flashing from the endless re-ordering loops
    return throttledRectIntersection;
  } else {
    return rectIntersection;
  }
};

const SortableDragAndDropWrapper = ({
  children,
  sortableItems,
  onDragStart,
  onDragOver,
  onDragEnd,
  onDragCancel,
  sortingStrategy,
  screenReaderAnnouncements,
  staticSortableObjectName,
  dndType = DND_TYPE.DEFAULT,
}) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: ACTIVATION_CONSTRAINT,
    }),
    useSensor(TouchSensor, {
      activationConstraint: ACTIVATION_CONSTRAINT,
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: getCoordinateGetterByType(dndType),
    })
  );

  return (
    <DndContext
      sensors={sensors}
      measuring={MEASURING}
      collisionDetection={getCollisionDetection(dndType)}
      modifiers={[restrictToParentElement]}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
      accessibility={{
        announcements: getScreenReaderAnnouncements(
          staticSortableObjectName,
          screenReaderAnnouncements
        ),
        screenReaderInstructions: getScreenReaderInstructions(
          staticSortableObjectName
        ),
      }}
    >
      <SortableContext items={sortableItems} strategy={sortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export default SortableDragAndDropWrapper;
