import { Icon } from '@monash/portal-react';
import c from './unit-colour-menu.module.scss';
import React, { forwardRef } from 'react';

const UnitColourMenuTrigger = forwardRef(
  ({ unitCode, unitColour, onClick, className, ...buttonProps }, ref) => {
    const unitCodeForScreenReader = unitCode.split('').join(' ');

    return (
      // Unit button that triggers colour palette menu to open
      <button
        type="button"
        ref={ref}
        className={className ? [c.unitRow, className].join(' ') : c.unitRow}
        onClick={onClick}
        {...buttonProps}
        data-tracking-event="schedule-unit-color-square"
      >
        <div className={c.unitCode}>
          <abbr aria-label={unitCodeForScreenReader}>{unitCode}</abbr>
        </div>
        <div
          className={c.colourSquare}
          style={{ backgroundColor: unitColour }}
        />
        <Icon.ChevronRight />
      </button>
    );
  }
);

export default UnitColourMenuTrigger;
