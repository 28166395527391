// Rainbow palette colours, translated from hex to name using https://www.color-name.com/
export const COLOUR_NAMES = [
  'Sky Blue',
  'Sea Foam',
  'Lilac',
  'Light Ochre',
  'Pale Coral',
  'Pale Grey',
  'Aquamarine',
  'Teal',
  'Violet',
  'Turmeric',
  'Red',
  'Mid Grey',
  'Neon Blue',
  'Neon Green',
  'Neon Pink',
  'Yellow',
  'Scarlet',
  'Black',
];
