import React from 'react';
import { Icon, IconButton } from '@monash/portal-react';
import { sub, add } from 'date-fns';
export const CalendarControls = ({
  numberOfDisplayDays,
  selectedDay,
  setSelectedDay,
}) => {
  const previousDay = sub(selectedDay, { days: numberOfDisplayDays });
  const nextDay = add(selectedDay, { days: numberOfDisplayDays });
  return (
    <>
      <IconButton
        onClick={(e) => {
          setSelectedDay(previousDay);
        }}
        mode="canvas"
        icon={Icon.ChevronLeft}
        aria-label={
          'Previous ' +
          (numberOfDisplayDays === 1 ? 'day' : numberOfDisplayDays + ' days')
        }
        data-tracking-event="schedule-previous-days"
      />
      <IconButton
        onClick={(e) => {
          setSelectedDay(nextDay);
        }}
        mode="canvas"
        icon={Icon.ChevronRight}
        aria-label={
          'Next ' +
          (numberOfDisplayDays === 1 ? 'day' : numberOfDisplayDays + ' days')
        }
        data-tracking-event="schedule-next-days"
      />
    </>
  );
};

export default CalendarControls;
