import { InlineExternalLink } from '@monash/portal-react';

export const MISSING_CLASSES_ERROR = (
  <>
    We're having trouble retrieving your classes right now. In the meantime, you
    can check your classes in{' '}
    <InlineExternalLink
      text="Allocate+"
      link="https://www.monash.edu/students/admin/timetables/allocate/login"
    />
  </>
);

export const MISSING_EXAMS_ERROR = (
  <>
    We're having trouble retrieving your scheduled assessments right now. In the
    meantime, you can check your assessments in{' '}
    <InlineExternalLink
      text="Allocate+"
      link="https://www.monash.edu/students/admin/timetables/allocate/login"
    />
  </>
);

export const MISSING_CLASSES_AND_EXAMS_ERROR = (
  <>
    We're having trouble retrieving your classes and assessments right now. In
    the meantime, you can check{' '}
    <InlineExternalLink
      text="Allocate+"
      link="https://www.monash.edu/students/admin/timetables/allocate/login"
    />
  </>
);

export const MISSING_CLASSES_EXAMS_ASSESSMENTS_ERROR = (
  <>
    We're having trouble retrieving your classes and assessments right now. In
    the meantime, you can check{' '}
    <InlineExternalLink text="Moodle" link="https://learning.monash.edu/" /> and{' '}
    <InlineExternalLink
      text="Allocate+"
      link="https://www.monash.edu/students/admin/timetables/allocate/login"
    />
  </>
);

export const MISSING_SOME_ASSESSMENTS_ERROR = (
  <>
    Assessments may not be up to date, check{' '}
    <InlineExternalLink text="Moodle" link="https://learning.monash.edu/" />
  </>
);

export const MISSING_ALL_ASSESSMENTS_ERROR = (
  <>
    We're having trouble retrieving your assessments right now. In the meantime,
    you can check your assessments in{' '}
    <InlineExternalLink text="Moodle" link="https://learning.monash.edu/" />
  </>
);

export const NO_UNIT_COLOURS_ERROR = (
  <>
    We're having trouble retrieving unit colours right now - please try again
    later
  </>
);

export const UPDATE_UNIT_COLOURS_ERROR = (
  <>
    We're having trouble saving your unit colour preferences right now - please
    try again later
  </>
);
