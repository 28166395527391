import { format, isDate } from 'date-fns';
import { isNumber } from '@monash/portal-frontend-common';

const INS_DAY_CELL_KEY_NAV = 'Use the arrow keys to navigate.';

/**
 * getDayCellScreenReaderMsg
 * @param {Date} date
 * @param {?number} eventsCount
 * @param {boolean} includeEvents
 * @param {boolean} includeInstructions
 * @returns {string}
 */
export const getDayCellScreenReaderMsg = (
  date = null,
  eventsCount = 0,
  includeEvents = false,
  includeInstructions = false
) => {
  if (!isDate(date)) return '';
  const dateMsg = format(date, 'EEEE, d MMMM yyyy.');
  const insMsg = includeInstructions ? ` ${INS_DAY_CELL_KEY_NAV}` : '';
  if (includeEvents) {
    eventsCount = isNumber(eventsCount) ? eventsCount : 0;
    const eventsMsg =
      ' ' +
      (eventsCount > 0
        ? eventsCount > 1
          ? `${eventsCount} events.`
          : `${eventsCount} event.`
        : 'No event.');
    return `${dateMsg}${eventsMsg}${insMsg}`;
  } else {
    return `${dateMsg}${insMsg}`;
  }
};
