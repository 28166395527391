/**
 * Util to generate and append unique id's to course objects in the case of duplicate course codes returned from academic results API.
 *
 * Rationale:
 * To store the user's selected GPA widget course option in firestore, we need a unique id to associate with each course.
 * It's necessary to generate these id's because the academic results api doesn't return any unique identifier for courses.
 * For example, it's possible for a student to have completed a course with course code 'B2000' multiple times, so the combination of course code
 * and status is not guaranteed to be unique.
 *
 * @param courses array course objects in the form returned by academic results api, sorted by priority
 * @returns same array of course objects, but each with an id attribute
 */
export const appendUniqueIdsToCourses = (courses) => {
  if (!Array.isArray(courses) || courses.length === 0) return [];

  const indexes = {}; // dictionary to keep track of how many times a certain course code has appeared in the array

  // appending indexes to course codes in reverse order,
  // i.e. from oldest to most recent / lowest priority to highest priority,
  // b/c this makes it less likely for the user's saved option to be "desynced"
  // if they enrol in a new course
  const coursesReversedOrder = Array.from(courses).reverse();

  const coursesWithIds = coursesReversedOrder.map((course) => {
    const courseCode = course.courseCode;

    // if no duplicate has been found for this course code yet,
    // set its id to its course code, and initialise the course code in the list of duplicates
    if (!Object.prototype.hasOwnProperty.call(indexes, courseCode)) {
      indexes[courseCode] = 0;
      return {
        ...course,
        id: courseCode,
      };
    }

    // if the course code exists in the list of duplicates,
    // append the corresponding index to generate the id, and increment its index in the list of duplicates
    const id = `${courseCode}-${indexes[courseCode]}`;
    indexes[courseCode] = indexes[courseCode] + 1;
    return {
      ...course,
      id,
    };
  });

  // reverse back to original order before returning
  return coursesWithIds.reverse();
};
