import React, { useContext, useState } from 'react';
import { nanoid } from 'nanoid';
import { Icon, RouterState } from '@monash/portal-react';
import {
  AccessibilityContext,
  ImpersonationContext,
  fsDocRef,
  fsGetDoc,
  fsWriteBatch,
} from '@monash/portal-frontend-common';
import { Data } from 'components/providers/data-provider/DataProvider';
import { Page } from 'components/providers/page-provider/PageProvider';
import { useSnackbar } from 'components/providers/SnackbarProvider';
import { MAX_PAGES_NUMBER } from '../../../../../constants';
import { ID_SR_PAGE_HEADING } from '../../../../ui/main/Main';
import {
  getDuplicatedPageName,
  transformPageNameForURL,
} from 'components/utilities/pages';
import Menu from 'components/ui/menu/Menu';
import DeletePageModal from './delete-page-modal/DeletePageModal';

const SettingsMenu = ({ pageId, pageData, setIsRenaming }) => {
  const { allPages, pagesData } = useContext(Page);
  const { resetAppLiveMsgs } = useContext(AccessibilityContext);
  const { currentUser } = useContext(ImpersonationContext);
  const { redirect } = useContext(RouterState);
  const { setPortalPreferences } = useContext(Data);
  const [isDeletingPage, setIsDeletingPage] = useState(false);
  const { addSnackbar } = useSnackbar();

  // TODO: handle error
  const duplicateWidgetData = async (widgetId, newWidgetId, batch) => {
    const getWidgetDataDocPath = (id) =>
      `users/${currentUser.uid}/widgets/${id}`;

    // get widget data
    const widgetDoc = await fsGetDoc(getWidgetDataDocPath(widgetId));
    const widgetData = widgetDoc.data();
    if (widgetData) {
      // queue new widget data doc with duplicated data
      const doc = fsDocRef(getWidgetDataDocPath(newWidgetId));
      batch.set(doc, widgetData);
    }
  };

  // rename
  const renamePage = () => {
    setIsRenaming(true);
  };

  // duplicate
  const duplicatePage = async () => {
    // TODO: reached max number of pages messaging
    if (allPages.length >= MAX_PAGES_NUMBER) return;
    const batch = fsWriteBatch();

    // widgets
    const duplicatedWidgets = {};
    const newWidgetDocQueue = pageData?.widgetOrder.map((widgetId) => {
      const newWidgetId = nanoid();
      // widgets in pages doc
      duplicatedWidgets[newWidgetId] = pagesData.widgets[widgetId];
      // widgets in sub-collection
      return duplicateWidgetData(widgetId, newWidgetId, batch);
    });
    await Promise.all(newWidgetDocQueue);

    // page
    const newPageId = nanoid();
    const newPageName = getDuplicatedPageName(allPages, pageData.name);
    const newWidgetOrder = Object.keys(duplicatedWidgets);
    const newPage = {
      [newPageId]: {
        name: newPageName,
        widgetOrder: newWidgetOrder,
      },
    };

    // pages
    const newPages = { ...pagesData };
    newPages.customPages = { ...newPages.customPages, ...newPage };
    newPages.widgets = { ...newPages.widgets, ...duplicatedWidgets };

    const newPageOrder = [...newPages.pageOrder];
    const pageIndex = newPageOrder.indexOf(pageId);
    newPageOrder.splice(pageIndex + 1, 0, newPageId);
    newPages.pageOrder = newPageOrder;

    // update pages
    const preferencesDoc = fsDocRef(`users/${currentUser.uid}`);
    batch.update(preferencesDoc, {
      'preferences.pages': newPages,
    });

    batch
      .commit()
      .then(() =>
        handleUpdateSuccess({
          newPages,
          newPageName,
          originalPageName: pageData.name,
        })
      )
      .catch(handleUpdateError)
      .finally(() => {
        document.querySelector(`#${ID_SR_PAGE_HEADING}`)?.focus();
      });
  };

  const handleUpdateSuccess = ({
    newPages,
    newPageName,
    originalPageName,
  } = {}) => {
    setPortalPreferences((f) => {
      resetAppLiveMsgs();
      addSnackbar({
        message: `${originalPageName} page has been duplicated.`,
        type: 'success',
      });
      return { ...f, pages: newPages };
    });
    redirect(`/page/${transformPageNameForURL(newPageName)}`, {
      ignorePageHeadingFocus: true,
    });
  };

  const handleUpdateError = (error) => {
    resetAppLiveMsgs();
    addSnackbar({
      message: "We can't duplicate the page right now – please try again later",
      type: 'error',
    });
    console.warn(
      '[updatePortalPreferences]: api call error, failed to duplicate page',
      error
    );
  };

  const menuItems = [
    {
      icon: <Icon.Pencil size={20} />,
      text: 'Rename page',
      function: renamePage,
      trackingLabel: 'custom-rename-page',
    },
    {
      icon: <Icon.Copy size={20} />,
      text: 'Duplicate page',
      function: duplicatePage,
      trackingLabel: 'custom-duplicate-page',
    },
    {
      icon: <Icon.Trash size={20} />,
      text: 'Delete page',
      haspopup: 'dialog',
      attention: true,
      function: () => {
        setIsDeletingPage(true);
      },
      trackingLabel: 'custom-delete-page',
    },
  ];

  return (
    <>
      <DeletePageModal
        open={isDeletingPage}
        setOpen={setIsDeletingPage}
        pageId={pageId}
        pageData={pageData}
      />
      <Menu
        items={menuItems}
        buttonVariant="icon"
        ariaLabel="Page settings"
        mode="canvas"
        icon={Icon.DotsHorizontal}
        trackingLabel="custom-page-setting"
      />
    </>
  );
};

export default SettingsMenu;
