import './App.scss';
import React, { useContext, useEffect } from 'react';
import {
  Routes,
  Route,
  SkipToContent,
  SkipToContentItem,
  VirtuallyHidden,
} from '@monash/portal-react';
import * as Sentry from '@sentry/browser';
import Nav from 'components/ui/nav/Nav';
import Main from 'components/ui/main/Main';
import ErrorScreen from 'components/utilities/error/ErrorScreen';
import Notification from 'components/ui/notification/Notification';
import { getTestDate } from 'components/providers/data-provider/utils';
import { format } from 'date-fns';
import SearchResult from 'components/pages/search/SearchResult';
import { ImpersonationContext } from '@monash/portal-frontend-common';
import { Data } from 'components/providers/data-provider/DataProvider';
import PortalPreferencesErrorModal from 'components/ui/portal-preferences-error-modal/PortalPreferencesErrorModal';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import GenericError from 'components/utilities/error/GenericError';

const App = () => {
  const { currentUser } = useContext(ImpersonationContext);
  const { errors } = useContext(Data);
  const testDate = getTestDate();

  useEffect(() => {
    Sentry.setUser({ id: currentUser?.uid });
  }, []);

  if (!currentUser?.successfulIdentityResponse) {
    return (
      <ErrorScreen
        heading="Access Denied"
        message="Sorry, you don't have permission to access this application"
        illustration="brokenFlowers"
      />
    );
  }

  return (
    <SentryErrorBoundary fallback={<GenericError />}>
      <div className="app">
        <SkipToContent>
          <SkipToContentItem text="Top navigation" skipTo="#topNav" />
          <SkipToContentItem text="Content" skipTo="#contentContainer" />
        </SkipToContent>

        <Nav />

        <div className="app-content-wrapper">
          <Routes matchFirstOnly>
            <Route to="/search" routeProps>
              <VirtuallyHidden element="h1" text="Search" />
              <SearchResult />
            </Route>

            <Route to="/preview">
              <Main />
            </Route>

            <Route to="/">
              <Main />
            </Route>
          </Routes>

          {testDate && (
            <p className="testDate" aria-hidden="true">
              {format(testDate, 'EEEE do MMMM y, h.mmaaa')}
            </p>
          )}

          {/* The modal animates twice when using the `open` prop conditionally */}
          {Boolean(errors.portalPreferences) && (
            <PortalPreferencesErrorModal open={true} />
          )}

          {currentUser.hasEncumbrances && <Notification type="encumbrance" />}
        </div>
      </div>
    </SentryErrorBoundary>
  );
};

export default App;
