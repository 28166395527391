import React, { useState, useEffect } from 'react';
import { Button } from '@monash/portal-react';
import { useSnackbar } from 'components/providers/SnackbarProvider';

import c from './popup.module.scss';

const SNACKBAR_OPTIONS = ['error', 'success', 'warning', 'info'];

const MESSAGES = {
  SUCCESS: [
    'You have successfully completed the task',
    'Your changes have been saved',
    'Your profile has been updated',
  ],
  ERROR: [
    'Oops, something went wrong',
    "Oops, you've reached the custom page limit (67/67)",
    'Uh oh, we couldn’t add a new page. Please try again later',
    'Oops, couldn’t duplicate page. Please try again later',
    'Can’t add widget – try again later',
  ],
  WARNING: [
    'Your session is about to expire',
    'Changes may not be saved if you leave the page',
  ],
  INFO: [
    'Item deleted successfully',
    'You have 1 new notification',
    'You have 3 new messages',
  ],
};

export const SnackbarDispatcher = () => {
  const { addSnackbar } = useSnackbar();

  const [type, setType] = useState(SNACKBAR_OPTIONS[0]);
  const [message, setMessage] = useState(MESSAGES[type.toUpperCase()][0]);
  const [duration, setDuration] = useState(3000);

  useEffect(() => {
    setMessage(MESSAGES[type.toUpperCase()][0]);
  }, [type]);

  return (
    <div className={c.formGroup}>
      <div className={`${c.radioGroup} ${c.inlineGroup}`}>
        <label className={c.inlineLabel}>Type</label>
        <div className={c.radioInputs}>
          {SNACKBAR_OPTIONS.map((option, index) => {
            return (
              <div className={c.radioInput} key={index}>
                <label htmlFor={`test-harness-ui-input-snackbar-type-${index}`}>
                  {option}
                </label>

                <input
                  id={`test-harness-ui-input-snackbar-type-${index}`}
                  type="radio"
                  name="snackbar-type"
                  value={option}
                  checked={type === option}
                  onChange={(e) => setType(e.target.value)}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={c.inlineGroup}>
        <label
          className={c.inlineLabel}
          htmlFor="test-harness-ui-input-snackbar-message"
        >
          Message
        </label>

        <input
          id="test-harness-ui-input-snackbar-message"
          className={c.fullWidthInput}
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>

      <div className={c.inlineGroup}>
        <label
          className={c.inlineLabel}
          htmlFor="test-harness-ui-input-snackbar-duration"
        >
          Duration
        </label>

        <input
          id="test-harness-ui-input-snackbar-duration"
          type="number"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
        />
      </div>

      <div className={c.formFooter}>
        <Button
          size="medium"
          onClick={() =>
            addSnackbar({
              message,
              type,
              duration,
            })
          }
        >
          Dispatch
        </Button>

        <Button
          size="medium"
          variant="text"
          onClick={() => {
            const randomType =
              SNACKBAR_OPTIONS[
                Math.floor(Math.random() * SNACKBAR_OPTIONS.length)
              ];

            const randomMessage = MESSAGES[randomType.toUpperCase()];
            const messageIndex = Math.floor(
              Math.random() * randomMessage.length
            );

            addSnackbar({
              message: randomMessage[messageIndex],
              type: randomType,
              duration,
            });
          }}
        >
          Randomise
        </Button>
      </div>
    </div>
  );
};
