import { transformPageNameForURL } from './utils';

export const getNewPageName = (pages) => {
  const transformedPageNames = pages
    ?.filter((page) => page.custom)
    .map((page) => transformPageNameForURL(page.name));

  // filter out list of untitled pages in the specific format UNTITLED (x)
  const numberedUntitledPageRegex = /^untitled-\([1-9][0-9]*\)$/g;
  const numberedUntitledNames = transformedPageNames.filter((name) =>
    name.match(numberedUntitledPageRegex)
  );

  // find list of numbers in the bracket
  const sortedNumberUntitledPageNumbers = numberedUntitledNames
    .map((name) => {
      const bracketRegex = /\((.*?)\)/;
      return bracketRegex.exec(name)[1];
    })
    .sort((a, b) => a - b);

  const nextLabel = parseInt(sortedNumberUntitledPageNumbers.slice(-1)) + 1;

  // if the "UNTITLED" page does not exist
  if (!transformedPageNames.includes('untitled')) {
    return 'UNTITLED';
    // if "UNTITLED" page exists and there is no numbered untitled pages
  } else if (sortedNumberUntitledPageNumbers.length === 0) {
    return `UNTITLED (1)`;
  } else return `UNTITLED (${nextLabel})`;
};
