import { Data } from 'components/providers/data-provider/DataProvider';
import { isSameDay } from 'date-fns';
import React, { useContext, useMemo } from 'react';
import ScheduleItem from '../../../schedule-item/ScheduleItem';
import { calculateGridColumnValue } from '../../utils';
import { getCurrentDateRange, hasOverlap } from './utils';
import c from './collapsed-multi-day-events.module.scss';

const CollapsedMultiDayEvents = ({
  numberOfDisplayDays,
  selectedDay,
  openScheduleCard,
}) => {
  const { userEvents } = useContext(Data);
  const dateRange = getCurrentDateRange(numberOfDisplayDays, selectedDay);

  const multiDayEvents = userEvents
    .filter((event) => !isSameDay(event.start.time, event.end.time))
    .filter((event) =>
      hasOverlap({ start: event.start.time, end: event.end.time }, dateRange)
    );

  const refs = useMemo(
    () => multiDayEvents?.map(() => React.createRef()),
    [multiDayEvents]
  );

  return (
    <div className={c.collapsedMultiDayEvents}>
      {multiDayEvents.map((event, index) => {
        const unroundedLeft = event.start.time < dateRange.start;
        const unroundedRight = event.end.time > dateRange.end;

        const gridColumn = calculateGridColumnValue(
          dateRange,
          event,
          numberOfDisplayDays
        );

        return (
          <div className={c.outerWrapper} key={event.uuid}>
            <div className={c.innerWrapper}>
              <button
                type="button"
                className={c.button}
                ref={refs[index]}
                style={{
                  gridColumn,
                }}
                onClick={() => {
                  openScheduleCard(refs[index], {
                    ...event,
                    hideDate: true,
                  });
                }}
              >
                <ScheduleItem
                  item={event}
                  expanded={false}
                  unroundedLeft={unroundedLeft}
                  unroundedRight={unroundedRight}
                  isCollapsedMultiDay
                />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CollapsedMultiDayEvents;
