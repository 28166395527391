import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const id = (x) => {
  return x;
};

const useWindowSize = (options = {}) => {
  const { delay = 200, useDebounce = true } = options;
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });

  const finalFn = useDebounce ? debounce : id;

  const handleSize = finalFn(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, delay);

  useEffect(() => {
    handleSize();

    window.addEventListener('resize', handleSize);

    return () => window.removeEventListener('resize', handleSize);
  }, []);

  return windowSize;
};

export default useWindowSize;
