// TPs from mock data below must be first added here
export const mockCurrentTPs = [
  'S1-01',
  'FY-32',
  'T1-58',
  'AUG-12',
  'TRI-A-29',
  'S2-32',
  'SSB-01',
  'WS-01',
  'MO-TP6-01',
];

// Mock data is based on API endpoint: https://home.student-dev.monash/api/appResources/keyDates
export default [
  {
    teaching_period: mockCurrentTPs[0],
    teaching_period_desc: 'Semester one',
    start: new Date('Aug 17 2022 11:00').getTime(),
    end: new Date('Aug 17 2022 11:59').getTime(),
    title: 'Cut off for enrolment',
    desc: 'Cut off for enrolling in a course',
  },
  {
    teaching_period: mockCurrentTPs[0],
    teaching_period_desc: 'Semester one',
    start: new Date('Aug 23 2022 11:00').getTime(),
    end: new Date('Aug 24 2022 10:59').getTime(),
    title: 'Allocate+ preference entry opens',
    desc: '10am for Semester 1',
  },
  {
    teaching_period: mockCurrentTPs[1],
    teaching_period_desc: 'Full-year (extended)',
    start: new Date('Aug 24 2022 11:00').getTime(),
    end: new Date('Aug 25 2022 10:59').getTime(),
    title: 'Full-year (extended) starts',
    desc: 'Teaching period starts for Full-year (extended) FY-32',
  },
  {
    teaching_period: mockCurrentTPs[2],
    teaching_period_desc: 'Trimester 1',
    start: new Date('Aug 25 2022 11:00').getTime(),
    end: new Date('Aug 26 2022 10:59').getTime(),
    title: 'Trimester 1 results for Law units only',
    desc: 'Result released for T1-58 Trimester 1 for Law units only',
  },
  {
    teaching_period: mockCurrentTPs[3],
    teaching_period_desc: 'August intake – Australia',
    start: new Date('Aug 25 2022 11:00').getTime(),
    end: new Date('Aug 26 2022 10:59').getTime(),
    title: 'Final assessment period ends (November intake – Australia)',
    desc: 'final assessment period ends',
  },
  {
    teaching_period: mockCurrentTPs[4],
    teaching_period_desc: 'Trimester A',
    start: new Date('Aug 26 2022 11:00').getTime(),
    end: new Date('Aug 27 2022 10:59').getTime(),
    title:
      'Last day to withdraw from units without academic penalty (trimester A)',
    desc: "You'll receive a Withdrawn Fail grade on your academic record if you withdraw from a unit after this date.",
  },
  {
    teaching_period: mockCurrentTPs[5],
    teaching_period_desc: 'Semester 2 (extended)',
    start: new Date('Aug 27 2022 11:00').getTime(),
    end: new Date('Aug 28 2022 10:59').getTime(),
    title:
      'Deferred and supplementary assessment period (semester 2 – extended)',
    desc: 'Deferred and supplementary assessment period Starts',
  },
  {
    teaching_period: mockCurrentTPs[6],
    teaching_period_desc: 'Summer semester B',
    start: new Date('Aug 27 2022 11:00').getTime(),
    end: new Date('Aug 28 2022 10:59').getTime(),
    title: 'Census date for summer semester B',
    desc: 'Last day to withdraw from units without incurring fees',
  },
  {
    teaching_period: mockCurrentTPs[7],
    teaching_period_desc: 'Winter semester',
    start: new Date('Aug 27 2022 11:00').getTime(),
    end: new Date('Aug 28 2022 10:59').getTime(),
    title: "University closed: New Year's Day ",
    desc: 'Monash Connect, libraries and on-campus facilities (like study spaces) will be closed.',
  },
  {
    teaching_period: mockCurrentTPs[8],
    teaching_period_desc: 'Monash Online 6',
    start: new Date('Aug 28 2022 11:00').getTime(),
    end: new Date('Aug 29 2022 10:59').getTime(),
    title: 'Course fees  ',
    desc: 'payment due date',
  },
  {
    teaching_period: mockCurrentTPs[8],
    teaching_period_desc: 'Monash Online 7',
    start: new Date('Sep 19 2022 11:00').getTime(),
    end: new Date('Sep 20 2022 10:59').getTime(),
    title: 'Course fees  ',
    desc: 'payment due date',
  },
  {
    teaching_period: mockCurrentTPs[8],
    teaching_period_desc: 'Monash Online 6',
    start: new Date('Nov 10 2022 11:00').getTime(),
    end: new Date('Nov 11 2022 10:59').getTime(),
    title: 'Post semester key date',
    desc: 'Post semester key date',
  },
];
