import { formatLocation } from 'components/providers/data-provider/utils';
import { capitaliseAllWords } from '@monash/portal-frontend-common';

export const getLocation = (location) => {
  if (location) {
    const { locationKind, data } = location;

    switch (locationKind) {
      case 'details':
        return formatLocation(
          Array.isArray(data) ? location.data[0] : location.data
        );
      case 'online':
        return 'Online';
      case 'unknown':
        return capitaliseAllWords(data.description);
      default:
        return '–';
    }
  }
};
