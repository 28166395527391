const removeBlankValues = (obj) => {
  const newObject = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (value !== undefined && value !== null) {
      newObject[key] = value;
    }
  });
  return newObject;
};

export const combineThemeWithCustomThemeVariables = (
  theme,
  customThemeVariables
) => {
  const applied = removeBlankValues({
    '--canvas-bg-color': customThemeVariables.background,
    '--card-bg-color': customThemeVariables.cardColor,
    '--card-cta-bg-color': customThemeVariables.cardCtaColor,
    '--card-text-color': customThemeVariables.textColor,
    '--canvas-text-color': customThemeVariables.headingColor,
    '--canvas-cta-text-color': customThemeVariables.canvasCtaTextColor,
    '--canvas-cta-bg-color': customThemeVariables.headingColor,
  });

  return {
    ...theme,
    variables: {
      ...theme?.variables,
      ...applied,
    },
  };
};
