export const BACKGROUND_TYPE = {
  COLOUR: 'COLOUR',
  GRADIENT: 'GRADIENT',
};

const backgroundsList = [
  {
    name: 'Dark Cornflower Blue',
    value: '#204f88',
    text: '#204f88',
    type: BACKGROUND_TYPE.COLOUR,
  },
  {
    name: 'Eerie Black',
    value: '#1C1C1C',
    text: '#1C1C1C',
    type: BACKGROUND_TYPE.COLOUR,
  },
  {
    name: 'White Smoke',
    value: '#f4f4f4',
    text: '#f4f4f4',
    type: BACKGROUND_TYPE.COLOUR,
  },
  {
    name: 'Ming Green',
    value: '#2D6C7A',
    text: '#2D6C7A',
    type: BACKGROUND_TYPE.COLOUR,
  },
  {
    name: 'Granny Smith Apple',
    value: '#99e5a0',
    text: '#99e5a0',
    type: BACKGROUND_TYPE.COLOUR,
  },
  {
    name: 'Salmon',
    value: '#f67d6e',
    text: '#f67d6e',
    type: BACKGROUND_TYPE.COLOUR,
  },
  {
    name: 'American Red',
    value: '#ba1836',
    text: '#ba1836',
    type: BACKGROUND_TYPE.COLOUR,
  },
  {
    name: 'Orchid Pink',
    value: '#f0c2d5',
    text: '#f0c2d5',
    type: BACKGROUND_TYPE.COLOUR,
  },
  {
    name: 'Celtic Blue Gradient',
    value: 'linear-gradient(180deg, #2C6CBA 0%, #183C67 100%)',
    text: '#2C6CBA',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'Independence Blue Gradient',
    value: 'linear-gradient(to right, #485563, #29323c)',
    text: '#485563',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'Dark Bronze Gradient',
    value: 'linear-gradient(to right, #414d0b, #727a17)',
    text: '#414d0b',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'Amaranth Red Gradient',
    value: 'linear-gradient(to right, #eb3349, #f45c43)',
    text: '#eb3349',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'Light Carmine Pink Gradient',
    value: 'linear-gradient(#e66465, #9198e5)',
    text: '#e66465',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'Mikado Yellow Gradient',
    value:
      'radial-gradient(circle 957px at 8.7% 50.5%, #F6BF0D 0%, #F92F2F 90%)',
    text: '#F6BF0D',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'Vivid Tangerine Gradient',
    value: 'linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)',
    text: '#FF9A8B',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'Sea Serpent Blue Gradient',
    value:
      'linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%)',
    text: '#eea2a2',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'Royal Purple Gradient',
    value: 'linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%)',
    text: '#FF3CAC',
    type: BACKGROUND_TYPE.GRADIENT,
  },
  {
    name: 'French Pink Gradient',
    value: 'linear-gradient(to right, #fa709a 0%, #fee140 100%)',
    text: '#fa709a',
    type: BACKGROUND_TYPE.GRADIENT,
  },
];

export default backgroundsList;
