import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { isDomNodeType } from '@monash/portal-frontend-common';

const INITIAL_INDEX = -1;

export const useMenuFocus = ({
  // refs
  triggerRef,
  menuWrapperRef,
  itemNodes,
  // configs
  expandOnArrowKeys = false,
  preventDefaultOnArrowKeys = true,
}) => {
  const [isShown, setIsShown] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState(INITIAL_INDEX);
  const menuItemCount = itemNodes?.length;

  const handleKeyDown = (e) => {
    if (!isShown) {
      if (
        expandOnArrowKeys &&
        (e.code === 'ArrowDown' || (e.code === 'ArrowRight' && !e.shiftKey))
      ) {
        preventDefaultOnArrowKeys && e.preventDefault();
        flushSync(() => {
          setIsShown(true);
        });
        setFocusedItemIndex(INITIAL_INDEX);
      }
    } else {
      if (e.code === 'Tab') {
        setIsShown(false);
      }

      if (e.code === 'Escape') {
        setIsShown(false);
        triggerRef?.current?.focus();
      }

      if (e.code === 'ArrowUp' || (e.code === 'ArrowLeft' && !e.shiftKey)) {
        preventDefaultOnArrowKeys && e.preventDefault();
        const targetIndex =
          focusedItemIndex <= 0 ? menuItemCount - 1 : focusedItemIndex - 1;
        setFocusedItemIndex(targetIndex);
        itemNodes?.[targetIndex]?.focus();
      }

      if (e.code === 'ArrowDown' || (e.code === 'ArrowRight' && !e.shiftKey)) {
        preventDefaultOnArrowKeys && e.preventDefault();
        const targetIndex =
          focusedItemIndex >= menuItemCount - 1 ? 0 : focusedItemIndex + 1;
        setFocusedItemIndex(targetIndex);
        itemNodes?.[targetIndex]?.focus();
      }
    }
  };

  useEffect(() => {
    if (isShown && isDomNodeType(menuWrapperRef?.current)) {
      menuWrapperRef.current.focus();
    }
    if (!isShown) {
      setFocusedItemIndex(INITIAL_INDEX);
    }
  }, [isShown]);

  return {
    handleKeyDown,
    isShown,
    setIsShown,
    focusedItemIndex,
    setFocusedItemIndex,
  };
};
